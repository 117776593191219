import React from 'react';

const SlippageBtn = ({value,changeSlippage}) => {
  return (
    <div>
        <button onClick={(e)=>changeSlippage(e)} value={value}>{value}%</button>
    </div>
    );
};

export default SlippageBtn;
