import { createAction, handleAction, handleActions } from 'redux-actions';
import produce from 'immer';

const initialState = {
    account: "",
    connectStatus: false,
    chainId: 0,
    userSlippageTolerance: 0.5,
    deadline: 1200,
    farmInterval: "",
    stakingInterval: "",
    transactionHash:"",
};

const SETACCOUNTADDRESS = "user/SETACCOUNTADDRESS";
export const setAccountAddress = createAction(SETACCOUNTADDRESS, (user) => user);

const SETCONNECTSTATUS = "user/SETCONNECTSTATUS";
export const setConnectStatus = createAction(SETCONNECTSTATUS, (user) => user);

const UPDATEUSERSLIPPAGETOLERANCE = "user/UPDATEUSERSLIPPAGETOLERANCE";
export const setUpdateUserSlippageTolerance = createAction(UPDATEUSERSLIPPAGETOLERANCE, (slippage) => slippage);

const UPDATEUSERDEADLINE = "user/UPDATEUSERDEADLINE";
export const setUpdateUserDeadLine = createAction(UPDATEUSERDEADLINE, (deadline) => deadline);

const SETFARMINTERVAL = "user/SETFARMINTERVAL";
export const setFarmInterval = createAction(SETFARMINTERVAL, (input) => input);

const SETSTAKINGINTERVAL = "user/SETSTAKINGINTERVAL";
export const setStakingInterval = createAction(SETSTAKINGINTERVAL, (input) => input);

const SETCHAINID = "user/SETCHAINID";
export const setChainId = createAction(SETCHAINID, (input)=>input);

const SETTRANSACTIONHASH = "user/SETTRANSACTIONHASH";
export const setTransactionHash = createAction(SETTRANSACTIONHASH, (input)=>input);

const user = handleActions(
    {
        [SETACCOUNTADDRESS]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.account = input
            }),
        [SETCONNECTSTATUS]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.connectStatus = input;
            }),
        [UPDATEUSERSLIPPAGETOLERANCE]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.userSlippageTolerance = input;
            }),
        [UPDATEUSERDEADLINE]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.deadline = input;
            }),
        [SETFARMINTERVAL]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.farmInterval = input
            }),
        [SETSTAKINGINTERVAL]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.stakingInterval = input
            }),
        [SETCHAINID]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.chainId = input
            }),
        [SETTRANSACTIONHASH]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.transactionHash = input
            }),

    }, initialState
);

export default user;