import React from 'react'

const RemoveBtns = ({ signature, signatureStatus,onClickRemove,removeStatus}) => {
    return (
        <div>
            <button disabled={signatureStatus} onClick={() => signature()}>Signature</button>
            <button disabled={removeStatus} onClick={()=>onClickRemove()}>Remove</button>
        </div>
    )
}

export default RemoveBtns