export const noticeLists = [
    {
        title: "title1",
        postedDate: "2022/08/01",
        description: "내용~~~~~~~~~~~~~~~~~~~"
    },
    {
        title: "title2",
        postedDate: "2022/08/02",
        description: "내용~~~~~~~~~~~~~~~~~~~"
    },
    {
        title: "title3",
        postedDate: "2022/08/03",
        description: "내용~~~~~~~~~~~~~~~~~~~"
    },
    {
        title: "title4",
        postedDate: "2022/08/04",
        description: "내용~~~~~~~~~~~~~~~~~~~"
    },
    {
        title: "title5",
        postedDate: "2022/08/05",
        description: "내용~~~~~~~~~~~~~~~~~~~"
    },
    {
        title: "title6",
        postedDate: "2022/08/06",
        description: "내용~~~~~~~~~~~~~~~~~~~"
    },
    {
        title: "title7",
        postedDate: "2022/08/07",
        description: "내용~~~~~~~~~~~~~~~~~~~"
    },
    {
        title: "title8",
        postedDate: "2022/08/08",
        description: "내용~~~~~~~~~~~~~~~~~~~"
    },
    {
        title: "title9",
        postedDate: "title9",
        postedDate: "2022/08/09",
        description: "내용~~~~~~~~~~~~~~~~~~~"
    },
    {
        title: "title10",
        postedDate: "2022/08/10",
        description: "내용~~~~~~~~~~~~~~~~~~~"
    },
    {
        title: "title11",
        postedDate: "2022/08/11",
        description: "내용~~~~~~~~~~~~~~~~~~~"
    },
    {
        title: "title12",
        postedDate: "2022/08/12",
        description: "내용~~~~~~~~~~~~~~~~~~~"
    },
]