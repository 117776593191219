import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Web3 from 'web3';
import Amount from '../containers/Amount';
import { LpTokenInYourWallet } from '../containers/LpTokenInYourWallet';
import ResultAmount from '../containers/ResultAmount';
import { setPair, setPair_input, setToken_0, setToken_0_Input, setToken_0_Symbol, setToken_1, setToken_1_Input, setToken_1_Symbol} from '../modules/remove';
import { splitSignature } from '@ethersproject/bytes';
import Router from "../abi/Router.json";
import Factory from "../abi/Factory.json";
import ERC20 from "../abi/ERC20.json";
import Pair from "../abi/Pair.json";
import BN from "bn.js";
import RemoveBtns from '../containers/RemoveBtns';
import Time from '../containers/Time';
import { setTransactionHash, setUpdateUserDeadLine } from '../modules/user';


import noneTokenImg from "../assets/images/sym_none.png"
import MMTTokenImg from "../assets/images/sym_mmt.png"

const RemoveLiquidity = ({ account, ROUTER_ADDRESS, token_0, token_1, FACTORY_ADDRESS, WMMT_ADDRESS, chainId }) => {
  const web3 = new Web3(window.web3.currentProvider);

  const [percent, setPercent] = useState(0);
  const [timeStamp, setTimeStamp] = useState("");
  // const [time, setTime] = useState(20);
  const [signatureStatus, SetSignatureStatus] = useState(true);
  const [removeStatus, setRemoveStatus]=useState(true)
  const [rsv,setRSV] = useState({
    r:"",
    s:"",
    v:""
  })

  const pair = useSelector((state) => state.remove.pair);
  const token_0_re = useSelector((state) => state.remove.token_0);
  const token_1_re = useSelector((state) => state.remove.token_1);
  const slippage = useSelector((state)=>state.user.userSlippageTolerance);
  const time = useSelector((state)=>state.user.deadline);
  // const [transactionHash, setTransactionHash] = useState("");
  const transactionHash = useSelector((state) => state.user.transactionHash);

  const dispatch = useDispatch();


  const getRemoveInfo = async () => {
    // url로 토큰 주소 가져오기
    const url = (window.location.pathname).split("/")
    let remove0 = url[2];
    let remove1 = url[3];
    const factoryContract = new web3.eth.Contract(Factory,FACTORY_ADDRESS);
    // 페어 주소 가져오기
    let pairAddress = await factoryContract.methods.getPair(remove0,remove1).call();
    let pairContract = new web3.eth.Contract(Pair, pairAddress);
    const pairDecimal = await pairContract.methods.decimals().call();
    let pairBalance = await pairContract.methods.balanceOf(account).call();
    const pairTotalSupply = await pairContract.methods.totalSupply().call();
    // 페어의 최소 liquidity
    let min = await pairContract.methods.MINIMUM_LIQUIDITY().call();
    let defaultDecimal = web3.utils.toWei("1");
    // 내가 가지고 있는 lp 퍼센트
    let reservesPercent = ((pairBalance/pairTotalSupply)*100).toFixed(4);
    const pairGetReserves = await pairContract.methods.getReserves().call();
    pairBalance = web3.utils.fromWei(await pairContract.methods.balanceOf(account).call());
    let pairData = {
      address: pairAddress,
      haveAmount: pairBalance,
      decimal: pairDecimal,
    }
    // token0
    const token0Contract = new web3.eth.Contract(ERC20, remove0);
    const token0Symbol = await token0Contract.methods.symbol().call();
    const token0Decimal = await token0Contract.methods.decimals().call();
    // token0의 
    const token0HaveAmount = web3.utils.fromWei((new BN(`${pairGetReserves[0]}`).mul(new BN(`${Math.round(reservesPercent*10000)}`))).div(new BN(`${1000000}`)).toString());
    const token0HaveAmount_min = (new BN(`${pairGetReserves[0]}`).mul(new BN(`${min}`))).div(new BN(`${defaultDecimal}`)).toString();
    const remove0_data = {
      address:remove0,
      symbol:token0Symbol,
      decimal:token0Decimal,
      haveAmountMin:token0HaveAmount_min,
      haveAmount:token0HaveAmount
    }
    // token1
    const token1Contract = new web3.eth.Contract(ERC20, remove1);
    const token1Symbol = await token1Contract.methods.symbol().call();
    const token1Decimal = await token1Contract.methods.decimals().call();
    const token1HaveAmount = web3.utils.fromWei((new BN(`${pairGetReserves[1]}`).mul(new BN(`${Math.round(reservesPercent*10000)}`))).div(new BN(`${1000000}`)).toString());
    const token1HaveAmount_min = (new BN(`${pairGetReserves[1]}`).mul(new BN(`${min}`))).div(new BN(`${defaultDecimal}`)).toString();
    const remove1_data = {
      address:remove1,
      symbol:token1Symbol,
      decimal:token1Decimal,
      haveAmountMin:token1HaveAmount_min,
      haveAmount:token1HaveAmount
    }
    dispatch(setPair(pairData));
    dispatch(setToken_0(remove0_data))
    dispatch(setToken_1(remove1_data))
  }

  const checkAllowance = async () => {

  }

  
  const onChangeTime = (e) => {
    const time = e.target.value;
    dispatch(setUpdateUserDeadLine(time*60))
  }

  const clickPercentBtn = async(InputPercent) => {
    if(InputPercent!=0) SetSignatureStatus(false);
    if(removeStatus==false)setRemoveStatus(true)
    setPercent(InputPercent*100);
    let re = InputPercent*100;
    let remove_0 = web3.utils.fromWei((new BN(web3.utils.toWei(token_0.haveAmount)).mul(new BN(`${re}`))).div(new BN(`${100}`)).toString());
    let remove_1 = web3.utils.fromWei((new BN(web3.utils.toWei(token_1.haveAmount)).mul(new BN(`${re}`))).div(new BN(`${100}`)).toString());
    let pair_inputAmount = web3.utils.fromWei((new BN(web3.utils.toWei(pair.haveAmount)).mul(new BN(`${re}`))).div(new BN(`${100}`)).toString());
    // 밑 BN 보면서 다시 작성(다른 계산 하는 것두)
    // (new BN(`${token_0.haveAmount}`))
    dispatch(setToken_0_Input(remove_0));
    dispatch(setToken_1_Input(remove_1));
    dispatch(setPair_input(pair_inputAmount));
  }

  useEffect(() => {
    getRemoveInfo();
  }, [account]);


  const signature = async () => {
    let pairContract = new web3.eth.Contract(Pair, pair.address);
    let value = web3.utils.toWei(`${pair.inputAmount}`);
    // value 계산
    // value = ((new BN(`${value}`)).mul(new BN(`${percent}`)).div(new BN(`${100}`))).toString();
    //.div(percent);
    let nonces = await pairContract.methods.nonces(account).call();
    // nonces = nonces.toString(16);
    nonces = nonces.toString(16);
    if(nonces.length==1)nonces=`0${nonces}`
    nonces = `0x${nonces}`;

    let deadline = Date();
    deadline = + new Date(deadline);
    deadline = deadline/1000
    deadline = (deadline + (time * 60));

    const typedData = {
      domain: {
        name: "Pancake LPs",
        version: "1",
        chainId: chainId,
        verifyingContract: pair.address
      },
      message: {
        owner:`${account}`,
        spender: `${ROUTER_ADDRESS}`,
        value: `${value}`,
        nonce: `${nonces}`,
        deadline: `${deadline}`,
      },
      // domain에 있던 타입
      primaryType: "Permit",
      types: {
        EIP712Domain: [
          {
            name: "name",
            type: "string"
          },
          {
            name: "version",
            type: "string"
          },
          {
            name: "chainId",
            type: "uint256"
          },
          {
            name: "verifyingContract",
            type: "address"
          }
        ],
        // message에 있던 타입
        Permit: [
          {
            name: "owner",
            type: "address"
          },
          {
            name: "spender",
            type: "address"
          },
          {
            name: "value",
            type: "uint256"
          },
          {
            name: "nonce",
            type: "uint256"
          },
          {
            name: "deadline",
            type: "uint256"
          }
        ],
      }
    };

    await window.web3.currentProvider.send({
      jsonrpc: "2.0",
      id: 8898,
      method:"eth_signTypedData_v4",
      params:[account,JSON.stringify(typedData)],
    }, (err, res) => {
      if(err){
        // console.log(err)
      }else{

        let {r,s,v} = splitSignature(res.result)
        setTimeStamp(deadline);
        setRSV({
          r:r,
          s:s,
          v:v
        })
        SetSignatureStatus(true);
        setRemoveStatus(false)
      }
    });
  }

  const onClickRemove = async () => {
    const RouterContract = new web3.eth.Contract(Router, ROUTER_ADDRESS);
    let liquidity = web3.utils.toWei(pair.inputAmount);
    let result;

    try {
      if((token_0_re.address==WMMT_ADDRESS&&token_0_re.symbol=="BNB")||(token_1_re.address==WMMT_ADDRESS&&token_1_re.symbol=="BNB")){
        // removeLiquidityETHWithPermit
        let tokenInfo;
        let MMTInfo;
  
        if(token_0_re.symbol=="BNB"){
          tokenInfo=token_1_re;
          MMTInfo=token_0_re;
        }else if(token_1_re.symbol=="BNB"){
          tokenInfo=token_0_re;
          MMTInfo=token_1_re;
        }
  
        let tokenAddress = tokenInfo.address;
        let amountTokenMin = web3.utils.toWei(tokenInfo.inputAmount);
        let amountETHMin = web3.utils.toWei(MMTInfo.inputAmount);
        amountTokenMin = (new BN(amountTokenMin).sub(new BN(`${tokenInfo.haveAmountMin}`))).toString();
        amountETHMin = (new BN(amountETHMin).sub(new BN(`${MMTInfo.haveAmountMin}`))).toString();
  
        let removeLiquidityETHWithPermit = await RouterContract.methods.removeLiquidityETHWithPermit(
          tokenAddress,
          liquidity,
          amountTokenMin,
          amountETHMin,
          account,
          timeStamp,
          false,
          rsv.v,
          rsv.r,
          rsv.s,
        ).send({
          from:account
        });
        result=removeLiquidityETHWithPermit;
      }else if(
        ((token_0_re.address==WMMT_ADDRESS&&token_0_re.symbol=="WMMT")||(token_1_re.address==WMMT_ADDRESS&&token_1_re.symbol=="WMMT"))
        ||
        (token_0_re.address != WMMT_ADDRESS && token_1_re.address != WMMT_ADDRESS)
      ){
        // removeLiquidityWithPermit
        let amountAMin = web3.utils.toWei(token_0_re.inputAmount);
        let amountBMin = web3.utils.toWei(token_1_re.inputAmount);
        amountAMin = (new BN(amountAMin).sub(new BN(`${token_0_re.haveAmountMin}`))).toString();
        amountBMin = (new BN(amountBMin).sub(new BN(`${token_1_re.haveAmountMin}`))).toString();
        let removeLiquidityWithPermit = await RouterContract.methods.removeLiquidityWithPermit(
          token_0.address,
          token_1.address,
          liquidity,
          amountAMin,
          amountBMin,
          account,
          timeStamp,
          false,
          rsv.v,
          rsv.r,
          rsv.s
        ).send({
          from: account
        });
        result = removeLiquidityWithPermit;
      }


      // setTransactionHash(result.transactionHash);
      dispatch(setTransactionHash(result.transactionHash));
      setRemoveStatus(true);
      SetSignatureStatus(false);
      dispatch(setToken_0_Input(""));
      dispatch(setToken_1_Input(""));
      dispatch(setPair_input(""));
      setPercent(0);
    } catch (error) {
      // console.log(error)
      if(error.code == 4001){
        setRemoveStatus(true);
        SetSignatureStatus(false);
      }
    }
  }

  const onClickChangeTokenSymbol = (token) => {
    let changeSymbol;
    if(token_0_re.address == token.address){
      if(token.symbol == "WMMT"){
        changeSymbol="BNB"
      }else{
        changeSymbol="WMMT"
      }
      dispatch((setToken_0_Symbol(changeSymbol)));
    }else if(token_1_re.address == token.address){
      if(token.symbol == "WMMT"){
        changeSymbol="BNB"
      }else{
        changeSymbol="WMMT"
      }
      dispatch((setToken_1_Symbol(changeSymbol)));
    }
  }

  const onClickExplorer = () => {
    // window.open(`https://mmtscan.io/tx/${transactionHash}`)
    // window.open(`https://testnet.bscscan.com/tx/${transactionHash}`)
    window.open(`https://testnet.mmtscan.io/tx/${transactionHash}`)
  }

  return (
    <div>
      <div>Remove Liquidity</div>
      <Amount clickPercentBtn={clickPercentBtn} percent={percent} />
      <ResultAmount token_0={token_0} token_1={token_1} WMMT_ADDRESS={WMMT_ADDRESS}/>
      <LpTokenInYourWallet token_0={token_0} token_1={token_1} pair={pair} />
      <Time time={time} onChangeTime={onChangeTime}/>
      <RemoveBtns signature={signature} signatureStatus={signatureStatus} removeStatus={removeStatus} onClickRemove={onClickRemove}/>
    </div>
  )
}
// 각각 balance는 각 토큰 balanceof 해서 pair주소 넣기
export default RemoveLiquidity