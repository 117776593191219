import React from 'react';
import styles from '../assets/css/Header.module.css';
import styles1 from '../assets/css/AddLiquidity.module.css';


const ConnectWallet = ({ handleConnect,toggleMobileMenu }) => {
    return (
        <div>
            <button className={styles.greenWalletBtn} onClick={() => {handleConnect()}} >Connect Wallet</button>
        </div>
    );
};

export default ConnectWallet;
