import { combineReducers } from "redux";
import { all } from "@redux-saga/core/effects";
import { persistReducer } from 'redux-persist';
import storage from "redux-persist/lib/storage";
import user from "./user";
import liquidity from "./liquidity";
import remove from "./remove";
import swap from "./swap";
import farm from "./farm";
import stake from "./stake";
import main from "./main";
import info from "./info";

const persistConfig = {
    key: "IvorySwap",
    storage,
    whitelist: [
        "main",
        "user"
    ],
}


const rootreducer = combineReducers({
    user,
    liquidity,
    remove,
    swap,
    farm,
    stake,
    main,
    info,
});


export function* rootsaga() {
    yield all([
    ]);
}

const persistedReducer = persistReducer(persistConfig, rootreducer);

export default persistedReducer;