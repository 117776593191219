import React, { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../assets/css/PairList.module.css";
import stylesSwap from "../assets/css/Swap.module.css";
import { setTransactionHash, setUpdateUserDeadLine } from "../modules/user";
import Web3 from "web3";
import Router from "../abi/Router.json";
import Pair from "../abi/Pair.json";
import BN from "bn.js";
import { splitSignature } from "@ethersproject/bytes";
import {
  setPair,
  setPair_input,
  setToken_0,
  setToken_0_Input,
  setToken_0_Symbol,
  setToken_1,
  setToken_1_Input,
  setToken_1_Symbol,
} from "../modules/remove";

import noneTokenImg from "../assets/images/sym_none.png";
import MMTTokenImg from "../assets/images/sym_mmt.png";
import { returnDecimal } from "../common/commonService";

const PairList = ({
  wCoinSymbol,
  coinSymbol,
  onErrorImg,
  providerWeb3,
  web3Provider,
  active,
  pairList,
  account,
  loading,
  ROUTER_ADDRESS,
  WMMT_ADDRESS,
  clickRemoveLiquiidityBtn,
  onClickAddLiquidityBtn,
  chainId,
  defaultProvider,
}) => {
  const [chain, setChain] = useState("MMT");
  useEffect(() => {
    if (chainId == 8898 || chainId == 88998) {
      setChain("MMT");
    } else if (chainId == 97) {
      setChain("BNB");
    } else if (chainId == 5 || chainId == 1) {
      setChain("ETH");
    } else if (chainId == 898989 || chainId == 8989) {
      setChain("GMMT");
    }
  }, [chainId]);

  const [elRefs, setElRefs] = useState([]);
  const [range, setRange] = useState(0);
  const [rangeBgWidth, setRangeBgWidth] = useState();
  const testDiv = [0, 1, 2];
  const [popupShow, setPopupShow] = useState(false);
  const rangeWidth = useRef(0);
  const [nowModalStatus, setNowModalStatus] = useState("removeLiquidity");

  // const [transactionHash, setTransactionHash] = useState("");
  const transactionHash = useSelector((state) => state.user.transactionHash);

  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const pairItemToggle = (index) => {
    if (elRefs[index].current.classList.contains(styles.block)) {
      elRefs[index].current.classList.remove(styles.block);
    } else {
      elRefs[index].current.classList.add(styles.block);
    }
  };

  const getRangeValue = (e) => {
    let rangeValue = e.target.value;
    if (rangeValue == 0) SetSignatureStatus(true);
    clickPercentBtn(rangeValue);
    setRange(e.target.value);
    setRangeBgWidth({
      width: e.target.value * (rangeWidth.current.clientWidth / 100) + "px",
    });
  };
  const getRangeValueBtn = (percent) => {
    let rangeValue = percent;
    if (rangeValue != 0) SetSignatureStatus(false);
    setRange(rangeValue);
    setRangeBgWidth({
      width: rangeValue * (rangeWidth.current.clientWidth / 100) + "px",
    });
  };

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(pairList.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [pairList.length]);

  // finn
  const [percent, setPercent] = useState(0);
  const [timeStamp, setTimeStamp] = useState("");
  // const [time, setTime] = useState(20);
  const [signatureStatus, SetSignatureStatus] = useState(true);
  const [removeStatus, setRemoveStatus] = useState(true);
  const [rsv, setRSV] = useState({
    r: "",
    s: "",
    v: "",
  });

  // useEffect(() => {
  //   if (nowModalStatus == 'confirmation') {
  //     if (transactionHash != "") {
  //       setNowModalStatus('submitted');
  //     }
  //   }
  // }, [nowModalStatus, transactionHash])

  const pair = useSelector((state) => state.remove.pair);
  const token_0_re = useSelector((state) => state.remove.token_0);
  const token_1_re = useSelector((state) => state.remove.token_1);
  const slippage = useSelector((state) => state.user.userSlippageTolerance);
  const time = useSelector((state) => state.user.deadline);

  const dispatch = useDispatch();

  const onChangeTime = (e) => {
    const time = e.target.value;
    dispatch(setUpdateUserDeadLine(time * 60));
  };

  const signature = async () => {
    if (
      (((await defaultProvider) != undefined?.eth.getChainId()) ==
        (await web3Provider)) !=
      undefined?.eth.getChainId()
    ) {
      const web3 = web3Provider;
      let pairContract = new web3.eth.Contract(Pair, pair.address);
      let value = web3.utils.toWei(`${pair.inputAmount}`);
      // value 계산
      // value = ((new BN(`${value}`)).mul(new BN(`${percent}`)).div(new BN(`${100}`))).toString();
      //.div(percent);
      let nonces = await pairContract.methods.nonces(account).call();
      // nonces = nonces.toString(16);
      nonces = Number(nonces).toString(16);
      if (nonces.length == 1) nonces = `0${nonces}`;
      nonces = `0x${nonces}`;

      let deadline = Date();
      deadline = +new Date(deadline);
      deadline = deadline / 1000;
      deadline = new BN(`${deadline}`).add(new BN(`${time}`)).toString();

      const typedData = {
        domain: {
          name: pair.name,
          version: "1",
          chainId: chainId,
          verifyingContract: pair.address,
        },
        message: {
          owner: `${account}`,
          spender: `${ROUTER_ADDRESS}`,
          value: `${value}`,
          nonce: `${nonces}`,
          deadline: `${deadline}`,
        },
        // domain에 있던 타입
        primaryType: "Permit",
        types: {
          EIP712Domain: [
            {
              name: "name",
              type: "string",
            },
            {
              name: "version",
              type: "string",
            },
            {
              name: "chainId",
              type: "uint256",
            },
            {
              name: "verifyingContract",
              type: "address",
            },
          ],
          // message에 있던 타입
          Permit: [
            {
              name: "owner",
              type: "address",
            },
            {
              name: "spender",
              type: "address",
            },
            {
              name: "value",
              type: "uint256",
            },
            {
              name: "nonce",
              type: "uint256",
            },
            {
              name: "deadline",
              type: "uint256",
            },
          ],
        },
      };

      await providerWeb3.send(
        {
          jsonrpc: "2.0",
          id: chainId,
          method: "eth_signTypedData_v4",
          params: [account, JSON.stringify(typedData)],
        },
        (err, res) => {
          if (err) {
            // console.log(err)
          } else {
            let { r, s, v } = splitSignature(res.result);
            setTimeStamp(deadline);
            setRSV({
              r: r,
              s: s,
              v: v,
            });
            SetSignatureStatus(true);
            setRemoveStatus(false);
          }
        }
      );
    }
  };

  const clickPercentBtn = async (InputPercent) => {
    const web3 = defaultProvider;
    if (InputPercent != 0) SetSignatureStatus(false);
    if (removeStatus == false) setRemoveStatus(true);
    setPercent(InputPercent);
    let re = `${InputPercent}`;

    let decimalPowToken0 = new BN(`${10}`)
      .pow(new BN(`${token_0_re.decimal}`))
      .toString();
    let re_DecimalsToken0 = returnDecimal(defaultProvider, decimalPowToken0);

    // let remove_0 = web3.utils.fromWei((new BN(web3.utils.toWei(token_0_re.haveAmount,re_DecimalsToken0)).mul(new BN(`${re}`))).div(new BN(`${100}`)).toString(),re_DecimalsToken0);
    let remove_0_amount_toWei = toWeiBalance(
      token_0_re.haveAmount,
      token_0_re.decimal
    );
    let remove_0_amount = new BN(`${remove_0_amount_toWei}`)
      .mul(new BN(`${re}`))
      .div(new BN(`${100}`))
      .toString();
    let remove_0 = fromWeiBalance(remove_0_amount, token_0_re.decimal);

    let decimalPowToken1 = new BN(`${10}`)
      .pow(new BN(`${token_1_re.decimal}`))
      .toString();
    let re_DecimalsToken1 = returnDecimal(defaultProvider, decimalPowToken1);

    // let remove_1 = web3.utils.fromWei((new BN(web3.utils.toWei(token_1_re.haveAmount,re_DecimalsToken1)).mul(new BN(`${re}`))).div(new BN(`${100}`)).toString(),re_DecimalsToken1);
    let remove_1_amount_toWei = toWeiBalance(
      token_1_re.haveAmount,
      token_1_re.decimal
    );
    let remove_1_amount = new BN(`${remove_1_amount_toWei}`)
      .mul(new BN(`${re}`))
      .div(new BN(`${100}`))
      .toString();
    let remove_1 = fromWeiBalance(remove_1_amount, token_1_re.decimal);

    let pair_inputAmount = web3.utils.fromWei(
      new BN(web3.utils.toWei(pair.haveAmount))
        .mul(new BN(`${re}`))
        .div(new BN(`${100}`))
        .toString()
    );
    // 밑 BN 보면서 다시 작성(다른 계산 하는 것두)
    // (new BN(`${token_0.haveAmount}`))
    dispatch(setToken_0_Input(remove_0));
    dispatch(setToken_1_Input(remove_1));
    dispatch(setPair_input(pair_inputAmount));
  };

  const onClickRemove = async () => {
    const web3 = web3Provider;
    const RouterContract = new web3.eth.Contract(Router, ROUTER_ADDRESS);
    let liquidity = web3.utils.toWei(pair.inputAmount);
    // setTransactionHash("");
    dispatch(setTransactionHash(""));

    let result;
    try {
      if (
        (token_0_re.address.toLowerCase() == WMMT_ADDRESS.toLowerCase() &&
          token_0_re.symbol == chain) ||
        (token_1_re.address.toLowerCase() == WMMT_ADDRESS.toLowerCase() &&
          token_1_re.symbol == chain)
      ) {
        // removeLiquidityETHWithPermit
        let tokenInfo;
        let MMTInfo;

        if (token_0_re.symbol == chain) {
          tokenInfo = token_1_re;
          MMTInfo = token_0_re;
        } else if (token_1_re.symbol == chain) {
          tokenInfo = token_0_re;
          MMTInfo = token_1_re;
        }

        let tokenAddress = tokenInfo.address;

        let decimalPowA = new BN(`${10}`)
          .pow(new BN(`${tokenInfo.decimal}`))
          .toString();
        let re_DecimalsA = returnDecimal(web3Provider, decimalPowA);

        // let amountTokenMin_0 = web3.utils.toWei(tokenInfo.inputAmount,re_DecimalsA);
        let amountTokenMin = toWeiBalance(
          tokenInfo.inputAmount,
          tokenInfo.decimal
        );

        let amountMMTMin = web3.utils.toWei(MMTInfo.inputAmount);

        let slippageInt = slippage * 100;

        let amountA_min = new BN(`${amountTokenMin}`)
          .mul(new BN(`${10000}`).sub(new BN(`${slippageInt}`)))
          .div(new BN(`${10000}`))
          .toString();
        let amountMMT_min = new BN(`${amountMMTMin}`)
          .mul(new BN(`${10000}`).sub(new BN(`${slippageInt}`)))
          .div(new BN(`${10000}`))
          .toString();

        let removeLiquidityETHWithPermit = await RouterContract.methods
          .removeLiquidityETHWithPermit(
            tokenAddress,
            liquidity,
            amountA_min,
            amountMMT_min,
            account,
            timeStamp,
            false,
            rsv.v,
            rsv.r,
            rsv.s
          )
          .send({
            from: account,
          });
        result = removeLiquidityETHWithPermit;
      } else if (
        (token_0_re.address.toLowerCase() == WMMT_ADDRESS.toLowerCase() &&
          token_0_re.symbol == wCoinSymbol) ||
        (token_1_re.address.toLowerCase() == WMMT_ADDRESS.toLowerCase() &&
          token_1_re.symbol == wCoinSymbol) ||
        (token_0_re.address.toLowerCase() != WMMT_ADDRESS.toLowerCase() &&
          token_1_re.address.toLowerCase() != WMMT_ADDRESS.toLowerCase())
      ) {
        //여기 부터 삭제!
        let decimalPowA = new BN(`${10}`)
          .pow(new BN(`${token_0_re.decimal}`))
          .toString();
        let re_DecimalsA = returnDecimal(web3Provider, decimalPowA);

        let decimalPowB = new BN(`${10}`)
          .pow(new BN(`${token_1_re.decimal}`))
          .toString();
        let re_DecimalsB = returnDecimal(web3Provider, decimalPowB);

        // let amountAMin_0 = web3.utils.toWei(token_0_re.inputAmount,re_DecimalsA);
        // let amountBMin_0 = web3.utils.toWei(token_1_re.inputAmount,re_DecimalsB);
        let amountAMin = toWeiBalance(
          token_0_re.inputAmount,
          token_0_re.decimal
        );
        let amountBMin = toWeiBalance(
          token_1_re.inputAmount,
          token_1_re.decimal
        );

        let slippageInt = Math.floor(slippage * 100);

        let slippageTokenA = new BN(amountAMin)
          .mul(new BN(`${slippageInt}`))
          .div(new BN(`${10000}`))
          .toString();
        let slippageTokenB = new BN(amountBMin)
          .mul(new BN(`${slippageInt}`))
          .div(new BN(`${10000}`))
          .toString();

        let amountA_min = new BN(amountAMin)
          .sub(new BN(`${slippageTokenA}`))
          .toString();
        let amountB_min = new BN(amountBMin)
          .sub(new BN(`${slippageTokenB}`))
          .toString();

        // amountAMin = (new BN(amountAMin).sub(new BN(`${amountAMin}`))).toString();
        // amountBMin = (new BN(amountBMin).sub(new BN(`${amountBMin}`))).toString();

        // amountAMin = amountAMin-testA;
        // amountBMin = amountBMin-testB;

        let removeLiquidityWithPermit = await RouterContract.methods
          .removeLiquidityWithPermit(
            token_0_re.address,
            token_1_re.address,
            liquidity,
            amountA_min,
            amountB_min,
            account,
            timeStamp,
            false,
            rsv.v,
            rsv.r,
            rsv.s
          )
          .send({
            from: account,
          });

        result = removeLiquidityWithPermit;
      }
      if (result.status) {
        setNowModalStatus("submitted");
      } else {
        setNowModalStatus("failed");
      }
      // setTransactionHash(result.transactionHash);
      dispatch(setTransactionHash(result.transactionHash));
      setRemoveStatus(true);
      // SetSignatureStatus(false);
      // dispatch(setToken_0_Input(""));
      // dispatch(setToken_1_Input(""));
      // dispatch(setPair_input(""));
      const reset = {
        address: "",
        symbol: "",
        name: "",
        decimal: 0,
        inputAmount: "",
        haveAmount: 0,
        image: "",
      };
      let pairData = {
        address: "",
        haveAmount: "",
        decimal: 0,
        reservesPercent: 0,
      };
      dispatch(setPair(pairData));
      dispatch(setToken_0(reset));
      dispatch(setToken_0_Input(reset.inputAmount));
      dispatch(setToken_1(reset));
      dispatch(setToken_1_Input(reset.inputAmount));
      dispatch(setPair_input(reset.inputAmount));
      setPercent(0);
      setRange(0);
      setRangeBgWidth({ width: 0 + "px" });
    } catch (error) {
      // console.log(error)
      // console.log(error)
      // if (error.code == 4001) {
      setNowModalStatus("removeLiquidity");
      setRemoveStatus(true);
      SetSignatureStatus(false);
      // }
    }
  };

  const onClickOutDetail = () => {
    const reset = {
      address: "",
      symbol: "",
      name: "",
      decimal: 0,
      inputAmount: "",
      haveAmount: 0,
      image: "",
    };
    let pairData = {
      address: "",
      haveAmount: "",
      decimal: 0,
      reservesPercent: 0,
    };
    dispatch(setPair(pairData));
    dispatch(setToken_0(reset));
    dispatch(setToken_0_Input(reset.inputAmount));
    dispatch(setToken_1(reset));
    dispatch(setToken_1_Input(reset.inputAmount));
    dispatch(setPair_input(reset.inputAmount));
    setPercent(0);
    // getRangeValueBtn(0);
    SetSignatureStatus(true);
    setRemoveStatus(true);
  };

  const onClickExplorer = () => {
    if (chainId == 8898) {
      window.open(`https://mmtscan.io/tx/${transactionHash}`);
    } else if (chainId == 88998) {
      window.open(`https://testnet.mmtscan.io/tx/${transactionHash}`);
    } else if (chainId == 97) {
      window.open(`https://testnet.bscscan.com/tx/${transactionHash}`);
    } else if (chainId == 8989) {
      window.open(`https://scan.gmmtchain.io/tx/${transactionHash}`);
    } else if (chainId == 898989) {
      window.open(`https://testnet-scan.gmmtchain.io/tx/${transactionHash}`);
    } else if (chainId == 1) {
      window.open(`https://etherscan.io/tx/${transactionHash}`);
    }
  };

  const onClickClose = () => {
    setPopupShow(false);
    onClickOutDetail();
    setRange(0);
    setRangeBgWidth({ width: 0 + "px" });
  };

  const balanceInCode = (balance) => {
    if (
      balance == undefined ||
      balance.length == undefined ||
      balance == "-" ||
      balance == "" ||
      Number(balance) == 0
    ) {
      return 0;
    } else {
      let splitBalance = balance.split(".");

      let backBalance = "";
      let afterVal = "";
      if (splitBalance.length == 2) {
        let q1 = splitBalance[1].length - (splitBalance[1].search(/[1-9]/) + 1);
        let nextVal;
        for (let i = 0; i < q1; i++) {
          nextVal = splitBalance[1].substr(
            splitBalance[1].search(/[1-9]/) + (i + 1),
            1
          );
          if (nextVal != "0") {
            afterVal = afterVal + `${nextVal}`;
            break;
          } else {
            afterVal = afterVal + "0";
          }
          if (i == q1 - 1) {
            nextVal = "";
          }
        }
        if (afterVal.search(/[1-9]/) == -1) {
          afterVal = "";
        }
        backBalance =
          splitBalance[1].substr(0, splitBalance[1].search(/[1-9]/) + 1) != ""
            ? "." +
              splitBalance[1].substr(0, splitBalance[1].search(/[1-9]/) + 1) +
              afterVal
            : "";
      }

      return splitBalance[0] + backBalance;
    }
  };

  const fromWeiBalance = (balance, decimal) => {
    let result = new BN(`${10}`).pow(new BN(`${decimal}`)).toString();
    let fromWeiBalance = new BN(`${balance}`).divmod(new BN(`${result}`));
    let divBalance = fromWeiBalance.div.toString();
    let modBalance = fromWeiBalance.mod.toString();
    let subDecimal = decimal - modBalance.length;
    let zero = "";
    for (let i = 0; i < subDecimal; i++) {
      zero += "0";
    }

    let returnBalance = divBalance + "." + zero + modBalance;
    return returnBalance;
  };

  const toWeiBalance = (balance, decimal) => {
    if (defaultProvider != undefined) {
      let point = balance.split(".");
      let pointPow = 1;
      let powLength = 0;
      let lastPoint = "";
      if (point[1] != undefined) {
        powLength = point[1].length;
        pointPow = new BN(`${10}`).pow(new BN(`${point[1].length}`)).toString();
        lastPoint = point[1].substr(0, decimal);
      }

      let balancePoinPow = point[0] + lastPoint;

      let subDecimal = new BN(`${decimal}`)
        .sub(new BN(`${powLength}`))
        .toString();

      let result = new BN(`${10}`).pow(new BN(`${subDecimal}`)).toString();
      let decimalBalance = new BN(`${balancePoinPow}`)
        .mul(new BN(`${result}`))
        .toString();

      return decimalBalance;
    }
  };

  const onClickChangeSymbol = (item, tokenType) => {
    let changeSymbol;
    let image;
    if (item.symbol == wCoinSymbol) {
      changeSymbol = chain;
      image = `common_ico_${chain.toLocaleLowerCase()}@2x`;
    } else if (item.symbol == chain) {
      changeSymbol = wCoinSymbol;
      image = WMMT_ADDRESS;
    }

    let data = {
      symbol: changeSymbol,
      image: image,
    };

    if (tokenType == "0") {
      dispatch(setToken_0_Symbol(data));
    } else if (tokenType == "1") {
      dispatch(setToken_1_Symbol(data));
    }
  };

  const onClickTitle = (pageName) => {
    const htmlTitle = document.querySelector("title");
    htmlTitle.innerHTML = pageName;
  };

  const toLocaleStringOption = (amount) => {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  const checkModalStatusSubmit = () => {
    if (nowModalStatus == "submitted") {
      setTimeout(() => {
        setPopupShow(false);
      }, [4000]);
    }
  };

  useEffect(() => {
    checkModalStatusSubmit();
  }, [nowModalStatus]);

  return (
    <div>
      <div className={styles.title}>
        My Liquidity ({account == "" ? 0 : pairList.length})
      </div>
      <div>
        <div
          className={`${styles.listBox} ${
            account == "" ||
            (pairList == "" && account != "") ||
            loading == false
              ? styles.listNone
              : ""
          }`}
        >
          {
            <div>
              {loading == true ? (
                <div>
                  {account == "" ? (
                    <div>Connect to a wallet to view your liquidity.</div>
                  ) : pairList == "" ? (
                    <div>No liquidity found.</div>
                  ) : (
                    <div className={styles.pairList}>
                      {pairList.map((item, index) => (
                        <div
                          className={`${styles.pairItem} pairItem${item}`}
                          // onClick={() => pairItemToggle(index)}
                          ref={elRefs[index]}
                          key={index}
                        >
                          <div
                            className={`${styles.pairRoute} pairRoute${item}`}
                            onClick={() => pairItemToggle(index)}
                          >
                            <div className={styles.symbol}>
                              <img
                                className={styles.noMargin}
                                src={`${chain}/logo_${item.token0.image}.png`}
                                alt={`${item.token0.symbol}`}
                                onError={onErrorImg}
                              />
                              {item.token0.symbol}
                            </div>
                            <div className={styles.bar}>|</div>
                            <div className={styles.symbol}>
                              <img
                                src={`${chain}/logo_${item.token1.image}.png`}
                                alt={`${item.token1.symbol}`}
                                onError={onErrorImg}
                              />
                              {item.token1.symbol}
                            </div>
                            <img
                              className={styles.arrowImg}
                              src={require("../assets/images/swap/ico_arrow_bl.png")}
                              alt={"arrow"}
                            />
                          </div>
                          <div className={`${styles.pairInfo}`}>
                            <div>
                              <span className={`${styles.tokenSymbol}`}>
                                {item.token0.symbol}
                              </span>
                              :
                            </div>
                            <div>
                              {Number(item.token0.haveAmount) < 0.001
                                ? "< 0.001"
                                : toLocaleStringOption(
                                    balanceInCode(item.token0.haveAmount)
                                  )}
                            </div>
                          </div>
                          <div className={`${styles.pairInfo}`}>
                            <div>
                              <span className={`${styles.tokenSymbol}`}>
                                {item.token1.symbol}
                              </span>
                              :
                            </div>
                            <div>
                              {Number(item.token1.haveAmount) < 0.001
                                ? "< 0.001"
                                : toLocaleStringOption(
                                    balanceInCode(item.token1.haveAmount)
                                  )}
                            </div>
                          </div>
                          <div className={`${styles.pairInfo}`}>
                            <div>Pool Tokens :</div>
                            <div>
                              {Number(item.pair.haveAmount) < 0.001
                                ? "< 0.001"
                                : toLocaleStringOption(
                                    balanceInCode(item.pair.haveAmount)
                                  )}
                            </div>
                          </div>
                          <div className={`${styles.pairInfo}`}>
                            <div>Pool share :</div>
                            <div>{item.pair.reservesPercent}%</div>
                          </div>
                          <div className={styles.pairBtn}>
                            <Link to={"/add"}>
                              <button
                                className={`${styles.addBtn} ${styles.btn}`}
                                onClick={() => {
                                  onClickAddLiquidityBtn(
                                    item.token0,
                                    item.token1
                                  );
                                  onClickTitle("AddLiquidity - Ivory Swap");
                                }}
                              >
                                Add
                              </button>
                            </Link>
                            <button
                              className={`${styles.removeBtn} ${styles.btn}`}
                              onClick={() => {
                                clickRemoveLiquiidityBtn(
                                  item.token0,
                                  item.token1,
                                  item.pair
                                );
                                setNowModalStatus("removeLiquidity");
                                setPopupShow(true);
                                onClickTitle("RemoveLiquidity - Ivory Swap");
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <img
                  src={require("../assets/images/swap/loading.gif")}
                  alt={"ico_minus_bl"}
                />
              )}
            </div>
          }
        </div>
      </div>
      {popupShow ? (
        <div className={stylesSwap.popupWrap}>
          <div
            className={stylesSwap.popupDim}
            onClick={() => {
              onClickTitle("Liquidity - Ivory Swap");
              onClickClose();
            }}
          />
          <div className={stylesSwap.popupContainer}>
            <div
              className={stylesSwap.closeBtn}
              onClick={() => {
                onClickTitle("Liquidity - Ivory Swap");
                onClickClose();
              }}
            >
              <div>
                <img
                  src={require("../assets/images/swap/ico_minus_bl.png")}
                  alt={"ico_minus_bl"}
                />
                <img
                  src={require("../assets/images/swap/ico_minus_bl.png")}
                  alt={"ico_minus_bl"}
                />
              </div>
            </div>
            {nowModalStatus === "removeLiquidity" ? (
              <div className={styles.removePopup}>
                <div className={styles.title}>Remove Liquidity</div>
                <div className={styles.rangeBox}>
                  <div className={styles.rangeTitle}>
                    <div className={styles.rangeRoute}>
                      {/* <div><img src={require(`../assets/images/swap/logo_${token_0_re.image}.png`)} style={{"width":"35px","height":"35px"}} alt={"symbol_logo"} />{token_0_re.symbol}</div> */}
                      <div
                        onClick={() => {
                          if (
                            token_0_re.address.toLowerCase() ==
                            WMMT_ADDRESS.toLowerCase()
                          ) {
                            onClickChangeSymbol(token_0_re, "0");
                          }
                        }}
                      >
                        <img
                          src={`${chain}/logo_${token_0_re.image}.png`}
                          alt={`${token_0_re.symbol}`}
                          onError={onErrorImg}
                        />
                        {token_0_re.symbol}
                      </div>
                      <div className={styles.bar}>|</div>
                      {/* <div><img src={require(`../assets/images/swap/logo_${token_1_re.image}.png`)} style={{"width":"35px","height":"35px"}}alt={"symbol_logo"} />{token_1_re.symbol}</div> */}
                      <div
                        onClick={() => {
                          if (
                            token_1_re.address.toLowerCase() ==
                            WMMT_ADDRESS.toLowerCase()
                          ) {
                            onClickChangeSymbol(token_1_re, "1");
                          }
                        }}
                      >
                        <img
                          src={`${chain}/logo_${token_1_re.image}.png`}
                          alt={`${token_1_re.symbol}`}
                          onError={onErrorImg}
                        />
                        {token_1_re.symbol}
                      </div>
                    </div>
                    <div className={styles.text}>
                      <span>
                        {Number(pair.reservesPercent) < 0.01
                          ? "< 0.01"
                          : pair.reservesPercent}
                        %
                      </span>{" "}
                      In range
                    </div>
                    {/* <div className={styles.text}><span>{pair.reservesPercent}%</span> In range</div> */}
                  </div>
                  <div className={styles.amountBox}>
                    <div className={styles.title}>Amount</div>
                    <div className={styles.rangeSet}>
                      <div className={styles.nowRange}>
                        {percent}
                        <span>%</span>
                      </div>
                      <div className={styles.rangeBtn}>
                        <div
                          onClick={() => {
                            clickPercentBtn(25);
                            getRangeValueBtn(25);
                          }}
                        >
                          25%
                        </div>
                        <div
                          onClick={() => {
                            clickPercentBtn(50);
                            getRangeValueBtn(50);
                          }}
                        >
                          50%
                        </div>
                        <div
                          onClick={() => {
                            clickPercentBtn(75);
                            getRangeValueBtn(75);
                          }}
                        >
                          75%
                        </div>
                        <div
                          className={styles.active}
                          onClick={() => {
                            clickPercentBtn(100);
                            getRangeValueBtn(100);
                          }}
                        >
                          MAX
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputRange}>
                      <div className={styles.rangeBg} style={rangeBgWidth} />
                      <input
                        ref={rangeWidth}
                        type="range"
                        onChange={getRangeValue}
                        value={range}
                        min={0}
                        max={100}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.pooledBox}>
                  <div className={`${styles.pooledToken} ${styles.bt}`}>
                    {/* <div>Remove {token_0_re.symbol}</div> */}
                    <div
                      onClick={() => {
                        if (
                          token_0_re.address.toLowerCase() ==
                          WMMT_ADDRESS.toLowerCase()
                        ) {
                          onClickChangeSymbol(token_0_re, "0");
                        }
                      }}
                    >
                      Remove{" "}
                      <img
                        src={`${chain}/logo_${token_0_re.image}.png`}
                        alt={`${token_0_re.symbol}`}
                        onError={onErrorImg}
                      />
                      {token_0_re.symbol}
                    </div>
                    {/* {
                      token_0_re.address == WMMT_ADDRESS ?
                      <div onClick={()=>{ onClickChangeTokenSymbol(token_0_re)}}>Receive {token_0_re.symbol =="WMMT"?<>MMT</>:<>WMMT</>}</div>:<></>
                    } */}
                    {/* <div>{Number(token_0_re.inputAmount).toFixed(4).replace(/\.?0+$/, "")}</div> */}
                    <div>
                      {toLocaleStringOption(
                        balanceInCode(String(token_0_re.inputAmount))
                      )}
                    </div>
                  </div>
                  <div className={`${styles.pooledToken} ${styles.mb20}`}>
                    {/* <div>Remove {token_1_re.symbol}</div> */}
                    <div
                      onClick={() => {
                        if (
                          token_1_re.address.toLowerCase() ==
                          WMMT_ADDRESS.toLowerCase()
                        ) {
                          onClickChangeSymbol(token_1_re, "0");
                        }
                      }}
                    >
                      Remove{" "}
                      <img
                        src={`${chain}/logo_${token_1_re.image}.png`}
                        alt={`${token_1_re.symbol}`}
                        onError={onErrorImg}
                      />
                      {token_1_re.symbol}
                    </div>
                    {/* <div>{Number(token_1_re.inputAmount).toFixed(4).replace(/\.?0+$/, "")}</div> */}
                    <div>
                      {toLocaleStringOption(
                        balanceInCode(String(token_1_re.inputAmount))
                      )}
                    </div>
                  </div>
                  <div className={`${styles.pooledToken} ${styles.bt}`}>
                    {/* <div>Pooled {token_0_re.symbol}</div> */}
                    <div
                      onClick={() => {
                        if (
                          token_0_re.address.toLowerCase() ==
                          WMMT_ADDRESS.toLowerCase()
                        ) {
                          onClickChangeSymbol(token_0_re, "0");
                        }
                      }}
                    >
                      Pooled{" "}
                      <img
                        src={`${chain}/logo_${token_0_re.image}.png`}
                        alt={`${token_0_re.symbol}`}
                        onError={onErrorImg}
                      />
                      {token_0_re.symbol}
                    </div>
                    {/* <div>{Number(token_0_re.haveAmount).toFixed(4).replace(/\.?0+$/, "")}</div> */}
                    <div>
                      {toLocaleStringOption(
                        balanceInCode(String(token_0_re.haveAmount))
                      )}
                    </div>
                  </div>
                  <div className={`${styles.pooledToken} ${styles.mb20}`}>
                    {/* <div>Pooled {token_1_re.symbol}</div> */}
                    <div
                      onClick={() => {
                        if (
                          token_1_re.address.toLowerCase() ==
                          WMMT_ADDRESS.toLowerCase()
                        ) {
                          onClickChangeSymbol(token_1_re, "0");
                        }
                      }}
                    >
                      Pooled{" "}
                      <img
                        src={`${chain}/logo_${token_1_re.image}.png`}
                        alt={`${token_1_re.symbol}`}
                        onError={onErrorImg}
                      />
                      {token_1_re.symbol}
                    </div>
                    {/* <div>{Number(token_1_re.haveAmount).toFixed(4).replace(/\.?0+$/, "")}</div> */}
                    <div>
                      {toLocaleStringOption(
                        balanceInCode(String(token_1_re.haveAmount))
                      )}
                    </div>
                  </div>
                  {/* <div className={styles.pooledBottomText}>You will also collect fees earned from this liquidity.</div>
                      <div className={`${styles.tokenFees} ${styles.bt}`}>
                          <div>TokenA fees Earned</div>
                          <div>0.92</div>
                      </div>
                      <div className={styles.tokenFees}>
                          <div>TokenB fees Earned</div>
                          <div>1.98</div>
                      </div> */}
                </div>
                <div className={styles.popupBtnBox}>
                  <button
                    disabled={signatureStatus}
                    className={`${styles.popupApproveBtn} ${
                      signatureStatus ? styles.btnDisActive : ""
                    }`}
                    onClick={() => signature()}
                  >
                    Approve
                  </button>
                  <button
                    disabled={removeStatus}
                    className={`${styles.popupRemoveBtn} ${
                      removeStatus ? styles.btnDisActive : ""
                    }`}
                    onClick={() => {
                      onClickRemove();
                      setNowModalStatus("confirmation");
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ) : nowModalStatus === "confirmation" ? (
              <div className={styles.confirmation}>
                <img
                  src={require("../assets/images/swap/loading.gif")}
                  alt={"loading.gif"}
                />
                <div className={styles.bold}>Waiting for confirmation</div>
                <div className={styles.normal}>
                  Confirm this transaction in your wallet
                </div>
                <div className={styles.gray}>
                  Removing{" "}
                  {toLocaleStringOption(
                    balanceInCode(String(token_0_re.inputAmount))
                  )}{" "}
                  {token_0_re.symbol} and{" "}
                  {toLocaleStringOption(
                    balanceInCode(String(token_1_re.inputAmount))
                  )}{" "}
                  {token_1_re.symbol}
                </div>
              </div>
            ) : nowModalStatus === "submitted" ? (
              <div className={styles.submitted}>
                <img
                  src={require("../assets/images/swap/ico_confirmed.png")}
                  alt={"ico_confirmed"}
                />
                <div className={styles.bold}>Transaction Submitted</div>
                <div onClick={onClickExplorer} style={{ cursor: "pointer" }}>
                  View on Explorer{" "}
                  <img
                    className={styles.greenArrow}
                    src={require("../assets/images/swap/green-arrow.png")}
                    alt={"green-arrow"}
                  />{" "}
                </div>
              </div>
            ) : nowModalStatus === "failed" ? (
              <div className={styles.submitted}>
                <img
                  src={require("../assets/images/ico_rejected.png")}
                  alt={"ico_confirmed"}
                />
                <div className={styles.bold} style={{ color: "#e54e4e" }}>
                  Transaction Rejected
                </div>
                {/* <div onClick={onClickExplorer} style={{ cursor: "pointer" }} >View on Explorer <img className={styles.greenArrow} src={require("../assets/images/swap/green-arrow.png")} alt={"green-arrow"} /> </div> */}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PairList;
