import React from 'react';

const AddToken = ({tokenAStatus,tokenBStatus,changeRadioStatus,selectToken}) => {
    return (
        <div>
            <input type={'radio'} id={"TokenA"} checked={tokenAStatus==="checked"?true:false}  onClick={()=>changeRadioStatus()}/>
            <label htmlFor="TokenA">TokenA</label>
            <input type={'radio'} id={"TokenB"} checked={tokenBStatus==="checked"?true:false} onClick={()=>changeRadioStatus()}/>
            <label htmlFor='TokenB'>TokenB</label>
            <input className='search_Token' onChange={(e)=>selectToken(e)}/>
        </div>);
};

export default AddToken;
