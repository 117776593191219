import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import Web3 from "web3";
import Factory from "../abi/Factory.json";
import Pair from "../abi/Pair.json";
import ERC20 from "../abi/ERC20.json";
import PairList from "../containers/PairList";
import { useDispatch, useSelector } from "react-redux";
import { setPair, setToken_0, setToken_1 } from "../modules/remove";
import { calcDecimal, returnDecimal } from "../common/commonService";
import { isBigNumber } from "web3-utils";
import safemath from "safemath";
import BN from "bn.js";
import { setLiquidity, setTokenA, setTokenB } from "../modules/liquidity";
import styles from "../assets/css/Liquidity.module.css";

const Liquidity = ({
  defaultProvider,
  wCoinSymbol,
  coinSymbol,
  handleConnect,
  providerWeb3,
  web3Provider,
  account,
  FACTORY_ADDRESS,
  ROUTER_ADDRESS,
  WMMT_ADDRESS,
  chainId,
  active,
}) => {
  // const web3 = web3Provider;

  const [loading, setLoading] = useState(false);
  const [pairList, setPairList] = useState([]);

  const dispatch = useDispatch();

  const token_0 = useSelector((state) => state.remove.token_0);
  const token_1 = useSelector((state) => state.remove.token_1);
  const pair = useSelector((state) => state.remove.pair);

  const transactionHash = useSelector((state) => state.user.transactionHash)

  // const [chain, setChain] = useState("MMT");

  // useEffect(() => {
  //   if (chainId == 8898 || chainId == 88998) {
  //     setChain("MMT");
  //   } else if (chainId == 97) {
  //     setChain("BNB");
  //   } else if (chainId == 5 || chainId == 1) {
  //     setChain("ETH");
  //   } else if (chainId == 898989 || chainId == 8989) {
  //     setChain("GMMT");
  //   }
  //   setLoading(false);
  //   console.log("kl")
  // }, [chainId]);

  const getPairsList = async () => {
    setLoading(false);
    try {
      if (defaultProvider != undefined && FACTORY_ADDRESS != "" && active) {
        const web3 = defaultProvider;
        // const web3 = new Web3(providerWeb3);
        const pairsList = [];
        const contract = new web3.eth.Contract(Factory, FACTORY_ADDRESS);
        const allPairsLength = await contract.methods.allPairsLength().call();
        setPairList([]);
        if (account != "") {
          for (let i = 0; i < allPairsLength; i++) {
            pairsList.push(
              new Promise(async (resolve, reject) => {
                let pairAddress = await contract.methods.allPairs(i).call();
                let pairContract = new web3.eth.Contract(Pair, pairAddress);
                const pairDecimal = await pairContract.methods
                  .decimals()
                  .call();
                const pairGetReserves = await pairContract.methods
                  .getReserves()
                  .call();
                const pairTotalSupply = await pairContract.methods
                  .totalSupply()
                  .call();

                let pairBalance = "0";
                let reservesPercent = 0;

                if (pairTotalSupply != "0") {
                  pairBalance = await pairContract.methods
                    .balanceOf(account)
                    .call();
                  reservesPercent = ((pairBalance / pairTotalSupply) *100).toFixed(2);
                  pairBalance = web3.utils.fromWei(pairBalance);
                  // reservesPercent = (new BN(`${pairBalance}`).div(new BN(`${pairTotalSupply}`))).mul(new BN(`${100}`)).toString();
                }
                // web3.utils.toWei
                if (pairBalance != "0") {
                  const token0 = await pairContract.methods.token0().call();
                  const token1 = await pairContract.methods.token1().call();
                  const token0Contract = new web3.eth.Contract(ERC20, token0);
                  const token1Contract = new web3.eth.Contract(ERC20, token1);
                  //token0
                  const token0Symbol = await token0Contract.methods
                    .symbol()
                    .call();
                  const token0Name = await token0Contract.methods.name().call();
                  const token0Decimal = await token0Contract.methods
                    .decimals()
                    .call();
                  let decimalPowToken0 = new BN(`${10}`)
                    .pow(new BN(`${token0Decimal}`))
                    .toString();
                  let re_DecimalsToken0 = returnDecimal(
                    defaultProvider,
                    decimalPowToken0
                  );
                  let token0Image;
                  if (
                    token0Symbol == "ETH" ||
                    token0Symbol == "WMMT" ||
                    token0Symbol == "MMT" ||
                    token0Symbol == "BTC"
                  ) {
                    token0Image = token0Symbol;
                  } else {
                    token0Image = "";
                  }

                  // const token0HaveAmount = web3.utils.fromWei((new BN(`${pairGetReserves[0]}`).mul(new BN(`${Math.round(reservesPercent * 10000)}`))).div(new BN(`${1000000}`)).toString(),re_DecimalsToken0);
                  let haveAmount_0 = new BN(`${pairGetReserves[0]}`)
                    .mul(new BN(`${Math.round(reservesPercent * 10000)}`))
                    .div(new BN(`${1000000}`))
                    .toString();
                  const token0HaveAmount = fromWeiBalance(
                    haveAmount_0,
                    token0Decimal
                  );
                  //token1
                  const token1Symbol = await token1Contract.methods
                    .symbol()
                    .call();
                  const token1Name = await token1Contract.methods.name().call();
                  const token1Decimal = await token1Contract.methods
                    .decimals()
                    .call();
                  let decimalPowToken1 = new BN(`${10}`)
                    .pow(new BN(`${token1Decimal}`))
                    .toString();
                  let re_DecimalsToken1 = returnDecimal(
                    defaultProvider,
                    decimalPowToken1
                  );

                  // const token1HaveAmount = web3.utils.fromWei((new BN(`${pairGetReserves[1]}`).mul(new BN(`${Math.round(reservesPercent * 10000)}`))).div(new BN(`${1000000}`)).toString(),re_DecimalsToken1);
                  let haveAmount_1 = new BN(`${pairGetReserves[1]}`)
                    .mul(new BN(`${Math.round(reservesPercent * 10000)}`))
                    .div(new BN(`${1000000}`))
                    .toString();
                  const token1HaveAmount = fromWeiBalance(
                    haveAmount_1,
                    token1Decimal
                  );

                  let token1Image;
                  if (
                    token1Symbol == "ETH" ||
                    token1Symbol == "WMMT" ||
                    token1Symbol == "MMT" ||
                    token1Symbol == "BTC"
                  ) {
                    token1Image = token1Symbol;
                  } else {
                    token1Image = "";
                  }
                  const data = {
                    token0: {
                      address: token0,
                      symbol: token0Symbol,
                      name: token0Name,
                      decimal: token0Decimal,
                      image: token0.toLowerCase(),
                      haveAmount: token0HaveAmount,
                    },
                    token1: {
                      address: token1,
                      symbol: token1Symbol,
                      name: token1Name,
                      decimal: token1Decimal,
                      image: token1.toLowerCase(),
                      haveAmount: token1HaveAmount,
                    },
                    pair: {
                      address: pairAddress,
                      haveAmount: pairBalance,
                      decimal: pairDecimal,
                      reservesPercent: reservesPercent,
                    },
                  };
                  // pairsList.push(data)
                  resolve(data);
                } else {
                  resolve("");
                }
              })
            );
          }
          Promise.all(pairsList).then((values) => {
            let result = values.filter((e) => {
              return e != "";
            });
            setPairList(result);
            setLoading(true);
          });
        }
        //  else {
        //   setPairList([]);
        //   setLoading(true);
        // }
      } else {
        setPairList([]);

        setTimeout(() => {
          setLoading(true);
        },[1000])
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const clickRemoveLiquiidityBtn = async (
    set_token_0,
    set_token_1,
    set_pair
  ) => {
    const web3 = defaultProvider;

    let remove0 = set_token_0.address;
    let remove1 = set_token_1.address;
    const factoryContract = new web3.eth.Contract(Factory, FACTORY_ADDRESS);
    // 페어 주소 가져오기
    let pairAddress = await factoryContract.methods
      .getPair(remove0, remove1)
      .call();
    let pairContract = new web3.eth.Contract(Pair, set_pair.address);
    const pairDecimal = await pairContract.methods.decimals().call();
    let pairBalance = await pairContract.methods.balanceOf(account).call();
    const pairTotalSupply = await pairContract.methods.totalSupply().call();
    const pairtoken0 = await pairContract.methods.token0().call();
    const pairtoken1 = await pairContract.methods.token1().call();
    const pairName = await pairContract.methods.name().call();

    // 페어의 최소 liquidity
    let min = await pairContract.methods.MINIMUM_LIQUIDITY().call();
    let defaultDecimal = web3.utils.toWei("1");
    // 내가 가지고 있는 lp 퍼센트
    let reservesPercent = ((pairBalance / pairTotalSupply) * 100).toFixed(4);
    const pairGetReserves = await pairContract.methods.getReserves().call();
    let remove0_reserve;
    let remove1_reserve;
    if (remove0 == pairtoken0 && remove1 == pairtoken1) {
      remove0 = pairtoken0;
      remove1 = pairtoken1;

      remove0_reserve = pairGetReserves[0];
      remove1_reserve = pairGetReserves[1];
    } else if (remove0 == pairtoken1 && remove1 == pairtoken0) {
      remove0 = pairtoken1;
      remove1 = pairtoken0;

      remove0_reserve = pairGetReserves[1];
      remove1_reserve = pairGetReserves[0];
    }

    // pairBalance = web3.utils.fromWei(await pairContract.methods.balanceOf(account).call());
    pairBalance = web3.utils.fromWei(pairBalance);
    let pairData = {
      address: pairAddress,
      haveAmount: pairBalance,
      decimal: pairDecimal,
      reservesPercent: reservesPercent,
      pairName: pairName,
    };
    // token0
    const token0Contract = new web3.eth.Contract(ERC20, remove0);
    const token0Symbol = await token0Contract.methods.symbol().call();
    const token0Decimal = await token0Contract.methods.decimals().call();
    let decimalPowToken0 = new BN(`${10}`)
      .pow(new BN(`${token0Decimal}`))
      .toString();
    let re_DecimalsToken0 = returnDecimal(defaultProvider, decimalPowToken0);
    // // token0의
    // const token0HaveAmount = web3.utils.fromWei((new BN(`${remove0_reserve}`).mul(new BN(`${Math.round(reservesPercent * 10000)}`))).div(new BN(`${1000000}`)).toString(),re_DecimalsToken0);
    let token0HaveAmountPercent = new BN(`${remove0_reserve}`)
      .mul(new BN(`${Math.round(reservesPercent * 10000)}`))
      .div(new BN(`${1000000}`))
      .toString();
    const token0HaveAmount = fromWeiBalance(
      token0HaveAmountPercent,
      token0Decimal
    );
    const token0HaveAmount_min = new BN(`${remove0_reserve}`)
      .mul(new BN(`${min}`))
      .div(new BN(`${defaultDecimal}`))
      .toString();
    let token0Image;
    if (
      token0Symbol == "ETH" ||
      token0Symbol == "WMMT" ||
      token0Symbol == "MMT" ||
      token0Symbol == "BTC"
    ) {
      token0Image = token0Symbol;
    } else {
      token0Image = "";
    }
    const remove0_data = {
      address: remove0,
      symbol: token0Symbol,
      decimal: token0Decimal,
      haveAmountMin: 1000,
      image: remove0.toLowerCase(),
      // haveAmountMin:token0HaveAmount_min,
      haveAmount: token0HaveAmount,
    };
    // // token1
    const token1Contract = new web3.eth.Contract(ERC20, remove1);
    const token1Symbol = await token1Contract.methods.symbol().call();
    const token1Decimal = await token1Contract.methods.decimals().call();
    let decimalPowToken1 = new BN(`${10}`)
      .pow(new BN(`${token1Decimal}`))
      .toString();
    let re_DecimalsToken1 = returnDecimal(defaultProvider, decimalPowToken1);

    // const token1HaveAmount = web3.utils.fromWei((new BN(`${remove1_reserve}`).mul(new BN(`${Math.round(reservesPercent * 10000)}`))).div(new BN(`${1000000}`)).toString(),re_DecimalsToken1);
    let token1HaveAmountPercent = new BN(`${remove1_reserve}`)
      .mul(new BN(`${Math.round(reservesPercent * 10000)}`))
      .div(new BN(`${1000000}`))
      .toString();
    const token1HaveAmount = fromWeiBalance(
      token1HaveAmountPercent,
      token1Decimal
    );

    const token1HaveAmount_min = new BN(`${remove1_reserve}`)
      .mul(new BN(`${min}`))
      .div(new BN(`${defaultDecimal}`))
      .toString();
    let token1Image;
    if (
      token1Symbol == "ETH" ||
      token1Symbol == "WMMT" ||
      token1Symbol == "MMT" ||
      token1Symbol == "BTC"
    ) {
      token1Image = token1Symbol;
    } else {
      token1Image = "";
    }

    const remove1_data = {
      address: remove1,
      symbol: token1Symbol,
      decimal: token1Decimal,
      // haveAmountMin:token1HaveAmount_min,
      haveAmountMin: 1000,
      image: remove1.toLowerCase(),
      haveAmount: token1HaveAmount,
    };
    dispatch(setPair(pairData));
    dispatch(setToken_0(remove0_data));
    dispatch(setToken_1(remove1_data));
  };

  const onClickAddLiquidityBtn = (set_token_0, set_token_1) => {
    let data = {
      setA: {
        address: set_token_0.address,
        symbol: set_token_0.symbol,
        name: set_token_0.name,
        haveAmount: set_token_0.haveAmount,
        decimal: set_token_0.decimal,
        image: set_token_0.address.toLowerCase(),
        inputAmount: "",
      },
      setB: {
        address: set_token_1.address,
        symbol: set_token_1.symbol,
        name: set_token_1.name,
        haveAmount: set_token_1.haveAmount,
        decimal: set_token_1.decimal,
        image: set_token_1.address.toLowerCase(),
        inputAmount: "",
      },
    };

    dispatch(setLiquidity(data));
    // dispatch(setTokenA(set_token_0));
    // dispatch(setTokenB(set_token_1));
  };

  // useEffect(() => {
  useLayoutEffect(() => {
    // setLoading(false);
    getPairsList();
  }, [
    // pair,
    transactionHash,
    defaultProvider,
    account,
    active,
    FACTORY_ADDRESS,
  ]);

  const onClickCreateLiquidity = () => {
    const reset = {
      address: "",
      symbol: "",
      name: "",
      decimal: 0,
      inputAmount: "",
      haveAmount: 0,
      image: "",
    };
    dispatch(setTokenA(reset));
    dispatch(setTokenB(reset));
  };

  const fromWeiBalance = (balance, decimal) => {
    let result = new BN(`${10}`).pow(new BN(`${decimal}`)).toString();
    let fromWeiBalance = new BN(`${balance}`).divmod(new BN(`${result}`));
    let divBalance = fromWeiBalance.div.toString();
    let modBalance = fromWeiBalance.mod.toString();
    let subDecimal = decimal - modBalance.length;
    let zero = "";
    for (let i = 0; i < subDecimal; i++) {
      zero += "0";
    }

    let returnBalance = divBalance + "." + zero + modBalance;
    return returnBalance;
  };

  const toWeiBalance = (balance, decimal) => {
    if (web3Provider != undefined) {
      let point = balance.split(".");
      let pointPow = 1;
      let powLength = 0;
      let lastPoint = "";
      if (point[1] != undefined) {
        powLength = point[1].length;
        pointPow = new BN(`${10}`).pow(new BN(`${point[1].length}`)).toString();
        lastPoint = point[1].substr(0, decimal);
      }

      let balancePoinPow = point[0] + lastPoint;

      let subDecimal = new BN(`${decimal}`)
        .sub(new BN(`${powLength}`))
        .toString();

      let result = new BN(`${10}`).pow(new BN(`${subDecimal}`)).toString();
      let decimalBalance = new BN(`${balancePoinPow}`)
        .mul(new BN(`${result}`))
        .toString();

      return decimalBalance;
    }
  };

  const onErrorImg = (e) => {
    e.target.src = "logo_.png";
  };

  const onClickTitle = (pageName) => {
    const htmlTitle = document.querySelector("title");
    htmlTitle.innerHTML = pageName;
  };

  useEffect(() => {
    onClickTitle("Liquidity - Ivory Swap");
  }, []);

  return (
    <div className={styles.swapBg}>
      <div className={styles.wrap}>
        <div className={styles.title}>Liquidity</div>

        <div className={styles.liqTitle}>
          Provide liquidity on Ivoryswap
          <img
            src={require("../assets/images/img_tip_wh@2x.png")}
            alt={"img_tip_wh"}
          />
        </div>

        <div className={styles.container}>
          {/* <div className={styles.goInfo}>
                        <Link to={"/info"}>
                            <button>
                                View LP
                            </button>
                        </Link>
                    </div> */}
          <PairList
            wCoinSymbol={wCoinSymbol}
            coinSymbol={coinSymbol}
            active={active}
            providerWeb3={providerWeb3}
            web3Provider={web3Provider}
            pairList={pairList}
            account={account}
            loading={loading}
            clickRemoveLiquiidityBtn={clickRemoveLiquiidityBtn}
            ROUTER_ADDRESS={ROUTER_ADDRESS}
            WMMT_ADDRESS={WMMT_ADDRESS}
            onClickAddLiquidityBtn={onClickAddLiquidityBtn}
            chainId={chainId}
            onErrorImg={onErrorImg}
            defaultProvider={defaultProvider}
          />

          {active ? (
            <Link
              to={"/add"}
              onClick={() => {
                onClickTitle("AddLiquidity - Ivory Swap");
              }}
            >
              <button
                className={styles.addBtn}
                onClick={() => {
                  onClickCreateLiquidity();
                }}
              >
                <span className={styles.mo_plus}>
                  <span></span>
                  <span></span>
                </span>
                <span className={styles.pc_plus}>
                  <img
                    src={require("../assets/images/swap/ico_plus_bl@2x.png")}
                    alt={"plus_bl"}
                  />
                </span>{" "}
                Create a liquidity
              </button>
            </Link>
          ) : (
            <button className={styles.addBtn} onClick={handleConnect}>
              Connect Wallet
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Liquidity;
