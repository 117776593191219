import { createAction, handleAction, handleActions } from 'redux-actions';
import produce from 'immer';

const initialState = {
    token_0: {
        address: "",
        symbol: "",
        name: "",
        decimal: 0,
        haveAmount: 0,
        inputAmount: 0,
        haveAmountMin:0,
        image:"",
        token_allowance: true
    },
    token_1: {
        address: "",
        symbol: "",
        name: "",
        decimal: 0,
        haveAmount: 0,
        inputAmount: 0,
        haveAmountMin:0,
        image:"",
        token_allowance: true
    },
    pair: {
        address: "",
        symbol: "",
        name: "",
        decimal: 0,
        haveAmount: 0,
        inputAmount: 0,
        reservesPercent:0,
        token_allowance: true
    },
};

// Pair
const SETPAIR = "remove/SETPAIR";
export const setPair = createAction(SETPAIR, (pair) => pair);

const SETPAIR_INPUT = "remove/SETPAIR_INPUT";
export const setPair_input = createAction(SETPAIR_INPUT, (pair) => pair);

// Token_0;
const SETTOKEN_0 = "remove/SETTOKEN_0";
export const setToken_0 = createAction(SETTOKEN_0, (token_0) => token_0);

const SETTOKEN_0_INPUT = "remove/SETTOKEN_0_INPUT";
export const setToken_0_Input = createAction(SETTOKEN_0_INPUT, (token_0) => token_0);

const SETTOKEN_0_SYMBOL = "remove/SETTOKEN_0_SYMBOL";
export const setToken_0_Symbol = createAction(SETTOKEN_0_SYMBOL, (token_0)=>token_0);

// Token_1;
const SETTOKEN_1 = "remove/SETTOKEN_1";
export const setToken_1 = createAction(SETTOKEN_1, (token_1) => token_1);

const SETTOKEN_1_INPUT = "remove/SETTOKEN_1_INPUT";
export const setToken_1_Input = createAction(SETTOKEN_1_INPUT, (token_1) => token_1);

const SETTOKEN_1_SYMBOL = "remove/SETTOKEN_1_SYMBOL";
export const setToken_1_Symbol = createAction(SETTOKEN_1_SYMBOL, (token_1)=>token_1);

const remove = handleActions({
    [SETPAIR]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.pair.address = input.address;
            draft.pair.haveAmount = input.haveAmount;
            draft.pair.decimal = input.decimal;
            draft.pair.reservesPercent = input.reservesPercent;
            draft.pair.name = input.pairName;
        }),
    [SETTOKEN_0]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.token_0.address = input.address
            draft.token_0.symbol = input.symbol
            draft.token_0.decimal = input.decimal
            draft.token_0.haveAmountMin = input.haveAmountMin
            draft.token_0.image = input.image
            draft.token_0.haveAmount = input.haveAmount;
        }),
    [SETTOKEN_1]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.token_1.address = input.address
            draft.token_1.symbol = input.symbol
            draft.token_1.decimal = input.decimal
            draft.token_1.haveAmountMin = input.haveAmountMin
            draft.token_1.image = input.image
            draft.token_1.haveAmount = input.haveAmount
        }),
    [SETTOKEN_0_INPUT]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.token_0.inputAmount = input
        }),
    [SETTOKEN_1_INPUT]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.token_1.inputAmount = input
        }),
    [SETPAIR_INPUT] : (state, {payload:input})=>
    produce(state, (draft) => {
        draft.pair.inputAmount = input
    }),
    [SETTOKEN_0_SYMBOL]:(state, {payload:input})=>
    produce(state,(draft)=>{
        draft.token_0.symbol = input.symbol
        draft.token_0.image = input.image
    }),
    [SETTOKEN_1_SYMBOL]:(state, {payload:input})=>
    produce(state,(draft)=>{
        draft.token_1.symbol = input.symbol
        draft.token_1.image = input.image

    }),

}, initialState);


export default remove;