import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ERC20JSON from "../abi/ERC20.json";
import Router from "../abi/Router.json";
import Factory from "../abi/Factory.json";
import Pair from "../abi/Pair.json";
import ERC20 from "../abi/ERC20.json";
import { BN } from "bn.js";

import styles from "../assets/css/Info.module.css";
import Web3 from "web3";

const Info = ({
  defaultProvider,
  providerWeb3,
  web3Provider,
  account,
  FACTORY_ADDRESS,
  ROUTER_ADDRESS,
  chainId,
}) => {
  const [infoList, setInfoList] = useState([]);
  const [viewInfoList, setViewInfoList] = useState("");
  const [totalPageNum, setTotalPageNum] = useState([]);
  const [nowPageNum, setNowPageNum] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const [sortLiquidty, setSortLiquidity] = useState(true);

  const [sizeup, setSizeup] = useState(false);
  const sizeupDoc = useRef(0);

  const [chain, setChain] = useState("MMT");

  useEffect(() => {
    if (chainId == 8898 || chainId == 88998) {
      setChain("MMT");
    } else if (chainId == 97) {
      setChain("BNB");
    } else if (chainId == 5 || chainId == 1) {
      setChain("ETH");
    } else if (chainId == 898989 || chainId == 8989) {
      setChain("GMMT");
    }
    setTotalPageNum(0);
  }, [chainId]);

  const fromWeiBalance = (balance, decimal) => {
    let result = new BN(`${10}`).pow(new BN(`${decimal}`)).toString();
    let fromWeiBalance = new BN(`${balance}`).divmod(new BN(`${result}`));
    let divBalance = fromWeiBalance.div.toString();
    let modBalance = fromWeiBalance.mod.toString();
    let subDecimal = decimal - modBalance.length;
    let zero = "";
    for (let i = 0; i < subDecimal; i++) {
      zero += "0";
    }

    let returnBalance = divBalance + "." + zero + modBalance;
    return returnBalance;
  };

  const toWeiBalance = (balance, decimal) => {
    // if (web3Provider != undefined) {
    let point = balance.split(".");
    let pointPow = 1;
    let powLength = 0;
    let lastPoint = "";
    if (point[1] != undefined) {
      powLength = point[1].length;
      pointPow = new BN(`${10}`).pow(new BN(`${point[1].length}`)).toString();
      lastPoint = point[1].substr(0, decimal);
    }

    let balancePoinPow = point[0] + lastPoint;

    let subDecimal = new BN(`${decimal}`)
      .sub(new BN(`${powLength}`))
      .toString();

    let result = new BN(`${10}`).pow(new BN(`${subDecimal}`)).toString();
    let decimalBalance = new BN(`${balancePoinPow}`)
      .mul(new BN(`${result}`))
      .toString();

    return decimalBalance;
    // }
  };

  const getInfoList = async () => {
    let list = [];
    // if (web3Provider != undefined && account !="") {
    if (defaultProvider != undefined && FACTORY_ADDRESS != "") {
      const web3 = defaultProvider;
      try {
        const factoryContract = new web3.eth.Contract(Factory, FACTORY_ADDRESS);
        // console.log(await factoryContract.methods.feeTo().call())
        const allPairsLength = await factoryContract.methods
          .allPairsLength()
          .call();
        if (allPairsLength == 0) {
          setInfoList([]);
        } else {
          for (let i = 0; i < allPairsLength; i++) {
            list.push(
              new Promise(async (resolve, reject) => {
                const pairAddress = await factoryContract.methods
                  .allPairs(i)
                  .call();
                const pairContract = new web3.eth.Contract(Pair, pairAddress);
                const token0Address = await pairContract.methods
                  .token0()
                  .call();
                const token1Address = await pairContract.methods
                  .token1()
                  .call();
                const getReserves = await pairContract.methods
                  .getReserves()
                  .call();

                let price = "1";

                let balance0 = new BN(`${getReserves[0]}`)
                  .mul(new BN(`${price}`))
                  .toString();
                let balance1 = new BN(`${getReserves[1]}`)
                  .mul(new BN(`${price}`))
                  .toString();

                const token0Contract = new web3.eth.Contract(
                  ERC20,
                  token0Address
                );
                const token1Contract = new web3.eth.Contract(
                  ERC20,
                  token1Address
                );

                const token0Symbol = await token0Contract.methods
                  .symbol()
                  .call();
                const token1Symbol = await token1Contract.methods
                  .symbol()
                  .call();

                const token0Name = await token0Contract.methods.name().call();
                const token1Name = await token1Contract.methods.name().call();

                const token0Decimals = await token0Contract.methods
                  .decimals()
                  .call();
                const token1Decimals = await token1Contract.methods
                  .decimals()
                  .call();

                let token0GetReserves = fromWeiBalance(
                  balance0,
                  token0Decimals
                );
                let token1GetReserves = fromWeiBalance(
                  balance1,
                  token1Decimals
                );

                let liquidity =
                  Number(token0GetReserves) + Number(token1GetReserves);

                let decimalBig =
                  Number(token0Decimals) > Number(token1Decimals)
                    ? token0Decimals
                    : token1Decimals;

                let data = {
                  index: i + 1,
                  pairAddress: pairAddress.toLowerCase(),
                  token0Address: token0Address.toLowerCase(),
                  token0Symbol: token0Symbol,
                  token0Name: token0Name,
                  token0GetReserves: token0GetReserves,
                  token1Address: token1Address.toLowerCase(),
                  token1Symbol: token1Symbol,
                  token1Name: token1Name,
                  token1GetReserves: token1GetReserves,
                  liquidity: liquidity.toFixed(decimalBig),
                  sizeup: false,
                };

                resolve(data);
              })
            );

            // list.push(data);
          }
          Promise.all(list).then((values) => {
            setInfoList(values);
          });
        }
      } catch (error) {
        console.log(error);
        setInfoList(list);
      }
    } else {
      setInfoList(list);
    }
  };

  const onClickPageButton = (i) => {
    setNowPageNum(i + 1);
  };

  const pageButton = () => {
    const result = [];

    for (let i = 0; i < totalPageNum + 1; i++) {
      result.push(
        <div
          style={{
            display: "inline-block",
            marginLeft: "25px",
            color: `${nowPageNum == i + 1 ? "blue" : "black"}`,
          }}
          key={i}
          onClick={() => onClickPageButton(i)}
        >
          {i + 1}
        </div>
      );
    }

    return result;
  };

  const viewPairList = (page, text) => {
    let result_1 = [];
    let toLowerCaseText = text.toLowerCase();
    if (infoList != "") {
      for (let i = 0; i < infoList.length; i++) {
        // token0
        let token0SymboltoLowerCase = infoList[i].token0Symbol.toLowerCase();
        let token0NametoLowerCase = infoList[i].token0Name.toLowerCase();

        // token1
        let token1SymboltoLowerCase = infoList[i].token1Symbol.toLowerCase();
        let token1NametoLowerCase = infoList[i].token1Name.toLowerCase();

        if (token0SymboltoLowerCase.includes(toLowerCaseText)) {
          result_1.push(infoList[i]);
        } else if (token0NametoLowerCase.includes(toLowerCaseText)) {
          result_1.push(infoList[i]);
        } else if (token1SymboltoLowerCase.includes(toLowerCaseText)) {
          result_1.push(infoList[i]);
        } else if (token1NametoLowerCase.includes(toLowerCaseText)) {
          result_1.push(infoList[i]);
        } else if (toLowerCaseText == "") {
          result_1.push(infoList[i]);
        }
      }

      let result_2 = [];

      result_1.sort((a, b) => {
        let sortLiquidtyQ1 = a.liquidity - b.liquidity;
        let sortLiquidtyQ2 = b.liquidity - a.liquidity;

        if (sortLiquidty) {
          return sortLiquidtyQ2;
        } else {
          return sortLiquidtyQ1;
        }
      });

      result_2 = result_1;

      let result_3 = [];
      for (let i = limit * page - limit; i < limit * page; i++) {
        if (result_2[i] == undefined) {
          break;
        }
        result_3.push(result_1[i]);
      }

      if (Array.isArray(result_3) && result_3.length === 0) {
        result_3 = "No results found.";
      }
      let totalPageNum = Math.floor(result_1.length / limit);

      setTotalPageNum(totalPageNum);
      setViewInfoList(result_3);
      setTimeout(() => {
        setLoading(false);
      }, [1000]);
    } else {
      setViewInfoList("No results found.");
      setLoading(false);
    }
  };

  const onClickSortByLiquidity = () => {
    setSortLiquidity(!sortLiquidty);
  };

  const onErrorImg = (e) => {
    e.target.src = "logo_.png";
  };

  const balanceInCode = (balance) => {
    if (
      balance == undefined ||
      balance.length == undefined ||
      balance == "-" ||
      balance == "" ||
      Number(balance) == 0
    ) {
      return 0;
    } else {
      let splitBalance = balance.split(".");

      let result_1 = splitBalance[0] + "." + splitBalance[1].substring(0, 2);
      if (result_1 == "0.00") return result_1;
      result_1 = (result_1 * 100) / 100;
      return result_1;
    }
  };

  const onChangeSearchText = (e) => {
    setSearchText(e.target.value);
    setNowPageNum(1);
  };

  const onClickPrevious = () => {
    if (Number(nowPageNum - 1) != 0) {
      setNowPageNum(nowPageNum - 1);
    }
  };

  const onClickNext = () => {
    if (Number(nowPageNum) != totalPageNum + 1) {
      setNowPageNum(nowPageNum + 1);
    }
  };

  const toLocaleStringOption = (amount) => {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  const onClickSizeup = (pairAddress) => {
    setViewInfoList(
      viewInfoList.map((info) =>
        info.pairAddress == pairAddress
          ? { ...info, sizeup: !info.sizeup }
          : info
      )
    );
    // setViewInfoList((prevData) => ({
    //     ...prevData,
    //     prevData[index]:{

    //     }
    // }))
  };

  // useEffect(() => {
  useLayoutEffect(() => {
    // let boolean = Array.isArray(infoList) && infoList.length === 0;
    viewPairList(nowPageNum, searchText);
    // if(account == ""){
    //     setLoading(false);
    //     setViewInfoList("");
    // }else if (!boolean) {
    //     setLoading(true)
    //     viewPairList(nowPageNum, searchText);
    // }
  }, [nowPageNum, infoList, searchText, sortLiquidty]);

  useEffect(() => {
    setLoading(true);
    setNowPageNum(1);
    setTotalPageNum(0);
    getInfoList();
  }, [
    // account,
    FACTORY_ADDRESS,
    // ROUTER_ADDRESS,
    defaultProvider,
  ]);
    

  return (
    <div className={styles.bg}>
      <div className={styles.searchWrap}>
        <div className={styles.searchFilter}>
          <div className={styles.filter_left}>
            {/* <button className={styles.active} onClick={() => { }}>Pools</button> */}
          </div>
          <div className={styles.search}>
            <span>Search</span>
            <input
              className={styles.inputBox}
              type={"text"}
              placeholder={"Search pools"}
              value={searchText}
              onChange={(e) => {
                onChangeSearchText(e);
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.tableWrap}>
        <p className={styles.tit}>All Pools</p>
        <div className={styles.tagleXscroll}>
          <div className={styles.tableWidth}>
            <div className={`${styles.thead} ${styles.twidth}`}>
              <div className={styles.num}>No</div>
              <div className={styles.cont}>Pool</div>
              <div className={styles.volumeh}></div>
              <div
                className={styles.liquidity}
                style={{ cursor: "pointer", width: "35rem" }}
                onClick={() => {
                  onClickSortByLiquidity();
                }}
              >
                Liquidity{sortLiquidty ? "↓" : "↑"}
              </div>
              <div className={styles.volumed}></div>
              <div className={styles.lpFee}>
                <br />
              </div>
              <div className={styles.lpApr}>
                <br />
              </div>
              {/* <div className={styles.cont}>Pool</div>
                            <div className={styles.volumeh}>Volume 24H</div>
                            <div className={styles.volumed}>Volume 7D</div>
                            <div className={styles.lpFee}>LP Reward<br />Fees 24H</div>
                            <div className={styles.lpApr}>LP Reward<br />APR</div> */}
            </div>
            <div className={styles.tbodyWrap}>
              {loading === true ? (
                // <div style={{ "padding": "20rem", "textAlign": "center" }}>
                <div className={styles.loadingWrap}>
                  <img
                    src={require("../assets/images/swap/loading.gif")}
                    alt={"loading.gif"}
                  />
                </div>
              ) : (
                <>
                  {
                    // account == "" ?
                    //     <div  style={{ "padding": "20rem", "textAlign": "center","fontSize":"3.5rem","color":"#999"}}>
                    //        Connect to a wallet to view pools.
                    //     </div> :
                    // (viewInfoList=="No results found.") ?
                    viewInfoList == "" ? (
                      <div style={{ padding: "20rem", textAlign: "center" }}>
                        <img
                          src={require("../assets/images/swap/loading.gif")}
                          alt={"loading.gif"}
                        />
                      </div>
                    ) : viewInfoList == "No results found." ? (
                      <div
                        style={{
                          padding: "20rem",
                          textAlign: "center",
                          fontSize: "3.5rem",
                          color: "#999",
                        }}
                      >
                        No results found.
                      </div>
                    ) : (
                      <>
                        {viewInfoList.map((item, index) => (
                          <div
                            key={index}
                            className={`${styles.tbody} ${styles.twidth}`}
                          >
                            <div className={styles.num}>
                              {10 * (nowPageNum - 1) + index + 1}
                            </div>
                            <div
                              className={`${styles.cont} ${
                                item.sizeup ? styles.sizeup : ""
                              }`}
                              onClick={() => {
                                onClickSizeup(item.pairAddress);
                              }}
                              ref={sizeupDoc}
                            >
                              <img
                                src={`${chain}/logo_${item.token0Address}.png`}
                                onError={onErrorImg}
                                alt={`sym_${item.token0Symbol}`}
                              />
                              <img
                                src={`${chain}/logo_${item.token1Address}.png`}
                                onError={onErrorImg}
                                alt={`sym_${item.token1Symbol}`}
                              />
                              <span
                                className={`${styles.name} ${
                                  item.sizeup ? styles.sizeup : ""
                                }`}
                                title={`${item.token0Symbol}/${item.token1Symbol}`}
                              >
                                {item.token0Symbol} <span>/</span>{" "}
                                {item.token1Symbol}
                              </span>
                            </div>
                            <div className={styles.volumeh}></div>
                            <div
                              className={styles.liquidity}
                              style={{ width: "35rem" }}
                            >
                              {/* $ */}
                              {toLocaleStringOption(
                                balanceInCode(item.liquidity)
                              )}
                            </div>
                            <div className={styles.volumed}></div>
                            <div className={styles.lpFee}></div>
                            <div className={styles.lpApr}></div>
                          </div>

                          // <div key={index} style={{ "borderBottom": "1px solid black" }}>
                          //     <div style={{ "display": "inline-block", "marginLeft": "30px" }}>
                          //         <img src={`logo_${item.token0Address}.png`} onError={onErrorImg} style={{ "width": "35px" }} />
                          //         <img src={`logo_${item.token1Address}.png`} onError={onErrorImg} style={{ "width": "35px" }} />
                          //     </div>
                          //     <div style={{ "display": "inline-block", "marginLeft": "30px" }}>
                          //         <span>{item.token0Symbol}</span>/
                          //         <span>{item.token1Symbol}</span>
                          //     </div>
                          //     <div style={{ "display": "inline-block", "marginLeft": "30px" }}>
                          //         <div>{balanceInCode(item.liquidity)}</div>
                          //     </div>
                          // </div>
                        ))}
                      </>
                    )
                  }
                </>
              )}
              {/* 

                            <div className={`${styles.tbody} ${styles.twidth}`}>
                                <div className={styles.num}>1</div>
                                <div className={styles.cont}>
                                    <img src={require("../assets/images/info/sym_usdc@2x.png")} alt={"sym_usdc"} />
                                    <img src={require("../assets/images/info/sym_ivy@2x.png")} alt={"sym_ivy"} />
                                    <span className={styles.name}>USDC/IVY</span>
                                </div>
                                <div className={styles.volumeh}>$8,824,875</div>
                                <div className={styles.volumed}>$73,055,970</div>
                                <div className={styles.lpFee}>$17,650</div>
                                <div className={styles.lpApr}>28.53%</div>
                                <div className={styles.liquidity}>$22,580,475</div>
                            </div> */}

              {/* 페이징 */}
              {/* <div className={styles.paging}>
                                <button type={"button"} className={`${styles.arrowL} ${nowPageNum==1?styles.off:styles.on}`}  onClick={() => { onClickPrevious() }}></button>
                                <div className={styles.pageNum}>Page <span>{nowPageNum}</span> of <span>{totalPageNum+1}</span></div>
                                <button type={"button"} className={`${styles.arrowR} ${nowPageNum==totalPageNum+1?styles.off:styles.on}`}  onClick={() => { onClickNext() }}></button>
                            </div> */}
            </div>
          </div>
        </div>

        <div className={styles.paging}>
          <button
            type={"button"}
            className={`${styles.arrowL} ${
              nowPageNum == 1 ? styles.off : styles.on
            }`}
            onClick={() => {
              onClickPrevious();
            }}
          ></button>
          <div className={styles.pageNum}>
            Page <span>{nowPageNum}</span> of <span>{totalPageNum + 1}</span>
          </div>
          <button
            type={"button"}
            className={`${styles.arrowR} ${
              nowPageNum == totalPageNum + 1 ? styles.off : styles.on
            }`}
            onClick={() => {
              onClickNext();
            }}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default Info;
