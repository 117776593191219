import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import Header from "./containers/Header";
import AddLiquidity from "./pages/AddLiquidity";
import RemoveLiquidity from "./pages/RemoveLiquidity";
import Swap from "./pages/Swap";
import Error404 from "./pages/Error404";

import { useWeb3React } from "@web3-react/core";
import { injected } from "./lib/connector";
import { isNoEthereumObject } from "./lib/errors";
import Web3 from "web3";
import Liquidity from "./pages/Liquidity";
import Main from "./pages/Main";
import "./App.css";
import Footer from "./containers/Footer";
import ScrollToTop from "./components/ScrollTop";

import WalletConnectProvider from "@walletconnect/web3-provider";

import styles from "./assets/css/App.module.css";
import stylesSwap from "./assets/css/Swap.module.css";
import Farm from "./pages/Farm";
import Stake from "./pages/Stake";
import StakeList from "./pages/StakeList";
import {
  setAccountAddress,
  setChainId,
  setConnectStatus,
} from "./modules/user";
import ComingSoon from "./pages/ComingSoon";
import { setNowChainName } from "./modules/main";
import Info from "./pages/Info";
import Info_Test from "./pages/Info_Test";
import Notice from "./pages/Notice";
import NoticeDetail from "./pages/NoticeDetail";

function App() {
  const dispatch = useDispatch();

  const [active, setActive] = useState(false);
  const [popupShow, setPopupShow] = useState(false);
  const [web3, setWeb3] = useState();
  const [providerWeb3, setProviderWeb3] = useState();
  const [windowWidth, setWindowWidth] = useState("");
  const [netWorkMessage, setNetWorkMessage] = useState("");
  const [nowUrl, setNowUrl] = useState("");
  const [nowUrlStatus, setUrlStatus] = useState(true);
  const [urlList, setUrlList] = useState([
    "",
    "swap",
    "add",
    "liquidity",
    "farm",
    "stake",
    "stakeList",
  ]);
  const [ROUTER_ADDRESS, setROUTER_ADDRESS] = useState("");
  const [FACTORY_ADDRESS, setFACTORY_ADDRESS] = useState("");
  const [WMMT_ADDRESS, setWMMT_ADDRESS] = useState("");
  const [FARM_ADDRESS, setFARM_ADDRESS] = useState("");
  const [IVY_ADDRESS, setIVY_ADDRESS] = useState("");
  const [STAKING_LAB_ADDRESS, setSTAKING_LAB_ADDRESS] = useState("");
  const [sIVY_ADDRESS, setsIVY_ADDRESS] = useState("");
  const [chain, setChain] = useState("");
  const [windowEhter, setWindowEther] = useState();

  const [coinSymbol, setCoinSymbol] = useState("");
  const [wCoinSymbol, setWCoinSymbol] = useState("");

  const [toastPopup, setToastPopup] = useState(false);

  const [mobileHeaderPopup, setMobileHeaderPopup] = useState(true);

  const [mobileCheck, setMobileCheck] = useState(false); // false이면 데탑;

  const [changeNetWorkStatus, setChangeNetWorkStatus] = useState(false);
  const [provider, setProvider] = useState();

  const account = useSelector((state) => state.user.account);
  const chainId = useSelector((state) => state.user.chainId);
  const token_0 = useSelector((state) => state.remove.token_0);
  const token_1 = useSelector((state) => state.remove.token_1);
  const networkName = useSelector((state) => state.main.nowChainName);

  // 체인 변경
  const defaultChainId = process.env.REACT_APP_NETWORK_CHAINID;

  useEffect(() => {
    if (chainId == 0) {
      // if (networkName == "MMT TestNet") {
      if (defaultChainId == "88998") {
        setCoinSymbol("MMT");
        setWCoinSymbol("WMMT");
        // setChain("88998");
        dispatch(setChainId("88998"));
        // feeTo X
        // setROUTER_ADDRESS("0xBa76c9787F9CE3D52a8C37e94AA85f980582EAF4");
        // setFACTORY_ADDRESS("0x336321776C65095309Fe1D66FDB75c19b7e5eBf3");

        // feeTo O
        setROUTER_ADDRESS("0x1F76b70E08f3Fb47556ea7e2ca17DB9074792B6A");
        setFACTORY_ADDRESS("0x56Ab46032f4D3D30E77563E19748749D3dB7c98a");

        setWMMT_ADDRESS("0x6C744BaaC69ba137056c7c6757Ccb08B66D4c8b4");
        // setFARM_ADDRESS("0xb3Ee9f79285eCfEF54D2AB7395931F87382ADee4");
        // setIVY_ADDRESS("0x3F8e065f3cDd720a772956985aaCF68A981A1baf");
        // setSTAKING_LAB_ADDRESS("0x64951D3Fcd3C5d06e5D38D19f188890D5D616d1A");
        // settIVY_ADDRESS("0x515285B5a500Ca84f214f0d9AAf7E1b8bC878199");

        // sIvy O
        // setFARM_ADDRESS("0x1F8364Db56b9Db1F67b910B4b1c5E606D71943D6");
        // setIVY_ADDRESS("0x435ee63E2Bb8e4860F6818A4B14E1663a096d4db");
        // setsIVY_ADDRESS("0x401974F04424f3117C8515C8B932f202eD6F59Ac");
        // setSTAKING_LAB_ADDRESS("0xF511E32c3aD3cCf01c98eE5BaB667351158e6Bc0");

        // sIvy X
        // setFARM_ADDRESS("0x8C8bC09eE1942136B160Bbf328f8b2EeF1D176F7");
        // setIVY_ADDRESS("0x0ef6E46A731A54F178F0CfDe18b9F2Ef966B3bEF");
        // setSTAKING_LAB_ADDRESS("0xCeb0CA2B2C6558f88a5090aB9CD28c86605F5aA2");
        setFARM_ADDRESS("");
        setIVY_ADDRESS("");
        setSTAKING_LAB_ADDRESS("");
      } else if (defaultChainId == "8898") {
        // setChain("8898");
        dispatch(setChainId("8898"));
        setCoinSymbol("MMT");
        setWCoinSymbol("WMMT");
        setFACTORY_ADDRESS("0x6aD630595ADC6717119aB5c8192e1CEd94E0C587");
        setROUTER_ADDRESS("0x92d8bF464931aab6323dab18d56bBb37e119DE53");
        setWMMT_ADDRESS("0x1Ab2b7FAD0bF326bf66EAe336C9BFeD9DEb98444");

        // sIvy X
        setFARM_ADDRESS("");
        setIVY_ADDRESS("");
        setSTAKING_LAB_ADDRESS("");
      } else if (defaultChainId == "5") {
        // setChain("8898");
        dispatch(setChainId("5"));
        setCoinSymbol("ETH");
        setWCoinSymbol("WETH");
        setFACTORY_ADDRESS("0x40B9fc2E49c659506F7076801d192D4E0b4CE20c");
        setROUTER_ADDRESS("0x4c2e362b7E470fc94dA835bf3A47db7116BdE47B");
        setWMMT_ADDRESS("0x0AE9eDC0417D8216d4A99D57aAACc8319E871D94");
      } else if (defaultChainId == "1") {
        // setChain("8898");
        dispatch(setChainId("1"));
        setCoinSymbol("ETH");
        setWCoinSymbol("WETH");
        setFACTORY_ADDRESS("0x1Ab2b7FAD0bF326bf66EAe336C9BFeD9DEb98444");
        setROUTER_ADDRESS("0x92d8bF464931aab6323dab18d56bBb37e119DE53");
        setWMMT_ADDRESS("0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2");

        // sIvy X
        setFARM_ADDRESS("");
        setIVY_ADDRESS("");
        setSTAKING_LAB_ADDRESS("");
      } else if (defaultChainId == "8989") {
        // setChain("8898");
        dispatch(setChainId("8989"));
        setCoinSymbol("GMMT");
        setWCoinSymbol("WGMMT");
        setFACTORY_ADDRESS("0x6aD630595ADC6717119aB5c8192e1CEd94E0C587");
        setROUTER_ADDRESS("0x92d8bF464931aab6323dab18d56bBb37e119DE53");
        setWMMT_ADDRESS("0xddfE042e414978e59Dd16C8AC8487160EbAF24a5");

        // sIvy X
      setFARM_ADDRESS("0xc0A4d2e0B3C2a6B84DF0c7Df926BB56498FcDf66");
      setIVY_ADDRESS("0x48a590907f15f54137B352037ba09e1638b592ac");
      setSTAKING_LAB_ADDRESS("0x3032152E2DBC07b10F5692947Ad8eE35Fcb54950");
      } else if (defaultChainId == "898989") {
        // setChain("8898");
        dispatch(setChainId("898989"));
        setCoinSymbol("GMMT");
        setWCoinSymbol("WGMMT");
        setFACTORY_ADDRESS("0x0e9e740319A4A2f4ea89Af6fd3A8B8016D6fe7e9");
        setROUTER_ADDRESS("0xd78eC64aFB1273d80ae947fF0797830828975c9D");
        setWMMT_ADDRESS("0xe5a8fce4252c4eac79414869f2c39693d02993a8");

        // sIvy X
        // setFARM_ADDRESS("0xE2861EbF74f49A48FCC7211143aA9985d356289C");
        // setIVY_ADDRESS("0x26C1D3f1E16bB957b9222a2FF9452a6aFD1f5680");
        // setSTAKING_LAB_ADDRESS("");
        setFARM_ADDRESS("0xf3b38aFFCFe3F089361C0D802ef8651321B5d5f3");
        setIVY_ADDRESS("0xcACdA3ba5Cb08114371c1d0f6d7a64bBeB9f6108");
        setSTAKING_LAB_ADDRESS("0xB890341aa7998A6638ecA7DdA6594DD74b6ebb5a");
      }
    }
  }, [chainId]);

  const onChangeHeaderNetWork = (chainName) => {
    if (networkName != chainName) {
      // networkChangeInject();
      setActive(false);
      dispatch(setNowChainName(chainName));
    }
  };

  const networkChangeInject = () => {
    setActive(false);
    // dispatch(setAccountAddress(""));
    // dispatch(setChainId(0));
    // dispatch(setConnectStatus(false));
  };

  useEffect(() => {
    let providers;
    if (provider == undefined) {
      // walletConnect_v2
      // providers = new WalletConnectProvider({
      //   rpc: {
      //     88998: "https://data-seed-premmt-1.mmtscan.io",
      //     8898: "https://dataseed.mmtscan.io",
      //     5: "https://rpc.ankr.com/eth_goerli",
      //     1: "https://rpc.ankr.com/eth",
      //     8989: "https://rpc-asia.gmmtchain.io",
      //     898989: "https://testnet-rpc.gmmtchain.io",
      //   },
      //   bridge: "https://bridge.walletconnect.org",
      //   qrcode: true,
      // });
      // setProvider(providers);
    }
  }, [provider]);

  const handleConnect = async () => {
    setPopupShow(true);
  };

  const onClickClose = () => {
    setPopupShow(false);
  };

  const checkInject = async () => {
    let walletconnect = window.localStorage.getItem("walletconnect");
    walletconnect = JSON.parse(walletconnect);
    try {
      if (window.localStorage.getItem("DEX_MetaMask") == "inject") {
        return await connectMetaMask();
      } else if (provider != undefined && walletconnect != null) {
        return await onClickWalletConnect();
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    // if (provider != undefined) {
      checkInject();
    // }
  }, [chainId, provider]);

  const handelDisConnect = async (bool) => {

    try {
      setActive(false);
      window.localStorage.removeItem("DEX_MetaMask");
      window.localStorage.removeItem("walletconnect");
      dispatch(setAccountAddress(""));
      dispatch(setConnectStatus(false));
      setProvider(undefined);
      setWeb3(undefined)
      setWindowEther(undefined)
      try {
        if (bool) {
          if (provider!=undefined?provider.connected:false) {
            await provider.disconnect();
          }
        }
      } catch (error) {
        console.log("error 456 =>",error)
      }
    } catch (error) {
      console.log("er",error)
    }
  };

  useEffect(async () => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
      checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
        checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
      });
    };
  }, []);

  useEffect(() => {
    checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
  }, []);

  useEffect(() => {
    window.onbeforeunload = function pushRefresh() {
      window.scrollTo(0, 0);
    };
  }, []);

  const onClickMetaMask = () => {
    try {
      connectMetaMask();
    } catch (error) {
      if (error.code == 4001) {
        setChangeNetWorkStatus(true);
        setNetWorkMessage(`User rejected the request.`);
      } else if (error.code == -32002) {
        setChangeNetWorkStatus(true);
        setNetWorkMessage(
          `Already processing eth_requestAccounts. Please wait.`
        );
        //Already processing eth_requestAccounts. Please wait.
      } else {
        setChangeNetWorkStatus(true);
        setNetWorkMessage(`Unsupported Chain Id Error.\\nCheck your chain Id.`);
      }
    }
  };

  const connectMetaMask = async () => {
    if (window.ethereum != undefined) {
      try {
        let web3MetaMask;
        let accounts;
        let chainNum;
        let windowEthers;

        if (window.ethereum.overrideIsMetaMask) {
          for (const [key, value] of window.ethereum.providerMap.entries()) {
            if (key === "MetaMask") {
              value.enable();
              web3MetaMask = new Web3(value);
              setWindowEther(value);
              windowEthers = value;
              accounts = await value.send("eth_requestAccounts");
              console.log(accounts)
              accounts = accounts.result[0];
              chainNum = await web3MetaMask.eth.getChainId();
            }
          }
        } else if (
          window.ethereum.overrideIsMetaMask == undefined &&
          window.ethereum.isMetaMask
        ) {
          window.ethereum.enable();
          web3MetaMask = new Web3(window.ethereum);
          setWindowEther(window.ethereum);
          windowEthers = window.ethereum;
          accounts = await window.ethereum.send("eth_requestAccounts");
          accounts = accounts.result!=undefined?accounts.result[0]:accounts[0];
          chainNum = await web3MetaMask.eth.getChainId();
        } else if (window.ethereum.isMetaMask == false) {
          window.open(
            `https://metamask.app.link/dapp/${process.env.REACT_APP_URL}`
          );
        }

        if (String(chainNum) == chainId) {
          setWeb3(web3MetaMask);
          setProviderWeb3(windowEthers);
          setChangeNetWorkStatus(false);
          setPopupShow(false);
          // dispatch(setAccountAddress(result.result[0]));
          dispatch(setAccountAddress(accounts));
          // dispatch(setChainId(chainNum));
          setActive(true);
          dispatch(setConnectStatus(true));
          window.localStorage.setItem("DEX_MetaMask", "inject");
        } else {
          onChangeNetWork(windowEthers, "Meta");
          setPopupShow(false);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      // window.open("https://metamask.app.link/dapp/ivory-aiinz.aiinz.com");
      window.open(
        `https://metamask.app.link/dapp/${process.env.REACT_APP_URL}`
      );
    }
  };

  // useEffect(() => {
  //   console.log("provider",provider)
  //   console.log("providerWeb3",providerWeb3)
  //   if (window.ethereum != undefined) {
  //     connectMetaMask();
  //   }
  // }, [chain,provider,providerWeb3]);
  
  const onClickWalletConnect = async () => {
    try {
      let providers = provider.connected?provider:new WalletConnectProvider({
        rpc: {
          88998: "https://data-seed-premmt-1.mmtscan.io",
          8898: "https://dataseed.mmtscan.io",
          5: "https://rpc.ankr.com/eth_goerli",
          1: "https://rpc.ankr.com/eth",
          8989: "https://rpc-asia.gmmtchain.io",
          898989: "https://testnet-rpc.gmmtchain.io",
        },
        bridge: "https://bridge.walletconnect.org",
        qrcode: true,
      })
      let result = await providers.enable();
      console.log("result",result)
      if (result != undefined) {
        const reChainId = await providers.chainId;
        if (providers.connected && String(reChainId) == String(chainId)) {
          setPopupShow(false);
          setWeb3(new Web3(providers));
          setProviderWeb3(providers);
          setProvider(providers)
          dispatch(setAccountAddress(result[0]));
          // dispatch(setChainId(reChainId));
          setActive(true);
          dispatch(setConnectStatus(true));
          window.localStorage.removeItem("DEX_MetaMask");
        } else {
          onChangeNetWork(providers, "Wallet");
          // setChangeNetWorkStatus(true);
          // setPopupShow(false);
        }
      } else {
        setProvider(undefined);
      }
    } catch (error) {
      console.log(error)
      // dispatch(setAccountAddress(""));
      // setActive(false);
      // setProvider(undefined);
      handelDisConnect();
    }
  };

  const connectWalletConnect = async (result) => {
    try {
      const reChainId = await provider.chainId;
      if (provider.connected && reChainId == chainId) {
        setPopupShow(false);
        setWeb3(new Web3(provider));
        setProviderWeb3(provider);
        dispatch(setAccountAddress(result[0]));
        dispatch(setChainId(reChainId));
        setActive(true);
        dispatch(setConnectStatus(true));
        window.localStorage.removeItem("DEX_MetaMask");
      } else {
        onChangeNetWork(provider, "Wallet");
        // setChangeNetWorkStatus(true);
        setPopupShow(false);
      }
    } catch (error) {
      console.log("connectWalletConnect", error);
    }
  };

  /*
    chainId 변동 감지 - start
  */

  // if (window.ethereum != undefined) {
  // window.ethereum.on("accountsChanged", async function (accountsed) {
  if (windowEhter != undefined) {
    windowEhter.on("accountsChanged", async function (accountsed) {
      if (account != accountsed[0]) {
        handelDisConnect(true);
      }
    });

    windowEhter.on("chainChanged", async function (chainIds) {
      if (
        !(
          Number(chainIds) == 8989 ||
          Number(chainIds) == 898989 ||
          Number(chainIds) == 1 ||
          Number(chainIds) == 5 ||
          Number(chainIds) == 8898 ||
          Number(chainIds) == 88998
        )
      ) {
        handelDisConnect(true);
      }
    });
  }
  if (provider != undefined) {

    provider.on("accountsChanged", async (accounts) => {
      if (account.toLowerCase() != accounts[0].toLowerCase()) {
        handelDisConnect(false);
      }
    });

    provider.on("chainChanged", async (chainIds) => {
      if (
        !(
          Number(chainIds) == 8989 ||
          Number(chainIds) == 898989 ||
          Number(chainIds) == 1 ||
          Number(chainIds) == 5 ||
          Number(chainIds) == 8898 ||
          Number(chainIds) == 88998
        )
      ) {
        handelDisConnect(true);
      }
    });

    provider.on("disconnect", async () => {
        handelDisConnect(false);
    });
  }

  // useEffect(() => {
  //   console.log("p",provider)
  // },[provider])

  const onChangeNetWork = async (providerWeb3, type) => {
    let netWorkVersion;
    let _rpcUrl;
    let blockExplorerURL;
    let chainName;
    let symbol;
    let result;
    try {
      if (chainId == "88998") {
        // testnet
        netWorkVersion = "0x15ba6";
        _rpcUrl = "https://data-seed-premmt-1.mmtscan.io/";
        blockExplorerURL = "https://testnet.mmtscan.io";
        chainName = "Mammoth TestNet";
        symbol = "MMT";
      } else if (chainId == "8898") {
        //  mainnet
        netWorkVersion = "0x22c2";
        _rpcUrl = "https://dataseed.mmtscan.io/";
        blockExplorerURL = "https://mmtscan.io";
        chainName = "Mammoth Pro";
        symbol = "MMT";
      } else if (chainId == "5") {
        netWorkVersion = "0x5";
        _rpcUrl = "https://rpc.ankr.com/eth_goerli/";
        blockExplorerURL = "https://goerli.etherscan.io";
        chainName = "Goerli Test Net";
        symbol = "Goerli";
      } else if (chainId == "1") {
        netWorkVersion = "0x1";
        _rpcUrl = "https://rpc.ankr.com/eth/";
        blockExplorerURL = "https://etherscan.io";
        chainName = "Ethereum Mainnet";
        symbol = "ETH";
      } else if (chainId == "8989") {
        netWorkVersion = "0x231D";
        _rpcUrl = "https://rpc-asia.gmmtchain.io/";
        blockExplorerURL = "https://scan.gmmtchain.io";
        chainName = "GiantMammoth";
        symbol = "GMMT";
      } else if (chainId == "898989") {
        netWorkVersion = "0xDB7AD";
        _rpcUrl = "https://testnet-rpc.gmmtchain.io";
        blockExplorerURL = "https://testnet-scan.gmmtchain.io/";
        chainName = "GiantMammoth";
        symbol = "GMMT";
      }
      result = await providerWeb3.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: netWorkVersion,
          },
        ],
      });
      if(type.toLowerCase() == "meta"){
        connectMetaMask();
      }else if(type.toLowerCase() == "wallet"){
        onClickWalletConnect();
      }
    } catch (error) {
      try {
        if(String(error) == "Error: JSON RPC response format is invalid"){
          if(type.toLowerCase() == "meta"){
            connectMetaMask();
          }else if(type.toLowerCase() == "wallet"){
            onClickWalletConnect();
          }
        }else if(error.code == 4001 || String(error) == "Error: User rejected the request."){
          handelDisConnect();
        }else if(error.code === 4902 || String(error).includes("Try adding the chain using wallet_addEthereumChain first.")){
          try {
            let a = await providerWeb3.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: netWorkVersion,
                  chainName: chainName,
                  rpcUrls: [_rpcUrl],
                  nativeCurrency: {
                    name: chainName,
                    symbol: symbol,
                    decimals: 18,
                  },
                  blockExplorerUrls: [blockExplorerURL],
                },
              ],
            });
          } catch (error) {
            if(error.code == 4001 || String(error) == "Error: User rejected the request."){
              handelDisConnect();
            }
          }
        }
        
      } catch (error) {
        
      }


      // try {
      //   if (
      //     error.code == 4001 ||
      //     String(error) == "Error: User rejected the request."
      //   ) {
      //     try {
      //       handelDisConnect(true);
            
      //     } catch (error) {
      //      console.log("disconnectError") 
      //     }
      //   } else if (error.code === 4902) {
      //     try {
            // let a = await providerWeb3.request({
            //   method: "wallet_addEthereumChain",
            //   params: [
            //     {
            //       chainId: netWorkVersion,
            //       chainName: chainName,
            //       rpcUrls: [_rpcUrl],
            //       nativeCurrency: {
            //         name: chainName,
            //         symbol: symbol,
            //         decimals: 18,
            //       },
            //       blockExplorerUrls: [blockExplorerURL],
            //     },
            //   ],
            // });
            // console.log(a);
      //     } catch (error) {
      //       handelDisConnect(true);
      //     }
      //   }
      //   // else {
      //   //   try {
      //   //     let an = await providerWeb3.send({
      //   //       method: "wallet_addEthereumChain",
      //   //       params: [
      //   //         {
      //   //           chainId: netWorkVersion,
      //   //           chainName: chainName,
      //   //           rpcUrls: [_rpcUrl],
      //   //           nativeCurrency: {
      //   //             name: chainName,
      //   //             symbol: symbol,
      //   //             decimals: 18,
      //   //           },
      //   //           blockExplorerUrls: [blockExplorerURL],
      //   //         },
      //   //       ],
      //   //     });
      //   //     console.log(providerWeb3);
      //   //   } catch (error) {
      //   //     console.log("error3")
      //   //     handelDisConnect(true);
      //   //   }
      //   // }
      // } catch (addError) {
      //   console.log("addError 2", addError);
      // }
    }
  };

  const onClickHowToConnect = () => {
    window.open(
      "https://ivoryswap-finance.gitbook.io/ivoryswap/undefined-1/undefined"
    );
  };

  const checkdetectMoileDevice = (agent, width) => {
    const mobileRegex = [
      /Android/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    // setMobileCheck(mobileRegex.some(mobile => agent.match(mobile)));
    setMobileCheck(width <= 720);
  };

  const DefaultURL = process.env.REACT_APP_URL;

  if (DefaultURL != window.location.hostname) {
    window.location.assign(`https://${DefaultURL}`);
  }

  const connectCheck = () => {
    if (account == "") {
      setToastPopup(true);
    }
  };

  useEffect(() => {
    if (toastPopup) {
      setTimeout(() => {
        setToastPopup(false);
      }, 1200);
    }
  }, [toastPopup]);

  const [defaultProvider, setDefaultProvider] = useState();

  const defaultRpc = process.env.REACT_APP_NETWORK_RPC;

  const getdefaultProvider = () => {
    try {
      let web3 = new Web3(new Web3.providers.HttpProvider(defaultRpc));
      setDefaultProvider(web3);
      return web3;
    } catch (error) {
      console.log("error ==> ", error);
    }
  };

  useEffect(() => {
    getdefaultProvider();
  }, []);

  const onClickChangeNetWork = (rpc, chainIds) => {
    // let web3 = new Web3(new Web3.providers.HttpProvider(rpc));
    // handelDisConnect()
    // setDefaultProvider(web3);
    dispatch(setChainId(chainIds));
  };
  useEffect(() => {
    // if (networkName == "MMT TestNet") {
    if (chainId == "88998") {
      setCoinSymbol("MMT");
      setWCoinSymbol("WMMT");
      // setChain("88998");
      // dispatch(setChainId("88998"));
      // feeTo X
      // setROUTER_ADDRESS("0xBa76c9787F9CE3D52a8C37e94AA85f980582EAF4");
      // setFACTORY_ADDRESS("0x336321776C65095309Fe1D66FDB75c19b7e5eBf3");

      // feeTo O
      setROUTER_ADDRESS("0x1F76b70E08f3Fb47556ea7e2ca17DB9074792B6A");
      setFACTORY_ADDRESS("0x56Ab46032f4D3D30E77563E19748749D3dB7c98a");

      setWMMT_ADDRESS("0x6C744BaaC69ba137056c7c6757Ccb08B66D4c8b4");
      // setFARM_ADDRESS("0xb3Ee9f79285eCfEF54D2AB7395931F87382ADee4");
      // setIVY_ADDRESS("0x3F8e065f3cDd720a772956985aaCF68A981A1baf");
      // setSTAKING_LAB_ADDRESS("0x64951D3Fcd3C5d06e5D38D19f188890D5D616d1A");
      // settIVY_ADDRESS("0x515285B5a500Ca84f214f0d9AAf7E1b8bC878199");

      // sIvy O
      // setFARM_ADDRESS("0x1F8364Db56b9Db1F67b910B4b1c5E606D71943D6");
      // setIVY_ADDRESS("0x435ee63E2Bb8e4860F6818A4B14E1663a096d4db");
      // setsIVY_ADDRESS("0x401974F04424f3117C8515C8B932f202eD6F59Ac");
      // setSTAKING_LAB_ADDRESS("0xF511E32c3aD3cCf01c98eE5BaB667351158e6Bc0");

      // sIvy X
      // setFARM_ADDRESS("0x8C8bC09eE1942136B160Bbf328f8b2EeF1D176F7");
      // setIVY_ADDRESS("0x0ef6E46A731A54F178F0CfDe18b9F2Ef966B3bEF");
      // setSTAKING_LAB_ADDRESS("0xCeb0CA2B2C6558f88a5090aB9CD28c86605F5aA2");
      setFARM_ADDRESS("");
      setIVY_ADDRESS("");
      setSTAKING_LAB_ADDRESS("");
      setDefaultProvider(
        new Web3(
          new Web3.providers.HttpProvider(
            "https://data-seed-premmt-1.mmtscan.io"
          )
        )
      );
    } else if (chainId == "8898") {
      // setChain("8898");
      // dispatch(setChainId("8898"));
      setCoinSymbol("MMT");
      setWCoinSymbol("WMMT");
      setFACTORY_ADDRESS("0x6aD630595ADC6717119aB5c8192e1CEd94E0C587");
      setROUTER_ADDRESS("0x92d8bF464931aab6323dab18d56bBb37e119DE53");
      setWMMT_ADDRESS("0x1Ab2b7FAD0bF326bf66EAe336C9BFeD9DEb98444");

      // sIvy X
      setFARM_ADDRESS("");
      setIVY_ADDRESS("");
      setSTAKING_LAB_ADDRESS("");
      setDefaultProvider(
        new Web3(
          new Web3.providers.HttpProvider("https://dataseed.mmtscan.io/")
        )
      );
    } else if (chainId == "5") {
      // setChain("8898");
      // dispatch(setChainId("5"));
      setCoinSymbol("ETH");
      setWCoinSymbol("WETH");
      setFACTORY_ADDRESS("0x40B9fc2E49c659506F7076801d192D4E0b4CE20c");
      setROUTER_ADDRESS("0x4c2e362b7E470fc94dA835bf3A47db7116BdE47B");
      setWMMT_ADDRESS("0x0AE9eDC0417D8216d4A99D57aAACc8319E871D94");
      setDefaultProvider(
        new Web3(
          new Web3.providers.HttpProvider("https://rpc.ankr.com/eth_goerli/")
        )
      );
    } else if (chainId == "1") {
      // setChain("8898");
      // dispatch(setChainId("1"));
      setCoinSymbol("ETH");
      setWCoinSymbol("WETH");
      setFACTORY_ADDRESS("0x1Ab2b7FAD0bF326bf66EAe336C9BFeD9DEb98444");
      setROUTER_ADDRESS("0x92d8bF464931aab6323dab18d56bBb37e119DE53");
      setWMMT_ADDRESS("0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2");

      // sIvy X
      setFARM_ADDRESS("");
      setIVY_ADDRESS("");
      setSTAKING_LAB_ADDRESS("");
      setDefaultProvider(
        new Web3(new Web3.providers.HttpProvider("https://eth.llamarpc.com"))
      );
    } else if (chainId == "8989") {
      // setChain("8898");
      // dispatch(setChainId("8989"));
      setCoinSymbol("GMMT");
      setWCoinSymbol("WGMMT");
      setFACTORY_ADDRESS("0x6aD630595ADC6717119aB5c8192e1CEd94E0C587");
      setROUTER_ADDRESS("0x92d8bF464931aab6323dab18d56bBb37e119DE53");
      setWMMT_ADDRESS("0xddfE042e414978e59Dd16C8AC8487160EbAF24a5");

      // sIvy X
      setFARM_ADDRESS("0xc0A4d2e0B3C2a6B84DF0c7Df926BB56498FcDf66");
      setIVY_ADDRESS("0x48a590907f15f54137B352037ba09e1638b592ac");
      setSTAKING_LAB_ADDRESS("0x3032152E2DBC07b10F5692947Ad8eE35Fcb54950");
      setDefaultProvider(
        new Web3(
          new Web3.providers.HttpProvider("https://rpc-asia.gmmtchain.io/")
        )
      );
    } else if (chainId == "898989") {
      // setChain("8898");
      // dispatch(setChainId("898989"));
      setCoinSymbol("GMMT");
      setWCoinSymbol("WGMMT");
      setFACTORY_ADDRESS("0x0e9e740319A4A2f4ea89Af6fd3A8B8016D6fe7e9");
      setROUTER_ADDRESS("0xd78eC64aFB1273d80ae947fF0797830828975c9D");
      setWMMT_ADDRESS("0xe5a8fce4252c4eac79414869f2c39693d02993a8");

      // sIvy X
      setFARM_ADDRESS("0xf3b38aFFCFe3F089361C0D802ef8651321B5d5f3");
      setIVY_ADDRESS("0xcACdA3ba5Cb08114371c1d0f6d7a64bBeB9f6108");
      setSTAKING_LAB_ADDRESS("0xB890341aa7998A6638ecA7DdA6594DD74b6ebb5a");
      // setFARM_ADDRESS("0xE2861EbF74f49A48FCC7211143aA9985d356289C");
      // setIVY_ADDRESS("0x26C1D3f1E16bB957b9222a2FF9452a6aFD1f5680");
      // setSTAKING_LAB_ADDRESS("");
      setDefaultProvider(
        new Web3(
          new Web3.providers.HttpProvider("https://testnet-rpc.gmmtchain.io")
        )
      );
    }
  }, [chainId]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className={"wrapBackground"}>
        <Header
          onClickChangeNetWork={onClickChangeNetWork}
          coinSymbol={coinSymbol}
          wCoinSymbol={wCoinSymbol}
          mobileHeaderPopup={mobileHeaderPopup}
          toastPopup={toastPopup}
          setMobileHeaderPopup={setMobileHeaderPopup}
          connectCheck={connectCheck}
          defaultChainId={defaultChainId}
          networkName={networkName}
          mobileCheck={mobileCheck}
          netWorkMessage={netWorkMessage}
          setUrlStatus={setUrlStatus}
          onChangeHeaderNetWork={onChangeHeaderNetWork}
          nowUrlStatus={nowUrlStatus}
          setChangeNetWorkStatus={setChangeNetWorkStatus}
          changeNetWorkStatus={changeNetWorkStatus}
          web3Provider={web3}
          active={active}
          chainId={chainId}
          account={account}
          handleConnect={handleConnect}
          handelDisConnect={handelDisConnect}
          WMMT_ADDRESS={WMMT_ADDRESS}
          defaultProvider={defaultProvider}
        />
        {popupShow ? (
          <div className={stylesSwap.popupWrap}>
            <div className={stylesSwap.popupDim} onClick={onClickClose} />
            <div className={styles.popupContainer}>
              <div className={styles.title}>Connect Wallet</div>
              <div className={styles.connectList}>
                {/* {mobileCheck ? <></> : */}
                <div className={styles.mb} onClick={onClickMetaMask}>
                  <img
                    src={require(`./assets/images/sym_metamask@2x.png`)}
                    alt={"ico_metamask"}
                  />{" "}
                  MetaMask
                </div>
                {/* } */}
                {/*
                <div onClick={onClickWalletConnect}>
                  <img
                    src={require(`./assets/images/sym_walletconnect@2x.png`)}
                    alt={"ico_walletConnect"}
                  />{" "}
                  WalletConnect
                </div>
                */}
              </div>
              <button
                className={styles.howToConnectBtn}
                onClick={onClickHowToConnect}
              >
                Learn how to connect
              </button>
              <p onClick={onClickClose} style={{ cursor: "pointer" }}>
                I will not connect the wallet
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
        <Routes>
          <Route
            path="/"
            element={
              <Main
                active={active}
                setToastPopup={setToastPopup}
                mobileCheck={mobileCheck}
                providerWeb3={providerWeb3}
                web3Provider={web3}
                account={account}
              />
            }
          />
          <Route
            path="/swap"
            element={
              <Swap
                coinSymbol={coinSymbol}
                wCoinSymbol={wCoinSymbol}
                defaultProvider={defaultProvider}
                handleConnect={handleConnect}
                active={active}
                providerWeb3={providerWeb3}
                web3Provider={web3}
                account={account}
                ROUTER_ADDRESS={ROUTER_ADDRESS}
                WMMT_ADDRESS={WMMT_ADDRESS}
                chainId={chainId}
                FACTORY_ADDRESS={FACTORY_ADDRESS}
              />
            }
          />
          <Route
            path="/liquidity"
            element={
              <Liquidity
                coinSymbol={coinSymbol}
                wCoinSymbol={wCoinSymbol}
                handleConnect={handleConnect}
                providerWeb3={providerWeb3}
                web3Provider={web3}
                account={account}
                FACTORY_ADDRESS={FACTORY_ADDRESS}
                ROUTER_ADDRESS={ROUTER_ADDRESS}
                WMMT_ADDRESS={WMMT_ADDRESS}
                chainId={chainId}
                active={active}
                defaultProvider={defaultProvider}
              />
            }
          />
          <Route
            path="/add"
            element={
              <AddLiquidity
                coinSymbol={coinSymbol}
                wCoinSymbol={wCoinSymbol}
                defaultProvider={defaultProvider}
                handleConnect={handleConnect}
                web3Provider={web3}
                account={account}
                ROUTER_ADDRESS={ROUTER_ADDRESS}
                FACTORY_ADDRESS={FACTORY_ADDRESS}
                WMMT_ADDRESS={WMMT_ADDRESS}
                chainId={chainId}
                active={active}
              />
            }
          />
          {defaultChainId == "88998" ? (
            <>
              <Route
                path="/farm"
                element={
                  <Farm
                    defaultProvider={defaultProvider}
                    handleConnect={handleConnect}
                    account={account}
                    ROUTER_ADDRESS={ROUTER_ADDRESS}
                    web3Provider={web3}
                    chainId={chainId}
                    FARM_ADDRESS={FARM_ADDRESS}
                    IVY_ADDRESS={IVY_ADDRESS}
                    active={active}
                  />
                }
              />
              {/* <Route path="/stake" element={<Stake account={account} web3Provider={web3} chainId={chainId} sIVY_ADDRESS={sIVY_ADDRESS} IVY_ADDRESS={IVY_ADDRESS} handleConnect={handleConnect} />} />
              <Route path="/stakeList" element={<StakeList account={account} providerWeb3={providerWeb3} web3Provider={web3} chainId={chainId} STAKING_LAB_ADDRESS={STAKING_LAB_ADDRESS} IVY_ADDRESS={IVY_ADDRESS} />} /> */}
              <Route
                path="/stake"
                element={
                  <StakeList
                    handleConnect={handleConnect}
                    defaultProvider={defaultProvider}
                    account={account}
                    providerWeb3={providerWeb3}
                    web3Provider={web3}
                    chainId={chainId}
                    STAKING_LAB_ADDRESS={STAKING_LAB_ADDRESS}
                    IVY_ADDRESS={IVY_ADDRESS}
                    active={active}
                  />
                }
              />
              <Route
                path="/info"
                element={
                  <Info
                    defaultProvider={defaultProvider}
                    account={account}
                    web3Provider={web3}
                    providerWeb3={providerWeb3}
                    chainId={chainId}
                    FACTORY_ADDRESS={FACTORY_ADDRESS}
                    ROUTER_ADDRESS={ROUTER_ADDRESS}
                  />
                }
              />
            </>
          ) : (
            <>
              {/* <Route path="/farm" element={<ComingSoon setUrlStatus={setUrlStatus} />} />
              <Route path="/stake" element={<ComingSoon setUrlStatus={setUrlStatus} />} /> */}
              <Route
                path="/farm"
                element={
                  <Farm
                    defaultProvider={defaultProvider}
                    handleConnect={handleConnect}
                    account={account}
                    ROUTER_ADDRESS={ROUTER_ADDRESS}
                    web3Provider={web3}
                    chainId={chainId}
                    FARM_ADDRESS={FARM_ADDRESS}
                    IVY_ADDRESS={IVY_ADDRESS}
                    active={active}
                  />
                }
              />
              <Route
                path="/stake"
                element={
                  <StakeList
                    handleConnect={handleConnect}
                    defaultProvider={defaultProvider}
                    account={account}
                    providerWeb3={providerWeb3}
                    web3Provider={web3}
                    chainId={chainId}
                    STAKING_LAB_ADDRESS={STAKING_LAB_ADDRESS}
                    IVY_ADDRESS={IVY_ADDRESS}
                    active={active}

                  />
                }
              />
              {/* <Route
                path="/stakeList"
                element={<ComingSoon setUrlStatus={setUrlStatus} />}
              /> */}
              <Route path="/stakeList" element={<Stake account={account} web3Provider={web3} chainId={chainId} sIVY_ADDRESS={sIVY_ADDRESS} IVY_ADDRESS={IVY_ADDRESS} handleConnect={handleConnect} />} />
              <Route
                path="/info"
                element={
                  <Info
                    defaultProvider={defaultProvider}
                    account={account}
                    web3Provider={web3}
                    providerWeb3={providerWeb3}
                    chainId={chainId}
                    FACTORY_ADDRESS={FACTORY_ADDRESS}
                    ROUTER_ADDRESS={ROUTER_ADDRESS}
                    active={active}
                  />
                }
              />
              {/* <Route path="/info" element={<Info account={account} web3Provider={web3} providerWeb3={providerWeb3} chainId={chainId} FACTORY_ADDRESS={FACTORY_ADDRESS} ROUTER_ADDRESS={ROUTER_ADDRESS}/>} /> */}
            </>
          )}
          <Route
            path={`/remove/:token0/:token1`}
            element={
              <RemoveLiquidity
                web3Provider={web3}
                account={account}
                ROUTER_ADDRESS={ROUTER_ADDRESS}
                token_0={token_0}
                token_1={token_1}
                FACTORY_ADDRESS={FACTORY_ADDRESS}
                chainId={chainId}
              />
            }
          />
          <Route
            path="/coming"
            element={<ComingSoon setUrlStatus={setUrlStatus} />}
          />
          <Route
            path="/voting"
            element={<ComingSoon setUrlStatus={setUrlStatus} />}
          />
          <Route
            path="/notice"
            element={<Notice setUrlStatus={setUrlStatus} />}
          />
          <Route
            path="/notice/detail/:no"
            element={<NoticeDetail setUrlStatus={setUrlStatus} />}
          />
          <Route path="*" element={<Error404 setUrlStatus={setUrlStatus} />} />
        </Routes>
      </div>
      {/*<Footer />*/}
    </BrowserRouter>
  );
  {
    /* <Route path="/add/:tokenA/:tokenB" element={<AddLiquidity account={account} ROUTER_ADDRESS={ROUTER_ADDRESS}/>} /> */
  }
}

export default App;
