export const Farms = [
    {
        pid: "0",
        lpSymbol: `ETH - BTC`,
        lpAddress: {
            8898: "",
            88998: "0xe438D558e4FF6EF1497fE440f8e616962484026A",
        },
        token: {
            8898: "",
            88998: "0x28695D7A1129bC198268389c06Ad1602bB830ec6",
        },
        quoteToken: {
            8898: "",
            88998: "0xddfE042e414978e59Dd16C8AC8487160EbAF24a5",
        },
        tokenSymbol: {
            8898: "",
            88998: "ETH",
        },
        quoteTokenSymbol: {
            8898: "",
            88998: "BTC",
        }
    },
    {
        pid: "1",
        lpSymbol: "BNB - ETH",
        lpAddress: {
            8898: "",
            88998: "0xE921230F00ea08Bdb9b181baba950FF582988Ea2",
        },
        token: {
            8898: "",
            88998: "0x251009eCeE7838C95C18b3d79EA6c3F857361539",
        },
        quoteToken: {
            8898: "",
            88998: "0x28695D7A1129bC198268389c06Ad1602bB830ec6",
        },
        tokenSymbol: {
            8898: "",
            88998: "BNB",
        },
        quoteTokenSymbol: {
            8898: "",
            88998: "ETH",
        }
    },
    {
        pid: "2",
        lpSymbol: "BTC - BNB",
        lpAddress: {
            8898: "",
            88998: "0xC49F9dEC7f6D76903a407e64165B853810aeA25E",
        },
        token: {
            8898: "",
            88998: "0x251009eCeE7838C95C18b3d79EA6c3F857361539",
        },
        quoteToken: {
            8898: "",
            88998: "0xddfE042e414978e59Dd16C8AC8487160EbAF24a5",
        },
        tokenSymbol: {
            8898: "",
            88998: "BNB",
        },
        quoteTokenSymbol: {
            8898: "",
            88998: "BTC",
        }
    }
]