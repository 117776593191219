import React, { createRef, useCallback, useEffect, useLayoutEffect, useState } from "react";
import styles from "../assets/css/Farm.module.css";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import farm, {
  setAllFarms,
  setAllowance,
  setFarms,
  setPendingIvys,
} from "../modules/farm";
import { MaxUint256 } from "@ethersproject/constants";

import FarmJson from "../abi/Farm.json";
import IvyJson from "../abi/Ivy.json";
import Factory from "../abi/Factory.json";
import ERC20 from "../abi/ERC20.json";
import Pair from "../abi/Pair.json";
import { Farms } from "../farmList/farmList";
import { FarmsMain } from "../farmList/farmListMain";
import { Farms_ETH } from "../farmList/farmList_ETH";
import BN from "bn.js";
import { setTokenA, setTokenB } from "../modules/liquidity";
import { Link } from "react-router-dom";
import { BigNumber } from "ethers";
import { setFarmInterval, setTransactionHash } from "../modules/user";

const Farm = ({
  defaultProvider,
  handleConnect,
  web3Provider,
  IVY_ADDRESS,
  account,
  ROUTER_ADDRESS,
  WMMT_ADDRESS,
  FACTORY_ADDRESS,
  chainId,
  active,
  FARM_ADDRESS,
}) => {
  const [elRefs, setElRefs] = useState([]);
  const [imgRefs, setImgRefs] = useState([]);

  // const [itemList, setItemList] = useState(['Select ', 'Earned', 'Total staked', 'Latest'])
  const [itemList, setItemList] = useState([
    "Earned",
    "Total staked",
    "Latest",
  ]);
  const [selectFlip, setSelectFlip] = useState(false);
  const [selectedItem, setSelectedItem] = useState(itemList[0]);
  const [popupShow, setPopupShow] = useState(false);
  const [popupType, setPopupType] = useState("");

  //finn
  const [ivyBalance, setIvyBalance] = useState(0);
  const [pendingIvy, setPendingIvy] = useState(0);
  const [inputAmount, setInputAmount] = useState("");
  const [txtMessage, setTxtMessage] = useState("");
  const [chooseFarm, setChooseFarm] = useState();
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [confirmTxt, setConfirmTxt] = useState("");
  const [enableTxt, setEnableTxt] = useState("enable");
  const [searchTxt, setSearchTxt] = useState("");
  const [intervalBool, setIntervalBool] = useState(true);
  const [approveIndex, setApproveIndex] = useState("");
  const [stakeConfirmText, setConfirmText] = useState("Confirm");
  const [loading, setLoading] = useState(true);

  // const [allFarms, setAllFarms] = useState();

  const [activeFarms, setActiveFarms] = useState(true);
  // const [activeFarms, setActiveFarms] = useState("inactive");
  const [chain, setChain] = useState("MMT");
  useEffect(() => {
    if (chainId == 8898 || chainId == 88998) {
      setChain("MMT");
    } else if (chainId == 97) {
      setChain("BNB");
    } else if (chainId == 5 || chainId == 1) {
      setChain("ETH");
    } else if (chainId == 898989 || chainId == 8989) {
      setChain("GMMT");
    }
    if (farmInterval != "") {
      clearInterval(farmInterval);
      dispatch(setFarmInterval(""));
    }
  }, [chainId]);

  const selectToggle = () => {
    setSelectFlip(!selectFlip);
  };

  const getSelectItem = (item) => {
    setSelectedItem(item);
    // setSortByFarm(item)
    selectToggle();
  };

  const testDiv = [0, 1, 2];

  const farmToggle = (index) => {
    if (elRefs[index].current.classList.contains(styles.active)) {
      elRefs[index].current.classList.remove(styles.active);
      imgRefs[index].current.classList.remove(styles.rotate);
    } else {
      elRefs[index].current.classList.add(styles.active);
      imgRefs[index].current.classList.add(styles.rotate);
    }
  };

  const closePopup = () => {
    setPopupShow(false);
    setTxtMessage("");
    setChooseFarm();
    setConfirmStatus(false);
    setInputAmount("");
    setPopupType("");
  };

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(testDiv.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
    setImgRefs((imgRefs) =>
      Array(testDiv.length)
        .fill()
        .map((_, i) => imgRefs[i] || createRef())
    );
  }, [testDiv.length]);

  // finn

  const dispatch = useDispatch();

  const farms = useSelector((state) => state.farm.farms);
  const allFarms = useSelector((state) => state.farm.allFarms);
  const transactionHash = useSelector((state) => state.user.transactionHash);

  const fromWeiBalance = (balance, decimal) => {
    if (balance != undefined) {
      let result = new BN(`${10}`).pow(new BN(`${decimal}`)).toString();
      let fromWeiBalance = new BN(`${balance}`).divmod(new BN(`${result}`));
      let divBalance = fromWeiBalance.div.toString();
      let modBalance = fromWeiBalance.mod.toString();
      let subDecimal = decimal - modBalance.length;
      let zero = "";
      for (let i = 0; i < subDecimal; i++) {
        zero += "0";
      }

      let returnBalance = divBalance + "." + zero + modBalance;
      return returnBalance;
    } else {
      return "0";
    }
  };

  const toWeiBalance = (balance, decimal) => {
    // if (web3Provider != undefined) {
    if (balance != undefined) {
      let point = balance.split(".");
      let pointPow = 1;
      let powLength = 0;
      let lastPoint = "";
      if (point[1] != undefined) {
        powLength = point[1].length;
        pointPow = new BN(`${10}`).pow(new BN(`${point[1].length}`)).toString();
        lastPoint = point[1].substr(0, decimal);
      }

      let balancePoinPow = point[0] + lastPoint;

      let subDecimal = new BN(`${decimal}`)
        .sub(new BN(`${powLength}`))
        .toString();

      let result = new BN(`${10}`).pow(new BN(`${subDecimal}`)).toString();
      let decimalBalance = new BN(`${balancePoinPow}`)
        .mul(new BN(`${result}`))
        .toString();

      return decimalBalance;
    } else {
      return "0";
    }
    // }
  };

  const balanceInCode = (balance) => {
    if (
      balance == undefined ||
      balance.length == undefined ||
      balance == "-" ||
      balance == "" ||
      Number(balance) == 0
    ) {
      return 0;
    } else {
      let splitBalance = balance.split(".");

      let backBalance = "";
      let afterVal = "";
      if (splitBalance.length == 2) {
        let q1 = splitBalance[1].length - (splitBalance[1].search(/[1-9]/) + 1);
        let nextVal;
        for (let i = 0; i < q1; i++) {
          nextVal = splitBalance[1].substr(
            splitBalance[1].search(/[1-9]/) + (i + 1),
            1
          );
          if (nextVal != "0") {
            afterVal = afterVal + `${nextVal}`;
            break;
          } else {
            afterVal = afterVal + "0";
          }
          if (i == q1 - 1) {
            nextVal = "";
          }
        }
        if (afterVal.search(/[1-9]/) == -1) {
          afterVal = "";
        }
        backBalance =
          splitBalance[1].substr(0, splitBalance[1].search(/[1-9]/) + 1) != ""
            ? "." +
            splitBalance[1].substr(0, splitBalance[1].search(/[1-9]/) + 1) +
            afterVal
            : "";
      }

      return splitBalance[0] + backBalance;
    }
  };

  const zeroDelete = (balance) => {
    const web3 = new Web3(web3Provider);
    let balanceBefore = web3.utils.toWei(`${balance}`).toString();
    let balanceAfter = web3.utils.fromWei(`${balanceBefore}`).toString();
    return balanceAfter;
  };

  const selectSortBy = (resultList) => {
    if (resultList != "") {
      let farmsList = resultList;
      let sort = farmsList.sort((a, b) => {
        let result = new BN(`${toWeiBalance(a.pid, "18")}`)
          .sub(new BN(`${toWeiBalance(b.pid, "18")}`))
          .toString();
        return result;
      });

      return sort;
    }
    return resultList;
  };

  const earnedSort = (resultList) => {
    if (resultList != "") {
      let farmsList = resultList;
      let sort = farmsList.sort((a, b) => {
        let result = new BN(`${toWeiBalance(b.pendingIvy, "18")}`)
          .sub(new BN(`${toWeiBalance(a.pendingIvy, "18")}`))
          .toString();
        return result;
      });

      return sort;
    }
    return resultList;
  };

  const totalStakedSort = (resultList) => {
    if (resultList != "") {
      let farmsList = resultList;
      let sort = farmsList.sort((a, b) => {
        let result = new BN(`${toWeiBalance(b.depositAmount, "18")}`)
          .sub(new BN(`${toWeiBalance(a.depositAmount, "18")}`))
          .toString();
        return result;
      });

      return sort;
    }
    return resultList;
  };

  const latestSort = (resultList) => {
    if (resultList != "") {
      let farmsList = resultList;
      let sort = farmsList.sort((a, b) => {
        let result = new BN(`${b.lastRewardTime}`)
          .sub(new BN(`${a.lastRewardTime}`))
          .toString();
        return result;
      });

      return sort;
    }
    return resultList;
  };

  const SetFarmsList = async () => {
    // 여기부터 다시 수정
    let farmsList = [];
    // if (chainId == "8898") {
    //   farmsList = FarmsMain;
    // } else if (chainId == "88998") {
    //   farmsList = Farms;
    // } else if (chainId == "1") {
    //   farmsList = Farms_ETH;
    // }

    let SetFarmsList = [];
    try {
      if (defaultProvider != undefined && FARM_ADDRESS != "") {
        // const web3 = new Web3(web3Provider);
        const web3 = new Web3(defaultProvider);
        const contract = new web3.eth.Contract(FarmJson, FARM_ADDRESS);
        let poolLength = await contract.methods.poolLength().call();
        let view;

        const ivyPerSecond = await contract.methods.ivyPerSecond().call();
        const totalAllocPoint = await contract.methods.totalAllocPoint().call();

        for (let i = 0; i < poolLength; i++) {
          SetFarmsList.push(
            new Promise(async (resolve, reject) => {
              let userInfo = {
                amount: "0",
                rewardDebt: "0",
              };
              let lpBalance = "0";
              let info = await contract.methods.poolInfo(i).call();
              const pairContract = new web3.eth.Contract(Pair, info.lpToken);
              let lpSymbol = await pairContract.methods.symbol().call();
              let lpDecimal = await pairContract.methods.decimals().call();
              let allowance = false;
              if (account != "") {
                userInfo = await contract.methods.userInfo(i, account).call();
                allowance = await checkAllowance(info.lpToken);
                lpBalance = await pairContract.methods
                  .balanceOf(account)
                  .call();
              }

              let farmLpBalance = await pairContract.methods
                .balanceOf(FARM_ADDRESS)
                .call();
              let ivyPending = await getpendingIvy(i);
              let token0 = await pairContract.methods.token0().call();
              let token1 = await pairContract.methods.token1().call();

              let token0Contract = new web3.eth.Contract(ERC20, token0);
              let token1Contract = new web3.eth.Contract(ERC20, token1);
              let token0Info = {
                address: token0,
                decimals: await token0Contract.methods.decimals().call(),
                name: await token0Contract.methods.name().call(),
                symbol: await token0Contract.methods.symbol().call(),
              };
              let token1Info = {
                address: token1,
                decimals: await token1Contract.methods.decimals().call(),
                name: await token1Contract.methods.name().call(),
                symbol: await token1Contract.methods.symbol().call(),
              };
              let ivyPerSecondAtPool = new BN(`${ivyPerSecond}`).mul(new BN(`${info.allocPoint}`)).div(new BN(`${totalAllocPoint}`)).toString();
              let apr = ((Number(new BN(`${ivyPerSecondAtPool}`).mul(new BN(`${86400}`)).mul(new BN(`${365}`)).toString()) / farmLpBalance) * 100).toFixed(0);

              view = {
                lpAddress: info.lpToken,
                lpBalance: fromWeiBalance(lpBalance, lpDecimal),
                lpSymbol: lpSymbol,
                pid: i,
                token: token0Info.address.toLowerCase(),
                quoteToken: token1Info.address.toLowerCase(),
                tokenSymbol: token0Info.symbol,
                quoteTokenSymbol: token1Info.symbol,
                accShare: info.accIVYPerShare,
                allocPoint: info.allocPoint,
                lastRewardTime: info.lastRewardTime,
                allowance: allowance,
                pendingIvy: fromWeiBalance(ivyPending, "18"),
                // apr: "0",
                apr:apr,
                depositAmount: fromWeiBalance(userInfo.amount, "18"),
                farmLpBalance: fromWeiBalance(farmLpBalance, "18"),
              };

              resolve(view);
            })
          );
          // SetFarmsList.push(view);
        }

        Promise.all(SetFarmsList).then((values) => {
          dispatch(setAllFarms(values));
        });
        // setAllFarms(SetFarmsList)
        // dispatch(setFarms(SetFarmsList));
      } else {
        dispatch(setAllFarms(SetFarmsList));
      }
    } catch (error) {
      dispatch(setAllFarms(SetFarmsList));
    }
    // if (web3Provider != undefined && chainId != 0 && account != "") {
  };

  const functionAll = (activeStatus, sortBy, searchText) => {
    // if (web3Provider != undefined && chainId != 0 && account != "" && allFarms != undefined) {
    if (allFarms != undefined || allFarms != "") {
      let farmList = allFarms;
      let resultList = [];
      let nowTimeStamp = +new Date();
      let setSearchResult = [];
      // nowTimeStamp= Math.floor(Number(nowTimeStamp) / 1000);
      let result = farmList.filter((item) => {
        let filterBool = activeStatus
          ? Number(item.allocPoint) > 0
          : Number(item.allocPoint) == 0;
        // let filterBool = activeStatus ? Number(item.allocPoint) > nowTimeStamp : Number(item.allocPoint) < nowTimeStamp
        if (filterBool) {
          return resultList.push(item);
        }
      });

      let condition;

      switch (sortBy) {
        case "Earned":
          condition = earnedSort(resultList);
          break;

        case "Total staked":
          condition = totalStakedSort(resultList);
          break;

        case "Latest":
          condition = latestSort(resultList);
          break;

        default:
          condition = selectSortBy(resultList);
          break;
      }

      for (let i = 0; i < condition.length; i++) {
        const tokenSymbolToLowerCase = condition[i].tokenSymbol.toLowerCase();
        const quoteTokenSymbolToLowerCase =
          condition[i].quoteTokenSymbol.toLowerCase();
        const textToLowerCase = searchText.toLowerCase();
        if (
          tokenSymbolToLowerCase.includes(textToLowerCase) ||
          quoteTokenSymbolToLowerCase.includes(textToLowerCase)
        ) {
          setSearchResult.push(condition[i]);
        }
      }

      dispatch(setFarms(setSearchResult));
      setInterval(() => {
        setLoading(false);
      }, 2000);
    } else {
      dispatch(setFarms([]));
      setInterval(() => {
        setLoading(false);
      }, 3000);
    }
  };

  const setActiveOption = (bool) => {
    setActiveFarms(bool);
  };

  const onChangeInputValue = async (e, address) => {
    let inputAmount = e;
    let pattern = /^[0-9]*[.,]?[0-9]*$/;
    let balance;

    if (txtMessage === "Stake") {
      balance = chooseFarm.lpBalance;
    } else if (txtMessage === "UnStake") {
      balance = chooseFarm.depositAmount;
    }

    if (inputAmount != "" && pattern.test(inputAmount) == true) {
      if (inputAmount == ".") inputAmount = "0."
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(Pair, address);
      const allowance = await contract.methods
        .allowance(account, FARM_ADDRESS)
        .call();
      let inputAmounttoWei = web3.utils.toWei(inputAmount);

      let allowanceBool = new BN(`${inputAmounttoWei}`).lte(
        new BN(`${allowance}`)
      );
      setInputAmount(inputAmount);

      if (
        txtMessage == "Stake"
          ? Number(chooseFarm.lpBalance) != "0" &&
          new BN(web3.utils.toWei(chooseFarm.lpBalance)).gte(
            new BN(`${inputAmounttoWei}`)
          )
          : true
      ) {
        if (allowanceBool || txtMessage == "UnStake") {
          setConfirmText("Confirm");
          if (pattern.test(inputAmount) == true) {
            let bool;

            let BNResult = new BN(`${toWeiBalance(inputAmount, "18")}`).lte(
              new BN(`${toWeiBalance(balance, "18")}`)
            );
            let token = {
              inputAmount: inputAmount,
              decimal: 18,
            };
            if (inputAmount == "") {
              bool = false;
            } else if (
              inputAmount != "0" &&
              BNResult &&
              Number(inputAmount) > 0
            ) {
              bool = checkInputAmountDecimal(token);
            }
            setConfirmStatus(bool);
          }
        } else {
          setConfirmText("Approve");
          setConfirmStatus(true);
        }
      } else {
        setConfirmStatus(false);
      }
    } else if (inputAmount == "") {
      setInputAmount("")
      setConfirmText("Confirm");
      setConfirmStatus(false);
    }
  };

  const checkAllowance = async (address) => {
    if (defaultProvider != undefined) {
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(Pair, address);
      const allowance = await contract.methods
        .allowance(account, FARM_ADDRESS)
        .call();
      if (allowance != "0") {
        return true;
      } else {
        return false;
      }
    }
  };

  const onClickApprove = async (i, item) => {
    try {
      if (web3Provider != undefined) {
        if (await defaultProvider != undefined?.eth.getChainId() == await web3Provider != undefined?.eth.getChainId()) {
          const web3 = new Web3(web3Provider);
          const contract = new web3.eth.Contract(Pair, item.lpAddress);
          // const inputAmount = web3.utils.toWei(`${item.lpBalance}`);
          // const inputAmount =`${1}`;
          try {
            setApproveIndex(i);
            setEnableTxt("Approving");
            const approve = await contract.methods
              .approve(FARM_ADDRESS, MaxUint256.toString())
              .send({
                // const approve = await contract.methods.approve(FARM_ADDRESS, `${0}`).send({
                from: account,
              });
            if (approve.status == true) {
              let data = {
                index: i,
                result: true,
              };
              dispatch(setAllowance(data));
            }
            setApproveIndex("");
            setEnableTxt("enable");
          } catch (error) {
            setApproveIndex("");
            setEnableTxt("enable");
          }
        }
      }

    } catch (error) {
      console.log(error)
    }
  };

  const onClickApproveModal = async (lpAddress) => {
    try {
      const web3 = new Web3(web3Provider);
      const contract = new web3.eth.Contract(Pair, lpAddress);
      setConfirmText("Approving");
      setConfirmStatus(false);
      const approve = await contract.methods
        .approve(FARM_ADDRESS, MaxUint256.toString())
        .send({
          // const approve = await contract.methods.approve(FARM_ADDRESS, `${0}`).send({
          from: account,
        });
      if (approve.status == true) {
        const allowance = await contract.methods
          .allowance(account, FARM_ADDRESS)
          .call();
        let bool = new BN(`${web3.utils.toWei(inputAmount)}`).lte(
          new BN(`${allowance}`)
        );
        if (!bool) {
          setConfirmText("Approve");
          setConfirmStatus(true);
        } else {
          let bool = new BN(`${web3.utils.toWei(inputAmount)}`).lte(
            new BN(`${web3.utils.toWei(chooseFarm.lpBalance)}`)
          );
          setConfirmText("Confirm");
          if (bool) {
            setConfirmStatus(true);
          } else {
            setConfirmStatus(false);
          }
        }
      }
    } catch (error) {
      console.log(error);
      setConfirmText("Approve");
      setConfirmStatus(true);
    }
  };

  const onClickDeposit = async (item) => {
    if (confirmStatus) {
      // dispatch(setTransactionHash(""));
      if (await defaultProvider != undefined?.eth.getChainId() == await web3Provider != undefined?.eth.getChainId()) {
        const web3 = new Web3(web3Provider);
        const contract = new web3.eth.Contract(FarmJson, FARM_ADDRESS);
        const inputData = web3.utils.toWei(`${inputAmount}`);
        setConfirmTxt("Deposit");
        setPopupType("confirm");
        let result;
        try {
          result = await contract.methods.deposit(item.pid, inputData).send({
            from: account,
          });
          if (result.status) {
            setPopupType("submit");
            // setTx(result.transactionHash)
            dispatch(setTransactionHash(result.transactionHash));
          } else {
            setPopupType("Failed");
          }
        } catch (error) {
          setConfirmStatus(false);
          setInputAmount("");
          setPopupType("stake");
        }
      }
    }
  };

  const onClickWithDraw = async (item) => {
    if (confirmStatus) {
      // setTx("")
      if (await defaultProvider != undefined?.eth.getChainId() == await web3Provider != undefined?.eth.getChainId()) {
        dispatch(setTransactionHash(""));
        const web3 = new Web3(web3Provider);
        const contract = new web3.eth.Contract(FarmJson, FARM_ADDRESS);
        const inputData = web3.utils.toWei(`${inputAmount}`);
        setConfirmTxt("Withdraw");
        setPopupType("confirm");
        let result;
        try {
          result = await contract.methods.withdraw(item.pid, inputData).send({
            from: account,
          });
          if (result.status) {
            setPopupType("submit");
            // setTx(result.transactionHash)
            dispatch(setTransactionHash(result.transactionHash));
          } else {
            setPopupType("Failed");
          }
        } catch (error) {
          setConfirmStatus(false);
          setInputAmount("");
          setPopupType("stake");
        }
      }

    }
  };

  const onClickHarveset = async (item) => {
    let depositAmount = item.depositAmount;
    if (Number(depositAmount) != 0) {
      if (await defaultProvider != undefined?.eth.getChainId() == await web3Provider != undefined?.eth.getChainId()) {
        const web3 = new Web3(web3Provider);
        const contract = new web3.eth.Contract(FarmJson, FARM_ADDRESS);
        const withdraw = await contract.methods.withdraw(item.pid, `${0}`).send({
          from: account,
        });
        if (withdraw.status) {
          setIntervalPendingIVY();
        }
      }
    }
  };

  const getpendingIvy = async (item) => {
    if (defaultProvider != undefined && chainId != 0 && account != "" && FARM_ADDRESS != "") {
      try {
        const web3 = new Web3(defaultProvider);
        const contract = new web3.eth.Contract(FarmJson, FARM_ADDRESS);
        const pendingIvy = await contract.methods
          .pendingIVY(item, account)
          .call();
        return pendingIvy;
      } catch (error) {

      }
    } else if (account == "") {
      return 0;
    }
  };

  const onClickAddLiquidityBtn = async (set_token_0, set_token_1) => {
    const web3 = defaultProvider;

    let token0_contract = new web3.eth.Contract(ERC20, set_token_0);
    let token0_name = await token0_contract.methods.name().call();
    let token0_symbol = await token0_contract.methods.symbol().call();
    let token0_decimal = await token0_contract.methods.decimals().call();
    let token0_havAmount = "0";
    if (account != "") {
      token0_havAmount = await token0_contract.methods
        .balanceOf(account)
        .call();
    }

    token0_havAmount = fromWeiBalance(token0_havAmount, token0_decimal);
    let token0Image;

    if (
      token0_symbol == "ETH" ||
      token0_symbol == "WMMT" ||
      token0_symbol == "MMT" ||
      token0_symbol == "BTC"
    ) {
      token0Image = token0_symbol;
    } else {
      token0Image = "";
    }

    let token1_contract = new web3.eth.Contract(ERC20, set_token_1);
    let token1_name = await token1_contract.methods.name().call();
    let token1_symbol = await token1_contract.methods.symbol().call();
    let token1_decimal = await token1_contract.methods.decimals().call();
    let token1_havAmount = "0";
    if (account != "") {
      token1_havAmount = await token1_contract.methods
        .balanceOf(account)
        .call();
    }
    token1_havAmount = fromWeiBalance(token1_havAmount, token1_decimal);
    let token1Image;

    if (
      token1_symbol == "ETH" ||
      token1_symbol == "WMMT" ||
      token1_symbol == "MMT" ||
      token1_symbol == "BTC"
    ) {
      token1Image = token1_symbol;
    } else {
      token1Image = "";
    }

    let setAData = {
      address: set_token_0.toLowerCase(),
      decimal: token0_decimal,
      haveAmount: token0_havAmount,
      image: set_token_0.toLowerCase(),
      name: token0_name,
      symbol: token0_symbol,
      inputAmount: "",
      price: "",
    };
    let setBData = {
      address: set_token_1.toLowerCase(),
      decimal: token1_decimal,
      haveAmount: token1_havAmount,
      image: set_token_1.toLowerCase(),
      name: token1_name,
      symbol: token1_symbol,
      inputAmount: "",
      price: "",
    };

    dispatch(setTokenA(setAData));
    dispatch(setTokenB(setBData));
  };

  const onClickViewContract = (item) => {
    let url;
    if (chainId == 8898) {
      url = `https://mmtscan.io/address/${item.lpAddress}/contracts`;
    } else if (chainId == 88998) {
      url = `https://testnet.mmtscan.io/address/${item.lpAddress}/contracts`;
    } else if (chainId == 1) {
      url = `https://etherscan.io/address/${item.lpAddress}`;
    } else if (chainId == 8989) {
      url = `https://scan.gmmtchain.io/token/${item.lpAddress}`;
    } else if (chainId == 898989) {
      url = `https://testnet-scan.gmmtchain.io/token/${item.lpAddress}`;
    }
    window.open(url);
  };

  const onClickMaxButton = (item) => {
    let haveAmount =
      txtMessage === "Stake" ? item.lpBalance : item.depositAmount;
    if (Number(haveAmount) == 0) haveAmount = 0;

    let amount = zeroDelete(haveAmount);

    onChangeInputValue(amount, item.lpAddress);
    // setInputAmount(amount);
    // let bool;

    // if (haveAmount == "0" || Number(haveAmount) == 0) {
    //     bool = false
    // } else if (haveAmount != "0") {
    //     bool = true
    // }
    // setConfirmStatus(bool)
  };

  const onClickButton = (type, text, item) => {
    setConfirmText("Confirm");
    setChooseFarm(item);
    setPopupShow(true);
    setTxtMessage(text);
    setPopupType(type);
  };

  // useEffect(() => {
  useLayoutEffect(() => {
    setLoading(true);
    SetFarmsList();
  }, [
    account,
    transactionHash, defaultProvider, FARM_ADDRESS]);

  const setIntervalPendingIVY = async () => {
    if (
      defaultProvider != undefined &&
      chainId != 0 &&
      account != "" &&
      allFarms != "" &&
      FARM_ADDRESS != ""
    ) {

      try {
        const web3 = new Web3(defaultProvider);
        const contract = new web3.eth.Contract(FarmJson, FARM_ADDRESS);
        let havePool = [];
        let data = [];
        // for (let i = 0; i < farms.length; i++) {
        for (let i = 0; i < allFarms.length; i++) {
          let userInfo = await contract.methods
            .userInfo(allFarms[i].pid, account)
            .call();
          if (Number(userInfo.amount) != 0) {
            havePool.push(i);
          }
        }

        for (let x = 0; x < havePool.length; x++) {
          let ivyPending = await getpendingIvy(havePool[x]);
          ivyPending = fromWeiBalance(ivyPending, "18");
          let setData = {
            index: havePool[x],
            ivyPending: ivyPending,
          };
          data.push(setData);
        }

        dispatch(setPendingIvys(data));

      } catch (error) {

      }
    } else {
      let havePool = [];
      let data = [];

      for (let x = 0; x < havePool.length; x++) {
        let setData = {
          index: 0,
          ivyPending: 0,
        };
        data.push(setData);
      }
      dispatch(setPendingIvys(data));
    }
  };

  //state 값으로
  // let setIntervalBool = true

  // const [useEffectPendingInterval , setUseEffectPendingInterval] = useState();
  const farmInterval = useSelector((state) => state.user.farmInterval);

  useEffect(() => {
    let pendingInterval;

    if (
      defaultProvider != undefined &&
      chainId != 0 &&
      account != "" &&
      allFarms != "" &&
      farmInterval == "" &&
      FARM_ADDRESS != ""
    ) {
      pendingInterval = setInterval(() => {
        let nowUrl = window.location.href.split("/")[3];
        if (nowUrl != "farm") {
          clearInterval(pendingInterval);
          dispatch(setFarmInterval(""));
        }
        setIntervalPendingIVY();
      }, 9000);
      // },1000);
      dispatch(setFarmInterval(pendingInterval));
    } else if (account == "" && farmInterval != "") {
      // console.log("disconnect!",farmInterval)
      clearInterval(farmInterval);
      dispatch(setFarmInterval(""));
    }
  }, [
    account,
    chainId,
    allFarms,
    intervalBool,
    defaultProvider,
    farmInterval,
    FARM_ADDRESS,
  ]);

  const onChangeSearch = (e) => {
    let text = e.target.value;
    setSearchTxt(text);
  };

  const onErrorImg = (e) => {
    e.target.src = "logo_.png";
  };

  const toLocaleStringOption = (amount) => {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  const checkInputAmountDecimal = (token) => {
    // try {
    if (token.inputAmount != undefined || token.inputAmount != "") {
      let index = token.inputAmount.indexOf(".");
      if (index != -1) {
        let decimalLength = token.inputAmount.substr(
          index + 1,
          token.inputAmount.length
        );
        if (decimalLength.length <= token.decimal) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  };

  const onClickExplorer = () => {
    if (chainId == 8898) {
      window.open(`https://mmtscan.io/tx/${transactionHash}`);
    } else if (chainId == 88998) {
      window.open(`https://testnet.mmtscan.io/tx/${transactionHash}`);
    } else if (chainId == 97) {
      window.open(`https://testnet.bscscan.com/tx/${transactionHash}`);
    } else if (chainId == 8989) {
      window.open(`https://scan.gmmtchain.io/tx/${transactionHash}`);
    } else if (chainId == 898989) {
      window.open(`https://testnet-scan.gmmtchain.io/tx/${transactionHash}`);
    } else if (chainId == 1) {
      window.open(`https://etherscan.io/tx/${transactionHash}`);
    }
  };

  useEffect(() => {
    functionAll(activeFarms, selectedItem, searchTxt);
  }, [activeFarms, selectedItem, searchTxt, allFarms, account]);

  const onClickTitle = (pageName) => {
    const htmlTitle = document.querySelector("title");
    htmlTitle.innerHTML = pageName;
  };

  useEffect(() => {
    onClickTitle("Farm - Ivory Swap");
  }, []);

  const onClickStatus = (bool) => {
    window.scrollTo(0, 0);
    setActiveOption(bool);
  };

  const checkModalStatusSubmit = () => {
    if (popupType == "submit") {
      setTimeout(() => {
        setPopupShow(false);
        setTxtMessage("");
        setChooseFarm();
        setConfirmStatus(false);
        setInputAmount("");
        setPopupType("");
      }, [4000]);
    }
  };

  useEffect(() => {
    checkModalStatusSubmit();
  }, [popupType]);

  return (
    <div className={styles.bg}>
      <div className={styles.wrap}>
        <div className={styles.title}>Farm</div>
        <div className={styles.farmExp}>
          Let’s farm IVY with your LP tokens
          <img
            src={require("../assets/images/img_tip_wh@2x.png")}
            alt={"img_tip_wh"}
          />
        </div>
        <div className={styles.farmBox}>
          <div className={styles.farmFilter}>
            <div className={styles.filter_left}>
              <button
                className={`${activeFarms ? styles.active : ""}`}
                onClick={() => onClickStatus(true)}
              >
                Active
              </button>
              <button
                className={`${activeFarms ? "" : styles.active}`}
                onClick={() => onClickStatus(false)}
              >
                Inactive
              </button>
            </div>
            <div className={styles.filter_right}>
              <div className={styles.sort}>
                <span>Sort by</span>
                <div className={styles.select}>
                  <div
                    className={`${styles.itemListDim} ${selectFlip == false ? "" : styles.dimOff
                      }`}
                    onClick={selectToggle}
                  />
                  <div
                    className={`${styles.customSelect} ${selectFlip ? styles.show : ""
                      }`}
                  >
                    <div className={styles.selected} onClick={selectToggle}>
                      <span className={styles.coinName}>{selectedItem}</span>
                    </div>
                    {selectFlip && (
                      <div className={styles.itemList}>
                        {itemList &&
                          itemList?.map((item) => {
                            return (
                              <div
                                className={`${styles.item} ${item == selectedItem ? styles.on : ""
                                  }`}
                                onClick={() => getSelectItem(item)}
                                key={item}
                              >
                                <span className={styles.coinName}>{item}</span>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.search}>
                <span>Search</span>
                <input
                  className={styles.inputBox}
                  type={"text"}
                  placeholder={"Search Farms"}
                  value={searchTxt}
                  onChange={(e) => onChangeSearch(e)}
                />
                {/* <img src={require("../assets/images/swap/ico_search_bl.png")} alt={"img_search_ico"} /> */}
                {/* <div className={styles.inputBox}>
                                    <input type={"text"} placeholder={"Search Farms"} />
                                </div> */}
              </div>
            </div>
          </div>
          <div className={styles.farmList}>
            <div className={styles.farmList_title}>
              <div>Featured</div>
              <div>APR</div>
              <div>Liquidity</div>
              <div>Earned</div>
            </div>
            {loading ? (
              <div className={styles.loadingWrap}>
                <img
                  src={require("../assets/images/swap/loading.gif")}
                  alt={"loading.gif"}
                />
              </div>
            ) : farms == "" ? (
              <div className={styles.noResult}>
                <img
                  src={require("../assets/images/ico-noresults-gr@2x.png")}
                  alt={"noresults"}
                />{" "}
                <br />
                <span>No Results Found</span>
              </div>
            ) : (
              <>
                {farms.map((item, index) => (
                  <div
                    key={index}
                    className={`${styles.farmList_item}`}
                    ref={elRefs[index]}
                  >
                    <div
                      className={styles.flex}
                      onClick={() => farmToggle(index)}
                    >
                      {/* <div>
                                                {item.lpSymbol}
                                            </div> */}
                      <div className={styles.featured}>
                        <div className={styles.logoDiv}>
                          <img
                            className={styles.symbolLogo}
                            src={`${chain}/logo_${item.token}.png`}
                            alt={`${item.token}`}
                            onError={onErrorImg}
                          />
                          <img
                            className={styles.symbolLogo}
                            src={`${chain}/logo_${item.quoteToken}.png`}
                            alt={`${item.quoteToken}`}
                            onError={onErrorImg}
                          />
                        </div>
                        {/* <div className={styles.lpSymbol}>{item.lpSymbol}</div> */}
                        <div className={styles.lpSymbol}>
                          {item.tokenSymbol}{" "}
                          <span className={`${styles.bold}`}>-</span>{" "}
                          {item.quoteTokenSymbol}
                        </div>
                      </div>
                      <div>{toLocaleStringOption(item.apr)}%</div>
                      <div>
                        {/* $ */}
                        {toLocaleStringOption(
                          balanceInCode(item.farmLpBalance)
                        )}
                      </div>
                      <div className={styles.earnedTop}>
                        {Number(item.pendingIvy) > 0 && active
                          ? toLocaleStringOption(balanceInCode(item.pendingIvy))
                          : "-"}
                        <img
                          className={styles.openArrow}
                          ref={imgRefs[index]}
                          src={require("../assets/images/swap/ico_arrow_bl.png")}
                          alt={"ico_arrow_bl"}
                        />
                      </div>
                    </div>
                    <div className={styles.itemDetail}>
                      <div className={styles.earned}>
                        {/* <div className={styles.title}>{item.lpSymbol} Earned</div> */}
                        <div className={styles.title}>IVY Earned</div>
                        <div className={styles.flex}>
                          <input
                            type={"text"}
                            value={
                              Number(item.pendingIvy) > 0 && active
                                ? toLocaleStringOption(
                                  balanceInCode(item.pendingIvy)
                                )
                                : ""
                            }
                            placeholder={"0.0"}
                            readOnly
                          />
                          <button
                            onClick={() => {
                              onClickHarveset(item);
                            }}
                          >
                            harvest
                          </button>
                        </div>
                      </div>
                      <div className={styles.staked}>
                        <div className={styles.title}>Staked</div>
                        {account == "" || active == false ? (
                          <>
                            <button onClick={handleConnect}>
                              Connect Wallet
                            </button>
                          </>
                        ) : (
                          <>
                            {item.allowance == false && Number(item.depositAmount) == 0 ? (
                              <div className={styles.flex}>
                                <button
                                  onClick={() => {
                                    onClickApprove(index, item);
                                  }}
                                  className={styles.enable}
                                  disabled={
                                    enableTxt == "Approving" ? true : false
                                  }
                                >
                                  {approveIndex == index ? enableTxt : "enable"}
                                </button>
                              </div>
                            ) : Number(item.depositAmount) == 0 ? (
                              <div
                                className={styles.flex}
                                onClick={() => {
                                  onClickButton("stake", "Stake", item);
                                }}
                              >
                                <button>Stake LP</button>
                              </div>
                            ) : (
                              <div className={styles.flex}>
                                <button
                                  className={styles.stake}
                                  onClick={() => {
                                    onClickButton("stake", "Stake", item);
                                  }}
                                >
                                  Stake LP
                                </button>
                                <button
                                  className={styles.add}
                                  onClick={() => {
                                    onClickButton("stake", "Stake", item);
                                  }}
                                >
                                  <img
                                    src={require("../assets/images/ico_plus_bl.png")}
                                    alt={"ico_plus_bl"}
                                  />{" "}
                                  Add
                                </button>
                                <button
                                  className={styles.remove}
                                  onClick={() => {
                                    onClickButton("stake", "UnStake", item);
                                  }}
                                >
                                  <img
                                    src={require("../assets/images/ico_minus_red.png")}
                                    alt={"ico_minus_red"}
                                  />{" "}
                                  Remove
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div className={styles.myInfo}>
                        <div className={styles.title}>Info</div>
                        <div className={styles.flex}>
                          <button
                            onClick={() => {
                              onClickAddLiquidityBtn(
                                item.token,
                                item.quoteToken
                              );
                            }}
                          >
                            <Link to={"/add"}>
                              Get LP Token
                              <img
                                src={require("../assets/images/swap/ico_arrow_bl.png")}
                                alt={"ico_arrow_bl"}
                              />
                            </Link>
                          </button>
                          <button>
                            {/* <Link to={"/info"} > */}
                            <Link to={"/coming"}>
                              About Pair Info{" "}
                              <img
                                src={require("../assets/images/swap/ico_arrow_bl.png")}
                                alt={"ico_arrow_bl"}
                              />
                            </Link>
                          </button>
                          <button
                            onClick={() => {
                              onClickViewContract(item);
                            }}
                          >
                            View Contract{" "}
                            <img
                              src={require("../assets/images/swap/ico_arrow_bl.png")}
                              alt={"ico_arrow_bl"}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>

      {popupShow && (
        <div className={styles.popupWrap}>
          <div className={styles.popupDim} onClick={closePopup} />
          {popupType === "enough" ? (
            <div
              className={`${styles.popup_notEnough} ${styles.popupContainer}`}
            >
              <img
                className={styles.closeBtn}
                onClick={closePopup}
                src={require("../assets/images/ico_close_bl@2x.png")}
                alt={"ico_close_bl"}
              />
              <div>
                <p>
                  <img
                    className={styles.notEnough}
                    src={require("../assets/images/ico_enough@2x.png")}
                    alt={"ico_enough"}
                  />
                </p>
                <span>
                  <span>Make sure that</span> <span>you have enough gas!</span>
                </span>
              </div>
            </div>
          ) : popupType === "stake" ? (
            <div className={`${styles.popup_stakeLP} ${styles.popupContainer}`}>
              <img
                className={styles.closeBtn}
                onClick={closePopup}
                src={require("../assets/images/ico_close_bl@2x.png")}
                alt={"ico_close_bl"}
              />
              <div className={styles.title}>{txtMessage} LP Tokens</div>
              <div className={styles.tokenName_balance}>
                <div className={styles.tokenName}>
                  {chooseFarm.tokenSymbol}-{chooseFarm.quoteTokenSymbol} LP
                </div>
                <div className={styles.balance}>
                  Balance:
                  {toLocaleStringOption(
                    balanceInCode(
                      `${txtMessage === "Stake"
                        ? chooseFarm.lpBalance
                        : chooseFarm.depositAmount
                      }`
                    )
                  )}
                </div>
              </div>
              <div className={styles.inputWrap}>
                <input
                  type={"text"}
                  placeholder={0.0}
                  value={inputAmount === "" ? inputAmount : inputAmount}
                  onChange={(e) => {
                    onChangeInputValue(e.target.value, chooseFarm.lpAddress);
                  }}
                  min={0.000000000000000001}
                />
                <button
                  onClick={() => {
                    onClickMaxButton(chooseFarm);
                  }}
                >
                  MAX
                </button>
                {Number(
                  `${txtMessage === "Stake"
                    ? chooseFarm.lpBalance
                    : chooseFarm.depositAmount
                  }`
                ) === 0 ? (
                  <div className={styles.warning}>No Tokens to stake!</div>
                ) : (
                  ""
                )}
              </div>
              <div className={styles.btnBox}>
                <button className={styles.cancel} onClick={closePopup}>
                  Cancel
                </button>
                <button
                  className={`${styles.confirm} ${confirmStatus ? styles.btnActive : ""
                    }`}
                  onClick={() => {
                    if (stakeConfirmText == "Approve") {
                      onClickApproveModal(chooseFarm.lpAddress);
                    } else {
                      if (txtMessage === "Stake") {
                        onClickDeposit(chooseFarm);
                      } else if (txtMessage === "UnStake") {
                        onClickWithDraw(chooseFarm);
                      }
                    }
                  }}
                // >Confirm</button>
                >
                  {stakeConfirmText}
                </button>
              </div>
              {txtMessage === "UnStake" ? (
                ""
              ) : (
                <div
                  className={styles.getTokenBtn}
                  onClick={() =>
                    onClickAddLiquidityBtn(
                      chooseFarm.token,
                      chooseFarm.quoteToken
                    )
                  }
                >
                  <Link to={"/add"}>
                    {/* Get {chooseFarm.lpSymbol} LP */}
                    Get {chooseFarm.tokenSymbol}-{chooseFarm.quoteTokenSymbol}{" "}
                    LP
                  </Link>
                </div>
              )}
            </div>
          ) : popupType === "confirm" ? (
            <div className={`${styles.popup_confirm} ${styles.popupContainer}`}>
              <img
                className={styles.closeBtn}
                onClick={closePopup}
                src={require("../assets/images/ico_close_bl@2x.png")}
                alt={"ico_close_bl"}
              />
              <div>
                <img
                  src={require("../assets/images/swap/loading.gif")}
                  alt={"loading_gif"}
                />
              </div>
              <div className={styles.bold}>Waiting for confirmation</div>
              <div className={styles.normal}>
                Confirm this transaction in your wallet
              </div>
              <div className={styles.gray}>
                {confirmTxt} {toLocaleStringOption(balanceInCode(inputAmount))}{" "}
                {chooseFarm.tokenSymbol}-{chooseFarm.quoteTokenSymbol} LP
              </div>
            </div>
          ) : popupType === "submit" ? (
            <div className={`${styles.popup_confirm} ${styles.popupContainer}`}>
              <img
                className={styles.closeBtn}
                onClick={closePopup}
                src={require("../assets/images/ico_close_bl@2x.png")}
                alt={"ico_close_bl"}
              />
              <div>
                <img
                  src={require("../assets/images/swap/ico_confirmed.png")}
                  alt={"ico_confirmed"}
                />
              </div>
              <div className={styles.bold}>Transaction Submitted</div>
              <span
                className={styles.viewExplorer}
                onClick={() => onClickExplorer()}
              >
                View on Explorer
                <img
                  src={require("../assets/images/swap/green-arrow.png")}
                  alt={"green-arrow"}
                />
              </span>
            </div>
          ) : popupType === "Failed" ? (
            <div className={`${styles.popup_confirm} ${styles.popupContainer}`}>
              <img
                className={styles.closeBtn}
                onClick={closePopup}
                src={require("../assets/images/ico_close_bl@2x.png")}
                alt={"ico_close_bl"}
              />
              <div>
                <img
                  src={require("../assets/images/ico_rejected.png")}
                  alt={"ico-rejected"}
                />
              </div>
              <div className={styles.bold}>Transaction Rejected</div>
              {/* <div className={styles.normal}>Confirm this transaction in your wallet</div> */}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default Farm;
