import React from 'react'

const SwapComponent = ({ who, swap, clickMaxBtn, onChangeInputAmount , status}) => {
  return (
    <div>
      {who}{status?<>(estimated)</>:<></>}
      <div>Balance:{swap.haveAmount}</div>
      {who === "From" ?
        <button onClick={() => clickMaxBtn()}>MAX</button> : <div></div>
      }
      <div>Price : {swap.price}</div>
      <input type={"number"} value={swap.inputAmount} onChange={(e) =>onChangeInputAmount(e, who)} />
      {swap.symbol}
    </div>
  )
}

export default SwapComponent