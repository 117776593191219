export const stakingTokens = [
    {
        pid : "0",
        projectURL : ""
    },
    {
        pid : "1",
        projectURL : ""
    },
    {
        pid : "2",
        projectURL : ""
    },
]