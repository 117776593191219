import { createAction, handleAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { put, takeEvery } from "redux-saga/effects";


const initialState = {
    stakeList: [],
    allStakeList: [],
}

const SETSTAKELIST = "stake/SETSTAKELIST";
export const setStakeLists = createAction(SETSTAKELIST, (input) => input);

const SETALLSTAKELIST = "stake/SETALLSTAKELIST";
export const setAllStakeList = createAction(SETALLSTAKELIST, (input) => input);

const SETTOKENALLOWANCE = "stake/SETTOKENALLOWANCE";
export const setTokenAllowance = createAction(SETTOKENALLOWANCE, (input) => input);

const SETSTAKINGREWARD = "stake/SETSTAKINGREWARD";
export const setStakingReward = createAction(SETSTAKINGREWARD, (input) => input);

const SETFUNAMOUNT = "stake/SETFUNAMOUNT";
export const setFunAmount = createAction(SETFUNAMOUNT, (input) => input);

const DISCONNECT = "stake/DISCONNECT";
export const setDisConnect = createAction(DISCONNECT,(input)=>input);


const stake = handleActions({
    [SETSTAKELIST]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.stakeList = input
        }),
    [SETALLSTAKELIST]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.allStakeList = input
        }),
    [SETTOKENALLOWANCE]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.stakeList[input.index].allowance = input.result
            draft.allStakeList[input.index].allowance = input.result
        }),
    [SETSTAKINGREWARD]: (state, { payload: input }) =>
        produce(state, (draft) => {
            for (let i = 0; i < input.length; i++) {
                let a = draft.allStakeList.find((item, index) => {
                    if (item.pid === input[i].index.pid) {
                        draft.allStakeList[index].pendingReward = input[i].pedingValue
                    }
                });
            }

        }),
}, initialState);

export default stake;