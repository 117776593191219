import React from 'react'

const ResultAmount = ({ token_0, token_1 }) => {
    return (
        <div>
            <div>
                <div>{token_0.inputAmount} / {token_0.symbol}</div>
                <div>{token_1.inputAmount} / {token_1.symbol}</div>
                <div>{token_0.address}</div>
                <div>{token_1.address}</div>
            </div>
        </div>
    )
}

export default ResultAmount