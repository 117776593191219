import { createAction, handleAction, handleActions } from 'redux-actions';
import produce from 'immer';

const initialState = {
    // nowChainName : "MMT"
    nowChainName : "MMT TestNet",
};

const SETNOWCHAINNAME = "main/SETNOWCHAINNAME";
export const setNowChainName = createAction(SETNOWCHAINNAME, (user)=>user);

const main = handleActions({
    [SETNOWCHAINNAME] : (state, {payload : input}) =>
        produce(state,(draft)=> {
            draft.nowChainName = input
        })
},initialState);

export default main