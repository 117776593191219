import React from 'react';
import SlippageBtn from '../components/SlippageBtn';

const Slippage = ({slippage , changeSlippage}) => {
    return (
        <div>
            Slippage Tolerance
            <input type={'number'} value={slippage} onChange={(e)=>changeSlippage(e)}/>
            <SlippageBtn changeSlippage={changeSlippage} value={0.1}/>
            <SlippageBtn changeSlippage={changeSlippage} value={0.5}/>
            <SlippageBtn changeSlippage={changeSlippage} value={1.0}/>
        </div>
    );
};

export default Slippage;
