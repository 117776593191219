import React from 'react';

const AddLiquidityContainer = ({ tokenA, tokenB, changeInputValue }) => {


    return (
        <div>
            <div>
                <h2>TokenA</h2>
                <div>Name : {tokenA.name}</div>
                <div>Symbol :{tokenA.symbol}</div>
                <div>Balance :{tokenA.haveAmount}</div>
                <div>Price :{tokenA.price}</div>
                <input type={"number"} inputMode={"decimal"} pattern={"^[0-9]*[.,]?[0-9]*$"} maxLength={78}
                 placeholder={0}  onChange={(e) => { changeInputValue("tokenA", e) }} value={tokenA.inputAmount} />
            </div>
            <h2>+</h2>
            <div>
                <h2>TokenB</h2>
                <div>Name : {tokenB.name}</div>
                <div>Symbol :{tokenB.symbol}</div>
                <div>Balance :{tokenB.haveAmount}</div>
                <div>Price :{tokenB.price}</div>
                <input type={"number"} placeholder={0} onChange={(e) => { changeInputValue("tokenB", e) }} value={tokenB.inputAmount}/>
            </div>
        </div>
    );
};

export default AddLiquidityContainer;
