import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import AddToken from "../containers/AddToken";
import AddLiquidityContainer from "../containers/AddLiquidityContainer";
import ERC20JSON from "../abi/ERC20.json";
import WMMTJSON from "../abi/WMMT.json";
import FactoryJSON from "../abi/Factory.json";
import PairJSON from "../abi/Pair.json";
import LP from "../abi/LP.json";
import tokensListJSON from "../tokenList/tokenList.json";
import Router from "../abi/Router.json";
import {
  setLiquidity,
  setList,
  setTokenA,
  setTokenA_Allowance,
  setTokenA_HaveAmount,
  setTokenA_InputAmount,
  setTokenB,
  setTokenBalance,
  setTokenB_Allowance,
  setTokenB_HaveAmount,
  setTokenB_InputAmount,
  setTokenList,
  setTokensList,
} from "../modules/liquidity";
import TokenList from "../containers/TokenList";
import ApproveBtn from "../containers/ApproveBtn";
import AddLiquidityBtn from "../containers/AddLiquidityBtn";
import Slippage from "../containers/Slippage";
import Time from "../containers/Time";
import BN from "bn.js";
import styles from "../assets/css/AddLiquidity.module.css";
import stylesSwap from "../assets/css/Swap.module.css";
import tokenList_BSC_Testnet from "../tokenList/tokenList_BSC_Testnet.json";
import tokenList_MMT_Mainnet from "../tokenList/tokenList_MMT_Mainnet.json";
import tokenList_EthereumMainnet from "../tokenList/tokenList_EthereumMainnet.json";
import tokenList_sepolia from "../tokenList/tokenList_sepolia.json";
import tokenList_MainGMMT from "../tokenList/tokenList_MainGMMT.json";
import tokenList_TestGMMT from "../tokenList/tokenList_TestGMMT.json";

import { calcDecimal, returnDecimal } from "../common/commonService";
import { toBN, toNumber } from "web3-utils";
import {
  setTransactionHash,
  setUpdateUserDeadLine,
  setUpdateUserSlippageTolerance,
} from "../modules/user";
// import PriceAndPoolShare from '../containers/PriceAndPoolShare';

import { MaxUint256 } from "@ethersproject/constants";
import { Link } from "react-router-dom";

import noneTokenImg from "../assets/images/sym_none.png";
import MMTTokenImg from "../assets/images/sym_mmt.png";

const AddLiquidity = ({
  wCoinSymbol,
  coinSymbol,
  defaultProvider,
  handleConnect,
  account,
  web3Provider,
  ROUTER_ADDRESS,
  WMMT_ADDRESS,
  FACTORY_ADDRESS,
  // chainId,
  active,
}) => {
  const dispatch = useDispatch();

  // let chain =  "BNB"

  // const web3 = web3Provider;
  const web3 = defaultProvider;

  const [tokenAStatus, setTokenAStatus] = useState("checked");
  const [tokenBStatus, setTokenBStatus] = useState("");
  // const [slippage, setSlippage] = useState(0.1);
  // const [time, setTime] = useState(20);
  const [approveBtnMessage, setApproveBtnMessage] = useState("Invalid Pair");
  const [approveBtnStatus, setApproveBtnStatus] = useState(true);
  const [reserveamount0, setReserveamount0] = useState("-");
  const [reserveamount1, setReserveamount1] = useState("-");
  const [sharePercent, setSharePercent] = useState(0);
  const [poolStatusMessage, setPoolStatusMessage] = useState(
    "PRICES AND POOL SHARE"
  );

  // const [transactionHash, setTransactionHash] = useState("");

  const tokenA = useSelector((state) => state.liquidity.tokenA);
  const tokenB = useSelector((state) => state.liquidity.tokenB);
  const tokenList = useSelector((state) => state.liquidity.tokenList);
  const slippage = useSelector((state) => state.user.userSlippageTolerance);
  const time = useSelector((state) => state.user.deadline);
  const transactionHash = useSelector((state) => state.user.transactionHash);
  const chainId = useSelector((state) => state.user.chainId);
  const [chain, setChain] = useState(
    Number(chainId) == 8898 || Number(chainId) == 88998
      ? "MMT"
      : Number(chainId) == 5 || Number(chainId) == 1
      ? "ETH"
      : Number(chainId) == 898989 || Number(chainId) == 8989
      ? "GMMT"
      : ""
  );

  const [popupShow, setPopupShow] = useState(false);
  const [selectActive, setSelectActive] = useState("NAN");
  const [selectedToken, setSelectedToken] = useState("finnDev");
  const [nowModalStatus, setNowModalStatus] = useState("selectToken");
  const [addTokenMenuActive, setAddTokenMenuActive] = useState("token");
  const [selectTokenAddress, setSelectTokenAddress] = useState("");
  const [slipPageShow, setSlipPageShow] = useState(false);
  const [timePageShow, setTimePageShow] = useState(false);

  const [addTokenList, setAddTokenList] = useState([]);
  const [myAddTokenList, setMyAddTokenList] = useState("");
  const [selectActiveA, setSelectActiveA] = useState("NAN");
  const [selectedTokenA, setSelectedTokenA] = useState("finnDev");
  const [selectActiveB, setSelectActiveB] = useState("NAN");
  const [selectedTokenB, setSelectedTokenB] = useState("finnDev");
  const [searchToken, setSearchToken] = useState("");
  const [questionHover, setQuestionHover] = useState("");

  const [inputType, setInputType] = useState("");

  const [overInputBalance, setOverInputBalance] = useState("");

  const [addTokenSelectMessage, setAddtokenSelectMessage] = useState("");

  const [netWorkList, setNetWorkTokenList] = useState([]);
  const [insufficientStatusTokenA, setInsufficientStatusTokenA] =
    useState(false);
  const [insufficientStatusTokenB, setInsufficientStatusTokenB] =
    useState(false);

  const [resetStatus, setResetStatus] = useState(false);

  const [selectedTokenData, setSelectedTokenData] = useState();
  const [selectType, setSelectType] = useState("");

  useEffect(() => {
    let chainNum =
      chain == "MMT"
        ? [8898, 88998]
        : chain == "GMMT"
        ? [8989, 898989]
        : chain == "ETH"
        ? [1, 5]
        : [];
    if (Number(chainId) != 0) {
      if (Number(chainId) == 8898 || Number(chainId) == 88998) {
        setChain("MMT");
      } else if (Number(chainId) == 97) {
        setChain("BNB");
      } else if (Number(chainId) == 5 || Number(chainId) == 1) {
        setChain("ETH");
      } else if (Number(chainId) == 898989 || Number(chainId) == 8989) {
        setChain("GMMT");
      }
      if (!chainNum.includes(Number(chainId))) {
        onClickReset(false);
      }
    }
  }, [chainId]);

  useEffect(() => {
    let list = [];
    if (chainId == 88998) {
      for (let i = 0; i < tokensListJSON.length; i++) {
        let data = {
          address: tokensListJSON[i].address.toLowerCase(),
          decimal: tokensListJSON[i].decimal,
          image: tokensListJSON[i].image.toLowerCase(),
          name: tokensListJSON[i].name,
          price: tokensListJSON[i].price,
          symbol: tokensListJSON[i].symbol,
          inputAmount: tokensListJSON[i].inputAmount,
        };
        list.push(data);
      }
      setNetWorkTokenList(list);
    } else if (chainId == 97) {
      setNetWorkTokenList(tokenList_BSC_Testnet);
    } else if (chainId == 8898) {
      for (let i = 0; i < tokenList_MMT_Mainnet.length; i++) {
        let data = {
          address: tokenList_MMT_Mainnet[i].address.toLowerCase(),
          decimal: tokenList_MMT_Mainnet[i].decimal,
          image: tokenList_MMT_Mainnet[i].image.toLowerCase(),
          name: tokenList_MMT_Mainnet[i].name,
          price: tokenList_MMT_Mainnet[i].price,
          symbol: tokenList_MMT_Mainnet[i].symbol,
          inputAmount: tokenList_MMT_Mainnet[i].inputAmount,
        };
        list.push(data);
      }
      setNetWorkTokenList(list);
    } else if (chainId == 5) {
      for (let i = 0; i < tokenList_sepolia.length; i++) {
        let data = {
          address: tokenList_sepolia[i].address.toLowerCase(),
          decimal: tokenList_sepolia[i].decimal,
          image: tokenList_sepolia[i].image.toLowerCase(),
          name: tokenList_sepolia[i].name,
          price: tokenList_sepolia[i].price,
          symbol: tokenList_sepolia[i].symbol,
          inputAmount: tokenList_sepolia[i].inputAmount,
        };
        list.push(data);
      }
      setNetWorkTokenList(list);
    } else if (chainId == 1) {
      for (let i = 0; i < tokenList_EthereumMainnet.length; i++) {
        let data = {
          address: tokenList_EthereumMainnet[i].address.toLowerCase(),
          decimal: tokenList_EthereumMainnet[i].decimal,
          image: tokenList_EthereumMainnet[i].image.toLowerCase(),
          name: tokenList_EthereumMainnet[i].name,
          price: tokenList_EthereumMainnet[i].price,
          symbol: tokenList_EthereumMainnet[i].symbol,
          inputAmount: tokenList_EthereumMainnet[i].inputAmount,
        };
        list.push(data);
      }
      setNetWorkTokenList(list);
    } else if (chainId == 8989) {
      for (let i = 0; i < tokenList_MainGMMT.length; i++) {
        let data = {
          address: tokenList_MainGMMT[i].address.toLowerCase(),
          decimal: tokenList_MainGMMT[i].decimal,
          image: tokenList_MainGMMT[i].image.toLowerCase(),
          name: tokenList_MainGMMT[i].name,
          price: tokenList_MainGMMT[i].price,
          symbol: tokenList_MainGMMT[i].symbol,
          inputAmount: tokenList_MainGMMT[i].inputAmount,
        };
        list.push(data);
      }
      setNetWorkTokenList(list);
    } else if (chainId == 898989) {
      for (let i = 0; i < tokenList_TestGMMT.length; i++) {
        let data = {
          address: tokenList_TestGMMT[i].address.toLowerCase(),
          decimal: tokenList_TestGMMT[i].decimal,
          image: tokenList_TestGMMT[i].image.toLowerCase(),
          name: tokenList_TestGMMT[i].name,
          price: tokenList_TestGMMT[i].price,
          symbol: tokenList_TestGMMT[i].symbol,
          inputAmount: tokenList_TestGMMT[i].inputAmount,
        };
        list.push(data);
      }
      setNetWorkTokenList(list);
    }
  }, [chainId]);

  const fromWeiBalance = (balance, decimal) => {
    try {
      let result = new BN(`${10}`).pow(new BN(`${decimal}`)).toString();
      let fromWeiInputBalance = new BN(`${balance}`).divmod(
        new BN(`${result}`)
      );
      let divBalance = fromWeiInputBalance.div.toString();
      let modBalance = fromWeiInputBalance.mod.toString();
      let subDecimal = decimal - modBalance.length;
      let zero = "";
      for (let i = 0; i < subDecimal; i++) {
        zero += "0";
      }

      let returnBalance = divBalance + "." + zero + modBalance;
      return returnBalance;
    } catch (error) {
      // console.log(error)
    }
  };

  const toWeiBalance = (balance, decimal) => {
    // if (web3Provider != undefined) {
    let point = balance.split(".");
    let pointPow = 1;
    let powLength = 0;
    let lastPoint = "";
    if (point[1] != undefined) {
      powLength = point[1].length;
      pointPow = new BN(`${10}`).pow(new BN(`${point[1].length}`)).toString();
      lastPoint = point[1].substr(0, decimal);
    }

    let balancePoinPow = point[0] + lastPoint;

    let subDecimal = new BN(`${decimal}`)
      .sub(new BN(`${powLength}`))
      .toString();

    let result = new BN(`${10}`).pow(new BN(`${subDecimal}`)).toString();
    let decimalBalance = new BN(`${balancePoinPow}`)
      .mul(new BN(`${result}`))
      .toString();

    return decimalBalance;
    // }
  };

  const clickSelectToken = (tokenName) => {
    setSelectActive(tokenName);
    setSelectedToken(tokenName);
    setPopupShow(false);
  };

  const slipPageToggle = () => {
    if (slippage == "" || Number(slippage) <= 0) {
      dispatch(setUpdateUserSlippageTolerance(0.5));
    }
    setSlipPageShow(!slipPageShow);
  };

  const timePageToggle = () => {
    if (time == 0) {
      dispatch(setUpdateUserDeadLine(20 * 60));
    }
    setTimePageShow(!timePageShow);
  };

  // useEffect(() => {
  //   if (nowModalStatus == 'confirmation') {
  //     if (transactionHash != "") {
  //       setNowModalStatus('submitted');
  //     }
  //   }
  // }, [nowModalStatus, transactionHash]);

  useEffect(() => {
    if (account == "" && active == false) {
      setApproveBtnMessage("Connect Wallet");
      setApproveBtnStatus(false);
      dispatch(setTokenA_HaveAmount(0));
      dispatch(setTokenB_HaveAmount(0));
      // setApproveBtnMessage("Invalid Pair");
      // setApproveBtnStatus(true);
    } else {
      approveStatus();
      getTokenBalance();
    }
  }, [
    account,
    active,
    WMMT_ADDRESS,
    tokenA,
    tokenB,
    defaultProvider,
    chainId,
    web3Provider,
  ]);

  useEffect(() => {
    setTokensList();
    getAddTokenList();
  }, [
    account,
    tokenA,
    tokenB,
    transactionHash,
    netWorkList,
    defaultProvider,
    WMMT_ADDRESS,
  ]);

  useEffect(() => {
    if (tokenA.symbol != "" && account != "") {
      chooseToken(tokenA.address);
    }
  }, [account, tokenA, defaultProvider, active]);

  useEffect(() => {
    if (tokenB.symbol != "" && account != "") {
      chooseToken(tokenB.address);
    }
  }, [account, tokenB, defaultProvider, active]);

  useEffect(() => {
    if (tokenA.symbol != "" && tokenB.symbol != "" && account != "") {
      approveStatus();
      checkAllowance();
      showPricesAndPoolShare();
    }
  }, [
    account,
    tokenA,
    tokenB,
    transactionHash,
    defaultProvider,
    WMMT_ADDRESS,
    web3Provider,
    chainId,
  ]);

  // useEffect(() => {
  //   if (account != ""&&tokenA!="symbol") {
  //     let data;
  //     if (inputType == "tokenA") {
  //       data = tokenA.inputAmount
  //     } else if (inputType == "tokenB") {
  //       data = tokenB.inputAmount
  //     }
  //     checkPair(inputType, data);
  //   }
  // }, [account])

  //  tokenA, tokenB 선택할 수 있는 radio
  const changeRadioStatus = () => {
    if (tokenAStatus == "checked") {
      setTokenBStatus("checked");
      setTokenAStatus("");
    } else {
      setTokenBStatus("");
      setTokenAStatus("checked");
    }
  };

  useEffect(() => {
    if (tokenAStatus == "checked") {
      let e = {
        target: {
          value: tokenA.inputAmount,
        },
      };
      changeInputValue("tokenA", e);
    }
  }, [
    tokenA.address,
    // web3Provider,
    ROUTER_ADDRESS,
    WMMT_ADDRESS,
    FACTORY_ADDRESS,
    // chainId,
    // active,
  ]);

  useEffect(() => {
    if (tokenBStatus == "checked") {
      let e = {
        target: {
          value: tokenB.inputAmount,
        },
      };
      changeInputValue("tokenB", e);
    }
  }, [
    tokenB.address,
    // web3Provider,
    ROUTER_ADDRESS,
    WMMT_ADDRESS,
    FACTORY_ADDRESS,
    // chainId,
    // active,
  ]);

  // tokenA, tokenB 값 넣기
  const onClickAddToken = (item) => {
    try {
      const body = item;

      let amount;

      const reset = {
        address: "",
        symbol: "",
        name: "",
        decimal: 0,
        inputAmount: "",
        image: "",
        haveAmount: 0,
      };
      let q1 = tokenA.address == "" && tokenA.symbol == "";
      let q2 =
        (tokenA.address != "" && tokenA.symbol != "") ||
        (tokenA.address == "" && tokenA.symbol == chain);
      let q3 = tokenA.address != item.address && tokenA.symbol != item.symbol;
      let q3_1 = tokenA.address != item.address && tokenA.symbol == item.symbol;
      let q4 = tokenA.address == item.address && tokenA.symbol == item.symbol;

      let q5 = tokenB.address == "" && tokenB.symbol == "";
      let q6 =
        (tokenB.address != "" && tokenB.symbol != "") ||
        (tokenB.address == "" && tokenB.symbol == chain);
      let q7 = tokenB.address != item.address && tokenB.symbol != item.symbol;
      let q7_1 = tokenB.address != item.address && tokenB.symbol == item.symbol;
      let q8 = tokenB.address == item.address && tokenB.symbol == item.symbol;

      if (tokenAStatus == "checked") {
        if (q1) {
          if (q5) {
            dispatch(setTokenA(body));
            setSelectActiveA(body.address);
            setSelectedTokenA(body.address);
          } else if (q6) {
            if (q7 || q7_1) {
              dispatch(setTokenA(body));
              setSelectActiveA(body.address);
              setSelectedTokenA(body.address);
            } else if (q8) {
              dispatch(setTokenA(body));
              dispatch(setTokenB(reset));
              setSelectActiveA(body.address);
              setSelectedTokenA(body.address);
              setSelectActiveB("NAN");
              setSelectedTokenB("NAN");
            }
          }
        } else if (q2) {
          if (q5) {
            dispatch(setTokenA(body));
            setSelectActiveA(body.address);
            setSelectedTokenA(body.address);
          } else if (q6) {
            if (q7 || q7_1) {
              dispatch(setTokenA(body));
              setSelectActiveA(body.address);
              setSelectedTokenA(body.address);
            } else if (q8) {
              dispatch(setTokenA(body));
              dispatch(setTokenB(tokenA));
              setSelectActiveA(body.address);
              setSelectedTokenA(body.address);
              setSelectActiveB(tokenA.address);
              setSelectedTokenB(tokenA.address);
            }
          }
        }
      } else if (tokenBStatus == "checked") {
        if (q5) {
          if (q1) {
            dispatch(setTokenB(body));
            setSelectActiveB(body.address);
            setSelectedTokenB(body.address);
          } else if (q2) {
            if (q3 || q3_1) {
              dispatch(setTokenB(body));
              setSelectActiveB(body.address);
              setSelectedTokenB(body.address);
            } else if (q4) {
              dispatch(setTokenA(reset));
              dispatch(setTokenB(body));
              setSelectActiveA("NAN");
              setSelectedTokenA("NAN");
              setSelectActiveB(body.address);
              setSelectedTokenB(body.address);
            }
          }
        } else if (q6) {
          if (q1) {
            dispatch(setTokenB(body));
            setSelectActiveB(body.address);
            setSelectedTokenB(body.address);
          } else if (q2) {
            if (q3 || q3_1) {
              dispatch(setTokenB(body));
              setSelectActiveB(body.address);
              setSelectedTokenB(body.address);
            } else if (q4) {
              dispatch(setTokenA(tokenB));
              dispatch(setTokenB(body));
              setSelectActiveA(tokenB.address);
              setSelectedTokenA(tokenB.address);
              setSelectActiveB(body.address);
              setSelectedTokenB(body.address);
            }
          }
        }
      }
      if (searchToken != "") {
        setSearchToken("");
      }
      setNowModalStatus("selectToken");
      setPopupShow(false);
    } catch (error) {
      // console.log("err",error)
    }
  };

  const setSelectAddress = (e) => {
    let search = e.target.value;
    setSearchToken(search);
  };

  const selectTokenList = (e) => {
    let search = e.target.value;
    // setSearchToken(search);
    let result = [];
    try {
      if (search == "") {
        result = netWorkList;
      } else {
        if (search.length == 42) {
          for (let i = 0; i < netWorkList.length; i++) {
            if (netWorkList[i].address.toLowerCase() == search.toLowerCase()) {
              // result = tokensListJSON[i];
              result.push(netWorkList[i]);
            } else {
            }
          }
        } else {
          for (let i = 0; i < netWorkList.length; i++) {
            let nameToLowerCase = netWorkList[i].name.toLowerCase();
            let symbolToLowerCase = netWorkList[i].symbol.toLowerCase();
            if (nameToLowerCase.includes(search.toLowerCase())) {
              result.push(netWorkList[i]);
            } else if (symbolToLowerCase.includes(search.toLowerCase())) {
              result.push(netWorkList[i]);
            }
          }
        }
      }
      // console.log(result)
      dispatch(setTokenList(result));
    } catch (error) {
      console.log(error);
      dispatch(setTokenList([]));
    }
  };

  const clickAddTokenAndList = (item) => {
    let image = "";
    if (
      item.symbol == "MMT" ||
      item.symbol == "WMMT" ||
      item.symbol == "BTC" ||
      item.symbol == "ETH"
    ) {
      image = item.symbol.toLowerCase();
    }
    let body = {
      address: item.address,
      symbol: item.symbol,
      name: item.name,
      image: item.address.toLowerCase(),
      haveAmount: "",
      inputAmount: "",
      decimal: item.decimal,
    };
    onClickAddToken(body);
    body = {
      address: item.address,
      symbol: item.symbol,
      name: item.name,
      image: item.address.toLowerCase(),
      inputAmount: "",
      haveAmount: "",
      decimal: item.decimal,
      chainId: chainId,
    };
    let getTokenList = JSON.parse(window.localStorage.getItem("addTokenList"));
    let a;
    if (getTokenList.tokens == "") {
      a = getTokenList.tokens;
    } else {
      a = getTokenList.tokens.getTokenList;
    }

    if (a.length == 0) {
      a.push(body);
      getTokenList = a;
      window.localStorage.setItem(
        "addTokenList",
        JSON.stringify({
          tokens: {
            getTokenList,
          },
        })
      );
    } else {
      let result = true;
      for (let i = 0; i < a.length; i++) {
        if (a[i].address == body.address) {
          result = false;
        }
      }
      if (result == true) {
        a.push(body);
        getTokenList = a;
        window.localStorage.setItem(
          "addTokenList",
          JSON.stringify({
            tokens: {
              getTokenList,
            },
          })
        );
      }

      setSelectTokenAddress("");
      setAddTokenList([]);
      // 토큰 선택하면 추가하는 걸 만들기
    }
  };

  const getAddTokenList = () => {
    // if (account != "" && chainId != 0) {
    let getTokenList = JSON.parse(window.localStorage.getItem("addTokenList"));
    if (chainId != 0 && getTokenList == null) {
      window.localStorage.setItem(
        "addTokenList",
        JSON.stringify({ tokens: [] })
      );
    } else if (getTokenList.tokens != []) {
      getTokenList = getTokenList.tokens.getTokenList;
      if (getTokenList == undefined) {
        getTokenList = [];
      } else {
        let data = [];
        for (let i = 0; i < getTokenList.length; i++) {
          if (chainId == getTokenList[i].chainId) {
            data.push(getTokenList[i]);
          }
        }
        getTokenList = data;
      }
      setMyAddTokenList(getTokenList);
    }
    // }
  };

  const getDecimalPlaces = (value) => {
    const stringValue = value.toString();
    const decimalIndex = stringValue.indexOf(".");
    if (decimalIndex === -1) {
      return 0; // 소수점이 없는 경우, 소수점 자릿수가 없으므로 0을 반환합니다.
    }

    return stringValue.length - decimalIndex - 1;
  };

  const checkPair = async (kinds, inputAmount) => {
    //((tokenA.symbol=="MMT"&&tokenA.address=="")&&(tokenB.address!=""))&&((tokenB.symbol=="MMT"&&tokenB.address=="")&&(tokenA.address!=""))
    // 1. 요부분 다시 아무것도 안할때
    // 2. 그 뒤로 다시 생각해보기
    let token_0;
    let token_1;
    if (
      tokenA.symbol == chain &&
      tokenA.address == "" &&
      tokenB.address != ""
    ) {
      token_0 = WMMT_ADDRESS;
      token_1 = tokenB.address;
    } else if (
      tokenB.symbol == chain &&
      tokenB.address == "" &&
      tokenA.address != ""
    ) {
      token_0 = tokenA.address;
      token_1 = WMMT_ADDRESS;
    } else {
      token_0 = tokenA.address;
      token_1 = tokenB.address;
    }
    try {
      if (token_0 != "" && token_1 != "") {
        // setSelectActiveA(tokenA.address);
        // setSelectActiveB(tokenB.address);
        let factoryContract = new web3.eth.Contract(
          FactoryJSON,
          FACTORY_ADDRESS
        );
        let getPair = await factoryContract.methods
          .getPair(token_0, token_1)
          .call();
        if (getPair != "0x0000000000000000000000000000000000000000") {
          let pairContract = new web3.eth.Contract(PairJSON, getPair);
          let getReserves = await pairContract.methods.getReserves().call();
          let token0Address = await pairContract.methods.token0().call();
          let token1Address = await pairContract.methods.token1().call();

          let reserve0 = getReserves[0];
          let reserve1 = getReserves[1];

          if (Number(inputAmount) > 0) {
            let amountA = "";
            let amountB = "";
            let routerContract = new web3.eth.Contract(Router, ROUTER_ADDRESS);

            if (kinds == "tokenA") {
              if (
                token0Address.toLowerCase() == token_0.toLowerCase() &&
                token1Address.toLowerCase() == token_1.toLowerCase()
              ) {
                let reserve0Contract = new web3.eth.Contract(
                  ERC20JSON,
                  token_0
                );
                let decimal0 = await reserve0Contract.methods.decimals().call();
                // let decimal0Pow = new BN(`${10}`).pow(new BN(`${decimal0}`)).toString();
                // let re_Decimal0 = returnDecimal(web3Provider, decimal0Pow);

                let reserve1Contract = new web3.eth.Contract(
                  ERC20JSON,
                  token_1
                );
                let decimal1 = await reserve1Contract.methods.decimals().call();
                // let decimal1Pow = new BN(`${10}`).pow(new BN(`${decimal1}`)).toString();
                // let re_Decimal1 = returnDecimal(web3Provider, decimal1Pow);

                // amountA = web3.utils.toWei(`${inputAmount}`, re_Decimal0);
                amountA = toWeiBalance(`${inputAmount}`, decimal0);

                amountB = await routerContract.methods
                  .quote(amountA, reserve0, reserve1)
                  .call();
                // amountB = web3.utils.fromWei(amountB, re_Decimal1);
                amountB = fromWeiBalance(amountB, decimal1);
              } else if (
                token0Address.toLowerCase() == token_1.toLowerCase() &&
                token1Address.toLowerCase() == token_0.toLowerCase()
              ) {
                let reserve0Contract = new web3.eth.Contract(
                  ERC20JSON,
                  token_1
                );
                let decimal0 = await reserve0Contract.methods.decimals().call();
                // let decimal0Pow = new BN(`${10}`).pow(new BN(`${decimal0}`)).toString();
                // let re_Decimal0 = returnDecimal(web3Provider, decimal0Pow);

                let reserve1Contract = new web3.eth.Contract(
                  ERC20JSON,
                  token_0
                );
                let decimal1 = await reserve1Contract.methods.decimals().call();
                // let decimal1Pow = new BN(`${10}`).pow(new BN(`${decimal1}`)).toString();
                // let re_Decimal1 = returnDecimal(web3Provider, decimal1Pow);

                // amountA = web3.utils.toWei(`${inputAmount}`, re_Decimal1);
                amountA = toWeiBalance(`${inputAmount}`, decimal1);

                amountB = await routerContract.methods
                  .quote(amountA, reserve1, reserve0)
                  .call();
                // amountB = web3.utils.fromWei(amountB, re_Decimal0);
                amountB = fromWeiBalance(amountB, decimal0);
              }
              if (inputAmount == "") {
                dispatch(setTokenB_InputAmount(""));
              } else {
                dispatch(setTokenB_InputAmount(amountB));
              }
            } else if (kinds == "tokenB") {
              if (
                token0Address.toLowerCase() == token_0.toLowerCase() &&
                token1Address.toLowerCase() == token_1.toLowerCase()
              ) {
                let reserve0Contract = new web3.eth.Contract(
                  ERC20JSON,
                  token_1
                );
                let decimal0 = await reserve0Contract.methods.decimals().call();
                // let decimal0Pow = new BN(`${10}`).pow(new BN(`${decimal0}`)).toString();
                // let re_Decimal0 = returnDecimal(web3Provider, decimal0Pow);

                let reserve1Contract = new web3.eth.Contract(
                  ERC20JSON,
                  token_0
                );
                let decimal1 = await reserve1Contract.methods.decimals().call();
                // let decimal1Pow = new BN(`${10}`).pow(new BN(`${decimal1}`)).toString();
                // let re_Decimal1 = returnDecimal(web3Provider, decimal1Pow);

                // amountA = web3.utils.toWei(`${inputAmount}`, re_Decimal0);
                amountA = toWeiBalance(`${inputAmount}`, decimal0);

                amountB = await routerContract.methods
                  .quote(amountA, reserve1, reserve0)
                  .call();
                // amountB = web3.utils.fromWei(amountB, re_Decimal1);
                amountB = fromWeiBalance(amountB, decimal1);
              } else if (
                token0Address.toLowerCase() == token_1.toLowerCase() &&
                token1Address.toLowerCase() == token_0.toLowerCase()
              ) {
                let reserve0Contract = new web3.eth.Contract(
                  ERC20JSON,
                  token_0
                );
                let decimal0 = await reserve0Contract.methods.decimals().call();
                // let decimal0Pow = new BN(`${10}`).pow(new BN(`${decimal0}`)).toString();
                // let re_Decimal0 = returnDecimal(web3Provider, decimal0Pow);

                let reserve1Contract = new web3.eth.Contract(
                  ERC20JSON,
                  token_1
                );
                let decimal1 = await reserve1Contract.methods.decimals().call();
                // let decimal1Pow = new BN(`${10}`).pow(new BN(`${decimal1}`)).toString();
                // let re_Decimal1 = returnDecimal(web3Provider, decimal1Pow);

                // amountA = web3.utils.toWei(`${inputAmount}`, re_Decimal1);
                amountA = toWeiBalance(`${inputAmount}`, decimal1);

                amountB = await routerContract.methods
                  .quote(amountA, reserve0, reserve1)
                  .call();
                // amountB = web3.utils.fromWei(amountB, re_Decimal0);
                amountB = fromWeiBalance(amountB, decimal0);
              }

              if (inputAmount == "") {
                dispatch(setTokenA_InputAmount(""));
              } else {
                dispatch(setTokenA_InputAmount(amountB));
              }
            }
          } else {
            if (kinds == "tokenA") {
              dispatch(setTokenB_InputAmount(""));
            } else {
              dispatch(setTokenA_InputAmount(""));
            }
          }
        } else {
          // 페어 없음.
        }
      }
    } catch (error) {}
  };

  const setTokensList = async () => {
    const data = [];
    for (let i = 0; i < netWorkList.length; i++) {
      let haveAmount = 0;
      if (account != "" && web3Provider != undefined) {
        const web3 = defaultProvider;
        if (netWorkList[i].symbol != chain && netWorkList[i].address != "") {
          let contract;
          if (netWorkList[i].address != WMMT_ADDRESS.toLowerCase()) {
            contract = new web3.eth.Contract(ERC20JSON, netWorkList[i].address);
          } else {
            contract = new web3.eth.Contract(WMMTJSON, netWorkList[i].address);
          }
          if (account != "") {
            const balance = await contract.methods.balanceOf(account).call();
            const decimal = await contract.methods.decimals().call();
            // let decimalPow = new BN(`${10}`).pow(new BN(`${decimal}`)).toString();
            // let re_Decimals = returnDecimal(web3Provider, decimalPow);
            // (new BN(`${balance}`).div((new BN(`${10}`)).pow(new BN(`${tokensListJSON[i].decimal}`))).toString();
            // (new BN(`${10}`)).pow(new BN(`${18}`)).toString();
            // haveAmount = (new BN(`${balance}`).div((new BN(`${10}`)).pow(new BN(`${tokensListJSON[i].decimal}`)))).toString();
            // haveAmount = web3.utils.fromWei(`${balance}`,re_Decimals);
            haveAmount = fromWeiBalance(`${balance}`, decimal);
          }
        } else {
          if (account != "") {
            haveAmount = await web3.eth.getBalance(account);
            // (new BN(`${haveAmount}`).div((new BN(`${10}`)).pow(new BN(`${tokensListJSON[i].decimal}`))).toString();
            // haveAmount = (new BN(`${haveAmount}`).div((new BN(`${10}`)).pow(new BN(`${tokensListJSON[i].decimal}`)))).toString();
            haveAmount = web3.utils.fromWei(`${haveAmount}`);
          }
        }
      }
      const body = {
        address: netWorkList[i].address,
        symbol: netWorkList[i].symbol,
        name: netWorkList[i].name,
        decimal: netWorkList[i].decimal,
        inputAmount: "",
        image: netWorkList[i].image.toLowerCase(),
        price: netWorkList[i].price,
        haveAmount: haveAmount,
      };
      data[i] = body;
    }
    dispatch(setList(data));
  };

  const searchTokenAddress = async (e) => {
    const web3 = defaultProvider;
    const address = e.target.value;
    setSelectTokenAddress(address);
    try {
      if (address.length == 42) {
        const contract = new web3.eth.Contract(ERC20JSON, address);
        const symbol = await contract.methods.symbol().call();
        const name = await contract.methods.name().call();
        const decimal = await contract.methods.decimals().call();
        const balance =
          account == ""
            ? "0"
            : await contract.methods.balanceOf(account).call();
        const price = 5000;
        // let decimalPow = new BN(`${10}`).pow(new BN(`${decimal}`)).toString();
        // let re_Decimals = returnDecimal(web3Provider, decimalPow);
        // const haveAmount = web3.utils.fromWei(balance,re_Decimals);
        const haveAmount = fromWeiBalance(balance, decimal);
        let image = "";
        if (
          symbol == "ETH" ||
          symbol == "WMMT" ||
          symbol == "MMT" ||
          symbol == "BTC"
        ) {
          image = symbol;
        }
        let body = {
          address: address.toLowerCase(),
          symbol: symbol,
          name: name,
          decimal: decimal,
          inputAmount: "",
          price: price,
          haveAmount: haveAmount,
          image: address.toLowerCase(),
        };
        setAddTokenList([body]);
      } else if (address == "") {
        setAddTokenList([]);
        setAddtokenSelectMessage("");
      } else {
        setAddTokenList([]);
        setAddtokenSelectMessage("Enter valid token address");
      }
    } catch (error) {
      setAddTokenList([]);
      setAddtokenSelectMessage("Enter valid token address");
    }
  };

  const getTokenBalance = async () => {
    try {
      for (let i = 0; i < netWorkList.length; i++) {
        let web3 = defaultProvider;
        let haveAmount = 0;
        if (netWorkList[i].symbol != chain) {
          const contract = new web3.eth.Contract(
            ERC20JSON,
            netWorkList[i].address
          );
          const balance = await contract.methods.balanceOf(account).call();
          const decimal = await contract.methods.decimals().call();
          // let decimalPow = new BN(`${10}`).pow(new BN(`${decimal}`)).toString();
          // let re_Decimals = returnDecimal(web3Provider, decimalPow);
          // haveAmount = balance / 10 ** tokenList[i].decimal;
          // haveAmount = web3.utils.fromWei(`${balance}`,re_Decimals);
          haveAmount = fromWeiBalance(`${balance}`, decimal);
        } else {
          haveAmount = await web3.eth.getBalance(account);
          // haveAmount = haveAmount / 10 ** tokensListJSON[i].decimal;
          haveAmount = web3.utils.fromWei(`${haveAmount}`);
        }
        setTokenBalance(i, haveAmount);
      }
    } catch (error) {
      // console.log("error",error)
    }
  };

  // const selectToken = async (e) => {
  //   const address = e.target.value;
  //   dispatch(setTokenList([]));
  //   if (address.length == 42) {
  //     const contract = new web3.eth.Contract(ERC20JSON, address);
  //     const symbol = await contract.methods.symbol().call();
  //     const name = await contract.methods.name().call();
  //     const decimal = await contract.methods.decimals().call();
  //     const balance = await contract.methods.balanceOf(account).call();
  //     const price = 5000;
  //     // const haveAmount = balance / 10 ** decimal;
  //     const haveAmount = web3.utils.fromWei(`${balance}`);
  //     const body = {
  //       address: address,
  //       symbol: symbol,
  //       name: name,
  //       decimal: decimal,
  //       price: price,
  //       haveAmount: haveAmount,
  //     };
  //     dispatch(setTokenList(body));
  //   } else if (address == "") {
  //     setTokensList();
  //   }
  // };

  const changeInputValue = async (kinds, e) => {
    let inputAmount = e.target.value;
    let pattern = /^[0-9]*[.,]?[0-9]*$/;
    let q1 =
      tokenA.symbol == chain &&
      tokenA.address == "" &&
      tokenB.symbol == wCoinSymbol &&
      tokenB.address == WMMT_ADDRESS.toLowerCase();
    let q2 =
      tokenA.symbol == wCoinSymbol &&
      tokenA.address == WMMT_ADDRESS.toLowerCase() &&
      tokenB.symbol == chain &&
      tokenB.address == "";
    if (pattern.test(inputAmount) == true) {
      if (kinds == "tokenA") {
        if (getDecimalPlaces(`${inputAmount}`) <= Number(tokenA.decimal) + 1) {
          dispatch(setTokenA_InputAmount(inputAmount));
        }
        checkPair(kinds, inputAmount);
      } else if (kinds == "tokenB") {
        if (getDecimalPlaces(`${inputAmount}`) <= Number(tokenB.decimal) + 1) {
          dispatch(setTokenB_InputAmount(inputAmount));
        }
        checkPair(kinds, inputAmount);
      }
    }
  };

  const checkAllowance = async () => {
    let q1 =
      tokenA.symbol == chain &&
      tokenA.address == "" &&
      tokenB.symbol == wCoinSymbol &&
      tokenB.address == WMMT_ADDRESS.toLowerCase();
    let q2 =
      tokenA.symbol == wCoinSymbol &&
      tokenA.address == WMMT_ADDRESS.toLowerCase() &&
      tokenB.symbol == chain &&
      tokenB.address == "";
    if (defaultProvider != undefined) {
      let web3 = defaultProvider;
      if (q1 == false && q2 == false) {
        if (account != "") {
          if (tokenA.inputAmount != "" && tokenB.inputAmount != "") {
            if (tokenA.symbol == chain && tokenA.address == "") {
              const contractB = new web3.eth.Contract(
                ERC20JSON,
                tokenB.address
              );
              const allowanceB = await contractB.methods
                .allowance(account, ROUTER_ADDRESS)
                .call();
              let bool = new BN(`${allowanceB}`).gte(
                new BN(`${web3.utils.toWei(`${tokenB.inputAmount}`)}`)
              );
              // let bool = new BN(`${allowanceB}`).lte(new BN(`${web3.utils.toWei(`${tokenB.inputAmount}`)}`));
              // if (!bool) {
              if (bool) {
                dispatch(setTokenB_Allowance(false));
              } else {
                dispatch(setTokenB_Allowance(true));
              }
              dispatch(setTokenA_Allowance(false));
            } else if (tokenB.symbol == chain && tokenB.address == "") {
              const contractA = new web3.eth.Contract(
                ERC20JSON,
                tokenA.address
              );
              const allowanceA = await contractA.methods
                .allowance(account, ROUTER_ADDRESS)
                .call();
              let bool = new BN(`${allowanceA}`).gte(
                new BN(`${web3.utils.toWei(`${tokenA.inputAmount}`)}`)
              );
              // let bool = new BN(`${allowanceA}`).lte(new BN(`${web3.utils.toWei(`${tokenA.inputAmount}`)}`));
              // if (!bool) {
              if (bool) {
                dispatch(setTokenA_Allowance(false));
              } else {
                dispatch(setTokenA_Allowance(true));
              }
              dispatch(setTokenB_Allowance(false));
            } else {
              if (tokenA.address != "" && tokenB.address != "") {
                const contractA = new web3.eth.Contract(
                  ERC20JSON,
                  tokenA.address
                );
                const allowanceA = await contractA.methods
                  .allowance(account, ROUTER_ADDRESS)
                  .call();
                const contractB = new web3.eth.Contract(
                  ERC20JSON,
                  tokenB.address
                );
                const allowanceB = await contractB.methods
                  .allowance(account, ROUTER_ADDRESS)
                  .call();
                let tokenA_bool = new BN(`${allowanceA}`).gte(
                  new BN(`${web3.utils.toWei(`${tokenA.inputAmount}`)}`)
                );
                // let tokenA_bool = new BN(`${allowanceA}`).lte(new BN(`${web3.utils.toWei(`${tokenA.inputAmount}`)}`));
                let tokenB_bool = new BN(`${allowanceB}`).gte(
                  new BN(`${web3.utils.toWei(`${tokenB.inputAmount}`)}`)
                );
                // let tokenB_bool = new BN(`${allowanceB}`).lte(new BN(`${web3.utils.toWei(`${tokenB.inputAmount}`)}`));

                // if (!tokenA_bool) {
                if (tokenA_bool) {
                  dispatch(setTokenA_Allowance(false));
                } else {
                  dispatch(setTokenA_Allowance(true));
                }
                // if (!tokenB_bool) {
                if (tokenB_bool) {
                  dispatch(setTokenB_Allowance(false));
                } else {
                  dispatch(setTokenB_Allowance(true));
                }
              }
            }
          }
        }
      }
    }
  };

  const onClickApprove = async (token) => {
    setApproveBtnMessage("Approving");
    setApproveBtnStatus(true);
    try {
      const web3 = web3Provider;
      const contract = new web3.eth.Contract(ERC20JSON, token.address);
      const approve = await contract.methods
        .approve(ROUTER_ADDRESS, MaxUint256.toString())
        .send({
          from: account,
        });
      if (approve.status == true) {
        checkAllowance();
        approveStatus();
        // if (tokenA.address == token.address) {
        //   dispatch(setTokenA_Allowance(false));
        // } else {
        //   dispatch(setTokenB_Allowance(false));
        // }
      }
    } catch (error) {
      console.log(error);
      // approveStatus();
    }
  };

  const onClickSupply = async () => {
    // setTransactionHash("");
    dispatch(setTransactionHash(""));
    const web3 = web3Provider;
    if (tokenA.token_allowance == true) {
      onClickApprove(tokenA);
    } else if (tokenB.token_allowance == true) {
      onClickApprove(tokenB);
    } else {
      let gasPrice = await web3.eth.getGasPrice();
      const contract = new web3.eth.Contract(Router, ROUTER_ADDRESS);
      let date = Date();
      date = +new Date(date);
      date = date / 1000;
      date = date + time * 60;
      let addLiquidity;
      try {
        if (tokenA.symbol == chain || tokenB.symbol == chain) {
          let mammoth;
          let token;
          if (tokenA.symbol == chain) {
            mammoth = tokenA;
            token = tokenB;
          } else {
            mammoth = tokenB;
            token = tokenA;
          }

          let token_desired = token.inputAmount;
          let decimalPow = new BN(`${10}`)
            .pow(new BN(`${token.decimal}`))
            .toString();
          let re_Decimals = returnDecimal(web3Provider, decimalPow);

          // token_desired = web3.utils.toWei(String(token_desired),re_Decimals);
          token_desired = toWeiBalance(String(token_desired), token.decimal);

          let mammoth_desired = mammoth.inputAmount;
          mammoth_desired = web3.utils.toWei(String(mammoth_desired));
          mammoth_desired = new BN(`${mammoth_desired}`).toString();

          // new BN(`${token_desired}`).sub(new BN(`{$token_desired}`).mul(new BN(`${slippage*10}`))).toString();
          // let token_min = Math.floor(token_desired - (token_desired * slippage));
          // let mammoth_min = Math.floor(mammoth_desired - (mammoth_desired * slippage));
          // let tokenBN = (((new BN(`${token_desired}`).mul(new BN(`${slippage * 10}`))).toString()));
          let tokenBN = new BN(`${token_desired}`)
            .mul(new BN(`${slippage * 100}`))
            .div(new BN(`${100}`))
            .toString();
          // let tokenBN = (((new BN(`${token_desired}`).mul(new BN(`${slippage * 100}`))).div(new BN(`${100000}`)).toString()));
          // tokenBN = new BN(`${tokenBN}`).div(new BN(`${10}`)).toString();

          // let mammothBN = (((new BN(`${mammoth_desired}`).mul(new BN(`${slippage * 10}`))).toString()));
          let mammothBN = new BN(`${mammoth_desired}`)
            .mul(new BN(`${slippage * 100}`))
            .div(new BN(`${100}`))
            .toString();
          // let mammothBN = (((new BN(`${mammoth_desired}`).mul(new BN(`${slippage * 100}`))).div(new BN(`${100000}`)).toString()));
          // mammothBN = new BN(`${mammothBN}`).div(new BN(`${10}`)).toString();

          let token_min = tokenBN;
          let mammoth_min = mammothBN;

          // token_min = web3.utils.toWei(String(token_min));
          // mammoth_min = web3.utils.toWei(String(mammoth_min));

          // const token_desired = token.inputAmount * (Math.pow(10,token.decimal));
          // const token_min = token_desired - (token_desired * slippage);
          // const mammoth_desired = mammoth.inputAmount * (Math.pow(10,mammoth.decimal));
          // const mammoth_min = mammoth_desired - (mammoth_desired*slippage);

          let gasEstimate = "0";
          try {
            let addLiquidityETHABI =
              Router[Router.findIndex((i) => i.name == "addLiquidityETH")];
            let data = web3.eth.abi.encodeFunctionCall(addLiquidityETHABI, [
              token.address,
              String(token_desired),
              String(token_min),
              String(new BN(mammoth_min).toString()),
              account,
              date,
            ]);
            const transactionObject = {
              from: account,
              to: ROUTER_ADDRESS,
              data: data,
              value: String(new BN(mammoth_desired).toString()),
            };

            const estimateGas = await web3.eth.estimateGas(transactionObject);
            let estimateGasMul = new BN(`${estimateGas}`)
              .mul(new BN(`${await web3.eth.getGasPrice()}`))
              .toString();
            let estimateGasCeil = `${Math.ceil(estimateGasMul * 1.6)}`;
            let sum = new BN(`${mammoth_desired}`)
              .add(new BN(`${estimateGasCeil}`))
              .toString();
            let bool = new BN(
              `${web3.utils.toWei(`${mammoth.haveAmount}`)}`
            ).gte(new BN(`${sum}`));
            gasEstimate = bool ? "0" : `${Math.ceil(estimateGasCeil)}`;
            // gasEstimate = await contract.methods["addLiquidityETH"](
            //   token.address,
            //   String(token_desired),
            //   String(token_min),
            //   String(
            //     new BN(mammoth_min).sub(new BN(`${gasEstimate}`)).toString()
            //   ),
            //   account,
            //   date
            // ).estimateGas(transactionObject);
          } catch (error) {
            console.log("?????", error);
          }
          // addLiquidity = await contract.methods
          //   .addLiquidityETH(
          //     token.address,
          //     String(token_desired),
          //     String(token_min),
          //     String(mammoth_min),
          //     account,
          //     date
          //   )
          // .send({
          //   from: account,
          //   value: String(mammoth_desired),
          // });
          addLiquidity = await contract.methods
            .addLiquidityETH(
              token.address,
              String(token_desired),
              String(token_min),
              String(
                new BN(mammoth_min).sub(new BN(`${gasEstimate}`)).toString()
              ),
              account,
              date
            )
            .send({
              from: account,
              value: String(
                new BN(mammoth_desired).sub(new BN(`${gasEstimate}`)).toString()
              ),
            });
        } else {
          let tokenA_desired = tokenA.inputAmount;
          let tokenB_desired = tokenB.inputAmount;

          let decimalPowA = new BN(`${10}`)
            .pow(new BN(`${tokenA.decimal}`))
            .toString();
          let re_DecimalsA = returnDecimal(web3Provider, decimalPowA);

          let decimalPowB = new BN(`${10}`)
            .pow(new BN(`${tokenB.decimal}`))
            .toString();
          let re_DecimalsB = returnDecimal(web3Provider, decimalPowB);

          // tokenA_desired = web3.utils.toWei(String(tokenA_desired),re_DecimalsA);
          // tokenB_desired = web3.utils.toWei(String(tokenB_desired),re_DecimalsB);
          tokenA_desired = toWeiBalance(String(tokenA_desired), tokenA.decimal);
          tokenB_desired = toWeiBalance(String(tokenB_desired), tokenB.decimal);

          let tokenABN = new BN(`${tokenA_desired}`)
            .mul(new BN(`${slippage * 100}`))
            .div(new BN(`${100}`))
            .toString();
          // let tokenABN = (((new BN(`${tokenA_desired}`).mul(new BN(`${slippage * 100}`))).div(new BN(`${100000}`)).toString()));
          // tokenABN = new BN(`${tokenABN}`).div(new BN(`${10}`)).toString();

          let tokenBBN = new BN(`${tokenB_desired}`)
            .mul(new BN(`${slippage * 100}`))
            .div(new BN(`${100}`))
            .toString();
          // let tokenBBN = (((new BN(`${tokenB_desired}`).mul(new BN(`${slippage * 100}`))).div(new BN(`${100000}`)).toString()));
          // tokenBBN = new BN(`${tokenB_desired}`).div(new BN(`${10}`)).toString();
          // new BN(`${}`).sub(new BN(`${}`)).toString()
          // let tokenA_Min = tokenABN;
          // let tokenB_Min = tokenBBN;

          let tokenA_Min = new BN(`${tokenA_desired}`)
            .sub(new BN(`${tokenABN}`))
            .toString();
          let tokenB_Min = new BN(`${tokenB_desired}`)
            .sub(new BN(`${tokenBBN}`))
            .toString();

          addLiquidity = await contract.methods
            .addLiquidity(
              tokenA.address,
              tokenB.address,
              String(tokenA_desired),
              String(tokenB_desired),
              String(tokenA_Min),
              String(tokenB_Min),
              account,
              date
            )
            .send({
              from: account,
            });
        }
        dispatch(setTokenA_InputAmount(""));
        dispatch(setTokenB_InputAmount(""));
        if (addLiquidity.status) {
          setNowModalStatus("submitted");
        } else {
          setNowModalStatus("failed");
        }
        // setTransactionHash(addLiquidity.transactionHash);
        dispatch(setTransactionHash(addLiquidity.transactionHash));
      } catch (error) {
        // console.log("error", error)
        console.log(error);
        setNowModalStatus("approveAddToken");
      }
    }
  };

  const changeSlippage = async (e) => {
    let value = e.target.value;
    let pattern = /^[0-9]*[.,]?[0-9]*$/;
    let pattern2 = /^\d{0,10}(\.\d{0,2})?$/;
    if (pattern2.test(value)) {
      if (pattern.test(value)) {
        dispatch(setUpdateUserSlippageTolerance(value));
      }
    }
  };

  const focusSlippageInput = () => {
    if (Number(slippage) >= 50 || slippage == "") {
      dispatch(setUpdateUserSlippageTolerance(5));
    }
  };

  const chooseToken = async (tokenAddress) => {
    let haveAmount = 0;
    if (account != "" && active == true && defaultProvider != undefined) {
      const web3 = defaultProvider;
      try {
        if (tokenA.symbol == chain && tokenAddress == tokenA.address) {
          haveAmount = await web3.eth.getBalance(account);
          haveAmount = web3.utils.fromWei(haveAmount);
          // haveAmount = haveAmount / 10 ** tokenA.decimal;
          dispatch(setTokenA_HaveAmount(haveAmount));
          setSelectActiveA("");
        } else if (tokenB.symbol == chain && tokenAddress == tokenB.address) {
          haveAmount = await web3.eth.getBalance(account);
          // haveAmount = haveAmount / 10 ** tokenB.decimal;
          haveAmount = web3.utils.fromWei(haveAmount);
          dispatch(setTokenB_HaveAmount(haveAmount));
          setSelectActiveB("");
        } else {
          const contract = new web3.eth.Contract(ERC20JSON, tokenAddress);
          const balance = await contract.methods.balanceOf(account).call();
          const decimal = await contract.methods.decimals().call();

          let decimalPow = new BN(`${10}`).pow(new BN(`${decimal}`)).toString();
          let re_Decimals = returnDecimal(web3Provider, decimalPow);

          if (tokenAddress == tokenA.address) {
            // haveAmount = web3.utils.fromWei(balance,re_Decimals);
            haveAmount = fromWeiBalance(balance, decimal);
            dispatch(setTokenA_HaveAmount(haveAmount));
            setSelectActiveA(tokenAddress.toLowerCase());
          } else if (tokenAddress == tokenB.address) {
            // haveAmount = web3.utils.fromWei(balance,re_Decimals);
            haveAmount = fromWeiBalance(balance, decimal);
            dispatch(setTokenB_HaveAmount(haveAmount));
            setSelectActiveB(tokenAddress.toLowerCase());
          }
        }
      } catch (error) {}
    } else {
      dispatch(setTokenA_HaveAmount(haveAmount));
      dispatch(setTokenB_HaveAmount(haveAmount));
    }
  };

  const onChangeTime = (e) => {
    const time = e.target.value;
    dispatch(setUpdateUserDeadLine(time * 60));
  };

  const approveStatus = async () => {
    try {
      if (defaultProvider != undefined) {
        let web3 = defaultProvider;
        let q1 = tokenA.symbol == "" && tokenA.address == "";
        let q2 = tokenB.symbol == "" && tokenB.address == "";
        let q3 = tokenA.inputAmount == "" || tokenB.inputAmount == "";

        let tokenAInputAmount =
          tokenA.inputAmount == "" ? "0" : tokenA.inputAmount;
        let tokenBInputAmount =
          tokenB.inputAmount == "" ? "0" : tokenB.inputAmount;
        let tokenAHaveAmount =
          tokenA.haveAmount == "" ? "0" : tokenA.haveAmount;
        let tokenBHaveAmount =
          tokenB.haveAmount == "" ? "0" : tokenB.haveAmount;
        // Number()
        let q4 = Number(tokenA.inputAmount) > Number(tokenA.haveAmount);
        let q5 = Number(tokenB.inputAmount) > Number(tokenB.haveAmount);
        let q6 = Number(tokenA.inputAmount) <= Number(tokenA.haveAmount);
        let q7 = Number(tokenB.inputAmount) <= Number(tokenB.haveAmount);

        let q8 =
          tokenA.symbol == chain &&
          tokenA.address == "" &&
          tokenB.symbol == wCoinSymbol &&
          tokenB.address == WMMT_ADDRESS.toLowerCase();
        let q9 =
          tokenA.symbol == wCoinSymbol &&
          tokenA.address == WMMT_ADDRESS.toLowerCase() &&
          tokenB.symbol == chain &&
          tokenB.address == "";

        let q10 = tokenA.token_allowance == true;
        let q11 = tokenB.token_allowance == true;
        if (account != "" && active) {
          if (
            ((await web3) != undefined?.eth.getChainId()) !=
              ((await web3Provider) != undefined?.eth.getChainId()) &&
            !q1 &&
            !q2
          ) {
            setApproveBtnMessage("Please switch network.");
            setApproveBtnStatus(true);
          } else if (q8 == false && q9 == false) {
            setInsufficientStatusTokenA(false);
            setInsufficientStatusTokenB(false);
            if (checkInputAmountDecimal(tokenA) == false) {
              dispatch(setTokenB_InputAmount(""));
              setApproveBtnMessage("Enter an amount");
              setApproveBtnStatus(true);
            } else if (checkInputAmountDecimal(tokenB) == false) {
              dispatch(setTokenA_InputAmount(""));
              setApproveBtnMessage("Enter an amount");
              setApproveBtnStatus(true);
            } else {
              if (q1 || q2) {
                setApproveBtnMessage("Invalid Pair");
                setApproveBtnStatus(true);
              } else if (q3) {
                setApproveBtnMessage("Enter an amount");
                setApproveBtnStatus(true);
              } else if (q4) {
                setInsufficientStatusTokenA(true);
                setApproveBtnStatus(true);
                if (q5 == true) {
                  setInsufficientStatusTokenB(true);
                  setApproveBtnMessage(
                    `Insufficient ${tokenA.symbol} , ${tokenB.symbol} balance`
                  );
                } else if (q5 == false) {
                  setInsufficientStatusTokenB(false);
                  setApproveBtnMessage(`Insufficient ${tokenA.symbol} balance`);
                }
              } else if (q5) {
                setInsufficientStatusTokenB(true);
                setApproveBtnStatus(true);
                if (q4 == true) {
                  setInsufficientStatusTokenA(true);
                  setApproveBtnMessage(
                    `Insufficient ${tokenA.symbol} , ${tokenB.symbol} balance`
                  );
                } else if (q4 == false) {
                  setInsufficientStatusTokenA(false);
                  setApproveBtnMessage(`Insufficient ${tokenB.symbol} balance`);
                }
              } else if (q6 && q7) {
                if (q10) {
                  setApproveBtnMessage(`${tokenA.symbol} Approve`);
                } else if (q11) {
                  setApproveBtnMessage(`${tokenB.symbol}  Approve`);
                } else {
                  setApproveBtnMessage("Supply");
                }
                setApproveBtnStatus(false);
              }
            }
          } else {
            setApproveBtnMessage("Invalid Pair");
            setApproveBtnStatus(true);
          }
        } else {
          setApproveBtnMessage("Unlock Wallet");
          setApproveBtnStatus(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClickReset = (bool) => {
    const reset = {
      address: "",
      symbol: "",
      name: "",
      inputAmount: "",
      decimal: 0,
      image: "",
      haveAmount: 0,
    };
    setSelectActiveA("NAN");
    setSelectedTokenA("NAN");
    setSelectActiveB("NAN");
    setSelectedTokenB("NAN");
    dispatch(setTokenA(reset));
    dispatch(setTokenB(reset));
    dispatch(setUpdateUserSlippageTolerance(0.5));
    dispatch(setUpdateUserDeadLine(1200));
    setReserveamount0("-");
    setReserveamount1("-");
    setSharePercent(0);
    setInsufficientStatusTokenA(false);
    setInsufficientStatusTokenB(false);
    setApproveBtnMessage("Invalid Pair");
    setApproveBtnStatus(true);
    if (bool) setResetStatus(true);
  };

  const showPricesAndPoolShare = async () => {
    let q1 =
      tokenA.symbol == chain &&
      tokenA.address == "" &&
      tokenB.symbol == wCoinSymbol &&
      tokenB.address == WMMT_ADDRESS.toLowerCase();
    let q2 =
      tokenA.symbol == wCoinSymbol &&
      tokenA.address == WMMT_ADDRESS.toLowerCase() &&
      tokenB.symbol == chain &&
      tokenB.address == "";
    let token_0;
    let token_1;

    let token0DecimalQ = checkInputAmountDecimal(tokenA);
    let token1DecimalQ = checkInputAmountDecimal(tokenB);
    if (defaultProvider != undefined) {
      let web3 = defaultProvider;
      if (
        account != "" &&
        q1 == false &&
        q2 == false &&
        token0DecimalQ &&
        token1DecimalQ
      ) {
        if (
          tokenA.symbol == chain &&
          tokenA.address == "" &&
          tokenB.address != ""
        ) {
          token_0 = WMMT_ADDRESS;
          token_1 = tokenB.address;
        } else if (
          tokenB.symbol == chain &&
          tokenB.address == "" &&
          tokenA.address != ""
        ) {
          token_0 = tokenA.address;
          token_1 = WMMT_ADDRESS;
        } else {
          token_0 = tokenA.address;
          token_1 = tokenB.address;
        }

        if (token_0 != "" && token_1 != "") {
          let factoryContract = new web3.eth.Contract(
            FactoryJSON,
            FACTORY_ADDRESS
          );
          let getPair = await factoryContract.methods
            .getPair(token_0, token_1)
            .call();
          if (getPair != "0x0000000000000000000000000000000000000000") {
            let pairContract = new web3.eth.Contract(PairJSON, getPair);
            let getReserves = await pairContract.methods.getReserves().call();
            let token0Address = await pairContract.methods.token0().call();
            let token1Address = await pairContract.methods.token1().call();

            let reserve0;
            let reserve1;

            reserve0 = getReserves[0];
            reserve1 = getReserves[1];
            let amountA_re0;
            let amountA_re1;
            let routerContract = new web3.eth.Contract(Router, ROUTER_ADDRESS);
            let amount_0;
            let amount_1;

            if (
              token0Address.toLowerCase() == token_0.toLowerCase() &&
              token1Address.toLowerCase() == token_1.toLowerCase()
            ) {
              let reserve0Contract = new web3.eth.Contract(ERC20JSON, token_0);
              let decimal0 = await reserve0Contract.methods.decimals().call();
              // let decimal0Pow = new BN(`${10}`).pow(new BN(`${decimal0}`)).toString();
              // let re_Decimal0 = returnDecimal(web3Provider, decimal0Pow);

              let reserve1Contract = new web3.eth.Contract(ERC20JSON, token_1);
              let decimal1 = await reserve1Contract.methods.decimals().call();
              // let decimal1Pow = new BN(`${10}`).pow(new BN(`${decimal1}`)).toString();
              // let re_Decimal1 = returnDecimal(web3Provider, decimal1Pow);

              // amountA_re0 = web3.utils.toWei(`${1}`, re_Decimal0);
              // amountA_re1 = web3.utils.toWei(`${1}`, re_Decimal1);
              amountA_re0 = toWeiBalance(`${1}`, decimal0);
              amountA_re1 = toWeiBalance(`${1}`, decimal1);

              amount_0 = await routerContract.methods
                .quote(amountA_re0, reserve0, reserve1)
                .call();
              amount_1 = await routerContract.methods
                .quote(amountA_re1, reserve1, reserve0)
                .call();

              // amount_0 = web3.utils.fromWei(amount_0, re_Decimal1);
              // amount_1 = web3.utils.fromWei(amount_1, re_Decimal0);
              amount_0 = fromWeiBalance(amount_0, decimal1);
              amount_1 = fromWeiBalance(amount_1, decimal0);
            } else if (
              token0Address.toLowerCase() == token_1.toLowerCase() &&
              token1Address.toLowerCase() == token_0.toLowerCase()
            ) {
              let reserve0Contract = new web3.eth.Contract(ERC20JSON, token_1);
              let decimal0 = await reserve0Contract.methods.decimals().call();
              // let decimal0Pow = new BN(`${10}`).pow(new BN(`${decimal0}`)).toString();
              // let re_Decimal0 = returnDecimal(web3Provider, decimal0Pow);

              let reserve1Contract = new web3.eth.Contract(ERC20JSON, token_0);
              let decimal1 = await reserve1Contract.methods.decimals().call();
              // let decimal1Pow = new BN(`${10}`).pow(new BN(`${decimal1}`)).toString();
              // let re_Decimal1 = returnDecimal(web3Provider, decimal1Pow);

              // amountA_re0 = web3.utils.toWei(`${1}`, re_Decimal0);
              // amountA_re1 = web3.utils.toWei(`${1}`, re_Decimal1);
              amountA_re0 = toWeiBalance(`${1}`, decimal0);
              amountA_re1 = toWeiBalance(`${1}`, decimal1);

              amount_0 = await routerContract.methods
                .quote(amountA_re0, reserve0, reserve1)
                .call();
              amount_1 = await routerContract.methods
                .quote(amountA_re1, reserve1, reserve0)
                .call();

              // amount_0 = web3.utils.fromWei(amount_0, re_Decimal1);
              // amount_1 = web3.utils.fromWei(amount_1, re_Decimal0);
              amount_0 = fromWeiBalance(amount_0, decimal1);
              amount_1 = fromWeiBalance(amount_1, decimal0);
            }

            if (
              Number(tokenA.inputAmount) > 0 &&
              Number(tokenB.inputAmount) > 0
            ) {
              // let decimalPowA = new BN(`${10}`).pow(new BN(`${tokenA.decimal}`)).toString();
              // let re_DecimalsA = returnDecimal(web3Provider, decimalPowA);

              // let tokenAinputAmount = web3.utils.toWei(`${tokenA.inputAmount}`,re_DecimalsA);
              let tokenAinputAmount = toWeiBalance(
                `${tokenA.inputAmount}`,
                tokenA.decimal
              );

              // let decimalPowB = new BN(`${10}`).pow(new BN(`${tokenB.decimal}`)).toString();
              // let re_DecimalsB = returnDecimal(web3Provider, decimalPowB);

              // let tokenBinputAmount = web3.utils.toWei(`${tokenB.inputAmount}`,re_DecimalsB);
              let tokenBinputAmount = toWeiBalance(
                `${tokenB.inputAmount}`,
                tokenB.decimal
              );

              let reserveSum = new BN(`${reserve0}`)
                .add(new BN(`${reserve1}`))
                .toString();
              let tokenInputAmountSum = new BN(`${tokenAinputAmount}`)
                .add(new BN(`${tokenBinputAmount}`))
                .toString();
              let inputAmountReserveSum = new BN(`${reserveSum}`)
                .add(new BN(`${tokenInputAmountSum}`))
                .toString();
              // let inputAmountReserveDiv = (new BN(`${tokenInputAmountSum}`).div(new BN(`${inputAmountReserveSum}`))).toString(15);
              let inputAmountReserveDiv = (
                (tokenInputAmountSum / inputAmountReserveSum) *
                100
              ).toFixed(2);
              // let percent = new BN(`${inputAmountReserveDiv}`).mul(new BN(`${100}`)).toString();
              // percent = web3.utils.fromWei(percent);
              setSharePercent(inputAmountReserveDiv);
            } else {
              setSharePercent(0);
            }

            setPoolStatusMessage("PRICES AND POOL SHARE");
            setReserveamount0(amount_0);
            setReserveamount1(amount_1);
          } else {
            setPoolStatusMessage("INITIAL PRICES AND POOL SHARE");
            // 페어 없음.
            if (tokenA.inputAmount == "" || tokenB.inputAmount == "") {
              setReserveamount0("-");
              setReserveamount1("-");
              setSharePercent(0);
            } else if (
              tokenA.inputAmount != "" &&
              tokenB.inputAmount != "" &&
              Number(tokenA.inputAmount) != 0 &&
              Number(tokenB.inputAmount) != 0
            ) {
              let A = tokenA.inputAmount / tokenB.inputAmount;
              let B = tokenB.inputAmount / tokenA.inputAmount;

              if (tokenA.image > tokenB.image) {
                setReserveamount0(String(B));
                setReserveamount1(String(A));
              } else {
                setReserveamount1(String(A));
                setReserveamount0(String(B));
              }
              setSharePercent(100);
            }
          }
        }
      } else {
      }
    }
  };

  const onClickMaxBtn = async (kinds) => {
    if (kinds == "tokenA") {
      dispatch(setTokenA_InputAmount(tokenA.haveAmount));
      checkPair(kinds, tokenA.haveAmount);
    } else if (kinds == "tokenB") {
      dispatch(setTokenB_InputAmount(tokenB.haveAmount));
      checkPair(kinds, tokenB.haveAmount);
    }
  };

  const onClickExplorer = () => {
    if (chainId == 8898) {
      window.open(`https://mmtscan.io/tx/${transactionHash}`);
    } else if (chainId == 88998) {
      window.open(`https://testnet.mmtscan.io/tx/${transactionHash}`);
    } else if (chainId == 97) {
      window.open(`https://testnet.bscscan.com/tx/${transactionHash}`);
    } else if (chainId == 8989) {
      window.open(`https://scan.gmmtchain.io/tx/${transactionHash}`);
    } else if (chainId == 898989) {
      window.open(`https://testnet-scan.gmmtchain.io/tx/${transactionHash}`);
    } else if (chainId == 1) {
      window.open(`https://etherscan.io/tx/${transactionHash}`);
    }
  };

  const onClickRemoveToken = (item) => {
    let removeTokenAddress = item.address.toLowerCase();
    let bfgetTokenList = JSON.parse(
      window.localStorage.getItem("addTokenList")
    );
    bfgetTokenList = bfgetTokenList.tokens.getTokenList;
    let getTokenList = [];
    for (let i = 0; i < bfgetTokenList.length; i++) {
      if (bfgetTokenList[i].address != removeTokenAddress) {
        getTokenList.push(bfgetTokenList[i]);
      }
    }
    window.localStorage.setItem(
      "addTokenList",
      JSON.stringify({
        tokens: {
          getTokenList,
        },
      })
    );
    setMyAddTokenList(getTokenList);
  };

  const balanceInCode = (balance) => {
    if (
      balance == undefined ||
      balance.length == undefined ||
      balance == "" ||
      Number(balance) == 0
    ) {
      return 0;
    } else if (balance == "-") {
      return "-";
    } else {
      let splitBalance = balance.split(".");

      let backBalance = "";
      let afterVal = "";
      if (splitBalance.length == 2) {
        let q1 = splitBalance[1].length - (splitBalance[1].search(/[1-9]/) + 1);
        let nextVal;
        for (let i = 0; i < q1; i++) {
          nextVal = splitBalance[1].substr(
            splitBalance[1].search(/[1-9]/) + (i + 1),
            1
          );
          if (nextVal != "0") {
            afterVal = afterVal + `${nextVal}`;
            break;
          } else {
            afterVal = afterVal + "0";
          }
          if (i == q1 - 1) {
            nextVal = "";
          }
        }
        if (afterVal.search(/[1-9]/) == -1) {
          afterVal = "";
        }
        backBalance =
          splitBalance[1].substr(0, splitBalance[1].search(/[1-9]/) + 1) != ""
            ? "." +
              splitBalance[1].substr(0, splitBalance[1].search(/[1-9]/) + 1) +
              afterVal
            : "";
      }

      return splitBalance[0] + backBalance;
      // let balanceFixed = Number(balance).toFixed(4);
      // let returnBalance = (Number(balanceFixed)*10000)/10000;
      // return returnBalance;
    }
  };

  const onErrorImg = (e) => {
    e.target.src = "logo_.png";
  };

  const checkInputAmountDecimal = (token) => {
    // try {
    if (token.inputAmount != undefined || token.inputAmount != "") {
      let index = token.inputAmount.indexOf(".");
      if (index != -1) {
        let decimalLength = token.inputAmount.substr(
          index + 1,
          token.inputAmount.length
        );
        if (decimalLength.length <= token.decimal) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
    // } catch (error) {
    //   console.log("error",error)
    // }
  };

  const onClickTitle = (pageName) => {
    const htmlTitle = document.querySelector("title");
    htmlTitle.innerHTML = pageName;
  };

  const toLocaleStringOption = (amount) => {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  useEffect(() => {
    onClickTitle("AddLiquidity - Ivory Swap");
  }, []);

  // useEffect(() => {
  //   const body = {
  //     address: "",
  //     symbol: "",
  //     name: "",
  //     decimal: "",
  //     inputAmount: "",
  //     image: "",
  //     price: "",
  //     haveAmount: "",
  //   };
  //   let data = {
  //     setA: body,
  //     setB: body,
  //   };
  //   if (active == false) {
  //     dispatch(setLiquidity(data));
  //     setSelectActiveB("NAN");
  //     setSelectedTokenB("NAN");
  //     setSelectActiveA("NAN");
  //     setSelectedTokenA("NAN");
  //   }
  // }, [active]);

  useEffect(() => {
    if (resetStatus) {
      setTimeout(() => {
        setResetStatus(false);
      }, 400);
    }
  }, [resetStatus]);

  useLayoutEffect(() => {
    let e = {
      target: {
        value: searchToken,
      },
    };
    selectTokenList(e);
  }, [searchToken]);

  const checkModalStatusSubmit = () => {
    if (nowModalStatus == "submitted") {
      setTimeout(() => {
        setPopupShow(false);
      }, [4000]);
    }
  };

  useEffect(() => {
    checkModalStatusSubmit();
  }, [nowModalStatus]);

  return (
    <div className={styles.bg}>
      {/* finn */}
      {/*<AddToken changeRadioStatus={changeRadioStatus} tokenAStatus={tokenAStatus} tokenBStatus={tokenBStatus} selectToken={selectToken} />*/}
      {/*<hr/>*/}
      {/*<TokenList tokenList={tokenList} onClickAddToken={onClickAddToken} />*/}
      {/*<hr/>*/}
      {/*<AddLiquidityContainer tokenA={tokenA} tokenB={tokenB} changeInputValue={changeInputValue} setTokensList={setTokensList} onClickReset={onClickReset}/>*/}
      {/*<hr/>*/}
      {/*/!* <PriceAndPoolShare poolStatusMessage={poolStatusMessage} tokenA={tokenA} tokenB={tokenB} reserveamount0={reserveamount0} reserveamount1={reserveamount1} sharePercent={sharePercent}/> *!/*/}
      {/*<hr/>*/}
      {/*<ApproveBtn token={tokenA} onClickApprove={onClickApprove} />*/}
      {/*<ApproveBtn token={tokenB} onClickApprove={onClickApprove} />*/}
      {/*<hr/>*/}
      {/*<Slippage slippage={slippage} changeSlippage={changeSlippage} />*/}
      {/*<Time time={time} onChangeTime={onChangeTime}/>*/}
      {/*<hr/>*/}
      {/*<AddLiquidityBtn transactionHash={transactionHash} onClickSupply={onClickSupply} approveBtnMessage={approveBtnMessage} approveBtnStatus={approveBtnStatus}/>*/}

      <div className={styles.wrap}>
        <div className={styles.title}>Liquidity</div>

        <div className={styles.container}>
          <div className={styles.containerTitle}>
            <div className={styles.back}>
              <Link
                to={"/liquidity"}
                onClick={() => {
                  onClickTitle("Liquidity - Ivory Swap");
                }}
              >
                <img
                  src={require("../assets/images/swap/ico_back_bl@2x.png")}
                  alt={"back"}
                />
              </Link>
            </div>
            <div className={styles.text}>Add Liquidity</div>
            <div
              className={`${styles.reset} ${resetStatus ? styles.click : ""}`}
              onClick={() => onClickReset(true)}
            >
              <img
                className={styles.pc_reset}
                src={require("../assets/images/swap/ico-represh-wh.png")}
                alt={"reset"}
              />
              <img
                className={styles.mo_reset}
                src={require("../assets/images/swap/ico_refresh_bl.png")}
                alt={"reset_mo"}
              />
            </div>
          </div>

          <div className={styles.selectPairWrap}>
            <div className={styles.subTitle}>Select Pair</div>

            <div className={styles.selectPair}>
              <div
                className={styles.pairName}
                onClick={() => {
                  setPopupShow(true);
                  setTokenAStatus("checked");
                  setTokenBStatus("");
                  setNowModalStatus("selectToken");
                  setSearchToken("");
                  setSelectedTokenData(tokenA);
                  setSelectType("tokenA");
                }}
              >
                {/* <img className={styles.symbolLogo} src={require(`../assets/images/swap/logo_${tokenA.image}.png`)} alt={"eth_logo"} /> */}
                <img
                  className={styles.symbolLogo}
                  src={`${chain}/logo_${tokenA.image}.png`}
                  alt={`${tokenA.symbol}`}
                  onError={onErrorImg}
                />
                {/* <img className={styles.symbolLogo} src={require("../assets/images/swap/logo_ETH.png")} alt={"eth_logo"} /> */}
                <span>{tokenA.symbol}</span>
                <img
                  src={require("../assets/images/swap/ico_arrow_bl.png")}
                  alt={"ico_arrow_bl"}
                />
              </div>
              <div
                className={`${styles.pairBalance} ${
                  insufficientStatusTokenA ? styles.excess : ""
                }`}
              >
                <div className={styles.inputBox}>
                  <input
                    type="text"
                    placeholder={0}
                    onChange={(e) => {
                      changeInputValue("tokenA", e);
                      setInputType("tokenA");
                    }}
                    value={tokenA.inputAmount}
                    maxLength={72}
                  />
                  <button
                    className={styles.active}
                    onClick={() => onClickMaxBtn("tokenA")}
                  >
                    MAX
                  </button>
                  <div className={styles.balance}>
                    {toLocaleStringOption(balanceInCode(tokenA.haveAmount))}{" "}
                    (balance)
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.plus}>
              <img
                src={require("../assets/images/swap/ico_plus_bl@2x.png")}
                alt={"plus"}
              />
            </div>
            <div className={styles.selectPair}>
              <div
                className={styles.pairName}
                onClick={() => {
                  setPopupShow(true);
                  setTokenAStatus("");
                  setTokenBStatus("checked");
                  setNowModalStatus("selectToken");
                  setSearchToken("");
                  setSelectedTokenData(tokenB);
                  setSelectType("tokenB");
                }}
              >
                {/* <img className={styles.symbolLogo} src={require(`../assets/images/swap/logo_${tokenB.image}.png`)} alt={"eth_logo"} /> */}
                <img
                  className={styles.symbolLogo}
                  src={`${chain}/logo_${tokenB.image}.png`}
                  alt={`${tokenB.symbol}`}
                  onError={onErrorImg}
                />
                <span>{tokenB.symbol}</span>
                <img
                  src={require("../assets/images/swap/ico_arrow_bl.png")}
                  alt={"ico_arrow_bl"}
                />
              </div>
              <div
                className={`${styles.pairBalance} ${
                  insufficientStatusTokenB ? styles.excess : ""
                }`}
              >
                <div className={styles.inputBox}>
                  <input
                    type="text"
                    placeholder={0}
                    onChange={(e) => {
                      changeInputValue("tokenB", e);
                      setInputType("tokenB");
                    }}
                    value={tokenB.inputAmount}
                    maxLength={72}
                  />
                  <button
                    className={styles.active}
                    onClick={() => onClickMaxBtn("tokenB")}
                  >
                    MAX
                  </button>
                  <div className={styles.balance}>
                    {toLocaleStringOption(balanceInCode(tokenB.haveAmount))}{" "}
                    (balance)
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.slipPage}>
              <div className={styles.flexBox}>
                <div className={styles.titleFlex}>
                  Slippage Tolerance
                  <img
                    className={styles.question}
                    onMouseEnter={() => {
                      setQuestionHover("slipPage");
                    }}
                    onMouseOut={() => {
                      setQuestionHover("");
                    }}
                    src={require("../assets/images/swap/ico_question.png")}
                    alt={"ico_question"}
                  />
                  <img
                    className={styles.setOpenBtn}
                    onClick={slipPageToggle}
                    src={require("../assets/images/swap/ico_setting_bl.png")}
                    alt={"ico_setting_bl"}
                  />
                  <div
                    className={`${styles.questionBubble} ${
                      questionHover === "slipPage" ? styles.open : ""
                    }`}
                  >
                    Your transaction will revert if the price changes
                    unfavorably by more than this percentage.
                    <div className={styles.questionArrow}></div>
                  </div>
                </div>
                <div>
                  <span className={styles.changeNum}>{slippage}</span>{" "}
                  <span>%</span>
                </div>
              </div>
              {slipPageShow ? (
                <div className={styles.transSet}>
                  <div>{/* <button>AUTO</button> */}</div>
                  <div>
                    <input
                      max={1}
                      type={"text"}
                      step={0.01}
                      value={slippage}
                      onChange={(e) => changeSlippage(e)}
                      onBlur={focusSlippageInput}
                    />
                    %
                    {slippage <= 0.1 ? (
                      <p className={styles.importText}>
                        Your transaction may fail
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className={`${styles.slipPage} ${styles.mt10}`}>
              <div className={styles.flexBox}>
                <div className={styles.titleFlex}>
                  Transaction deadline
                  <img
                    className={styles.question}
                    onMouseEnter={() => {
                      setQuestionHover("transactoin");
                    }}
                    onMouseOut={() => {
                      setQuestionHover("");
                    }}
                    src={require("../assets/images/swap/ico_question.png")}
                    alt={"ico_question"}
                  />
                  <img
                    className={styles.setOpenBtn}
                    onClick={timePageToggle}
                    src={require("../assets/images/swap/ico_setting_bl.png")}
                    alt={"ico_setting_bl"}
                  />
                  <div
                    className={`${styles.questionBubble} ${
                      questionHover === "transactoin" ? styles.open : ""
                    }`}
                  >
                    Your transaction will revert if it is pending for more than
                    this long.
                    <div className={styles.questionArrow}></div>
                  </div>
                </div>
                <div>
                  <span className={styles.changeNum}>{time / 60}</span>{" "}
                  <span>Minutes</span>
                </div>
              </div>
              {timePageShow ? (
                <div className={styles.transSet}>
                  <div>{/* <button>AUTO</button> */}</div>
                  <div>
                    <input
                      max={1}
                      type={"text"}
                      value={time / 60}
                      onChange={(e) => onChangeTime(e)}
                    />
                    Minutes
                    {time / 60 == 0 ? (
                      <p className={styles.importText}>
                        Enter a valid deadline
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {approveBtnMessage != "Invalid Pair" &&
          tokenA.symbol != "" &&
          tokenB.symbol != "" ? (
            <div className={styles.poolShare}>
              <div className={styles.subTitle}>{poolStatusMessage}</div>
              <div className={styles.flexBox}>
                <div>
                  -
                  <img
                    className={styles.symbolLogo}
                    src={`${chain}/logo_${tokenB.image}.png`}
                    alt={`${tokenB.symbol}`}
                    onError={onErrorImg}
                  />
                  <span>{tokenB.symbol}</span> per
                  <img
                    className={styles.symbolLogo}
                    src={`${chain}/logo_${tokenA.image}.png`}
                    alt={`${tokenA.symbol}`}
                    onError={onErrorImg}
                  />
                  <span>{tokenA.symbol}</span>
                </div>
                <div>{toLocaleStringOption(balanceInCode(reserveamount0))}</div>
              </div>
              <div className={styles.flexBox}>
                <div>
                  -
                  <img
                    className={styles.symbolLogo}
                    src={`${chain}/logo_${tokenA.image}.png`}
                    alt={`${tokenA.symbol}`}
                    onError={onErrorImg}
                  />
                  <span>{tokenA.symbol}</span> per
                  <img
                    className={styles.symbolLogo}
                    src={`${chain}/logo_${tokenB.image}.png`}
                    alt={`${tokenB.symbol}`}
                    onError={onErrorImg}
                  />
                  <span>{tokenB.symbol}</span>
                </div>
                <div>{toLocaleStringOption(balanceInCode(reserveamount1))}</div>
              </div>
              <div className={styles.flexBox}>
                <div>- Share of Pool</div>
                <div>{sharePercent}%</div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <div className={styles.feetier}>
            <div className={styles.subTitle}>Fee tier</div>
            <div className={styles.flexBox}>
              <div className={styles.textBox}>
                <p className={styles.p18}>0.01% fee tier</p>
                <p className={styles.p15}>Best for <span>very stabled</span> pairs</p>
              </div>
              <div>
                <button className={styles.notCreatedBtn}>
                  Not created
                </button>
              </div>
            </div>
            <div className={styles.flexBox}>
              <div className={styles.textBox}>

                <p className={styles.p18}>0.05% fee tier</p>
                <p className={styles.p15}>Best for <span>stabled</span> pairs</p>
              </div>
              <div>
                <button className={styles.greenBtn}>
                  27% selected
                </button>
              </div>
            </div>
            <div className={`${styles.flexBox} ${styles.active}`}>
              <div className={styles.textBox}>
                <p className={styles.p18}>0.3% fee tier</p>
                <p className={styles.p15}>Best for <span>most</span> pairs</p>
              </div>
              <div>
                <button className={styles.greenBtn}>
                  100% selected
                </button>
              </div>
            </div>
            <div className={styles.flexBox}>
              <div className={styles.textBox}>
                <p className={styles.p18}>1% fee tier</p>
                <p className={styles.p15}>Best for <span>exotic</span> pairs</p>
              </div>
              <div>
                <button className={styles.greenBtn}>
                  0% selected
                </button>
              </div>
            </div>
          </div> */}
          {approveBtnMessage != "Invalid Pair" &&
          tokenA.inputAmount != "" &&
          tokenB.inputAmount != "" ? (
            <div className={styles.deposit}>
              <div className={styles.subTitle}>Deposit Amounts</div>
              <div className={`${styles.whiteBox} ${styles.mb}`}>
                {Number(tokenA.inputAmount) == 0
                  ? 0
                  : toLocaleStringOption(tokenA.inputAmount)}
              </div>
              <div className={`${styles.whiteBox} ${styles.disabled}`}>
                {Number(tokenB.inputAmount) == 0
                  ? 0
                  : toLocaleStringOption(tokenB.inputAmount)}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <div className={styles.setPrice}>
           <div className={styles.subTitle}>Set Starting Price</div>

          </div> */}
          <button
            disabled={approveBtnStatus}
            className={`${styles.approveBtn} ${
              approveBtnStatus ? styles.btnDisActive : ""
            }`}
            onClick={() => {
              if (active == false) {
                handleConnect();
              } else if (tokenA.token_allowance == true) {
                onClickApprove(tokenA);
              } else if (tokenB.token_allowance == true) {
                onClickApprove(tokenB);
              } else {
                setNowModalStatus("approveAddToken");
                setPopupShow(true);
              }
            }}
          >
            {approveBtnMessage}
          </button>
        </div>
      </div>

      {popupShow ? (
        <div className={stylesSwap.popupWrap}>
          <div
            className={stylesSwap.popupDim}
            onClick={() => {
              setPopupShow(false);
              setSelectTokenAddress("");
              setAddTokenList([]);
            }}
          />
          <div className={stylesSwap.popupContainer}>
            <div
              className={stylesSwap.closeBtn}
              onClick={() => {
                setPopupShow(false);
                setSelectTokenAddress("");
                setAddTokenList([]);
              }}
            >
              <div>
                <img
                  src={require("../assets/images/swap/ico_minus_bl.png")}
                  alt={"ico_minus_bl"}
                />
                <img
                  src={require("../assets/images/swap/ico_minus_bl.png")}
                  alt={"ico_minus_bl"}
                />
              </div>
            </div>
            {nowModalStatus === "selectToken" ? (
              <div className={stylesSwap.selectTokenBox}>
                <div className={stylesSwap.selectTitle}>Select a Token</div>
                <div className={stylesSwap.inputBox}>
                  <input
                    type={"text"}
                    maxLength={42}
                    className={stylesSwap.searchInput}
                    placeholder={"Search by Name or Address"}
                    value={searchToken}
                    onChange={(e) => setSelectAddress(e)}
                  />
                </div>

                <div className={stylesSwap.tokenList}>
                  {tokenList == "" ? (
                    <>No results found.</>
                  ) : (
                    <>
                      {tokenList.map((item, index) => (
                        <div
                          key={index}
                          className={`${stylesSwap.tokenItem} ${
                            (selectActiveA == item.address &&
                              selectActiveA == item.address) ||
                            (selectActiveB == item.address &&
                              selectActiveB == item.address)
                              ? stylesSwap.active
                              : ""
                          } ${
                            !(
                              (((selectActiveA == item.address &&
                                selectActiveA == item.address) ||
                                (selectActiveB == item.address &&
                                  selectActiveB == item.address)) &&
                                (selectType == "tokenA"
                                  ? tokenA
                                  : tokenB
                                ).address.toLowerCase() !=
                                  selectedTokenData.address.toLowerCase()) ||
                              item.address.toLowerCase() !=
                                selectedTokenData.address.toLowerCase() ||
                              (selectedTokenData.symbol == "" &&
                                selectedTokenData.address == "")
                            )
                              ? stylesSwap.selectedActive
                              : ""
                          }`}
                          onClick={() => {
                            if (
                              (selectType == "tokenA"
                                ? tokenA
                                : tokenB
                              ).address.toLowerCase() !=
                                selectedTokenData.address.toLowerCase() ||
                              item.address.toLowerCase() !=
                                selectedTokenData.address.toLowerCase() ||
                              (selectedTokenData.symbol == "" &&
                                selectedTokenData.address == "")
                            ) {
                              onClickAddToken(item);
                            }
                          }}
                        >
                          <span
                            className={`${stylesSwap.greenCheck} ${
                              !(
                                (((selectActiveA == item.address &&
                                  selectActiveA == item.address) ||
                                  (selectActiveB == item.address &&
                                    selectActiveB == item.address)) &&
                                  (selectType == "tokenA"
                                    ? tokenA
                                    : tokenB
                                  ).address.toLowerCase() !=
                                    selectedTokenData.address.toLowerCase()) ||
                                item.address.toLowerCase() !=
                                  selectedTokenData.address.toLowerCase() ||
                                (selectedTokenData.symbol == "" &&
                                  selectedTokenData.address == "")
                              )
                                ? stylesSwap.show
                                : ""
                            }`}
                          ></span>
                          {/* <img src={require(`../assets/images/swap/logo_${item.image}.png`)} style={{ "width": "45px", "height": "45px" }} /> */}
                          <img
                            src={`${chain}/logo_${item.image}.png`}
                            alt={`${item.symbol}`}
                            onError={onErrorImg}
                            className={stylesSwap.symbolLogo}
                          />
                          <span className={stylesSwap.coinName}>
                            {item.symbol}
                          </span>
                        </div>
                      ))}
                    </>
                  )}
                </div>

                <button
                  className={stylesSwap.addTokenBtn}
                  onClick={() => {
                    setNowModalStatus("addToken");
                    setSearchToken("");
                  }}
                >
                  Add Token
                </button>
              </div>
            ) : nowModalStatus === "addToken" ? (
              <div className={stylesSwap.addTokenBox}>
                <div className={stylesSwap.addTokenTitle}>
                  <img
                    onClick={() => {
                      setNowModalStatus("selectToken");
                      setSelectTokenAddress("");
                      setAddTokenList([]);
                      setAddtokenSelectMessage("");
                    }}
                    src={require("../assets/images/swap/ico_back_bl.png")}
                    alt={"ico_back_bl"}
                  />
                  <span>Add Token</span>
                  <span></span>
                </div>
                {/* <div className={stylesSwap.menuTab}>
                    <div className={`${addTokenMenuActive === 'list' ? stylesSwap.active : ''}`}
                      onClick={() => {
                        setAddTokenMenuActive('list');
                        setSelectTokenAddress("");
                      }}>
                      List
                    </div>
                    <div className={`${addTokenMenuActive === 'token' ? stylesSwap.active : ''}`}
                      onClick={() => {
                        setAddTokenMenuActive('token');
                        setSelectTokenAddress("");
                      }}>
                      Token
                    </div>
                  </div> */}
                <input
                  type={"text"}
                  className={stylesSwap.searchInput}
                  placeholder={
                    addTokenMenuActive === "token"
                      ? "0x0000"
                      : "https:j// or ipfs:// or ENS name"
                  }
                  value={selectTokenAddress}
                  onChange={(e) => {
                    if (addTokenMenuActive == "token") {
                      searchTokenAddress(e);
                    }
                  }}
                />
                <div className={stylesSwap.tokenList}>
                  {addTokenMenuActive == "list" ? (
                    <></>
                  ) : (
                    <>
                      {addTokenList == "" ? (
                        <div className={`${stylesSwap.errorMessage}`}>
                          {addTokenSelectMessage}
                        </div>
                      ) : (
                        <>
                          {addTokenList.map((item, index) => (
                            <div
                              key={index}
                              className={`${stylesSwap.tokenItem} ${
                                (selectActiveA == item.address &&
                                  selectActiveA == item.address) ||
                                (selectActiveB == item.address &&
                                  selectActiveB == item.address)
                                  ? stylesSwap.active
                                  : ""
                              } ${
                                !(
                                  (((selectActiveA == item.address &&
                                    selectActiveA == item.address) ||
                                    (selectActiveB == item.address &&
                                      selectActiveB == item.address)) &&
                                    (selectType == "tokenA"
                                      ? tokenA
                                      : tokenB
                                    ).address.toLowerCase() !=
                                      selectedTokenData.address.toLowerCase()) ||
                                  item.address.toLowerCase() !=
                                    selectedTokenData.address.toLowerCase() ||
                                  (selectedTokenData.symbol == "" &&
                                    selectedTokenData.address == "")
                                )
                                  ? stylesSwap.selectedActive
                                  : ""
                              }`}
                              onClick={() => {
                                if (
                                  (selectType == "tokenA"
                                    ? tokenA
                                    : tokenB
                                  ).address.toLowerCase() !=
                                    selectedTokenData.address.toLowerCase() ||
                                  item.address.toLowerCase() !=
                                    selectedTokenData.address.toLowerCase() ||
                                  (selectedTokenData.symbol == "" &&
                                    selectedTokenData.address == "")
                                ) {
                                  clickAddTokenAndList(item);
                                }
                                // onClickAddToken(item)
                              }}
                            >
                              <span
                                className={`${stylesSwap.greenCheck} ${
                                  !(
                                    (((selectActiveA == item.address &&
                                      selectActiveA == item.address) ||
                                      (selectActiveB == item.address &&
                                        selectActiveB == item.address)) &&
                                      (selectType == "tokenA"
                                        ? tokenA
                                        : tokenB
                                      ).address.toLowerCase() !=
                                        selectedTokenData.address.toLowerCase()) ||
                                    item.address.toLowerCase() !=
                                      selectedTokenData.address.toLowerCase() ||
                                    (selectedTokenData.symbol == "" &&
                                      selectedTokenData.address == "")
                                  )
                                    ? stylesSwap.show
                                    : ""
                                }`}
                              ></span>
                              {/* <img src={require(`../assets/images/swap/logo_${item.image}.png`)} style={{ "width": "45px", "height": "45px" }} /> */}
                              <img
                                src={`${chain}/logo_${item.image}.png`}
                                alt={`${item.symbol}`}
                                onError={onErrorImg}
                                style={{ width: "30px", height: "30px" }}
                              />
                              <span className={stylesSwap.coinName}>
                                {item.symbol}
                              </span>
                            </div>
                          ))}
                        </>
                      )}
                      {myAddTokenList == "" ? (
                        ""
                      ) : (
                        <hr style={{ margin: "10px 0px 10px 0px" }} />
                      )}
                      {myAddTokenList == "" ? (
                        <></>
                      ) : (
                        <div className={stylesSwap.addMyTokenList}>
                          {myAddTokenList.map((item, index) => (
                            <div
                              key={index}
                              className={`${stylesSwap.tokenItem} ${
                                stylesSwap.closeBtnShow
                              } ${
                                (selectActiveA == item.address &&
                                  selectActiveA == item.address) ||
                                (selectActiveB == item.address &&
                                  selectActiveB == item.address)
                                  ? stylesSwap.active
                                  : ""
                              }  ${
                                !(
                                  (((selectActiveA == item.address &&
                                    selectActiveA == item.address) ||
                                    (selectActiveB == item.address &&
                                      selectActiveB == item.address)) &&
                                    (selectType == "tokenA"
                                      ? tokenA
                                      : tokenB
                                    ).address.toLowerCase() !=
                                      selectedTokenData.address.toLowerCase()) ||
                                  item.address.toLowerCase() !=
                                    selectedTokenData.address.toLowerCase() ||
                                  (selectedTokenData.symbol == "" &&
                                    selectedTokenData.address == "")
                                )
                                  ? stylesSwap.selectedActive
                                  : ""
                              }`}
                            >
                              <div className={stylesSwap.flexBox}>
                                <span
                                  className={`${stylesSwap.greenCheck} ${
                                    !(
                                      (((selectActiveA == item.address &&
                                        selectActiveA == item.address) ||
                                        (selectActiveB == item.address &&
                                          selectActiveB == item.address)) &&
                                        (selectType == "tokenA"
                                          ? tokenA
                                          : tokenB
                                        ).address.toLowerCase() !=
                                          selectedTokenData.address.toLowerCase()) ||
                                      item.address.toLowerCase() !=
                                        selectedTokenData.address.toLowerCase() ||
                                      (selectedTokenData.symbol == "" &&
                                        selectedTokenData.address == "")
                                    )
                                      ? stylesSwap.show
                                      : ""
                                  }`}
                                ></span>
                                {/* <img className={stylesSwap.symbolLogo} src={require(`../assets/images/swap/logo_${item.image}.png`)} alt={"logo_eth"} /> */}
                                <img
                                  className={stylesSwap.symbolLogo}
                                  src={`${chain}/logo_${item.image}.png`}
                                  alt={`${item.symbol}`}
                                  onError={onErrorImg}
                                />
                                <span
                                  className={stylesSwap.coinName}
                                  onClick={() => {
                                    if (
                                      (selectType == "tokenA"
                                        ? tokenA
                                        : tokenB
                                      ).address.toLowerCase() !=
                                        selectedTokenData.address.toLowerCase() ||
                                      item.address.toLowerCase() !=
                                        selectedTokenData.address.toLowerCase() ||
                                      (selectedTokenData.symbol == "" &&
                                        selectedTokenData.address == "")
                                    ) {
                                      clickAddTokenAndList(item);
                                    }
                                  }}
                                >
                                  {item.symbol}
                                </span>
                              </div>
                              <span onClick={() => onClickRemoveToken(item)}>
                                <img
                                  src={require("../assets/images/ico_close_bl.png")}
                                  alt={"ico_close"}
                                />
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : nowModalStatus === "approveAddToken" ? (
              <div className={styles.approveAddToken}>
                <div className={styles.approveAddTokenTitle}>Add Liquidity</div>
                <div className={styles.approveAddTokenWrap}>
                  <div className={styles.poolMessage}>Deposite Amounts</div>
                  <div className={styles.flexBox}>
                    <div>
                      <img
                        className={styles.symbolLogo}
                        src={`${chain}/logo_${tokenA.image}.png`}
                        alt={`${tokenA.symbol}`}
                        onError={onErrorImg}
                      />
                      {tokenA.symbol} Deposited
                    </div>
                    <div>
                      {toLocaleStringOption(balanceInCode(tokenA.inputAmount))}
                    </div>
                  </div>
                  <div className={styles.flexBox}>
                    <div>
                      <img
                        className={styles.symbolLogo}
                        src={`${chain}/logo_${tokenB.image}.png`}
                        alt={`${tokenB.symbol}`}
                        onError={onErrorImg}
                      />
                      {tokenB.symbol} Deposited
                    </div>
                    <div>
                      {toLocaleStringOption(balanceInCode(tokenB.inputAmount))}
                    </div>
                  </div>
                  <div
                    className={`${styles.poolMessage} ${styles.ratesMessage}`}
                    style={{ marginTop: "3rem" }}
                  >
                    Rates
                  </div>
                  <div className={`${styles.flexBox} ${styles.ratesMessage}`}>
                    <div>
                      <img
                        className={styles.symbolLogo}
                        src={`${chain}/logo_${tokenB.image}.png`}
                        alt={`${tokenB.symbol}`}
                        onError={onErrorImg}
                      />
                      {tokenB.symbol} per
                      <img
                        className={styles.symbolLogo}
                        src={`${chain}/logo_${tokenA.image}.png`}
                        alt={`${tokenA.symbol}`}
                        onError={onErrorImg}
                      />
                      {tokenA.symbol}
                    </div>
                    <div>
                      {toLocaleStringOption(balanceInCode(reserveamount0))}
                    </div>
                  </div>
                  <div className={`${styles.flexBox} ${styles.ratesMessage}`}>
                    <div>
                      <img
                        className={styles.symbolLogo}
                        src={`${chain}/logo_${tokenA.image}.png`}
                        alt={`${tokenA.symbol}`}
                        onError={onErrorImg}
                      />
                      {tokenA.symbol} per
                      <img
                        className={styles.symbolLogo}
                        src={`${chain}/logo_${tokenB.image}.png`}
                        alt={`${tokenB.symbol}`}
                        onError={onErrorImg}
                      />
                      {tokenB.symbol}
                    </div>
                    <div>
                      {toLocaleStringOption(balanceInCode(reserveamount1))}
                    </div>
                  </div>
                  {/*<div>/!*Fee tier 구현 X*!/Fee tier </div>*/}
                  {/*<div>/!*Selected Range 구현 X*!/Selected Range</div>*/}
                  {/*<div>/!*Current Price 구현 X*!/Current Price</div>*/}
                  <button
                    onClick={() => {
                      onClickSupply();
                      setNowModalStatus("confirmation");
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            ) : nowModalStatus === "confirmation" ? (
              <div className={styles.confirmation}>
                <img
                  src={require("../assets/images/swap/loading.gif")}
                  alt={"loading.gif"}
                />
                <div className={styles.bold}>Waiting for confirmation</div>
                <div className={styles.normal}>
                  Confirm this transaction in your wallet
                </div>
                <div className={styles.gray}>
                  Supplying{" "}
                  {toLocaleStringOption(balanceInCode(tokenA.inputAmount))}{" "}
                  {tokenA.symbol} and{" "}
                  {toLocaleStringOption(balanceInCode(tokenB.inputAmount))}{" "}
                  {tokenB.symbol}
                </div>
              </div>
            ) : nowModalStatus === "submitted" ? (
              <div className={styles.submitted}>
                <img
                  src={require("../assets/images/swap/ico_confirmed.png")}
                  alt={"ico_confirmed"}
                />
                <div className={styles.bold}>Transaction Submitted</div>
                <div onClick={onClickExplorer} style={{ cursor: "pointer" }}>
                  View on Explorer{" "}
                  <img
                    className={styles.greenArrow}
                    src={require("../assets/images/swap/green-arrow.png")}
                    alt={"green-arrow"}
                  />{" "}
                </div>
              </div>
            ) : nowModalStatus === "failed" ? (
              <div className={styles.submitted}>
                <img
                  src={require("../assets/images/ico_rejected.png")}
                  alt={"ico-rejected"}
                />
                <div className={styles.bold} style={{ color: "#e54e4e" }}>
                  Transaction Rejected
                </div>
                {/* <div onClick={onClickExplorer} style={{ cursor: "pointer" }} >View on Explorer <img className={styles.greenArrow} src={require("../assets/images/swap/green-arrow.png")} alt={"green-arrow"} /> </div> */}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AddLiquidity;
