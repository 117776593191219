import React from 'react';

const AddLiquidityBtn = ({ transactionHash, onClickSupply, approveBtnMessage, approveBtnStatus }) => {
    return (
        <div>
            <button disabled={approveBtnStatus} onClick={() => onClickSupply()}>{approveBtnMessage}</button>
            <div>{transactionHash}</div>
        </div>
    );
};

export default AddLiquidityBtn;
