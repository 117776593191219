import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../assets/css/Main.module.css';
import Footer from "../containers/Footer";
import { useDispatch, useSelector } from 'react-redux';
import { setTokenA, setTokenB } from '../modules/liquidity';

const Main = ({ mobileCheck, account, active }) => {

    const dispatch = useDispatch();

    const [scrollClass, setScrollClass] = useState('');
    const [goIconActive, setGoIconActive] = useState(true);
    const [mobileStatus, setMobileStatus] = useState("");

    const [popupStatus, setPopupStatus] = useState(true);

    const [toastPopup,setToastPopup] =useState("");
    const chainId = useSelector((state) => state.user.chainId);


    window.addEventListener('scroll', () => {
        let stylesData = "";
        if (!mobileStatus) {
            if (document.documentElement.scrollTop > 2400 && document.documentElement.scrollTop < 3599) {
                stylesData = styles.block
            }else if (document.documentElement.scrollTop > 3800 || document.documentElement.scrollTop < 2400) {
                stylesData = styles.none
            }
        } else {
            if (document.documentElement.scrollTop > 1400 && document.documentElement.scrollTop < 2399) {
                stylesData = styles.block
            }else if (document.documentElement.scrollTop > 2506 || document.documentElement.scrollTop < 2400) {
                stylesData = styles.none
            }
        }
        if(stylesData != ""){
            return  () => {
                setScrollClass(stylesData);
            }
        }
    })

    const onClickHere = () => {
        window.open("https://ivoryswap-finance.gitbook.io/ivoryswap-en/")
    }

    const onClickHowToConnect = () => {
        window.open("https://ivoryswap-finance.gitbook.io/ivoryswap-en/guide/undefined")
    }
    const onClickTitle = (pageName) => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerHTML = pageName;
    }
    
    useEffect(() => {
        setMobileStatus(mobileCheck);
    }, [mobileCheck])

    useEffect(() => {
        onClickTitle("Ivory Swap")
    }, []);

    useEffect(()=>{
        if(toastPopup){
            setTimeout(()=>{
                setToastPopup(false)
            },1000 )
        }
    },[toastPopup])

    return (<div className={styles.mainContainer}>
        <div className={styles.btnBottomWrap}>
            {/* <button className={styles.btnNotice}>
                <Link to={"/notice"}><img src={require("../assets/images/ico_notice_bl@3x.png")} alt={"ico_notice_bl"} width={"36"} /></Link>
            </button> */}
            <button className={styles.btnGuide}>
                <a href="https://ivoryswap-finance.gitbook.io/ivoryswap-en/products/swap" target={"_blank"}>
                    <span className={styles.light}></span>
                    <img src={require("../assets/images/main_ico_guide_bl@3x.png")} alt={"ico_guide_bl"} width={"40"} /><br />
                    <span className={styles.guideTxt}>Guide</span>
                </a>
            </button>
        </div>
        <div className={styles.bgCircle}>
            <img src={require("../assets/images/img_dying_wh.png")} alt={"img_dying_wh"} />
        </div>
        <div className={styles.mainTitle}>
            <div className={styles.mainImg}>
                <img src={require("../assets/images/img_logo_big.png")} alt={"img_logo_big"} />
                <span className={`${styles.yellowLight} ${styles.yellowLight1} ${styles.left}`} />
                <span className={`${styles.yellowLight} ${styles.yellowLight2} ${styles.left}`} />
                <span className={`${styles.yellowLight} ${styles.yellowLight3} ${styles.left}`} />
                <span className={`${styles.yellowLight} ${styles.yellowLight4} ${styles.left}`} />
                <span className={`${styles.yellowLight} ${styles.yellowLight5} ${styles.right}`} />
                <span className={`${styles.yellowLight} ${styles.yellowLight6} ${styles.right}`} />
                <span className={`${styles.yellowLight} ${styles.yellowLight7} ${styles.right}`} />
                <span className={`${styles.yellowLight} ${styles.yellowLight8} ${styles.right}`} />
            </div>
            <div className={styles.main}>
                <span>Grow your garden of potential into</span>
                <span> a fertile and big farm with IVORYSWAP</span>
            </div>
            <div className={styles.sub}>
                <span className={styles.block}>With Ivoryswap, you can be the best Token gardener </span>
                <span className={styles.block}>at the most Greenery decentralized platform in the Earth. </span>
                <span className={styles.block}>You want to know how to use our platform? </span>
                <span className={styles.block}>Will you join us?</span>
                <span>Then click </span>
                <span onClick={onClickHere} className={styles.bold}>here.</span>
                <img className={styles.leftPlant} src={require("../assets/images/img_main_plant_02.png")} alt={"img_main_plant_02"} />
                <img className={styles.rightPlant} src={require("../assets/images/img_main_plant_02.png")} alt={"img_main_plant_02_2"} />
            </div>
        </div>
        <div className={styles.category}>
            {/*<div className={styles.box}>*/}
            {/*    <div><img src={require("../assets/images/ico_24hr_gr@2x.png")} alt={"ico_24hr_gr"} /></div>*/}
            {/*    <div className={styles.fw_bold}>*/}
            {/*        $61million*/}
            {/*        <span className={styles.green}>24hrs Trading Volume</span>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className={styles.box}>*/}
            {/*    <div><img src={require("../assets/images/ico_trades_gr@2x.png")} alt={"ico_trades_gr"} /></div>*/}
            {/*    <div className={styles.fw_bold}>*/}
            {/*        2.3million*/}
            {/*        <span className={styles.green}>Trades</span>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className={styles.box}>*/}
            {/*    <div><img src={require("../assets/images/ico_locked_gr@2x.png")} alt={"ico_locked_gr"} /></div>*/}
            {/*    <div className={styles.fw_bold}>*/}
            {/*        $1.7billion*/}
            {/*        <span className={styles.green}>Total Value Locked</span>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={styles.liquidityBox}>
                <div className={styles.imgBox}>
                    <img className={styles.leftPlant} src={require("../assets/images/img_liquidity.png")} alt={"img_liquidity"} />
                </div>
                <div className={styles.textBox}>
                    <div className={styles.title}>Liquidity</div>
                    <div className={styles.text1}>
                        <span>Contribute to the pool that everyone swaps from </span>
                        <span>and collect swap fees.</span>
                    </div >
                    <Link to={"/add"} onClick={() => {
                    // <Link to={`${!active?"":"/add"}`} onClick={() => {
                        const reset = {
                            address: "",
                            symbol: "",
                            name: "",
                            decimal: 0,
                            inputAmount: "",
                            image: "",
                            haveAmount: 0,
                          };
                          dispatch(setTokenA(reset))
                          dispatch(setTokenB(reset))
                        if(account!=""){
                            onClickTitle("AddLiquidity - Ivory Swap");
                        }else if(account==""){
                            // setToastPopup(true)
                        }
                    }}>
                        <button>
                            Go!
                        </button>
                    </Link>
                </div >
            </div >
            <div className={styles.swapBox}>
                <div className={styles.textBox}>
                    <div className={styles.title}>Swap Tokens</div>
                    <div className={styles.text1}>
                        <span>Swap between tokens on the {chainId == "8898" || chainId == "88998" ? "MAMMOTH" : chainId == "898989" || chainId == "8989" ? "GIANT MAMMOTH" : chainId == "1" || chainId == "5" ? "ETHEREUM" : ""} network </span>
                        <span>at the lowest fees.</span>
                        <Link to={"/swap"} onClick={() => {
                        // <Link to={`${!active?"":"/swap"}`} onClick={() => {
                            if(account!=""){
                                onClickTitle("Swap - Ivory Swap");
                            }else if(account==""){
                                // setToastPopup(true)
                            }
                        }}>
                            <button>
                                Go!
                            </button>
                        </Link>
                    </div>
                </div>
                <div className={styles.imgBox}>
                    <img className={styles.leftPlant} src={require("../assets/images/img_swap.png")} alt={"img_swap"} />
                </div>
            </div>
        </div >

        {/* <div className={styles.roadMapWrap}>
            <div className={styles.roadMap}>
                <div className={`${styles.itemLine} ${styles.right}`}>
                    <div className={styles.item}>
                        <div className={styles.whiteText}>
                            2022 <br />
                            <span>
                                <span className={styles.green}>Q</span>1
                            </span>
                        </div>
                        <div className={styles.blackText}>
                            Swap, Liquidity <br />
                            Docs <br />
                            White paper v1.0
                        </div>
                    </div>
                </div>
                <div className={`${styles.itemLine} ${styles.left}`}>
                    <div className={styles.item}>
                        <div className={styles.whiteText}>
                            2022 <br />
                            <span>
                                <span className={styles.green}>Q</span>2
                            </span>
                        </div>
                        <div className={styles.blackText}>
                            Farm, Staking <br />
                            Ivoryswap Launching
                            IVY Private Sale
                        </div>
                    </div>
                </div>
                <div className={`${styles.itemLine} ${styles.right}`}>
                    <div className={styles.item}>
                        <div className={styles.whiteText}>
                            2022 <br />
                            <span>
                                <span className={styles.green}>Q</span>3
                            </span>
                        </div>
                        <div className={styles.blackText}>
                            IVY Public Sale <br/>
                            IVY Airdrop <br/>
                            IVY DAO <br />
                            Establish Governance <br />
                            Smart Contract Audits <br/>
                            Bridge Protocol
                        </div>
                    </div>
                </div>
                <div className={`${styles.itemLine} ${styles.left}`}>
                    <div className={styles.item}>
                        <div className={styles.whiteText}>
                            2022 <br />
                            <span>
                                <span className={styles.green}>Q</span>4
                            </span>
                        </div>
                        <div className={styles.blackText}>
                            IVY Token IDO, IEO <br />
                            IvorySwap Launch <br/>
                            White paper v2.0 <br/>
                            IVY Token Airdrop
                        </div>
                    </div>
                </div>
                <div className={`${styles.itemLine} ${styles.right}`}>
                    <div className={styles.item}>
                        <div className={styles.whiteText}>
                            2023 <br />
                            <span>
                                <span className={styles.green}>Q</span>1
                            </span>
                        </div>
                        <div className={styles.blackText}>
                            Mobile app release (Android&iOS) <br />
                            NFT Market <br />
                            Cex Listing <br />
                            IVY DAO
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <div className={styles.m_roadMap}>
            <div>
                <img src={require("../assets/images/img_roadmap_withtxt.png")} alt={"img_roadmap_withtxt"} />
            </div>
        </div>
        <div className={styles.ivyExpo}>
            {/* <div className={`${styles.whiteBoxText} ${scrollClass}`}>
                <span className={styles.ivyTitle}>What is IVY?</span> <br />
                <span className={styles.spanBlock}>IVY is a governance token that exercises voting rights that determine important agendas. </span>
                <span className={styles.spanBlock}>It is distributed as compensation to liquidity pool depositors who contributed to IvorySwap,</span>
                <span className={styles.spanBlock}>and distributed IVY can be used for transactions or additional profits can be obtained through IVY staking and pool voting.</span>
            </div> */}
            <div className={`${styles.whiteBoxText} ${styles.v2}`}>
                Trade tokens in an instant on {chainId == "8898" || chainId == "88998" ? "MAMMOTH" : chainId == "898989" || chainId == "8989" ? "GIANT MAMMOTH" : chainId == "1" || chainId == "5" ? "ETHEREUM" : ""} chain <br />
                just by connecting your wallet.
            </div>
            <img className={styles.mountain} src={require("../assets/images/img_mountain.png")} alt={"img_mountain"} />
            <div className={styles.greenCircleBox}>
                <img className={styles.greenCircle} src={require("../assets/images/img_dying_gr.png")} alt={"img_dying_gr"} />
            </div>
        </div>
        <div className={styles.goConnect}>
            {/* <span className={styles.bottomTextBox}>
                Trade tokens in an instant on MAMMOTH chain <br />
                just by connecting your wallet.
            </span> */}
            <div className={styles.lernDiv}>
                {/* <<<<<<< HEAD */}
                <div onClick={onClickHowToConnect} style={{ cursor: "pointer" }}>LEARN <br />
                    HOW TO CONNECT</div>
                <img className={styles.greenLongArrow} src={require("../assets/images/img_long_arrow_gr.png")} alt={"img_long_arrow_gr"} />
                <img onClick={onClickHowToConnect}
                    onMouseEnter={() => (setGoIconActive(false))}
                    onMouseOut={() => (setGoIconActive(true))}
                    className={`${styles.goIcon} ${goIconActive ? styles.active : ''}`}
                    src={require("../assets/images/go_icon.png")} alt={"go_icon"} />
                {/* ======= */}
                {/* <div onClick={onClickHowToConnect} style={{cursor:"pointer"}}>LEARN <br/>
                HOW TO CONNECT</div>
                <img className={styles.greenLongArrow} src={require("../assets/images/img_long_arrow_gr.png")} alt={"img_long_arrow_gr"} />
                <img onMouseEnter={() => (setGoIconActive(false))}
                     onMouseOut={() => (setGoIconActive(true))}
                     className={`${styles.goIcon} ${goIconActive ? styles.active : ''}`}
                     src={require("../assets/images/go_icon.png")} alt={"go_icon"} /> */}
                {/* >>>>>>> origin/main */}
                <img className={styles.greenArrow} src={require("../assets/images/img_arrow_gr.png")} alt={"img_arrow_gr"} />
                <img className={styles.greenArrow2} src={require("../assets/images/img_arrow_gr.png")} alt={"img_arrow_gr2"} />
            </div>
            <div className={styles.forestDiv}>
                {/*<img src={require("../assets/images/img_forest.png")} alt={"img_forest"} />*/}
                <div className={styles.greenCircleBox}>
                    <img className={styles.greenCircle} src={require("../assets/images/img_dying_gr.png")} alt={"img_dying_gr2"} />
                </div>
            </div>

            {/* 스캠 사이트 알림 */}
            {
                popupStatus ?
                    ""
                    : 
                    <div className={styles.popupWrap}>
                        <div className={styles.popupDim} onClick={() => { setPopupStatus(false); }} />
                        <div className={styles.popupContainer}>
                            <img src={require("../assets/images/ivory_scam_pop@2x.jpg")} alt={"ivory_scam_pop"} className={styles.scamPopImg}/>
                            <div className={styles.closeBtn} onClick={() => { setPopupStatus(false); }}>
                                <div className={styles.popupClose_pc}>
                                    <img src={require("../assets/images/swap/ico_minus_bl@2x.png")} alt={"ico_minus_bl"} />
                                    <img src={require("../assets/images/swap/ico_minus_bl@2x.png")} alt={"ico_minus_bl"} />
                                </div>
                            </div>
                            <button type={"button"} className={styles.closeBtnBottom} onClick={() => { setPopupStatus(false); }}>Close</button>
                        </div>
                    </div>
            }
            {
                toastPopup
                && <div className={styles.toastPopup}>
                    <div className={styles.popupDim} />
                    <div className={styles.popupContainer}>
                        <img src={require("../assets/images/ico_con_wallet@2x.png")} />
                        <div>If you want to use Ivoryswap,</div><div><span>Connect wallet</span> please.</div>
                        {/* <img onClick={() => setChangeNetWorkStatus(false)} src={require("../assets/images/ico_close_bl@2x.png")} alt={"ico_close_bl"} /> */}
                    </div>
                </div>
            }
        </div>
        <Footer />
    </div >)
}

export default Main;


