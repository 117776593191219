import React, { createRef, useEffect, useState } from 'react';
import styles from '../assets/css/Notice.module.css';

import { Link } from 'react-router-dom';

import { noticeLists } from '../noticeFAQList/noticeList';
import { FAQLists } from '../noticeFAQList/FAQList';
import { BN } from 'bn.js';

// class => className = {} 이렇게 사용하시면 됩니다!
// url은 http://localhost:3000/notice 임돠 (good...)
const Notice = () => {

  const [noticeList, setNoticeList] = useState([]);
  const [viewNoticeList, setViewNoticeList] = useState([]);
  const [totalPageNum, setTotalPageNum] = useState([]);
  const [nowPageNum, setNowPageNum] = useState(1);
  const [limit, setLimit] = useState(10);

  const [faqList, setFaqList] = useState([]);
  const [elRefs, setElRefs] = useState([]);

  // axios 하기
  const getNoticeList = () => {
    let list = [];
    for (let i = 0; i < noticeLists.length; i++) {
      let data = {
        num: `${i + 1}`,
        title: noticeLists[i].title,
        postedDate: noticeLists[i].postedDate,
        description: noticeLists[i].description
      }

      list.push(data)
    }
    setNoticeList(list)
  }

  const pageNoticeList = (page) => {
    const resultList_1 = [];
    for (let i = (limit * page) - limit; i < (limit * page); i++) {
      if (noticeList[i] == undefined) { break; }
      resultList_1.push(noticeList[i]);
    };
    let totalPageNum = Math.floor(noticeList.length / limit);
    setTotalPageNum(totalPageNum + 1)
    setViewNoticeList(resultList_1)
  }

  const onClickPageButton = (i) => {
    setNowPageNum(i + 1);
  };


  const onClickPrevious = () => {
    if (Number(nowPageNum - 1) != 0) {
      setNowPageNum(nowPageNum - 1);
    }
  }

  const onClickNext = () => {
    if (Number(nowPageNum) != totalPageNum + 1) {
      setNowPageNum(nowPageNum + 1);
    }
  }


  useEffect(() => {
    getNoticeList();
  }, []);

  useEffect(() => {
    if (noticeList != undefined) {
      pageNoticeList(nowPageNum)
    }
  }, [noticeList, nowPageNum]);


  const getFAQList = () => {
    let list = [];
    for (let i = 0; i < FAQLists.length; i++) {
      let data = {
        num: `${i + 1}`,
        Q: FAQLists[i].Q,
        A: FAQLists[i].A,
      }

      list.push(data)
    }
    setFaqList(list)
  };

  const faqToggle = (index) => {
    if (elRefs[index].current.classList.contains(styles.on)) {
      elRefs[index].current.classList.remove(styles.on)
    } else {
      elRefs[index].current.classList.add(styles.on)
    }
  }

  useEffect(() => {
    getFAQList();
  }, []);

  useEffect(() => {
    setElRefs((elRefs) => Array(faqList.length).fill().map((_, i) => elRefs[i] || createRef()));
  }, [faqList.length]);

  useEffect(()=>{
    const htmlTitle = document.querySelector("title");
    htmlTitle.innerHTML = "Notice - Ivory Swap";
  },[])

  const test = () => {
    let startBlock = "5138746";
    let endBlock = "5196346";
    let blockHeight = new BN(`${endBlock}`).sub(new BN(`${startBlock}`)).toString();

    console.log(blockHeight);
    let amount1 = "10000000";
    let amount2 = "20000000";
    let amount3 = "30000000";
    let amount4 = "40000000";
    let amount5 = "50000000";
    let inputAmount = [
      amount1,
      amount2,
      amount3,
      amount4,
      amount5,
    ]

    for (let i = 0; i < inputAmount.length; i++) {
      let decimal = new BN(`${inputAmount[i]}`).mul(new BN(`${1000000000000000000}`)).toString();
      let outputAmount = new BN(`${decimal}`).div(new BN(`${blockHeight}`)).toString();
      let modoutputAmount = new BN(`${decimal}`).mod(new BN(`${blockHeight}`)).toString();
      let mulAmount = new BN(`${outputAmount}`).mul(new BN(`${blockHeight}`)).toString();
      console.log(decimal ,outputAmount,modoutputAmount,mulAmount);
    }



  }

  useEffect(()=>{
    test()
  },[])

  return (
    <div className={styles.bg}>
      <div className={styles.wrap}>

        {/* Notice { */}
        <div className={styles.noticeWrap}>
          <p className={styles.tit}>Notice</p>
          <div className={`${styles.thead} ${styles.twidth}`}>
            <div className={styles.num}>#</div>
            <div className={styles.title}>Title</div>
            <div className={styles.date}>Posted Date</div>
          </div>
          <div className={styles.tbodyWrap}>

            {viewNoticeList.map((item, index) => (
              <div className={`${styles.tbody} ${styles.twidth}`} key={index}>
                  <div className={styles.num}>{item.num}</div>
                <div className={styles.title}>
                <Link  to={`/notice/detail/${item.num}`}>
                  <p>{item.title}</p>
                </Link>
                </div>
                <div className={styles.date}>{item.postedDate}</div>
              </div>
            ))}

            {/* paging */}
            <div className={styles.paging}>
              <button type={"button"} className={`${styles.arrowL} ${nowPageNum == "1" ? styles.off : styles.on}`} onClick={onClickPrevious}></button>
              <div className={styles.pageNum}>Page <span>{nowPageNum}</span> of <span>{totalPageNum}</span></div>
              <button type={"button"} className={`${styles.arrowR} ${nowPageNum == totalPageNum ? styles.off : styles.on}`} onClick={onClickNext}></button>
            </div>
          </div>
        </div>
        {/* } Notice */}

        {/* FAQ { */}
        <div className={styles.faqWrap}>
          <p className={styles.tit}>FAQ</p>
          <ul className={styles.faqList}>
            {
              faqList.map((item, index) => (
                <li key={index}
                  className={`${index}${styles.on}`}
                  ref={elRefs[index]}
                >

                  {/* Q { */}
                  <div className={styles.faqTit}
                  >
                    <div className={styles.tit}>Q.</div>
                    <div className={styles.cont}>{item.Q}</div>
                    <div className={styles.arrow}>
                      <button className={styles.btnArrow}
                        onClick={() => {
                          faqToggle(index)
                        }}
                        ref={elRefs[index]}
                      ></button>
                    </div>
                  </div>
                  {/* } Q */}

                  {/* A { */}
                  <div className={styles.faqCont}>
                    <div className={styles.tit}>A.</div>
                    <div className={styles.cont}>
                      {item.A}
                    </div>
                  </div>
                  {/* } A */}
                </li>
              ))
            }
          </ul>
        </div>
        {/* } FAQ */}
      </div>

    </div>
  )
}

export default Notice;