import React from 'react';

const ApproveBtn = ({token, onClickApprove}) => {
    return (
        <div key={token.address}>
            {token.token_allowance?<></>:<button onClick={(e)=>onClickApprove(token)}>Approve {token.symbol}</button>}
        </div>
    );
};

export default ApproveBtn;
