import { createAction, handleAction, handleActions } from 'redux-actions';
import produce from 'immer';

const initialState = {
    swapA: {
        address: "",
        symbol: "",
        name: "",
        decimal: 0,
        haveAmount: 0,
        inputAmount: "",
        image: "",
        price: 0,
        token_allowance: true
    },
    swapB: {
        address: "",
        symbol: "",
        name: "",
        decimal: 0,
        haveAmount: 0,
        inputAmount: "",
        image: "",
        price: 0,
        token_allowance: true
    },
};

//swapA
const SETSWAPA = "swap/SETSWAPA";
export const setSwapTokenA = createAction(SETSWAPA, (swapA) => swapA);

const MAXSWAPA = "swap/MAXSWAPA";
export const maxSwapA = createAction(MAXSWAPA, (input) => input);

const SETSWAPAHAVEAMOUNT = "swap/SETSWAPAHAVEAMOUNT";
export const setSwapAHaveAmount = createAction(SETSWAPAHAVEAMOUNT, (swapABalance => swapABalance));

const SETSWAPAINPUTAMOUNT = "swap/SETSWAPAINPUTAMOUNT";
export const setSwapAInputAmount = createAction(SETSWAPAINPUTAMOUNT, (swapAInputAmount) => swapAInputAmount);

const SETSWAPA_ALLOWANCE = "swap/SETSWAPA_ALLOWANCE";
export const setSwapA_Allowance = createAction(SETSWAPA_ALLOWANCE, (swapA_Allowance) => swapA_Allowance);

//swapB
const SETSWAPB = "swap/SETSWAPB";
export const setSwapTokenB = createAction(SETSWAPB, (swapB) => swapB);

const MAXSWAPB = "swap/MAXSWAPB";
export const maxSwapB = createAction(MAXSWAPB, (input) => input);

const SETSWAPBHAVEAMOUNT = "swap/SETSWAPBHAVEAMOUNT";
export const setSwapBHaveAmount = createAction(SETSWAPBHAVEAMOUNT, (swapBBalance => swapBBalance));

const SETSWAPBINPUTAMOUNT = "swap/SETSWAPBINPUTAMOUNT";
export const setSwapBInputAmount = createAction(SETSWAPBINPUTAMOUNT, (swapBInputAmount) => swapBInputAmount);

const CHANGESWAP = "swap/CHANGESWAP";
export const changeSwap = createAction(CHANGESWAP, (change) => change);

//all
const CHANGEINPUTAMOUNTALL = "swap/CHANGEINPUTAMOUNTALL";
export const changeInputAmountAll = createAction(CHANGEINPUTAMOUNTALL, (change) => change);

const ONCLICKNAVBAR = "swap/ONCLICKNAVBAR";
export const onClickNavBar = createAction(ONCLICKNAVBAR, (input) => input);

const SETSWAPRESET = "swap/SETSWAPRESET";
export const setSwapReset = createAction(SETSWAPRESET, (input) => input);

const swap = handleActions(
    {
        [SETSWAPA]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.swapA.address = input.address;
                draft.swapA.symbol = input.symbol;
                draft.swapA.name = input.name;
                draft.swapA.decimal = input.decimal;
                // draft.swapA.inputAmount = input.inputAmount;
                draft.swapA.price = input.price;
                draft.swapA.image = input.image;
                draft.swapA.haveAmount = input.haveAmount;
            }),
        [SETSWAPB]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.swapB.address = input.address;
                draft.swapB.symbol = input.symbol;
                draft.swapB.name = input.name;
                draft.swapB.decimal = input.decimal;
                // draft.swapB.inputAmount = input.inputAmount;
                draft.swapB.image = input.image;
                draft.swapB.price = input.price;
                draft.swapB.haveAmount = input.haveAmount;
            }),
        [MAXSWAPA]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.swapA.inputAmount = input;
            }),
        [MAXSWAPB]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.swapB.inputAmount = input;
            }),
        [SETSWAPAHAVEAMOUNT]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.swapA.haveAmount = input;
            }),
        [SETSWAPBHAVEAMOUNT]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.swapB.haveAmount = input;
            }),
        [SETSWAPAINPUTAMOUNT]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.swapA.inputAmount = input
            }),
        [SETSWAPBINPUTAMOUNT]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.swapB.inputAmount = input
            }),
        [SETSWAPA_ALLOWANCE]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.swapA.token_allowance = input
            }),
        [CHANGESWAP]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.swapA.address = input.A.address;
                draft.swapA.symbol = input.A.symbol;
                draft.swapA.name = input.A.name;
                draft.swapA.decimal = input.A.decimal;
                draft.swapA.inputAmount = input.A.inputAmount;
                draft.swapA.price = input.A.price;
                draft.swapA.image = input.A.image;
                draft.swapA.haveAmount = input.A.haveAmount;

                draft.swapB.address = input.B.address;
                draft.swapB.symbol = input.B.symbol;
                draft.swapB.name = input.B.name;
                draft.swapB.decimal = input.B.decimal;
                draft.swapB.inputAmount = input.B.inputAmount;
                draft.swapB.price = input.B.price;
                draft.swapB.image = input.B.image;
                draft.swapB.haveAmount = input.B.haveAmount;
            }),
        [CHANGEINPUTAMOUNTALL]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.swapA.inputAmount = input;
                draft.swapB.inputAmount = input;
            }),
        [ONCLICKNAVBAR]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.swapA.address = input.body.address;
                draft.swapA.symbol = input.body.symbol;
                draft.swapA.name = input.body.name;
                draft.swapA.decimal = input.body.decimal;
                draft.swapA.inputAmount = input.body.inputAmount;
                draft.swapA.price = input.body.price;
                draft.swapA.image = input.body.image;
                draft.swapA.haveAmount = input.body.haveAmount;

                draft.swapB.address = input.reset.address;
                draft.swapB.symbol = input.reset.symbol;
                draft.swapB.name = input.reset.name;
                draft.swapB.decimal = input.reset.decimal;
                draft.swapB.inputAmount = input.reset.inputAmount;
                draft.swapB.price = input.reset.price;
                draft.swapB.image = input.reset.image;
                draft.swapB.haveAmount = input.reset.haveAmount;
            }),
        [SETSWAPRESET]: (state, { payload: input }) =>
            produce(state, (draft) => {
                draft.swapA.address = input.address;
                draft.swapA.symbol = input.symbol;
                draft.swapA.name = input.name;
                draft.swapA.decimal = input.decimal;
                draft.swapA.inputAmount = input.inputAmount;
                draft.swapA.price = input.price;
                draft.swapA.image = input.image;
                draft.swapA.haveAmount = input.haveAmount;

                draft.swapB.address = input.address;
                draft.swapB.symbol = input.symbol;
                draft.swapB.name = input.name;
                draft.swapB.decimal = input.decimal;
                draft.swapB.inputAmount = input.inputAmount;
                draft.swapB.image = input.image;
                draft.swapB.price = input.price;
                draft.swapB.haveAmount = input.haveAmount;
            })

    }, initialState
);

export default swap;