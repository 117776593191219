import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from '../assets/css/Error404.module.css';

const Error404 = ({ setUrlStatus }) => {

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/farm' || pathname === '/stake' || pathname === '/stakelist' || pathname === '/voting' || pathname === '/info' || pathname === '/' || pathname === '/swap' || pathname === '/liquidity' || pathname === '/add') {
      setUrlStatus(true)
    } else {
      setUrlStatus(false)
    }
  }, [pathname]);

  const onClickTitle = (pageName) => {
    const htmlTitle = document.querySelector("title");
    htmlTitle.innerHTML = pageName;
  }


  useEffect(()=>{
    const htmlTitle = document.querySelector("title");
    htmlTitle.innerHTML = "ERROR404 - Ivory Swap";
  },[])


  return (
    <div className={styles.bg}>
      <div className={styles.wrap}>
        <div className={styles.title}>404</div>
        <div className={styles.message}>Page Not Found</div>
        <Link to={"/"}  onClick={() => { 
                            onClickTitle("Ivory Swap");
                            }}>
          <button className={styles.homeButton}>
            <span className={styles.buttonSpan} >
              Back to Home
            </span>
            <span >
              <img src={require("../assets/images/swap/ico_arrow_bl.png")} />
            </span>
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Error404