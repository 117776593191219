import React, { useEffect, useState } from 'react'
import styles from '../assets/css/NoticeDetail.module.css';
import { Link, useParams } from 'react-router-dom';

import { noticeLists } from '../noticeFAQList/noticeList';
// url은 http://localhost:3000/notice/detail 임돠

const NoticeDetail = () => {

  const [title, setTitle] = useState("")
  const [postedDate, setPostedDate] = useState("")
  const [description, setDescription] = useState("")
  const { no } = useParams();
  const [listsLength, setListsLength] = useState("");

  const [prevTitle, setPreTitle] = useState("");
  const [nextTitle, setNextTitle] = useState("");

  const getNoticeDetail = () => {
    if (listsLength != "") {
      let num = Number(no) - 1;
      let getTitle = noticeLists[num].title;
      let getPostedDate = noticeLists[num].postedDate;
      let getDescription = noticeLists[num].description;

      setTitle(getTitle);
      setPostedDate(getPostedDate);
      setDescription(getDescription);
      setPreTitle(num == 0 ? "" : noticeLists[num - 1].title);
      setNextTitle(num + 1 == listsLength ? "" : noticeLists[num + 1].title)
    }
  }

  const getListsLength = () => {
    let listLength = noticeLists.length;
    setListsLength(listLength);
  }


  useEffect(() => {
    getNoticeDetail()
  }, [listsLength, no])

  useEffect(() => {
    getListsLength()
  }, []);

  useEffect(()=>{
    const htmlTitle = document.querySelector("title");
    htmlTitle.innerHTML = "Notice - Ivory Swap";
  },[])

  return (
    <div className={styles.bg}>
      <div className={styles.noticeViewWrap}>
        {/* tit { */}
        <div className={styles.noticeViewTit}>
          <div className={styles.mark}>NOTICE</div>
          <div className={styles.tit}>
            {title}
          </div>
          <div className={styles.date}>{postedDate}</div>
        </div>
        {/* } tit */}

        {/* cont { */}
        <div className={styles.noticeViewCont}>
          {description}
        </div>
        {/* } cont */}

        <div className={styles.prevNextWrap}>
          {
            prevTitle != ""
              ?
              <div className={`${styles.prevWrap} ${nextTitle == "" ? styles.full:""} ${prevTitle == "" ? styles.displayNone:""} `}>
                <button className={styles.btnPrev}>
                  <Link to={`${prevTitle == "" ? "" : `/notice/detail/${Number(no) - 1}`}`}>
                    Prev
                  </Link>
                </button>
                <div className={styles.contWrap}>
                  <p className={styles.tit}>NOTICE</p>
                  <p className={styles.cont}>
                    <Link to={`${prevTitle == "" ? "" : `/notice/detail/${Number(no) - 1}`}`}>
                      {prevTitle}
                    </Link>
                  </p>
                </div>
              </div>
              :""
          }
          {
            nextTitle != "" ?
              <div className={`${styles.nextWrap} ${prevTitle == "" ? styles.full:""} ${nextTitle == "" ? styles.displayNone:""} `}>
                <div className={styles.contWrap}>
                  <p className={styles.tit}>NOTICE</p>
                  <p className={styles.cont}>
                    <Link to={`${nextTitle == "" ? "" : `/notice/detail/${Number(no) + 1}`}`}>
                      {nextTitle}
                    </Link>
                  </p>
                </div>
                <button className={styles.btnNext}>
                  <Link to={`${nextTitle == "" ? "" : `/notice/detail/${Number(no) + 1}`}`}>Next
                  </Link>
                </button>
              </div>
              : ""
          }

        </div>

        <div className={styles.btnWrap}>
          <Link to={'/notice'}>
            <button className={styles.btnList}>
              List
            </button>
          </Link>
        </div>

      </div>
    </div>
  )
}

export default NoticeDetail;