import React from 'react'

const SwapBtn = ({ swapBtnMessage, swapBtnStatus, onClickSwap }) => {
    return (
        <div>
            <button disabled={swapBtnStatus} onClick={() => onClickSwap()}>{swapBtnMessage}</button>
        </div>
    )
}

export default SwapBtn