import React, { useEffect, useState } from 'react'
import ERC20JSON from "../abi/ERC20.json";
import Router from "../abi/Router.json";
import Factory from "../abi/Factory.json";
import Pair from "../abi/Pair.json";
import ERC20 from "../abi/ERC20.json";
import { BN } from 'bn.js';

const Info_Test = ({ providerWeb3, web3Provider, account, FACTORY_ADDRESS, ROUTER_ADDRESS, }) => {

  const [infoList, setInfoList] = useState([]);
  const [viewInfoList, setViewInfoList] = useState([]);
  const [totalPageNum, setTotalPageNum] = useState([]);
  const [nowPageNum, setNowPageNum] = useState(1);
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const [sortLiquidty, setSortLiquidity] = useState(true);

  const fromWeiBalance = (balance, decimal) => {
    let result = new BN(`${10}`).pow(new BN(`${decimal}`)).toString();
    let fromWeiBalance = new BN(`${balance}`).divmod(new BN(`${result}`));
    let divBalance = fromWeiBalance.div.toString();
    let modBalance = fromWeiBalance.mod.toString();
    let subDecimal = decimal - modBalance.length;
    let zero = "";
    for (let i = 0; i < subDecimal; i++) {
      zero += "0"
    }

    let returnBalance = divBalance + "." + zero + modBalance;
    return returnBalance;
  }

  const toWeiBalance = (balance, decimal) => {
    if (web3Provider != undefined) {
      let point = balance.split(".");
      let pointPow = 1;
      let powLength = 0;
      let lastPoint = "";
      if (point[1] != undefined) {
        powLength = point[1].length;
        pointPow = new BN(`${10}`).pow(new BN(`${point[1].length}`)).toString();
        lastPoint = point[1].substr(0, decimal);
      }

      let balancePoinPow = point[0] + lastPoint;

      let subDecimal = new BN(`${decimal}`).sub(new BN(`${powLength}`)).toString();

      let result = new BN(`${10}`).pow(new BN(`${subDecimal}`)).toString();
      let decimalBalance = new BN(`${balancePoinPow}`).mul(new BN(`${result}`)).toString();

      return decimalBalance;
    }
  }

  const getInfoList = async () => {

    if (web3Provider != undefined) {
      const web3 = web3Provider;

      let list = [];

      const factoryContract = new web3.eth.Contract(Factory, FACTORY_ADDRESS);

      const allPairsLength = await factoryContract.methods.allPairsLength().call();

      for (let i = 0; i < allPairsLength; i++) {
        const pairAddress = await factoryContract.methods.allPairs(i).call();
        const pairContract = new web3.eth.Contract(Pair, pairAddress);
        const token0Address = await pairContract.methods.token0().call();
        const token1Address = await pairContract.methods.token1().call();
        const getReserves = await pairContract.methods.getReserves().call();

        let price = "1"

        let balance0 = new BN(`${getReserves[0]}`).mul(new BN(`${price}`)).toString();
        let balance1 = new BN(`${getReserves[1]}`).mul(new BN(`${price}`)).toString();


        const token0Contract = new web3.eth.Contract(ERC20, token0Address);
        const token1Contract = new web3.eth.Contract(ERC20, token1Address);

        const token0Symbol = await token0Contract.methods.symbol().call();
        const token1Symbol = await token1Contract.methods.symbol().call();

        const token0Name = await token0Contract.methods.name().call();
        const token1Name = await token1Contract.methods.name().call();

        const token0Decimals = await token0Contract.methods.decimals().call();
        const token1Decimals = await token1Contract.methods.decimals().call();

        let token0GetReserves = fromWeiBalance(balance0, token0Decimals);
        let token1GetReserves = fromWeiBalance(balance1, token1Decimals);

        let liquidity = Number(token0GetReserves) + Number(token1GetReserves);

        let decimalBig = Number(token0Decimals) > Number(token1Decimals) ? token0Decimals : token1Decimals;



        let data = {
          pairAddress: pairAddress,
          token0Address: token0Address,
          token0Symbol: token0Symbol,
          token0Name: token0Name,
          token0GetReserves: token0GetReserves,
          token1Address: token1Address,
          token1Symbol: token1Symbol,
          token1Name: token1Name,
          token1GetReserves: token1GetReserves,
          liquidity: liquidity.toFixed(decimalBig)
        };


        list.push(data);
      }

      setInfoList(list);
    }

  };

  const onClickPageButton = (i) => {
    setNowPageNum(i + 1);
  }


  const pageButton = () => {
    const result = [];

    for (let i = 0; i < totalPageNum + 1; i++) {
      result.push(<div style={{ "display": "inline-block", "marginLeft": "25px", "color": `${nowPageNum == i + 1 ? 'blue' : 'black'}` }} key={i} onClick={() => onClickPageButton(i)}>{i + 1}</div>);
    }

    return result;
  }


  const viewPairList = (page, text) => {
    let result_1 = [];
    let toLowerCaseText = text.toLowerCase();
    for (let i = 0; i < infoList.length; i++) {

      // token0
      let token0SymboltoLowerCase = infoList[i].token0Symbol.toLowerCase();
      let token0NametoLowerCase = infoList[i].token0Name.toLowerCase();

      // token1
      let token1SymboltoLowerCase = infoList[i].token1Symbol.toLowerCase();
      let token1NametoLowerCase = infoList[i].token1Name.toLowerCase();

      if (token0SymboltoLowerCase.includes(toLowerCaseText)) {
        result_1.push(infoList[i]);
      }
      else if (token0NametoLowerCase.includes(toLowerCaseText)) {
        result_1.push(infoList[i]);
      } else if (token1SymboltoLowerCase.includes(toLowerCaseText)) {
        result_1.push(infoList[i]);
      } else if (token1NametoLowerCase.includes(toLowerCaseText)) {
        result_1.push(infoList[i]);
      } else if (toLowerCaseText == "") {
        result_1.push(infoList[i]);
      }

    }


    let result_2 = [];
    
    result_1.sort((a,b)=>{
      let sortLiquidtyQ1 = a.liquidity - b.liquidity;
      let sortLiquidtyQ2 = b.liquidity - a.liquidity ;

      if(sortLiquidty){
        return sortLiquidtyQ2
      }else{
        return sortLiquidtyQ1
      }

    });

    result_2 = result_1;
    
    
    let result_3 = [];
    for (let i = (limit * page) - limit; i < (limit * page); i++) {
      if (result_2[i] == undefined) { break; }
      result_3.push(result_1[i]);
    }

    let totalPageNum = Math.floor(result_1.length / limit);
    setTotalPageNum(totalPageNum);
    setLoading(false)
    setViewInfoList(result_3);

  };

  const onClickSortByLiquidity = () => {
    setSortLiquidity(!sortLiquidty)
  }

  const onErrorImg = (e) => {
    e.target.src = "logo_.png";
  }

  const balanceInCode = (balance) => {
    if (balance == undefined || balance.length == undefined || balance == "-" || balance == "" || Number(balance) == 0) {
      return 0;
    } else {
      let splitBalance = balance.split(".");

      let backBalance = "";
      let afterVal = "";
      if (splitBalance.length == 2) {
        let q1 = splitBalance[1].length - (splitBalance[1].search(/[1-9]/) + 1);
        let nextVal;
        for (let i = 0; i < q1; i++) {
          nextVal = splitBalance[1].substr(splitBalance[1].search(/[1-9]/) + (i + 1), 1);
          if (nextVal != "0") {
            afterVal = afterVal + `${nextVal}`;
            break
          } else {
            afterVal = afterVal + "0";
          };
          if (i == (q1 - 1)) {

            nextVal = "";
          };
        }
        if (afterVal.search(/[1-9]/) == -1) {
          afterVal = "";
        }
        backBalance = splitBalance[1].substr(0, splitBalance[1].search(/[1-9]/) + 1) != "" ? "." + splitBalance[1].substr(0, splitBalance[1].search(/[1-9]/) + 1) + afterVal : "";
      }


      return splitBalance[0] + backBalance;
    }
  }

  const onChangeSearchText = (e) => {
    setSearchText(e.target.value);
    setNowPageNum(1);
  }

  const onClickPrevious = () => {
    if (Number(nowPageNum - 1) != 0) {
      setNowPageNum(nowPageNum - 1);
    }
  }

  const onClickNext = () => {
    if (Number(nowPageNum + 1) != totalPageNum) {
      setNowPageNum(nowPageNum + 1);
    }
  }

  useEffect(() => {
    if (infoList != undefined) {
      viewPairList(nowPageNum, searchText);
    }
  }, [nowPageNum, infoList, searchText,sortLiquidty])

  useEffect(() => {
    getInfoList();
  }, [providerWeb3, web3Provider, FACTORY_ADDRESS, ROUTER_ADDRESS]);




  return (
    <div style={{ "marginTop": "130px" }}>
      <input value={searchText} onChange={(e) => { onChangeSearchText(e) }} />
      {loading ? "" :
        <div>
          <div onClick={()=>{onClickSortByLiquidity();}}>LIQUIDITY {sortLiquidty?"↓":"↑"}</div>
          <div>
            {
              viewInfoList == "" ? "" :
                <>
                  {viewInfoList.map((item, index) => (
                    <div key={index} style={{ "borderBottom": "1px solid black" }}>
                      <div style={{ "display": "inline-block", "marginLeft": "30px" }}>
                        <img src={`logo_${item.token0Address}.png`} onError={onErrorImg} style={{ "width": "35px" }} />
                        <img src={`logo_${item.token1Address}.png`} onError={onErrorImg} style={{ "width": "35px" }} />
                      </div>
                      <div style={{ "display": "inline-block", "marginLeft": "30px" }}>
                        <span>{item.token0Symbol}</span>/
                        <span>{item.token1Symbol}</span>
                      </div>
                      <div style={{ "display": "inline-block", "marginLeft": "30px" }}>
                        <div>{balanceInCode(item.liquidity)}</div>
                      </div>
                    </div>
                  ))}
                  <div style={{ "marginTop": "30px" }}>
                    <div style={{ "display": "inline-block", "marginLeft": "30px" }} onClick={() => { onClickPrevious() }}>&#60;</div>
                    {pageButton()}
                    <div style={{ "display": "inline-block", "marginLeft": "30px" }} onClick={() => { onClickNext() }}>&#62;</div>
                  </div>
                </>
            }
          </div>
        </div>
      }
    </div>
  )
}

export default Info_Test;