import { createAction, handleAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { put, takeEvery } from "redux-saga/effects";
import Web3 from 'web3';

import ERC20JSON from "../abi/ERC20.json";

const initialState = {
    tokenA: {
        address: "",
        symbol: "",
        name: "",
        decimal: 0,
        haveAmount: 0,
        inputAmount: "",
        price: 0,
        image: "",
        token_allowance: true
    },
    tokenB: {
        address: "",
        symbol: "",
        name: "",
        decimal: 0,
        haveAmount: 0,
        inputAmount: "",
        price: 0,
        image: "",
        token_allowance: true
    },
    tokenList: []

};


const SETTOKENLIST = "liquidity/SETTOKENLIST";
export const setTokenList = createAction(SETTOKENLIST, (tokeList) => tokeList);

const SETLIST = "liquidity/SETLIST";
export const setList = createAction(SETLIST, (test) => test);

//test

const SETTOKENBALANCE = "liquidity/SETTOKENBALANCE";
export const setTokenBalance = createAction(SETTOKENBALANCE, (input) => input);


// tokenA
const SETTOKENA = "liquidity/SETTOKENA";
export const setTokenA = createAction(SETTOKENA, (tokenA) => tokenA);

const SETTOKENA_INPUTAMOUNT = "liquidity/SETTOKENA_INPUTAMOUNT";
export const setTokenA_InputAmount = createAction(SETTOKENA_INPUTAMOUNT, (inputA) => inputA);

const SETTOKENA_ALLOWANCE = "liquidity/SETTOKENA_ALLOWANCE";
export const setTokenA_Allowance = createAction(SETTOKENA_ALLOWANCE, (input) => input);

const SETTOKENA_HAVEAMOUNT = "liquidity/SETTOKENA_HAVEAMOUNT";
export const setTokenA_HaveAmount = createAction(SETTOKENA_HAVEAMOUNT, (input) => input);

// tokenB
const SETTOKENB = "liquidity/SETTOKENB";
export const setTokenB = createAction(SETTOKENB, (tokenB) => tokenB);

const SETTOKENB_INPUTAMOUNT = "liquidity/SETTOKENB_INPUTAMOUNT";
export const setTokenB_InputAmount = createAction(SETTOKENB_INPUTAMOUNT, (inputB) => inputB);

const SETTOKENB_ALLOWANCE = "liquidity/SETTOKENB_ALLOWANCE";
export const setTokenB_Allowance = createAction(SETTOKENB_ALLOWANCE, (input) => input);

const SETTOKENB_HAVEAMOUNT = "liquidity/SETTOKENB_HAVEAMOUNT";
export const setTokenB_HaveAmount = createAction(SETTOKENB_HAVEAMOUNT, (input) => input);

const SETLIQUIDITY = "liquidity/SETLIQUIDITY";
export const setLiquidity = createAction(SETLIQUIDITY,(input)=>input);

const liquidity = handleActions({
    [SETLIST]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.tokenList = (input);
        }),
    [SETTOKENBALANCE]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.tokenList[input.i].haveAmount = (input.haveAmount);
        }),
    [SETTOKENLIST]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.tokenList = input;
        }),
    [SETTOKENA]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.tokenA.address = input.address;
            draft.tokenA.symbol = input.symbol;
            draft.tokenA.name = input.name;
            draft.tokenA.decimal = input.decimal;
            draft.tokenA.inputAmount = input.inputAmount;
            draft.tokenA.image = input.image;
            draft.tokenA.price = input.price;
            draft.tokenA.haveAmount = input.haveAmount;
        }),
    [SETTOKENB]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.tokenB.address = input.address;
            draft.tokenB.symbol = input.symbol;
            draft.tokenB.name = input.name;
            draft.tokenB.decimal = input.decimal;
            draft.tokenB.inputAmount = input.inputAmount;
            draft.tokenB.image = input.image;
            draft.tokenB.price = input.price;
            draft.tokenB.haveAmount = input.haveAmount;
        }),
    [SETTOKENA_INPUTAMOUNT]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.tokenA.inputAmount = input;
        }),
    [SETTOKENB_INPUTAMOUNT]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.tokenB.inputAmount = input;
        }),
    [SETTOKENA_ALLOWANCE]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.tokenA.token_allowance = input;
        }),
    [SETTOKENB_ALLOWANCE]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.tokenB.token_allowance = input;
        }),
    [SETTOKENA_HAVEAMOUNT]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.tokenA.haveAmount = input;
        }),
    [SETTOKENB_HAVEAMOUNT]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.tokenB.haveAmount = input;
        }),
    [SETLIQUIDITY] : (state, {payload : input}) => 
        produce(state,(draft) => {
            draft.tokenA.address = input.setA.address;
            draft.tokenA.symbol = input.setA.symbol;
            draft.tokenA.name = input.setA.name;
            draft.tokenA.decimal = input.setA.decimal;
            draft.tokenA.inputAmount = input.setA.inputAmount;
            draft.tokenA.image = input.setA.image;
            draft.tokenA.haveAmount = input.setA.haveAmount;

            draft.tokenB.address = input.setB.address;
            draft.tokenB.symbol = input.setB.symbol;
            draft.tokenB.name = input.setB.name;
            draft.tokenB.decimal = input.setB.decimal;
            draft.tokenB.inputAmount = input.setB.inputAmount;
            draft.tokenB.image = input.setB.image;
            draft.tokenB.haveAmount = input.setB.haveAmount;
        })
   
}, initialState);

export default liquidity;