import React, { useEffect, useState } from "react";
import Web3 from "web3";
import styles from "../assets/css/Stake.module.css";
import { MaxUint256 } from '@ethersproject/constants';
import BN from 'bn.js';

import IVYJSON from "../abi/Ivy.json"
import MirrorWorld from "../abi/MirrorWorld.json"
import StakeList from "./StakeList";
import { Link } from "react-router-dom";

const Stake = ({ web3Provider, IVY_ADDRESS, account, chainId, sIVY_ADDRESS, handleConnect }) => {

    const [{ sivyBalance, ivyBalance }, setTokenBalance] = useState("");
    const [{ usersIVYBalacne, userIVYBalance }, setUserBalance] = useState("");
    const [btnMessage, setBtnMessage] = useState("Approve sIvy");
    const [inputAmount, setInputAmount] = useState("");
    const [approveStatus, setApproveStatus] = useState(false);
    const [confirmStatus, setConfirmStatus] = useState(false);

    const [popupShow, setPopupShow] = useState(false);
    const [popupType, setPopupType] = useState('');

    const [stakeType, setStakeType] = useState("");
    const [tvlValue, setTvlValue] = useState("");

    const fromWeiBalance = (balance, decimal) => {
        let result = new BN(`${10}`).pow(new BN(`${decimal}`)).toString();
        let fromWeiBalance = new BN(`${balance}`).divmod(new BN(`${result}`));
        let divBalance = fromWeiBalance.div.toString();
        let modBalance = fromWeiBalance.mod.toString();
        let subDecimal = decimal - modBalance.length;
        let zero = "";
        for (let i = 0; i < subDecimal; i++) {
            zero += "0"
        }

        let returnBalance = divBalance + "." + zero + modBalance;
        return returnBalance;
    }

    const toWeiBalance = (balance, decimal) => {
        if (web3Provider != undefined) {
            let point = balance.split(".");
            let pointPow = 1;
            let powLength = 0;
            let lastPoint = "";
            if (point[1] != undefined) {
                powLength = point[1].length;
                pointPow = new BN(`${10}`).pow(new BN(`${point[1].length}`)).toString();
                lastPoint = point[1].substr(0, decimal);
            }

            let balancePoinPow = point[0] + lastPoint;

            let subDecimal = new BN(`${decimal}`).sub(new BN(`${powLength}`)).toString();

            let result = new BN(`${10}`).pow(new BN(`${subDecimal}`)).toString();
            let decimalBalance = new BN(`${balancePoinPow}`).mul(new BN(`${result}`)).toString();

            return decimalBalance;
        }
    }



    useEffect(() => {
        setBalance();
    }, [web3Provider, account]);

    const setBalance = async () => {
        if (web3Provider != undefined && account != "") {
            const web3 = new Web3(web3Provider);
            const sIvyContract = new web3.eth.Contract(MirrorWorld, sIVY_ADDRESS);
            const IvyContract = new web3.eth.Contract(IVYJSON, IVY_ADDRESS);
            let balance = "1";
            let IVYForsIVY = await sIvyContract.methods.IVYForsIVY(web3.utils.toWei(balance)).call();
            setTokenBalance({
                sivyBalance: balance,
                ivyBalance: web3.utils.fromWei(IVYForsIVY),
            });
            let IvyBalance = await IvyContract.methods.balanceOf(account).call();
            let sIvyBalance = await sIvyContract.methods.balanceOf(account).call();
            setUserBalance({
                usersIVYBalacne: web3.utils.fromWei(sIvyBalance),
                userIVYBalance: web3.utils.fromWei(IvyBalance),
            })
        }else if(account==""){
            setUserBalance({
                usersIVYBalacne: "0",
                userIVYBalance: "0",
            })

        }
    }

    const onClickApprove = async () => {
        if (web3Provider != undefined) {
            const web3 = new Web3(web3Provider);
            const IvyContract = new web3.eth.Contract(IVYJSON, IVY_ADDRESS);
            setBtnMessage("Approving");
            try {
                const approve = await IvyContract.methods.approve(sIVY_ADDRESS, MaxUint256.toString()).send({
                    // const approve = await IvyContract.methods.approve(tIVY_ADDRESS,"0").send({
                    from: account
                });
                if (approve.status == true) {
                    setApproveStatus(true);
                }
            } catch (error) {
                setBtnMessage("Approve sivy");
            }
        }
    }

    const checkAllowance = async () => {
        if (web3Provider != undefined && account != "") {
            const web3 = new Web3(web3Provider);
            const IvyContract = new web3.eth.Contract(IVYJSON, IVY_ADDRESS);
            const allowance = await IvyContract.methods.allowance(account, sIVY_ADDRESS).call();
            if (allowance != "0") {
                setApproveStatus(true);
            }
        }
    }

    const onClickStake = async () => {
        if (web3Provider != undefined && account != "" && confirmStatus) {
            const web3 = new Web3(web3Provider);
            const sIvyContract = new web3.eth.Contract(MirrorWorld, sIVY_ADDRESS);
            let input = web3.utils.toWei(inputAmount);
            setPopupType("confirm")
            try {
                const enter = await sIvyContract.methods.enter(input).send({ from: account });
                if (enter.status) {
                    let sIvyAmount = new BN(`${web3.utils.toWei(usersIVYBalacne)}`).add(new BN(`${input}`));
                    let IvyAmount = new BN(`${web3.utils.toWei(userIVYBalance)}`).sub(new BN(`${input}`));
                    setUserBalance({
                        usersIVYBalacne: web3.utils.fromWei(sIvyAmount),
                        userIVYBalance: web3.utils.fromWei(IvyAmount)
                    })
                    setPopupType("submit")
                } else {
                    setPopupType("Failed")
                }
            } catch (error) {
                setPopupType("stake")
            }
        }
    }

    const onClickUnStake = async () => {
        if (web3Provider != undefined && account != "" && confirmStatus) {
            const web3 = new Web3(web3Provider);
            const sIvyContract = new web3.eth.Contract(MirrorWorld, sIVY_ADDRESS);
            let input = web3.utils.toWei(inputAmount)
            setPopupType("confirm")
            try {
                const leave = await sIvyContract.methods.leave(input).send({ from: account });
                if (leave.status) {
                    let sIvyAmount = new BN(`${web3.utils.toWei(usersIVYBalacne)}`).sub(new BN(`${input}`));
                    let IvyAmount = new BN(`${web3.utils.toWei(userIVYBalance)}`).add(new BN(`${input}`));
                    setUserBalance({
                        usersIVYBalacne: web3.utils.fromWei(sIvyAmount),
                        userIVYBalance: web3.utils.fromWei(IvyAmount)
                    })
                    setPopupType("submit")
                } else {
                    setPopupType("Failed")
                }
            } catch (error) {
                setPopupType("stake")
            }
        }
    }

    useEffect(() => {
        checkAllowance();
    }, [web3Provider, account]);

    const setTVL = async () => {
        const web3 = new Web3(web3Provider);
        const ivyContract = new web3.eth.Contract(IVYJSON, IVY_ADDRESS);
        const balance = await ivyContract.methods.balanceOf(sIVY_ADDRESS).call();
        let fromWeiTVLBalance = fromWeiBalance(balance, "18");
        // 나중에 IVY 가격 곱하기
        setTvlValue(fromWeiTVLBalance);
    }


    useEffect(() => {
        if (web3Provider != undefined) {
            setTVL();
        }
    }, [sIVY_ADDRESS, web3Provider, IVY_ADDRESS, popupType])



    const closePopup = () => {
        setPopupShow(false);
        setInputAmount("");
        setConfirmStatus(false);
        setPopupType('');
    }

    const onClickBtn = (type, txt) => {
        setPopupShow(true);
        setPopupType(type);
        setStakeType(txt)
    }

    const balanceInCode = (balance) => {
        if (balance == undefined || balance.length == undefined  || balance == "" || Number(balance) == 0) {
          return 0;
        } else if(balance == "-"){
          return "-";
        }else {
          let splitBalance = balance.split(".");
    
          let backBalance = ""
          let afterVal = ""
          if (splitBalance.length == 2) {
            let q1 = splitBalance[1].length - (splitBalance[1].search(/[1-9]/) + 1);
            let nextVal;
            for (let i = 0; i < q1; i++) {
              nextVal = splitBalance[1].substr(splitBalance[1].search(/[1-9]/) + (i + 1), 1);
              if (nextVal != "0") {
                afterVal = afterVal + `${nextVal}`
                break
              } else {
                afterVal = afterVal + "0"
              }
              if (i == (q1 - 1)) {
    
                nextVal = "";
              }
            }
            if (afterVal.search(/[1-9]/) == -1) {
              afterVal = ""
            }
            backBalance = splitBalance[1].substr(0, splitBalance[1].search(/[1-9]/) + 1) != "" ? "." + splitBalance[1].substr(0, splitBalance[1].search(/[1-9]/) + 1) + afterVal : ""
          }
    
    
          return splitBalance[0] + backBalance
          // let balanceFixed = Number(balance).toFixed(4);
          // let returnBalance = (Number(balanceFixed)*10000)/10000;
          // return returnBalance;
        }
    
      }

    const onChangeInputValue = (e) => {
        let inputAmount = e.target.value;
        let pattern = /^[0-9]*[.,]?[0-9]*$/;
        let balance;

        if (stakeType === "Stake") {
            balance = userIVYBalance
        } else if (stakeType === "UnStake") {
            balance = usersIVYBalacne
        }

        if (pattern.test(inputAmount) == true) {
            setInputAmount(e.target.value);
            let bool;

            let BNResult = new BN(`${toWeiBalance(e.target.value, "18")}`).lte(new BN(`${toWeiBalance(balance, "18")}`))
            let token = {
                inputAmount:inputAmount,
                decimal:18
            }
            if (e.target.value == "") {
                bool = false
            } else if (e.target.value != "0" && BNResult && Number(e.target.value) > 0) {
                bool = checkInputAmountDecimal(token);
            }
            setConfirmStatus(bool)
        }
    }

    const onClickMaxButton = (balance) => {
        setInputAmount(balance);
        if (balance != "0") setConfirmStatus(true)
    }

    const toLocaleStringOption = (amount) => {
        const parts = amount.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }


    const checkInputAmountDecimal = (token) => {
        // try {
        if (token.inputAmount != undefined || token.inputAmount != "") {
            let index = token.inputAmount.indexOf(".");
            if (index != -1) {
                let decimalLength = token.inputAmount.substr((index + 1), token.inputAmount.length);
                if (decimalLength.length <= token.decimal) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }
    }

    return (
        <div>
            <div className={styles.bg}>
                <div className={styles.wrap}>
                    <div className={styles.title}>
                        Stake
                        <div className={styles.goToButton}>
                            <Link to={"/stakeList"}>
                                <button>
                                    View List
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.stakeExp}>
                        Stake your IVY Tokens here to earn!
                        <img src={require("../assets/images/img_tip_wh@2x.png")} alt={"img_tip_wh"} />
                    </div>
                    <div className={styles.contentsWrap}>
                        <div className={styles.title}>
                            About IVY token and sIVY token
                        </div>
                        <div className={styles.contents}>
                            <div className={styles.item1}>
                                <img src={require("../assets/images/stake/img_stake.png")} alt={"img_stake"} />
                            </div>
                            <div className={styles.item2}>
                                <div className={styles.text}>
                                    Like liquidity providing (LP), you will earn fees according to your share in the pool, and your
                                    sIVY receipt is needed as proof when claiming the rewards.
                                </div>
                                <div className={styles.tokenValue}>
                                    <div className={styles.tivyToken}>
                                        <div>{balanceInCode(sivyBalance)}</div>
                                        <span className={styles.medium}>sIVY</span> <span>token</span>
                                    </div>
                                    <div className={styles.ikor}>=</div>
                                    <div className={styles.ivyToken}>
                                        <div>{balanceInCode(ivyBalance)}</div>
                                        <span className={styles.medium}>IVY</span> <span>token</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.item3}>
                                <div className={styles.flex}>
                                    <div>Claimable Ivy Token</div>
                                    <div>$ {toLocaleStringOption(balanceInCode(userIVYBalance))}</div>
                                </div>
                                <div className={styles.flex}>
                                    <div>TVL</div>
                                    <div>$ {toLocaleStringOption(balanceInCode(tvlValue))}</div>
                                </div>
                                <div className={styles.flex}>
                                    <div>APR</div>
                                    <div>0</div>
                                </div>
                                <div className={styles.flex}>
                                    {
                                        account != "" ?
                                            <>
                                                {approveStatus ?
                                                    <>
                                                        {usersIVYBalacne == "0" ?
                                                            <button onClick={() => { onClickBtn("stake", "Stake") }}>Stake sIvy</button>
                                                            :
                                                            <>
                                                                <button className={styles.stakeBtn} onClick={() => { onClickBtn("stake", "Stake") }}>Stake</button>
                                                                <button className={styles.unStakeBtn} onClick={() => { onClickBtn("stake", "UnStake") }}>UnStake</button>
                                                            </>

                                                        }
                                                    </>
                                                    :
                                                    <button onClick={() => onClickApprove()}>{btnMessage}</button>
                                                }
                                            </> : <button onClick={handleConnect}>Connect Wallet</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    popupShow &&
                    <div className={styles.popupWrap}>
                        <div className={styles.popupDim} onClick={closePopup} />
                        {
                            popupType === 'stake' ?
                                <div className={`${styles.popup_stakeLP} ${styles.popupContainer}`}>
                                    <img className={styles.closeBtn} onClick={closePopup} src={require("../assets/images/ico_close_bl@2x.png")} alt={"ico_close_bl"} />
                                    <div className={styles.title}>{stakeType}</div>
                                    <div className={styles.tokenName_balance}>
                                        <div className={styles.tokenName}>{stakeType === "Stake" ? "IVY" : "sIVY"}</div>
                                        <div className={styles.balance}>Balance:
                                            {toLocaleStringOption(balanceInCode(`${stakeType === "Stake" ? userIVYBalance : usersIVYBalacne}`))}
                                        </div>
                                    </div>
                                    <div className={styles.inputWrap}>
                                        <input type={"number"} placeholder={0.0} value={inputAmount === "" ? inputAmount : inputAmount} onChange={(e) => { onChangeInputValue(e) }} min={0.000000000000000001} />
                                        <button onClick={() => { onClickMaxButton(`${stakeType === "Stake" ? userIVYBalance : usersIVYBalacne}`) }}>MAX</button>
                                        {Number(`${stakeType === "Stake" ? userIVYBalance : usersIVYBalacne}`) === 0 ?
                                            <div className={styles.warning}>No Tokens to stake!</div>
                                            :
                                            ""
                                        }
                                    </div>
                                    <div className={styles.btnBox}>
                                        <button className={styles.cancel} onClick={closePopup} >Cancel</button>
                                        <button className={`${styles.confirm} ${confirmStatus ? styles.btnActive : ""}`}
                                            onClick={() => {
                                                if (stakeType === "Stake") {
                                                    onClickStake()
                                                } else if (stakeType === "UnStake") {
                                                    onClickUnStake()
                                                }
                                            }}
                                        >Confirm</button>
                                    </div>
                                    {/* <div className={styles.getTokenBtn}
                                    onClick={() => onClickAddLiquidityBtn(chooseFarm.token, chooseFarm.quoteToken)}
                                >
                                    <Link to={"/add"} >
                                        Get TokenA-TokenB LP
                                    </Link>
                                </div> */}
                                </div>
                                : popupType === 'confirm' ?
                                    <div className={`${styles.popup_confirm} ${styles.popupContainer}`}>
                                        <img className={styles.closeBtn} onClick={closePopup} src={require("../assets/images/ico_close_bl@2x.png")} alt={"ico_close_bl"} />
                                        <div><img src={require("../assets/images/swap/loading.gif")} alt={"loading_gif"} /></div>
                                        <div className={styles.bold}>Waiting for confirmation</div>
                                        <div className={styles.normal}>Confirm this transaction in your wallet</div>
                                        <div className={styles.gray}>{stakeType} {toLocaleStringOption(balanceInCode(inputAmount))} {stakeType === "Stake" ? "IVY" : "sIVY"}</div>
                                    </div>

                                    : popupType === 'submit' ?
                                        <div className={`${styles.popup_confirm} ${styles.popupContainer}`}>
                                            <img className={styles.closeBtn} onClick={closePopup} src={require("../assets/images/ico_close_bl@2x.png")} alt={"ico_close_bl"} />
                                            <div><img src={require("../assets/images/swap/ico_confirmed.png")} alt={"ico_confirmed"} /></div>
                                            <div className={styles.bold}>Transaction Submitted</div>
                                            {/* <div className={styles.normal}>Confirm this transaction in your wallet</div> */}
                                        </div>
                                        : popupType === 'Failed' ?
                                            <div className={`${styles.popup_confirm} ${styles.popupContainer}`}>
                                                <img className={styles.closeBtn} onClick={closePopup} src={require("../assets/images/ico_close_bl@2x.png")} alt={"ico_close_bl"} />
                                                <div><img src={require("../assets/images/ico_rejected.png")} alt={"ico-rejected"} /></div>
                                                <div className={styles.bold}>Transaction Rejected</div>
                                                {/* <div className={styles.normal}>Confirm this transaction in your wallet</div> */}
                                            </div>
                                            : ''
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default Stake;