export const FarmsMain = [
    {
        pid: "",
        lpSymbol: "",
        lpAddress: {
            8898: "",
            88998: "",
        },
        token: {
            8898: "",
            88998: "",
        },
        quoteToken: {
            8898: "",
            88998: "",
        }
    },
]