import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from '../assets/css/ComingSoon.module.css';

const ComingSoon = ({ setUrlStatus }) => {

  const { pathname } = useLocation();

  useEffect(() => {
    // if (pathname === '/farm' || pathname === '/stake' || pathname === '/stakelist' || pathname === '/voting' || pathname === '/info') {
    // setUrlStatus(true)
    // } else {
    //     setUrlStatus("visible")
    // }
  }, [pathname])

  const onClickTitle = (pageName) => {
    const htmlTitle = document.querySelector("title");
    htmlTitle.innerHTML = pageName;
  }

  useEffect(() => {
    const htmlTitle = document.querySelector("title");
    htmlTitle.innerHTML = "Coming Soon - Ivory Swap";
  }, [])

  return (
    <div className={styles.bg}>
      <div className={styles.wrap}>
        <div className={styles.title}>Coming Soon...</div>
        <Link to={"/"}
          onClick={() => {
            onClickTitle("Ivory Swap");
          }}>
          <button className={styles.homeButton}>
            <span className={styles.buttonSpan} >Back to Home</span>
            <span>
              <img src={require("../assets/images/swap/ico_arrow_bl.png")} />
            </span>
          </button>
        </Link>
        <div className={`${styles.imgSoon}`}>
          <img src={require("../assets/images/img_soon.png")} />
        </div>
      </div>
    </div>
  )
}

export default ComingSoon