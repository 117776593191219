export const FAQLists = [
    {
        Q: "How can I bid for your products?1",
        A: "Our products help you to attain the perfect beauty you can ever imagine.Our products help you to attain the perfect beauty you can ever imagine."
    },
    {
        Q: "How can I bid for your products?2",
        A: "Our products help you to attain the perfect beauty you can ever imagine."
    },
    {
        Q: "How can I bid for your products?3",
        A: "Our products help you to attain the perfect beauty you can ever imagine."
    },
]