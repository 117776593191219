import React from 'react';
import '../assets/css/Footer.css';

const Footer = () => {
    return (
        <div className={"footer"}>
            <div className={"footerInner"}>
                {/* <div className={"footerNav"}>
                    <div className={"about"}>
                        <p>About</p>
                        <span>Contact</span>
                        <span>Brand</span>
                        <span>Blog</span>
                        <span>Community</span>
                        <span>IVY Token</span>
                    </div>
                    <div className={"help"}>
                        <p>Help</p>
                        <span>Support</span>
                        <span>Troubleshooting</span>
                        <span>Guides</span>
                    </div>
                    <div className={"developers"}>
                        <p>Developers</p>
                        <span>Github</span>
                        <span>Docs</span>
                        <span>Audits</span>
                        <span>Careers</span>
                    </div>
                    <div className={"footerLogo"}>
                        <img className={"mountain"} src={require("../assets/images/logo_footer@2x.png")} alt={"logo_footer"} />
                    </div>
                </div>
                <div className={"footerSns"}>
                    <span>
                        <img src={require("../assets/images/ico_insta@2x.png")} alt={"ico_insta"} />
                    </span>
                    <span>
                        <img src={require("../assets/images/ico_twit@2x.png")} alt={"ico_twit"} />
                    </span>
                    <span>
                        <img src={require("../assets/images/ico_telegram@2x.png")} alt={"ico_telegram"} />
                    </span>
                    <span>
                        <img src={require("../assets/images/ico_reddit@2x.png")} alt={"ico_reddit"} />
                    </span>
                    <span>
                        <img src={require("../assets/images/ico_discord@2x.png")} alt={"ico_discord"} />
                    </span>
                </div> */}
                <div className={"copyright"}>
                    Ivoryswap All Rights Reserved.
                </div>
            </div>
        </div>
    )
}

export default Footer;