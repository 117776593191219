import { createAction, handleAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { put, takeEvery } from "redux-saga/effects";


const initialState = {
    farms: [],
    allFarms: [],
}

const SETFARMS = "farms/SETFARMS";
export const setFarms = createAction(SETFARMS, (item) => item);

const SETALLFARMS = "farms/SETALLFARMS";
export const setAllFarms = createAction(SETALLFARMS, (item) => item);

const SETALLOWANCE = "farm/SETALLOWANCE";
export const setAllowance = createAction(SETALLOWANCE, (item) => item);

const SETPENDINGIVY = "farm/SETPENDINGIVY";
export const setPendingIvys = createAction(SETPENDINGIVY, (item) => item);


const farm = handleActions({
    [SETFARMS]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.farms = input
        }),
    [SETALLFARMS]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.allFarms = input
        }),
    [SETALLOWANCE]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.farms[input.index].allowance = input.result
            draft.allFarms[input.index].allowance = input.result
        }),
    [SETPENDINGIVY]: (state, { payload: input }) =>
        produce(state, (draft) => {
            for (let i = 0; i < input.length; i++) {
                let returnIndex = draft.farms.findIndex((item) =>
                    item.pid == input[i].index
                );
                if (returnIndex != -1) {
                    // draft.farms[returnIndex].pendingIvy = input[i].ivyPending
                    draft.allFarms[input[i].index].pendingIvy = input[i].ivyPending
                }
            }
        })
}, initialState);

export default farm;