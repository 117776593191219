import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components"


const TokenListDiv = styled.div`
cursor:pointer;
&:hover{
    background-color:gray;
}
`


const TokenList = ({ tokenList, onClickAddToken, testFunction }) => {
    return (
        <div>
            {
                tokenList.map((item, index) => (
                    <TokenListDiv key={index} onClick={() => onClickAddToken(item)}>
                        {item.symbol}/
                        {item.name}/
                        {item.haveAmount}
                    </TokenListDiv>
                ))
            }
        </div>
    );
};

export default TokenList;
