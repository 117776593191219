import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "../assets/css/NavBar.module.css";
import stylesHeader from "../assets/css/Header.module.css";
import { onClickNavBar, setSwapTokenA, setSwapTokenB } from "../modules/swap";
import { useDispatch, useSelector } from "react-redux";

const NavBar = ({
  onClickChangeNetWork,
  setNetWorkListStatus,
  netWorkListStatus,
  setSiteListStatus,
  siteListStatus,
  toastPopup,
  connectCheck,
  defaultChainId,
  chainId,
  netWorkMessage,
  setUrlStatus,
  networkName,
  onChangeHeaderNetWork,
  changeNetWorkStatus,
  setChangeNetWorkStatus,
  web3Provider,
  account,
  toggleMobileMenu,
  mobileHeaderPopup
}) => {

  const siteType = process.env.REACT_APP_SITE_TYPE;
  const dispatch = useDispatch();

  const [comingPopup, setComingPopup] = useState(false);

  // const setSwapToken = async () => {
  //     toggleMobileMenu();
  // }
  const [selectFlip, setSelectFlip] = useState(false);
  const [languageList, setLanguageList] = useState([
    { name: "EN" },
    { name: "CH" },
    { name: "KR" },
  ]);
  const [selectedItem, setSelectedItem] = useState(languageList[0].name);

  const [selectFlipNetWork, setSelectFlipNetWork] = useState(false);
  const [testNetworkList, setTestNetworkList] = useState([
    {
      name: "TMMT",
      path: "https://ivoryswap.com/",
      backgroundImage: "sym_mmt.png",
      chainId:"88998",
      rpc : "https://data-seed-premmt-1.mmtscan.io/"
    },
    {
      name: "Goerli",
      path: "https://eth.ivoryswap.com/",
      backgroundImage: "logo_eth@2x.png",
      chainId:"5",
      rpc : "https://rpc.ankr.com/eth_goerli"
    },
    {
      name: "TGMMT",
      path: "https://gmmt.ivoryswap.com/",
      backgroundImage: "logo_gmmt@2x.png",
      chainId:"898989",
      rpc : "https://testnet-rpc.gmmtchain.io"
    },
  ]);
  const [mainNetworkList, setMainNetworkList] = useState([
    {
      name: "MMT",
      path: "https://ivoryswap.com/",
      backgroundImage: "sym_mmt.png",
      chainId:"8898",
      rpc : "https://dataseed.mmtscan.io/"
    },
    {
      name: "ETH",
      path: "https://eth.ivoryswap.com/",
      backgroundImage: "logo_eth@2x.png",
      chainId:"1",
      rpc : "https://eth.llamarpc.com"
    },
    {
      name: "GMMT",
      path: "https://gmmt.ivoryswap.com/",
      backgroundImage: "logo_gmmt@2x.png",
      chainId:"8989",
      rpc : "https://rpc-asia.gmmtchain.io/"
    },
  ]);

  // const [networkList, setNetworkList] = useState([{ name: 'BSC TestNet' }, { name: 'MMT TestNet' }]);
  const [selectedItemNetWork, setSelectedItemNetWork] = useState(networkName);

  const [nowPage, setNowPage] = useState("");

  const selectToggle = () => {
    setSelectFlip(!selectFlip);
  };

  const getSelectItem = (item) => {
    if (item !== "EN") {
      setComingPopup(true);
      return;
    }

    setSelectedItem(item);
    selectToggle();
  };

  useEffect(() => {
    setSelectedItemNetWork(networkName);
  }, [networkName]);

  const selectNetworkToggle = () => {
    setSelectFlipNetWork(!selectFlipNetWork);
  };

  const getSelectNetworkItem = (item) => {
    setSelectedItemNetWork(item);
    onChangeHeaderNetWork(item);
    selectNetworkToggle();
  };

  const { pathname } = useLocation();

  useEffect(() => {
    let q1 =
      // pathname === "/farm" ||
      // pathname === "/stake" ||
      // pathname === "/stakelist" ||
      pathname === "/voting";
    let q2 = pathname === "/voting" || pathname === "/coming";

    let q3;
    if (defaultChainId == "8898") {
      q3 = q1;
    } else {
      q3 = q2;
    }

    if (q3) {
      setUrlStatus(false);
    } else {
      setUrlStatus(true);
    }
  }, [pathname, networkName, defaultChainId]);

  const onClickTitle = (pageName) => {
    const htmlTitle = document.querySelector("title");
    htmlTitle.innerHTML = pageName;
  };

  useEffect(() => {
    setNowPage(pathname);
  }, [pathname]);

  const onClickSiteList = (bool) => {
    setSiteListStatus(bool);
  };

  const onClickNetworkList = (bool) => {
    setNetWorkListStatus(bool);
  };

  return (
    <div className={styles.navBar}>
      <div className={styles.rightMenu}>
        <ul className={styles.menuList}>
          <li
            className={`${styles.menuList} ${
              nowPage == "/swap" ? styles.on : ""
            }`}
            onClick={(e) => {
              toggleMobileMenu(e);
              onClickTitle("Swap - Ivory Swap");
              const reset = {
                address: "",
                symbol: "",
                name: "",
                decimal: 0,
                inputAmount: "",
                image: "",
                haveAmount: 0,
              };
              dispatch(setSwapTokenB(reset))
            }}
          >
            <Link to={"/swap"}>Swap</Link>
          </li>
          <li
            className={`${styles.menuList} ${
              nowPage == "/liquidity" || nowPage == "/add" ? styles.on : ""
            }`}
            onClick={(e) => {
              toggleMobileMenu(e);
              onClickTitle("Liquidity - Ivory Swap");
            }}
          >
            <Link to={"/liquidity"}>Liquidity</Link>
          </li>
          <li
            className={`${styles.menuList} ${
              nowPage == "/farm" ? styles.on : ""
            }`}
            onClick={(e) => {
              toggleMobileMenu(e);
              onClickTitle("Farm - Ivory Swap");
            }}
          >
            <Link to={"/farm"}>Farm</Link>
          </li>
          <li
            className={`${styles.menuList} ${
              nowPage == "/stake" || nowPage == "/stakeList" ? styles.on : ""
            }`}
            onClick={(e) => {
              toggleMobileMenu(e);
              onClickTitle("Stake - Ivory Swap");
            }}
          >
            <Link to={"/stake"}>Stake</Link>
          </li>
          <li
            className={`${styles.menuList} ${
              nowPage == "/voting" ? styles.on : ""
            }`}
            onClick={(e) => {
              toggleMobileMenu(e);
              onClickTitle("Voting - Ivory Swap");
            }}
          >
            <Link to={"/voting"}>Voting</Link>
          </li>
          <li
            className={`${styles.menuList} ${
              nowPage == "/info" ? styles.on : ""
            }`}
            onClick={(e) => {
              toggleMobileMenu(e);
              onClickTitle("Info - Ivory Swap");
            }}
          >
            <Link to={"/info"}>Info</Link>
          </li>
        </ul>
        <div
          className={`${styles.menuList} ${styles.siteList} ${
            siteListStatus ? styles.on : ""
          }`}
          onClick={(e) => {
            // toggleMobileMenu(e);
            // if(!siteListStatus){
              onClickSiteList(!siteListStatus);
            // }
          }}
        >
          <span
            className={styles.csp}
            onClick={(e) => {
              // toggleMobileMenu(e);
            }}
          >
            <span className={styles.site}>Site</span>
            <span className={styles.arrow}></span>
          </span>
          <div className={styles.siteListBox}>
            <div className={styles.popupDim} onClick={() => {
                // window.open("https://icebridge.io/")
            }}></div>
            <a
              className={styles.span_a}
              // href="https://icebridge.io/"
              target="_blank"
              onClick={(e) => {
                window.open("https://icebridge.io/")
                onClickSiteList(false);
                // toggleMobileMenu(e);
                // onClickSiteList(false);
              }}
            >
              Icebridge
            </a>
          </div>
        </div>
        <div
          className={`${styles.menuList} ${styles.siteList} ${
            netWorkListStatus ? styles.on : ""
          }`}
          onClick={(e) => {
            // toggleMobileMenu(e);
            onClickNetworkList(!netWorkListStatus);
          }}
        >
          <span className={styles.csp} onClick={(e) => {}}>
            <span className={styles.site}>Network</span>
            <span className={styles.arrow}></span>
          </span>
          <div className={styles.networksiteListBox}>
            <div className={styles.popupDim} onClick={() => {}}></div>
            <div>
              {(siteType == "test"?testNetworkList:mainNetworkList).map((item, index) => (
                  // item.chainId != chainId ? 
                <div className={styles.networksiteList}
                  key={index}
                >
                  <a
                    // href={item.path}
                    onClick={(e)=>{
                      onClickChangeNetWork(item.rpc, item.chainId);
                      if(!mobileHeaderPopup){
                        toggleMobileMenu(e);
                      }
                    }}
                    target="_self"
                    className={`${
                      item.name == "MMT"||item.name == "TMMT" ? styles.mmt : item.name == "GMMT"||item.name == "TGMMT" ? styles.gmmt : styles.eth
                    }`}
                    style={{
                      color:`${chainId == item.chainId ? "#5fbe73": "black"}`
                    }}
                  >
                    {item.name}
                  </a>
                </div>
                // :""
              ))}
            </div>
          </div>
        </div>

        {/* <div className={`${styles.customSelect} ${selectFlip ? styles.show : ''}`} onClick={selectToggle}>
                    <div className={`${styles.selected}`} >
                        <span className={styles.coinName}>{selectedItem}</span>
                        <img src={require("../assets/images/swap/ico_arrow_bl.png")} alt={"ico_arrow_bl"} />
                    </div>
                    {selectFlip &&
                        <div className={styles.itemList}>
                            {languageList && languageList?.map((item, index) => {
                                return (
                                    <div className={styles.item} onClick={() => getSelectItem(item.name)} key={index}>
                                        <span className={styles.coinName}>{item.name}</span>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div> */}

        {/* NetWork 전환 */}

        {/* <div className={`${styles.customSelectNetWork} ${selectFlipNetWork ? styles.show : ''}`} onClick={selectNetworkToggle} >
                    <div className={`${styles.selected}`} >
                        <span className={styles.coinName}>{selectedItemNetWork}</span>
                        <img src={require("../assets/images/swap/ico_arrow_bl.png")} alt={"ico_arrow_bl"} />
                    </div>
                    {selectFlipNetWork &&
                        <div className={styles.itemList}>
                            {networkList && networkList?.map((item, index) => {
                                return (
                                    <div className={styles.item} onClick={() => getSelectNetworkItem(item.name)} key={index}>
                                        <span className={styles.coinName}>{item.name}</span>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
                {
                    selectFlip && <div className={styles.selectDim} onClick={selectToggle}></div>
                }
                {
                    selectFlipNetWork && <div className={styles.selectDim}  onClick={selectNetworkToggle}></div>
                } */}
        {/*<div className={styles.mmtPrice}>*/}
        {/*    <img src={require("../assets/images/sym_mmt.png")} alt={"logo"} style={{"width":"35px","height":"35px"}} />*/}
        {/*    <span>$1</span>*/}
        {/*</div>*/}
      </div>

      {comingPopup && (
        <div className={styles.waitPopup}>
          <div
            className={styles.popupDim}
            onClick={() => setComingPopup(false)}
          />
          <div className={styles.popupContainer}>
            Coming Soon
            <img
              onClick={() => setComingPopup(false)}
              src={require("../assets/images/ico_close_bl@2x.png")}
              alt={"ico_close_bl"}
            />
          </div>
        </div>
      )}

      {changeNetWorkStatus && (
        <div className={styles.waitPopup}>
          <div
            className={styles.popupDim}
            onClick={() => setChangeNetWorkStatus(false)}
          />
          <div className={styles.popupContainer}>
            {/* <div>Unsupported Chain Id Error.</div>
                    <div>Check your chain Id.</div> */}
            <div>{netWorkMessage}</div>
            <img
              onClick={() => setChangeNetWorkStatus(false)}
              src={require("../assets/images/ico_close_bl@2x.png")}
              alt={"ico_close_bl"}
            />
          </div>
        </div>
      )}
      {toastPopup && (
        <div className={styles.toastPopup}>
          <div className={styles.popupDim} />
          <div className={styles.popupContainer}>
            <img src={require("../assets/images/ico_con_wallet@2x.png")} />
            <div>If you want to use Ivoryswap,</div>
            <div>
              <span>Connect wallet</span> please.
            </div>
            <div>{netWorkMessage}</div>
            {/* <img onClick={() => setChangeNetWorkStatus(false)} src={require("../assets/images/ico_close_bl@2x.png")} alt={"ico_close_bl"} /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
