import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { CookiesProvider } from "react-cookie";
import { createStore, applyMiddleware } from 'redux';
import rootreducer, { rootsaga } from './modules/index';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootreducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
const persistor = persistStore(store);
sagaMiddleware.run(rootsaga);

const getLibrary = (provider) => {
  const library = new Web3Provider(provider, "any");
  return library;
}


ReactDOM.render(
  <React.StrictMode>
    {/* <Web3ReactProvider getLibrary={getLibrary} store={store}>
      <CookiesProvider> */}
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      {/* </CookiesProvider>
    </Web3ReactProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
