import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ConnectWallet from "../components/ConnectWallet";
import NavBar from "../components/NavBar";
import styles from "../assets/css/Header.module.css";
import NavBarstyles from "../assets/css/NavBar.module.css";
import WMMTJSON from "../abi/WMMT.json";

import axios from "axios";
import Web3 from "web3";

import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { returnDecimal } from "../common/commonService";
import BN from "bn.js";

import ERC20 from "../abi/ERC20.json";

import { dirname } from "path";
import { useLayoutEffect } from "react";
const fs = require("fs");

const Header = ({
  onClickChangeNetWork,
  coinSymbol,
  wCoinSymbol,
  mobileHeaderPopup,
  setMobileHeaderPopup,
  toastPopup,
  connectCheck,
  defaultChainId,
  netWorkMessage,
  setUrlStatus,
  networkName,
  onChangeHeaderNetWork,
  nowUrlStatus,
  setChangeNetWorkStatus,
  changeNetWorkStatus,
  mobileCheck,
  web3Provider,
  active,
  account,
  handleConnect,
  chainId,
  handelDisConnect,
  WMMT_ADDRESS,
  defaultProvider
}) => {
  const [headerActive, setHeaderActive] = useState(false);
  const [walletInfoShow, setWalletInfoShow] = useState(false);
  const [reAccount, setReAccount] = useState("");
  const [mytokenList, setMyTokenList] = useState("");
  const [myMMTBalance, setMyMMTBalance] = useState(0);
  const [myWMMTBalance, setMyWMMTBalance] = useState(0);
  const [mobileShow, setMobileShow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [copiedCoinName, setCopedCoinName] = useState("");
  const [copiedCoinAddress, setCopedCoinAddress] = useState("");

  const [siteListStatus, setSiteListStatus] = useState(false);
  const [netWorkListStatus, setNetWorkListStatus] = useState(false);

  const [coinName, setCoinName] = useState("");

  const [checkUrlState, setCheckUrlState] = useState(false);

  const { pathname } = useLocation();

  const swapA = useSelector((state) => state.swap.swapA);
  const swapB = useSelector((state) => state.swap.swapB);

  const token_0 = useSelector((state) => state.remove.token_0);
  const token_1 = useSelector((state) => state.remove.token_1);

  const liquidity = useSelector((state) => state.liquidity.tokenA.haveAmount);
  const farm = useSelector((state) => state.farm.allFarms);
  const remove = useSelector((state) => state.remove.pair);
  const stake = useSelector((state) => state.stake.stakeList);
  const transactionHash = useSelector((state) => state.user.transactionHash);

  const [chain, setChain] = useState("MMT");

  useEffect(() => {
    if (chainId == 8898 || chainId == 88998) {
      setChain("MMT");
    } else if (chainId == 97) {
      setChain("BNB");
    } else if (chainId == 5 || chainId == 1) {
      setChain("ETH");
    } else if (chainId == 898989 || chainId == 8989) {
      setChain("GMMT");
    }
  }, [chainId]);

  const accountReNumber = () => {
    if (account != undefined) {
      const startStr = account.substring(0, 3);
      const endStr = account.substring(account.length, account.length - 3);

      setReAccount(startStr + "..." + endStr);
    }
  };

  useEffect(() => {
    if (chainId == 8898 || chainId == 88998) {
      setCoinName("MMT");
    } else if (chainId == 97) {
      setCoinName("BNB");
    } else if (chainId == 5 || chainId == 1) {
      setCoinName("ETH");
    } else if (chainId == 898989 || chainId == 8989) {
      setCoinName("GMMT");
    }
  }, [chainId]);

  const fromWeiBalance = (balance, decimal) => {
    let result = new BN(`${10}`).pow(new BN(`${decimal}`)).toString();
    let fromWeiBalance = new BN(`${balance}`).divmod(new BN(`${result}`));
    let divBalance = fromWeiBalance.div.toString();
    let modBalance = fromWeiBalance.mod.toString();
    let subDecimal = decimal - modBalance.length;
    let zero = "";
    for (let i = 0; i < subDecimal; i++) {
      zero += "0";
    }

    let returnBalance = divBalance + "." + zero + modBalance;

    return returnBalance;
  };

  const toWeiBalance = (balance, decimal) => {
    if (web3Provider != undefined) {
      let point = balance.split(".");
      let pointPow = 1;
      let powLength = 0;
      let lastPoint = "";
      if (point[1] != undefined) {
        powLength = point[1].length;
        pointPow = new BN(`${10}`).pow(new BN(`${point[1].length}`)).toString();
        lastPoint = point[1].substr(0, decimal);
      }

      let balancePoinPow = point[0] + lastPoint;

      let subDecimal = new BN(`${decimal}`)
        .sub(new BN(`${powLength}`))
        .toString();

      let result = new BN(`${10}`).pow(new BN(`${subDecimal}`)).toString();
      let decimalBalance = new BN(`${balancePoinPow}`)
        .mul(new BN(`${result}`))
        .toString();

      return decimalBalance;
    }
  };

  const balanceInCode = (balance) => {
    if (
      balance == undefined ||
      balance.length == undefined ||
      balance == "-" ||
      balance == "" ||
      Number(balance) == 0
    ) {
      return 0;
    } else {
      let splitBalance = balance.split(".");

      let backBalance = "";
      let afterVal = "";
      if (splitBalance.length == 2) {
        let q1 = splitBalance[1].length - (splitBalance[1].search(/[1-9]/) + 1);
        let nextVal;
        for (let i = 0; i < q1; i++) {
          nextVal = splitBalance[1].substr(
            splitBalance[1].search(/[1-9]/) + (i + 1),
            1
          );
          if (nextVal != "0") {
            afterVal = afterVal + `${nextVal}`;
            break;
          } else {
            afterVal = afterVal + "0";
          }
          if (i == q1 - 1) {
            nextVal = "";
          }
        }
        if (afterVal.search(/[1-9]/) == -1) {
          afterVal = "";
        }
        backBalance =
          splitBalance[1].substr(0, splitBalance[1].search(/[1-9]/) + 1) != ""
            ? "." +
              splitBalance[1].substr(0, splitBalance[1].search(/[1-9]/) + 1) +
              afterVal
            : "";
      }

      return splitBalance[0] + backBalance;
    }
  };

  const tokenList = async () => {
    let url;
    if (String(chainId) == "8898") {
      url = `https://mmtscan.io/api?module=account&action=tokenlist&address=${account}`;
    } else if (String(chainId) == "88998") {
      url = `https://testnet.mmtscan.io/api?module=account&action=tokenlist&address=${account}`;
    } else if (String(chainId) == "5") {
      url = `https://api-goerli.etherscan.io/api?module=account&action=tokentx&address=${account}&startblock=0&endblock=999999999&sort=asc&apikey=${process.env.REACT_APP_ETHERSCAN_APIKEY}`;
    } else if (String(chainId) == "1") {
      url = `https://api.etherscan.io/api?module=account&action=tokentx&address=${account}&startblock=0&endblock=999999999&sort=asc&apikey=${process.env.REACT_APP_ETHERSCAN_APIKEY}`;
    } else if (String(chainId) == "8989") {
      url = `https://scan.gmmtchain.io/api?module=account&action=tokenlist&address=${account}`;
    } else if (String(chainId) == "898989") {
      url = `https://testnet-scan.gmmtchain.io/api?module=account&action=tokenlist&address=${account}`;
    }

    if (url != undefined && defaultProvider != undefined && account != "" && chainId == (await defaultProvider?.eth.getChainId()).toString()) {
      const web3 = defaultProvider;
      let haveAmount = await web3.eth.getBalance(account);
      haveAmount = web3.utils.fromWei(haveAmount);
      // haveAmount = Number(haveAmount).toFixed(4).replace(/\.?0+$/, "");
      haveAmount = haveAmount;
      setMyMMTBalance(haveAmount);
      if (String(chainId) == "8898" || String(chainId) == "88998") {
        try {
          let list = await axios.get(url);
          list = list.data.result;
          let result = [];
          let image = "";
          for (let i = 0; i < list.length; i++) {
            result.push(
              new Promise(async (resovle, reject) => {
                if (list[i].type == "ERC-20") {
                  if (
                    list[i].symbol == "ETH" ||
                    list[i].symbol == "BTC" ||
                    list[i].symbol == "WMMT" ||
                    list[i].symbol == ""
                  ) {
                    image = list[i].symbol;
                  } else {
                    image = "";
                  }
    
                  const contract = new web3.eth.Contract(
                    ERC20,
                    list[i].contractAddress
                  );
    
                  let balanceOf = await contract.methods.balanceOf(account).call();
    
                  if (list[i].symbol != "Ivory-LP") {
                    let weiBalance = fromWeiBalance(balanceOf, list[i].decimals);
                    resovle({
                      balance: weiBalance,
                      decimals: list[i].decimals,
                      name: list[i].name,
                      symbol: list[i].symbol,
                      image: list[i].contractAddress.toLowerCase(),
                    });
                  }else{
                    resovle("")
                  }
                }else{
                  resovle("")
                }
              })
            )
          }

          Promise.all(result).then((values) => {
            let filterValues = values.filter((a) => {
              return a != ""
            })

            let sort = filterValues.sort((a, b) => {
              return b.balance - a.balance;
            });
            setMyTokenList(sort);
          });
        } catch (error) {
          console.log("error =>", error);
          setMyTokenList([]);
        }
      } else if (String(chainId) == "5" || String(chainId) == "1") {
        let list = await axios.get(url);
        try {
          let ercArray = [];

          for (const iterator of list.data.result) {
            let ercHistory = {};

            ercHistory["contractAddress"] = iterator.contractAddress;
            ercHistory["symbol"] = iterator.tokenSymbol;
            ercHistory["name"] = iterator.tokenName;
            ercHistory["decimals"] = iterator.tokenDecimal;

            ercArray.push(ercHistory);
          }

          let uniqueJson = ercArray.filter((item, index) => {
            const thing = JSON.stringify(item);
            return (
              index ===
              ercArray.findIndex((obj) => {
                return JSON.stringify(obj) === thing;
              })
            );
          });
          let image;
          let result = [];
          for (let i = 0; i < uniqueJson.length; i++) {
            if (
              uniqueJson[i].symbol == "ETH" ||
              uniqueJson[i].symbol == "BTC" ||
              uniqueJson[i].symbol == "WMMT" ||
              uniqueJson[i].symbol == ""
            ) {
              image = uniqueJson[i].symbol;
            } else {
              image = "";
            }

            const contract = new web3.eth.Contract(
              ERC20,
              uniqueJson[i].contractAddress
            );

            let balanceOf = await contract.methods.balanceOf(account).call();

            if (uniqueJson[i].symbol != "Ivory-LP") {
              let weiBalance = fromWeiBalance(
                balanceOf,
                uniqueJson[i].decimals
              );
              result.push({
                balance: weiBalance,
                decimals: uniqueJson[i].decimals,
                name: uniqueJson[i].name,
                symbol: uniqueJson[i].symbol,
                image: uniqueJson[i].contractAddress.toLowerCase(),
              });
            }
          }

          let sort = result.sort((a, b) => {
            return b.balance - a.balance;
          });

          setMyTokenList(sort);
        } catch (error) {
          console.log(error);
          setMyTokenList([]);

        }
      } else if (String(chainId) == "8989" || String(chainId) == "898989") {
        try {
          let list = await axios.get(url);
          list = list.data.result;
          let result = [];
          let image = "";
          for (let i = 0; i < list.length; i++) {
            if (list[i].type == "ERC-20") {
              if (
                list[i].symbol == "ETH" ||
                list[i].symbol == "BTC" ||
                list[i].symbol == "WGMMT" ||
                list[i].symbol == ""
              ) {
                image = list[i].symbol;
              } else {
                image = "";
              }

              const contract = new web3.eth.Contract(
                ERC20,
                list[i].contractAddress
              );

              let balanceOf = await contract.methods.balanceOf(account).call();

              if (list[i].symbol != "Ivory-LP") {
                let weiBalance = fromWeiBalance(balanceOf, list[i].decimals);
                result[i] = {
                  balance: weiBalance,
                  decimals: list[i].decimals,
                  name: list[i].name,
                  symbol: list[i].symbol,
                  image: list[i].contractAddress.toLowerCase(),
                };
              }
            }
          }

          let sort = result.sort((a, b) => {
            return b.balance - a.balance;
          });

          setMyTokenList(sort);
        } catch (error) {
          // console.log("error 12", error);
          setMyTokenList([]);
        }
      }
    }else if(account == ""){
      setMyTokenList([]);
    }else if(chainId == String(await defaultProvider?.eth.getChainId()) == false){
      setMyTokenList([]);

    }
  };

  // useEffect(() => {
  // useLayoutEffect(() => {
  //   accountReNumber();
  //   if (account != "") {
  //     tokenList();
  //   }
  //   if (account == false) {
  //     setMyWMMTBalance(0);
  //     setMyMMTBalance(0);
  //     setMyTokenList([]);
  //   }
  // }, [account, chainId]);

  // useEffect(()=>{
  //     console.log("account",account)
  //     if(account != undefined && account != ""){
  //     }
  // },account)

  useEffect(() => {
    accountReNumber();
      tokenList();
  }, [
    account,
    // swapA,
    // swapB,
    // token_0,
    // token_1,
    // liquidity,
    // farm,
    // remove,
    // stake,
    transactionHash,
    defaultProvider,
    chainId
  ]);

  useEffect(() => {
    if (pathname != "/") {
      setCheckUrlState(true);
    } else {
      setCheckUrlState(false);
    }
  }, [pathname]);

  const toggleMobileMenu = useCallback(
    (e) => {
      // const toggleMobileMenu = (e) => {
      try {
        if (
          e.target.className != `${styles.greenWalletBtn}` &&
          e.target.className != `${styles.closeBtn}` &&
          e.target.className != `${NavBarstyles.arrow}` &&
          e.target.className != `${NavBarstyles.site}` &&
          e.target.className != `${NavBarstyles.popupDim}` &&
          e.target.className != `${NavBarstyles.span_a}` &&
          e.target.className !=
            `${NavBarstyles.menuList} ${NavBarstyles.siteList}` &&
          e.target.className !=
            `${NavBarstyles.menuList} ${NavBarstyles.siteList} ${NavBarstyles.on}`
          // `${NavBarstyles.menuList} ${NavBarstyles.siteList}`.includes(e.target.className)
        ) {
          if (mobileCheck) {
            setMobileShow(!mobileShow);
            if (mobileShow) {
              // if (mobileShow) {
              setMobileHeaderPopup(true);
              document.body.style.cssText = "";
            } else {
              setMobileHeaderPopup(false);
              // if (pathname != "/add" || pathname != "/") {
              //     document.body.style.cssText = '';
              // } else {
              document.body.style.cssText = `
                                overflow: hidden;
                                height: 100%;
                                min-height: 100%;
                                touch-action: none
                                `;
              // }
            }
          }
        }
      } catch (error) {}
      // }
    },
    [mobileShow, mobileCheck]
  );

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  
  useEffect(() => {
    // if(mobileCheck){
    // if (pathname === '/add') {
    //     setHeaderActive(styles.none);
    // } else {
    //     setHeaderActive('')
    window.addEventListener("scroll", () => {
      if (document.documentElement.scrollTop > 50) {
        setHeaderActive(true);
      } else {
        setHeaderActive(false);
      }
    });
    // }
    // }else{

    // }
  }, [pathname]);

  // // const { pathname } = useLocation();
  useEffect(() => {
    let q1 =
      //   pathname === "/farm" ||
      //   pathname === "/stake" ||
      //   pathname === "/stakelist" ||
      pathname === "/voting" ||
      //   pathname === "/info" ||
      pathname === "/coming";
    let q2 = pathname === "/voting" || pathname === "/coming";

    let q3;
    if (defaultChainId == "88998") {
      q3 = q2;
    } else {
      q3 = q1;
    }

    if (q3) {
      setUrlStatus(false);
    } else {
      setUrlStatus(true);
    }
  }, [pathname, networkName, defaultChainId]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (copiedCoinAddress != "" && copiedCoinName != "") {
      setTimeout(() => {
        setCopedCoinName("");
        setCopedCoinAddress("");
      }, 1000);
    }
  }, [copiedCoinAddress, copiedCoinName]);

  if (windowWidth < 721) {
    if (pathname === "/add") {
      return null;
    }
  }

  const onErrorImg = (e) => {
    e.target.src = "logo_.png";
  };

  const onClickCopyContractAddress = async (item, symbol) => {
    let contractAddress = item;
    setCopedCoinName(symbol);
    setCopedCoinAddress(item);
    if (navigator.clipboard && navigator.permissions) {
      await navigator.clipboard.writeText(contractAddress);
    } else if (document.queryCommandSupported("copy")) {
      const ele = document.createElement("textarea");
      ele.value = contractAddress;
      document.body.appendChild(ele);
      ele.select();
      document.execCommand("copy");
      document.body.removeChild(ele);
    }
    // contractAddress.select();
    // document.execCommand("copy");;
    // e.clipboardData.setData("Text", contractAddress)
  };

  const onClickBalanceMaxCopy = async (item) => {
    let balance = item;
    await navigator.clipboard.writeText(balance);
    // e.clipboardData.setData("Text", contractAddress)
  };
  const onClickTitle = (pageName) => {
    const htmlTitle = document.querySelector("title");
    htmlTitle.innerHTML = pageName;
  };

  const toLocaleStringOption = (amount) => {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  const onClickCheckUrl = () => {
    setCheckUrlState(true);
  };

  let url = process.env.REACT_APP_URL;

  const onClickSpanBlue = () => [(window.location.href = `https://${url}`)];

  return (
    <div className={`${styles.header} ${headerActive?styles.fixed:""}  ${mobileHeaderPopup?"":styles.backGround}`}>
      {mobileHeaderPopup ? (
        <div
          className={`${styles.checkUrl} ${checkUrlState ? styles.close : ""}`}
        >
          <div className={styles.checkUrlInner}>
            <div>
              <img
                src={require("../assets/images/logo_round_bl@2x.png")}
                alt={"roundLogo"}
              />
              <p>
                <span className={styles.red}>[WARNING]</span> Recently, there
                have been many scams site that impersonate Ivoryswap. Please
                check again. <br className={styles.br} />
                We trying to eliminate scam site on google. <br />
                <span className={styles.black}>Only enter</span>{" "}
                <span className={styles.blue} onClick={onClickSpanBlue}>
                  https://{url}
                </span>{" "}
                - Please check the URL
              </p>
            </div>
            <div className={styles.closeBtn} onClick={onClickCheckUrl}>
              <div>
                <img
                  src={require("../assets/images/swap/ico_minus_bl@2x.png")}
                  alt={"ico_minus_bl"}
                />
                <img
                  src={require("../assets/images/swap/ico_minus_bl@2x.png")}
                  alt={"ico_minus_bl"}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={styles.headerWrap}>
        {nowUrlStatus ? (
          <div className={styles.headerInner}>
            <div
              className={`${styles.logo} ${headerActive?styles.fixed:""}`}
              onClick={(e) => {
                onClickTitle("Ivory Swap");
              }}
            >
              <Link to={"/"}>
                <img
                  className={styles.pc_logo}
                  src={require("../assets/images/logo_horizontal_main.png")}
                  alt={"logo"}
                />
                <img
                  className={styles.m_logo}
                  src={require("../assets/images/m_logo.png")}
                  alt={"m_logo"}
                />
              </Link>
            </div>
            <div
              className={styles.mobileMenuBtn}
              onClick={(e) => {
                setSiteListStatus(false);
                setNetWorkListStatus(false);
                toggleMobileMenu(e);
                setWalletInfoShow(false);
              }}
            >
              <img
                // className={styles.mobileMenuBtnImage}
                src={require("../assets/images/m_ico_menu.png")}
                alt={"mobileBtn"}
              />
            </div>
            <div
              className={`${styles.mobileMenuDim} ${
                mobileShow ? styles.show : ""
              }`}
              onClick={(e) => {
                toggleMobileMenu(e);
              }}
            ></div>
            <div
              className={`${styles.innerRight} ${
                mobileShow ? styles.show : ""
              }`}
              onClick={(e) => {
                // toggleMobileMenu(e)
              }}
            >
              <NavBar
                onClickChangeNetWork={onClickChangeNetWork}
                setNetWorkListStatus={setNetWorkListStatus}
                netWorkListStatus={netWorkListStatus}
                siteListStatus={siteListStatus}
                setSiteListStatus={setSiteListStatus}
                toastPopup={toastPopup}
                connectCheck={connectCheck}
                defaultChainId={defaultChainId}
                chainId={chainId}
                netWorkMessage={netWorkMessage}
                setUrlStatus={setUrlStatus}
                networkName={networkName}
                onChangeHeaderNetWork={onChangeHeaderNetWork}
                setChangeNetWorkStatus={setChangeNetWorkStatus}
                changeNetWorkStatus={changeNetWorkStatus}
                toggleMobileMenu={toggleMobileMenu}
                mobileHeaderPopup={mobileHeaderPopup}
                account={account}
                web3Provider={web3Provider}
              />
              {active === false ? (
                <ConnectWallet active={active} handleConnect={handleConnect} />
              ) : (
                <div className={styles.walletInfoPopup}>
                  <div
                    className={`${styles.walletInfoPopupDim} ${
                      walletInfoShow == false ? "" : styles.dimOff
                    }`}
                    onClick={() => setWalletInfoShow(false)}
                  />
                  <span
                    className={styles.greenWalletBtn}
                    onClick={() => setWalletInfoShow(true)}
                  >
                    {reAccount}
                  </span>
                  <div
                    className={`${styles.walletInfo} ${
                      walletInfoShow ? styles.show : ""
                    }`}
                  >
                    <div className={styles.title}>
                      <span>My ivory wallet</span>
                      <img
                        className={styles.closeBtn}
                        onClick={() => setWalletInfoShow(false)}
                        src={require("../assets/images/ico_close_bl.png")}
                        alt={"closeBtn"}
                      />
                    </div>
                    <div className={styles.myAddress}>
                      <div className={styles.myAddressTitle}>My address</div>
                      <div
                        className={styles.addressNumber}
                        onClick={() => {
                          onClickCopyContractAddress(account);
                        }}
                      >
                        {account}
                      </div>
                    </div>
                    <div className={styles.myBalance}>
                      <div className={styles.myBalanceTitle}>My balance</div>
                      <div className={styles.balanceList}>
                        <div className={styles.balance}>
                          <div
                            onClick={() => {
                              onClickCopyContractAddress(
                                WMMT_ADDRESS,
                                coinSymbol
                              );
                            }}
                          >
                            {chainId == "8898" || chainId == "88998" ? (
                              <img
                                src={require("../assets/images/sym_mmt.png")}
                                alt={"logo_eth"}
                              />
                            ) : chainId == "8989" || chainId == "898989" ? (
                              <img
                                src={require("../assets/images/common_ico_gmmt@3x.png")}
                                alt={"logo_eth"}
                              />
                            ) : (
                              <img
                                src={require("../assets/images/swap/logo_eth@2x.png")}
                                alt={"logo_eth"}
                              />
                            )}
                            <span>{coinName}</span>
                            <button
                              onClick={() => {
                                onClickCopyContractAddress(
                                  WMMT_ADDRESS,
                                  coinSymbol
                                );
                              }}
                              className={styles.btnCopy}
                            ></button>
                            <span
                              className={`${styles.copied} ${
                                copiedCoinName == coinSymbol &&
                                copiedCoinAddress == WMMT_ADDRESS
                                  ? styles.nowCopied
                                  : ""
                              }`}
                            >
                              copied
                            </span>
                          </div>
                          <span
                            onClick={() => {
                              onClickBalanceMaxCopy(myMMTBalance);
                            }}
                          >
                            {toLocaleStringOption(balanceInCode(myMMTBalance))}
                          </span>
                        </div>
                        {mytokenList != "" ? (
                          <>
                            {mytokenList.map((item, index) => (
                              <div className={styles.balance} key={index}>
                                <div
                                  onClick={() => {
                                    onClickCopyContractAddress(
                                      item.image,
                                      item.symbol
                                    );
                                  }}
                                >
                                  {/* <img src={require(`../assets/images/swap/logo_${item.image}.png`)} alt={"logo_eth"} /> */}
                                  <img
                                    src={`${chain}/logo_${item.image}.png`}
                                    alt={`${item.symbol}`}
                                    onError={onErrorImg}
                                  />
                                  <span className={`${item.symbol}`}>
                                    {item.symbol}
                                  </span>
                                  <button
                                    onClick={() => {
                                      onClickCopyContractAddress(
                                        item.image,
                                        item.symbol
                                      );
                                    }}
                                    className={styles.btnCopy}
                                  ></button>
                                  <span
                                    className={`${styles.copied} ${
                                      copiedCoinName == item.symbol &&
                                      copiedCoinAddress == item.image
                                        ? styles.nowCopied
                                        : ""
                                    }`}
                                  >
                                    copied
                                  </span>
                                </div>
                                <span
                                  onClick={() => {
                                    onClickBalanceMaxCopy(item.balance);
                                  }}
                                >
                                  {toLocaleStringOption(
                                    balanceInCode(item.balance)
                                  )}
                                </span>
                              </div>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <button
                      className={styles.disconnectBtn}
                      onClick={() => handelDisConnect(true)}
                    >
                      Disconnect
                    </button>
                  </div>
                  {/* </div> */}
                </div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Header;
