import BN from "bn.js";
import Web3 from "web3";

export const calcDecimal = (decimal) => {
  const exponent = decimal.indexOf("e");
  if (exponent >= 0) {
    decimal = Number(decimal)
      .toFixed(18)
      .replace(/(\.0*$)|(0*$)/, "");
    return String(decimal);
  }

  // 소수점 처리(내림처리를 하기 위함)
  const strPoint = decimal.indexOf(".");
  if (strPoint !== -1) {
    const mainStr = decimal.substr(0, strPoint);
    const subStr = decimal.substr(strPoint, 9);
    decimal = mainStr + subStr;
  }

  // 뒷자리 0처리
  decimal = Number(decimal)
    .toFixed(8)
    .replace(/(\.0*$)|(0*$)/, "");

  return String(decimal);
};

export const returnDecimal = (web3Provider, decimal) => {
  const web3 = web3Provider;
  let uintMap = web3.utils.unitMap;
  let uintMapValue = Object.values(uintMap);
  let uintMapKey = Object.keys(uintMap);

  let key = uintMapValue.indexOf(decimal);
  return uintMapKey[key];
};

const fromWeiBalance = (balance, decimal) => {
  let result = new BN(`${10}`).pow(new BN(`${decimal}`)).toString();
  let fromWeiBalance = new BN(`${balance}`).divmod(new BN(`${result}`));
  let divBalance = fromWeiBalance.div.toString();
  let modBalance = fromWeiBalance.mod.toString();
  let subDecimal = decimal - modBalance.length;
  let zero = "";
  for (let i = 0; i < subDecimal; i++) {
    zero += "0";
  }

  let returnBalance = divBalance + "." + zero + modBalance;
  return returnBalance;
};


let swapAbi = [
  {
    inputs: [
      { internalType: "uint256", name: "amountIn", type: "uint256" },
      { internalType: "address[]", name: "path", type: "address[]" },
    ],
    name: "getAmountsOut",
    outputs: [
      { internalType: "uint256[]", name: "amounts", type: "uint256[]" },
    ],
    stateMutability: "view",
    type: "function",
  },
];

let tokenAbi = [
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
];

const calcTokenPrice = async (
  defaultProvider,
  swapContractAddress,
  wrapTokenAddress,
  tokenAddress
) => {
  const web3 = defaultProvider;
  let wrapTokenToSell = web3.utils.toWei("1", "ether");
  let amountOut;
  try {
    let router = await new web3.eth.Contract(
      swapAbi,
      swapContractAddress
    );
    let tokenRouter = await new web3.eth.Contract( tokenAbi, tokenAddress );
    let tokenDecimals = await tokenRouter.methods.decimals().call();


    amountOut = await router.methods
      .getAmountsOut(wrapTokenToSell, [tokenAddress, wrapTokenAddress])
      .call();
    // amountOut = web3.utils.fromWei(amountOut[1]);
    amountOut =  fromWeiBalance(amountOut[1],tokenDecimals);
  } catch (error) {}
  if (!amountOut) return 0;
  return amountOut;
};

const calcCoinPrice = async (
  defaultProvider,
  swapContractAddress,
  USDTAddress,
  findAddress
) => {
  const web3 = defaultProvider;
  let wrapTokenToSell = web3.utils.toWei("1", "ether");

  let amountOut;
  try {
    let router = await new web3.eth.Contract(
      swapAbi,
      swapContractAddress
    );
      
      amountOut = await router.methods.getAmountsOut(wrapTokenToSell, [findAddress ,USDTAddress]).call();
      amountOut =  web3.utils.fromWei(amountOut[1]);
  } catch (error) {}
  if(!amountOut) return 0;
  return amountOut;
};

export const calcPrice = async (
  defaultProvider,
  swapContractAddress,
  wrapTokenAddress,
  findAddress,
  USDTAddress
) => {
  let coinPrice = await calcCoinPrice(defaultProvider,swapContractAddress,USDTAddress,wrapTokenAddress)
  let tokenPrice = await calcTokenPrice(defaultProvider,swapContractAddress,USDTAddress,findAddress)

  let returnsTokenPrice = tokenPrice * coinPrice;

  return {coinPrice, returnsTokenPrice}
};

// // 정수 ==> 소수
// export const balanceFromWei = (balance, decimal) => {
//   let returnBalance = new BN(`${balance}`)
// }

// // 소수 ==> 정수
// export const balanceToWei = () => {
//   let returnBalance = new BN(``)
// }
