import React, { createRef, useEffect, useState } from "react";
import Web3 from "web3";
import styles from "../assets/css/StakeList.module.css";

// import stakingJSON from "../abi/StakingLab.json";
import stakingJSON from "../abi/StakingLab_2.json";

import ERC20 from "../abi/ERC20.json";
import BN from "bn.js";
import { useDispatch, useSelector } from "react-redux";
import stake, {
  setAllStakeList,
  setFunAmount,
  setStakeLists,
  setStakingReward,
  setTokenAllowance,
} from "../modules/stake";
import { stakingTokens } from "../stakingList/stakeTokenList";
import { MaxUint256 } from "@ethersproject/constants";
import { setStakingInterval, setTransactionHash } from "../modules/user";
import { Link } from "react-router-dom";

const StakeList = ({
  defaultProvider,
  providerWeb3,
  web3Provider,
  IVY_ADDRESS,
  account,
  chainId,
  tIVY_ADDRESS,
  handleConnect,
  STAKING_LAB_ADDRESS,
  active,
}) => {
  const [elRefs, setElRefs] = useState([]);
  const [imgRefs, setImgRefs] = useState([]);

  // const [itemList, setItemList] = useState(['Select', 'Earned', 'Total staked', 'Latest'])
  const [itemList, setItemList] = useState([
    "Earned",
    "Total staked",
    "Latest",
  ]);
  const [selectFlip, setSelectFlip] = useState(false);
  const [selectedItem, setSelectedItem] = useState(itemList[0]);
  const [popupShow, setPopupShow] = useState(false);
  const [popupType, setPopupType] = useState("");

  const [activeStatus, setActiveStatus] = useState(true);
  const [approveIndex, setApproveIndex] = useState("");
  const [stakeStatus, setStakeStatus] = useState("");
  const [inputAmount, setInputAmount] = useState("");
  const [selectstakingToken, setSelectStakingToken] = useState([]);

  const [messageStatus, setMessageStatus] = useState(true);
  const [searchText, setSearchText] = useState("");

  const [modalMsg, setModalMsg] = useState("");

  const transactionHash = useSelector((state) => state.user.transactionHash);

  const testDiv = [0, 1, 2];

  const [chain, setChain] = useState("MMT");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (chainId == 8898 || chainId == 88998) {
      setChain("MMT");
    } else if (chainId == 97) {
      setChain("BNB");
    } else if (chainId == 5 || chainId == 1) {
      setChain("ETH");
    } else if (chainId == 898989 || chainId == 8989) {
      setChain("GMMT");
    }
    if (stakingInterval != "") {
      clearInterval(stakingInterval);
      dispatch(setStakingInterval(""));
    }
  }, [chainId]);

  const onChangeSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const selectToggle = () => {
    setSelectFlip(!selectFlip);
  };

  const getSelectItem = (item) => {
    setSelectedItem(item);
    selectToggle();
  };

  const farmToggle = (index) => {
    if (elRefs[index].current.classList.contains(styles.active)) {
      elRefs[index].current.classList.remove(styles.active);
      imgRefs[index].current.classList.remove(styles.rotate);
    } else {
      elRefs[index].current.classList.add(styles.active);
      imgRefs[index].current.classList.add(styles.rotate);
    }
  };

  const closePopup = () => {
    setPopupShow(false);
    setInputAmount("");
    setPopupType("");
    setMessageStatus(true);
  };

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(testDiv.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
    setImgRefs((imgRefs) =>
      Array(testDiv.length)
        .fill()
        .map((_, i) => imgRefs[i] || createRef())
    );
  }, [testDiv.length]);

  const fromWeiBalance = (balance, decimal) => {
    let result = new BN(`${10}`).pow(new BN(`${decimal}`)).toString();
    let fromWeiBalance = new BN(`${balance}`).divmod(new BN(`${result}`));
    let divBalance = fromWeiBalance.div.toString();
    let modBalance = fromWeiBalance.mod.toString();
    let subDecimal = decimal - modBalance.length;
    let zero = "";
    for (let i = 0; i < subDecimal; i++) {
      zero += "0";
    }

    let returnBalance = divBalance + "." + zero + modBalance;
    return returnBalance;
  };

  const toWeiBalance = (balance, decimal) => {
    // if (web3Provider != undefined) {
    let point = balance.split(".");
    let pointPow = 1;
    let powLength = 0;
    let lastPoint = "";
    if (point[1] != undefined) {
      powLength = point[1].length;
      pointPow = new BN(`${10}`).pow(new BN(`${point[1].length}`)).toString();
      lastPoint = point[1].substr(0, decimal);
    }

    let balancePoinPow = point[0] + lastPoint;

    let subDecimal = new BN(`${decimal}`)
      .sub(new BN(`${powLength}`))
      .toString();

    let result = new BN(`${10}`).pow(new BN(`${subDecimal}`)).toString();
    let decimalBalance = new BN(`${balancePoinPow}`)
      .mul(new BN(`${result}`))
      .toString();

    return decimalBalance;
    // }
  };

  // const [stakePoolList , setStakePoolLists] = useState([]);
  // const [stakeUserList , setStakeUserLists] = useState([]);

  const [stakeTokenInfoList, setStakeTokenInfoList] = useState([]);

  const dispatch = useDispatch();

  const stakePoolList = useSelector((state) => state.stake.stakeList);
  const allStakePoolList = useSelector((state) => state.stake.allStakeList);

  const setStakeList = async () => {
    // if (web3Provider != undefined && account != "" && chainId != 0) {
    if (defaultProvider != undefined && STAKING_LAB_ADDRESS != "") {
      // const web3 = new Web3(web3Provider);
      try {
        const web3 = new Web3(defaultProvider);
        const stakingContract = new web3.eth.Contract(
          stakingJSON,
          STAKING_LAB_ADDRESS
        );
        const PIDLength = await stakingContract.methods.poolLength().call();

        let data = [];
        let view;
        let getDate = Math.floor(+new Date() / 1000);

        for (let i = 0; i < PIDLength; i++) {
          data.push(
            new Promise(async (resolve, reject) => {
              let dataUser = {
                amount: "0",
                rewardDebt: "0",
              };
              let dataPool = await stakingContract.methods.poolInfo(i).call();
              // let inputTokenAddress = await stakingContract.methods.sivy().call();
              let inputTokenAddress = await stakingContract.methods
                .ivy()
                .call();
              let ivyContract = new web3.eth.Contract(ERC20, inputTokenAddress);
              let ivyDecimal = await ivyContract.methods.decimals().call();

              let ivyBalance = "0";
              if (account != "") {
                ivyBalance = await ivyContract.methods
                  .balanceOf(account)
                  .call();
              }
              let tokenContract = new web3.eth.Contract(
                ERC20,
                dataPool.RewardToken
              );
              let tokenSymbol = await tokenContract.methods.symbol().call();
              let tokenDecimal = await tokenContract.methods.decimals().call();
              if (account != "") {
                dataUser = await stakingContract.methods
                  .userInfo(i, account)
                  .call();
              }

              let pendingReward = await getPendingReward(i);
              let projectURL = stakingTokens[i].projectURL;
              let allowance = false;
              if (account != "") {
                allowance = await checkAllowance(inputTokenAddress);
              }
              let apr = 0
              try {
                const rewardPerYear = new BN(`${dataPool.RewardPerSecond}`).mul(new BN(`${86400}`)).mul(new BN(`${365}`)).toString();
                apr = new BN(`${rewardPerYear}`).div(new BN( `${dataPool.IvyStakedAmount}`)).mul(new BN(`${100}`)).toString();
              } catch (error) {
                console.log(error)
              }
              // console.log(apr)

              view = {
                pid: i,
                apr:apr,
                // apr:0,
                RewardPerSecond: dataPool.RewardPerSecond,
                RewardToken: dataPool.RewardToken.toLowerCase(),
                TokenPrecision: dataPool.TokenPrecision,
                accRewardPerShare: dataPool.accRewardPerShare,
                endTime: dataPool.endTime,
                lastRewardTime: dataPool.lastRewardTime,
                protocolOwnerAddress: dataPool.protocolOwnerAddress,
                startTime: dataPool.startTime,
                userLimitEndTime: dataPool.userLimitEndTime,
                // sIvyStakedAmount: fromWeiBalance(dataPool.sIvyStakedAmount, ivyDecimal),
                IvyStakedAmount: fromWeiBalance(
                  dataPool.IvyStakedAmount,
                  ivyDecimal
                ),
                amount: fromWeiBalance(dataUser.amount, ivyDecimal),
                rewardDebt: dataUser.rewardDebt,
                pendingReward: fromWeiBalance(pendingReward, tokenDecimal),
                tokenSymbol: tokenSymbol,
                tokenDecimal: tokenDecimal,
                projectURL: projectURL,
                inputTokenAddress: inputTokenAddress,
                allowance: allowance,
                ivyBalance: fromWeiBalance(ivyBalance, ivyDecimal),
                ivyDecimal: ivyDecimal,
              };
              resolve(view);
            })
          );
        }

        Promise.all(data).then((values) => {
          dispatch(setAllStakeList(values));
        });
      } catch (error) {
        console.log(error);
        dispatch(setAllStakeList([]));
      }
    } else {
      dispatch(setAllStakeList([]));
    }
  };

  const selectSort = (item) => {
    if (item != "") {
      let sort = item.sort((a, b) => {
        let result = Number(a.pid) - Number(b.pid);
        return result;
      });
      return sort;
    }
    return item;
  };

  const totalStakedSort = (item) => {
    if (item != "") {
      let sort = item.sort((a, b) => {
        // let result = Number(b.sIvyStakedAmount) - Number(a.sIvyStakedAmount);
        let result = Number(b.IvyStakedAmount) - Number(a.IvyStakedAmount);
        return result;
      });
      return sort;
    }
    return item;
  };

  const earnedSort = (item) => {
    if (item != "") {
      let sort = item.sort((a, b) => {
        let result = Number(b.pendingReward) - Number(a.pendingReward);
        return result;
      });
      return sort;
    }
    return item;
  };

  const latestSort = (item) => {
    if (item != "") {
      let sort = item.sort((a, b) => {
        let result = new BN(`${b.lastRewardTime}`)
          .sub(new BN(`${a.lastRewardTime}`))
          .toString();
        return result;
      });
      return sort;
    }
    return item;
  };

  const setStakingLists = (nowSearchText, nowActiveStatus, nowSortBy) => {
    // if (web3Provider != undefined && account != "" && chainId != 0) {
    let getDate = Math.floor(+new Date() / 1000);
    if (allStakePoolList != undefined || allStakePoolList != "") {
      let resultList = [];
      let result = allStakePoolList.filter((item) => {
        let filterBool = nowActiveStatus
          ? getDate < item.endTime
          : getDate > item.endTime;
        if (filterBool) {
          return resultList.push(item);
        }
      });

      let sortResult;

      switch (nowSortBy) {
        case "Earned":
          sortResult = earnedSort(resultList);
          break;

        case "Total staked":
          sortResult = totalStakedSort(resultList);
          break;

        case "Latest":
          sortResult = latestSort(resultList);
          break;

        default:
          sortResult = selectSort(resultList);
          break;
      }

      let searchResultList = [];

      for (let i = 0; i < sortResult.length; i++) {
        const nameToLowerCase = sortResult[i].tokenSymbol.toLowerCase();
        const textToLowerCase = nowSearchText.toLowerCase();
        if (nameToLowerCase.includes(textToLowerCase)) {
          searchResultList.push(sortResult[i]);
        }
      }
      dispatch(setStakeLists(searchResultList));
      setInterval(() => {
        setLoading(false);
      }, 2000);
    } else {
      dispatch(setStakeLists([]));
      setInterval(() => {
        setLoading(false);
      }, 3000);
    }
    // }
    // else if (account == "") {
    //     dispatch(setStakeLists([]))
    // }
  };

  useEffect(() => {
    setStakingLists(searchText, activeStatus, selectedItem);
  }, [allStakePoolList, searchText, activeStatus, selectedItem, account]);

  useEffect(() => {
    setLoading(true);
    setStakeList();
  }, [defaultProvider, STAKING_LAB_ADDRESS, account, transactionHash]);

  const getPendingReward = async (pid) => {
    if (
      defaultProvider != undefined &&
      account != "" &&
      STAKING_LAB_ADDRESS != ""
    ) {
      const web3 = new Web3(defaultProvider);
      const stakingContract = new web3.eth.Contract(
        stakingJSON,
        STAKING_LAB_ADDRESS
      );
      const pendingReward = await stakingContract.methods
        .pendingReward(pid, account)
        .call();
      return pendingReward;
    } else if (account == "") {
      return "0";
    }
  };

  const onErrorImg = (e) => {
    e.target.src = "logo_.png";
  };

  const addTokenInfo = async (tokenInfo) => {
    await providerWeb3.sendAsync({
      jsonrpc: "2.0",
      id: chainId,
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenInfo.RewardToken,
          symbol: tokenInfo.tokenSymbol,
          decimals: tokenInfo.tokenDecimal,
        },
      },
    });
  };

  const onClickViewProjectSite = (URL) => {
    window.open(URL);
  };

  const checkAllowance = async (tokenAddress) => {
    const web3 = new Web3(defaultProvider);
    const erc20Contract = new web3.eth.Contract(ERC20, tokenAddress);
    let allowance = await erc20Contract.methods
      .allowance(account, STAKING_LAB_ADDRESS)
      .call();
    if (allowance != "0") {
      return true;
    } else {
      return false;
    }
  };

  const onClickApprove = async (index, tokenAddress) => {
    if (
      ((await web3Provider) != undefined?.eth.getChainId()) ==
      ((await defaultProvider) != undefined?.eth.getChainId())
    ) {
      const web3 = new Web3(web3Provider);
      const erc20Contract = new web3.eth.Contract(ERC20, tokenAddress);
      try {
        setApproveIndex(index);
        let approve = await erc20Contract.methods
          .approve(STAKING_LAB_ADDRESS, MaxUint256.toString())
          .send({
            // let approve = await erc20Contract.methods.approve(STAKING_LAB_ADDRESS,"0").send({
            from: account,
          });

        if (approve.status) {
          setApproveIndex("");
          let result = {
            index: index,
            result: true,
          };
          dispatch(setTokenAllowance(result));
        } else {
          setApproveIndex("");
        }
      } catch (error) {
        // console.log(error)
        setApproveIndex("");
      }
    }
  };

  const onClickActiveStatus = (bool) => {
    setActiveStatus(bool);
  };

  const onClickPopUp = (type, message, item) => {
    setSelectStakingToken(item);
    setInputAmount("");
    setPopupShow(true);
    setStakeStatus(message);
    setPopupType(type);
  };

  const onClickFunction = async (fun, item) => {
    if (
      ((await web3Provider) != undefined?.eth.getChainId()) ==
      ((await defaultProvider) != undefined?.eth.getChainId())
    ) {
      const web3 = new Web3(web3Provider);
      const stakingContract = new web3.eth.Contract(
        stakingJSON,
        STAKING_LAB_ADDRESS
      );
      const erc20Contract = new web3.eth.Contract(
        ERC20,
        item.inputTokenAddress
      );
      let toWeiAmount = toWeiBalance(inputAmount, item.ivyDecimal);

      let result;
      try {
        if (fun == "Add") {
          setPopupType("confirm");
          result = await stakingContract.methods
            .deposit(item.pid, toWeiAmount)
            .send({ from: account });
        } else if (fun == "Remove") {
          setPopupType("confirm");
          result = await stakingContract.methods
            .withdraw(item.pid, toWeiAmount)
            .send({ from: account });
        } else if (fun == "harvest") {
          // setPopupShow(true)
          try {
            setPopupType("confirm");
            result = await stakingContract.methods
              .withdraw(item.pid, "0")
              .send({ from: account });
          } catch (error) {
            // setPopupType("Failed")
            // setPopupShow(false)
          }
        } else if (fun == "Approve") {
          setStakeStatus("Approving");
          setMessageStatus(true);
          try {
            result = await erc20Contract.methods
              .approve(STAKING_LAB_ADDRESS, MaxUint256.toString())
              .send({
                // let approve = await erc20Contract.methods.approve(STAKING_LAB_ADDRESS,"0").send({
                from: account,
              });
          } catch (error) {
            setStakeStatus("Approve");
            setMessageStatus(false);
          }
        }

        if (fun != "Approve" && result.status) {
          setPopupType("submit");
          dispatch(setTransactionHash(result.transactionHash));
          setPendingReward();
        } else if (fun == "Approve" && result.status) {
          let allowanceResult = await erc20Contract.methods
            .allowance(account, STAKING_LAB_ADDRESS)
            .call();
          let allowanceInputBool = new BN(
            `${web3.utils.toWei(inputAmount)}`
          ).lte(new BN(`${allowanceResult}`));
          if (
            !allowanceInputBool &&
            modalMsg == "Stake" &&
            new BN(`${toWeiBalance(inputAmount, "18")}`).lte(
              new BN(`${toWeiBalance(item.ivyBalance, "18")}`)
            )
          ) {
            return setStakeStatus("Approve"), setMessageStatus(false);
          } else {
            setStakeStatus(modalMsg == "Stake" ? "Add" : "Remove");
            setMessageStatus(false);
          }
        } else {
          setPopupType("Failed");
        }
      } catch (error) {
        setPopupType("stake");
      }
    }
  };

  const onChangeInputVal = async (e) => {
    let inputValue = e.target.value;
    let pattern = /^[0-9]*[.,]?[0-9]*$/;
    let balance =
      modalMsg == "Stake"
        ? selectstakingToken.ivyBalance
        : selectstakingToken.amount;

    if (pattern.test(inputValue) == true) {
      if (inputValue == ".") inputValue = "0.";
      setInputAmount(inputValue);
      let BNResult = new BN(`${toWeiBalance(inputValue, "18")}`).lte(
        new BN(`${toWeiBalance(balance, "18")}`)
      );
      let bool = true;

      if (inputValue == "") {
        bool = true;
        setStakeStatus(modalMsg == "Stake" ? "Add" : "Remove");
      } else {
        const web3 = new Web3(defaultProvider);
        const erc20Contract = new web3.eth.Contract(
          ERC20,
          selectstakingToken.inputTokenAddress
        );
        let checkInputAllowance = await erc20Contract.methods
          .allowance(account, STAKING_LAB_ADDRESS)
          .call();
        let allowanceInputBool = new BN(`${web3.utils.toWei(inputValue)}`).lte(
          new BN(`${checkInputAllowance}`)
        );
        if (!allowanceInputBool && modalMsg == "Stake" && BNResult) {
          return setStakeStatus("Approve"), setMessageStatus(false);
        } else {
          if (e.target.value != "0" && BNResult && Number(e.target.value) > 0) {
            bool = false;
          }
        }

        setStakeStatus(modalMsg == "Stake" ? "Add" : "Remove");
        setMessageStatus(bool);
      }
    }
  };

  const balanceInCode = (balance) => {
    if (
      balance == undefined ||
      balance.length == undefined ||
      balance == "-" ||
      balance == "" ||
      Number(balance) == 0
    ) {
      return 0;
    } else {
      let splitBalance = balance.split(".");

      let backBalance = "";
      let afterVal = "";
      if (splitBalance.length == 2) {
        let q1 = splitBalance[1].length - (splitBalance[1].search(/[1-9]/) + 1);
        let nextVal;
        for (let i = 0; i < q1; i++) {
          nextVal = splitBalance[1].substr(
            splitBalance[1].search(/[1-9]/) + (i + 1),
            1
          );
          if (nextVal != "0") {
            afterVal = afterVal + `${nextVal}`;
            break;
          } else {
            afterVal = afterVal + "0";
          }
          if (i == q1 - 1) {
            nextVal = "";
          }
        }
        if (afterVal.search(/[1-9]/) == -1) {
          afterVal = "";
        }
        backBalance =
          splitBalance[1].substr(0, splitBalance[1].search(/[1-9]/) + 1) != ""
            ? "." +
              splitBalance[1].substr(0, splitBalance[1].search(/[1-9]/) + 1) +
              afterVal
            : "";
      }

      return splitBalance[0] + backBalance;
    }
  };

  const onClickMaxBtn = (balance) => {
    setInputAmount(balance);
    if (Number(balance) != 0) {
      setMessageStatus(false);
    }
  };

  const setPendingReward = async () => {
    if (
      defaultProvider != undefined &&
      chainId != 0 &&
      account != "" &&
      STAKING_LAB_ADDRESS != ""
    ) {
      const web3 = new Web3(defaultProvider);
      const stakeContract = new web3.eth.Contract(
        stakingJSON,
        STAKING_LAB_ADDRESS
      );
      let havePool = [];
      let data = [];
      for (let i = 0; i < allStakePoolList.length; i++) {
        let userInfo = await stakeContract.methods
          .userInfo(allStakePoolList[i].pid, account)
          .call();
        if (Number(userInfo.amount) != 0) {
          havePool.push(allStakePoolList[i]);
        }
      }
      for (let i = 0; i < havePool.length; i++) {
        let pendingValue = await getPendingReward(havePool[i].pid);
        pendingValue = fromWeiBalance(pendingValue, havePool[i].tokenDecimal);

        let setData = {
          index: havePool[i],
          pedingValue: pendingValue,
        };

        data.push(setData);
      }
      dispatch(setStakingReward(data));
    }
  };

  const toLocaleStringOption = (amount) => {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  // const [useEffectPendingInterval , setUseEffectPendingInterval] = useState();
  const stakingInterval = useSelector((state) => state.user.stakingInterval);

  useEffect(() => {
    let setIntervalPending;
    if (
      defaultProvider != undefined &&
      chainId != 0 &&
      allStakePoolList != "" &&
      account != "" &&
      stakingInterval == "" &&
      STAKING_LAB_ADDRESS != ""
    ) {
      setIntervalPending = setInterval(() => {
        let nowUrl = window.location.href.split("/")[3];
        if (nowUrl != "stake") {
          clearInterval(setIntervalPending);
          dispatch(setStakingInterval(""));
        }
        setPendingReward();
        // }, 9000);
      }, 1000);
      dispatch(setStakingInterval(setIntervalPending));
    } else if (account == "" && stakingInterval != "") {
      clearInterval(stakingInterval);
      dispatch(setStakingInterval(""));
    }
  }, [
    defaultProvider,
    account,
    chainId,
    allStakePoolList,
    stakingInterval,
    STAKING_LAB_ADDRESS,
  ]);

  const checkInputAmountDecimal = (token) => {
    // try {
    if (token.inputAmount != undefined || token.inputAmount != "") {
      let index = token.inputAmount.indexOf(".");
      if (index != -1) {
        let decimalLength = token.inputAmount.substr(
          index + 1,
          token.inputAmount.length
        );
        if (decimalLength.length <= token.decimal) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  };

  const onClickExplorer = () => {
    if (chainId == 8898) {
      window.open(`https://mmtscan.io/tx/${transactionHash}`);
    } else if (chainId == 88998) {
      window.open(`https://testnet.mmtscan.io/tx/${transactionHash}`);
    } else if (chainId == 97) {
      window.open(`https://testnet.bscscan.com/tx/${transactionHash}`);
    } else if (chainId == 8989) {
      window.open(`https://scan.gmmtchain.io/tx/${transactionHash}`);
    } else if (chainId == 898989) {
      window.open(`https://testnet-scan.gmmtchain.io/tx/${transactionHash}`);
    } else if (chainId == 1) {
      window.open(`https://etherscan.io/tx/${transactionHash}`);
    }
  };

  const onClickTitle = (pageName) => {
    const htmlTitle = document.querySelector("title");
    htmlTitle.innerHTML = pageName;
  };

  useEffect(() => {
    onClickTitle("Stake - Ivory Swap");
  }, []);

  const onClickStatus = (bool) => {
    window.scrollTo(0, 0);
    onClickActiveStatus(bool);
  };

  const checkModalStatusSubmit = () => {
    if (popupType == "submit") {
      setTimeout(() => {
        setPopupShow(false);
      }, [4000]);
    }
  };

  useEffect(() => {
    checkModalStatusSubmit();
  }, [popupType]);

  return (
    <div className={styles.bg}>
      <div className={styles.wrap}>
        <div className={styles.title}>
          Stake
          {/* <div className={styles.backButton}>
                        <Link to={"/stake"}>
                            <button>
                                Back
                            </button>
                        </Link>
                    </div> */}
        </div>
        <div className={styles.stakeExp}>
          Stake your IVY Tokens here to earn!
          <img
            src={require("../assets/images/img_tip_wh@2x.png")}
            alt={"img_tip_wh"}
          />
        </div>
        <div className={styles.stakeBox}>
          <div className={styles.stakeFilter}>
            <div className={styles.filter_left}>
              <button
                className={`${activeStatus ? styles.active : ""}`}
                onClick={() => onClickStatus(true)}
              >
                Active
              </button>
              <button
                className={`${activeStatus ? "" : styles.active}`}
                onClick={() => onClickStatus(false)}
              >
                Inactive
              </button>
            </div>
            <div className={styles.filter_right}>
              <div className={styles.sort}>
                <span>Sort by</span>
                <div className={styles.select}>
                  <div
                    className={`${styles.customSelectDim} ${
                      selectFlip == false ? "" : styles.dimOff
                    }`}
                    onClick={selectToggle}
                  />
                  <div
                    className={`${styles.customSelect} ${
                      selectFlip ? styles.show : ""
                    }`}
                  >
                    <div className={styles.selected} onClick={selectToggle}>
                      <span className={styles.coinName}>{selectedItem}</span>
                    </div>
                    {selectFlip && (
                      <div className={styles.itemList}>
                        {itemList &&
                          itemList?.map((item) => {
                            return (
                              <div
                                className={`${styles.item} ${
                                  item == selectedItem ? styles.on : ""
                                }`}
                                onClick={() => getSelectItem(item)}
                                key={item}
                              >
                                <span className={styles.coinName}>{item}</span>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.search}>
                <span>Search</span>
                <input
                  type={"text"}
                  placeholder={"Search Pools"}
                  value={searchText}
                  onChange={(e) => {
                    onChangeSearchText(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.stakeList}>
            <div className={styles.stakeList_title}>
              <div>
                <span>Pools</span>
              </div>
              <div>
                <span>APR</span>
              </div>
              <div>
                <span>Total staked</span>
              </div>
              <div>
                <span>Earned</span>
              </div>
            </div>
            {/* map */}

            {loading ? (
              <div className={styles.loadingWrap}>
                <img
                  src={require("../assets/images/swap/loading.gif")}
                  alt={"loading.gif"}
                />
              </div>
            ) : stakePoolList == "" ? (
              <div className={styles.noResult}>
                <img
                  src={require("../assets/images/ico-noresults-gr@2x.png")}
                  alt={"noresults"}
                />{" "}
                <br />
                <span>No Results Found</span>
              </div>
            ) : (
              <>
                {stakePoolList.map((item, index) => (
                  <div
                    className={`${styles.stakeList_item}`}
                    ref={elRefs[index]}
                    key={index}
                  >
                    <div
                      className={styles.flex}
                      onClick={() => farmToggle(index)}
                    >
                      {/* <div><img className={styles.tokenLogo} src={require("../assets/images/swap/logo_MMT.png")} alt={"token_ico"} /> 토큰</div> */}
                      <div>
                        <img
                          className={styles.tokenLogo}
                          src={`${chain}/logo_${item.RewardToken}.png`}
                          alt={`${item.tokenSymbol}`}
                          onError={onErrorImg}
                        />
                        {item.tokenSymbol}
                      </div>
                      <div>{item.apr}%</div>
                      {/* <div>$23,103,563</div> */}
                      {/* <div>${toLocaleStringOption(balanceInCode(item.sIvyStakedAmount))}</div> */}
                      <div>
                        {/* $ */}
                        {toLocaleStringOption(
                          balanceInCode(item.IvyStakedAmount)
                        )}
                      </div>
                      <div className={styles.earnedTop}>
                        {Number(item.pendingReward) != 0 &&
                        account != "" &&
                        active
                          ? toLocaleStringOption(
                              balanceInCode(item.pendingReward)
                            )
                          : "-"}
                        <img
                          className={styles.openArrow}
                          ref={imgRefs[index]}
                          src={require("../assets/images/swap/ico_arrow_bl.png")}
                          alt={"ico_arrow_bl"}
                        />
                      </div>
                    </div>
                    <div className={styles.itemDetail}>
                      <div className={styles.earned}>
                        <div className={styles.title}>
                          <span>{item.tokenSymbol}</span> Earned
                        </div>
                        <div className={styles.flex}>
                          <input
                            type={"text"}
                            placeholder={0.0}
                            value={
                              account != "" && active
                                ? balanceInCode(item.pendingReward)
                                : "0"
                            }
                            readOnly
                          />
                          {/* <span>0 USD</span> */}
                          <button
                            disabled={
                              Number(item.pendingReward) == 0 ? true : false
                            }
                            onClick={() => {
                              onClickFunction("harvest", item);
                            }}
                          >
                            harvest
                          </button>
                        </div>
                      </div>
                      <div className={styles.staked}>
                        <div className={styles.title}>Staked</div>
                        {account != "" && active ? (
                          item.allowance == false &&
                          Number(item.amount) == 0 ? (
                            <div className={styles.enableBtn}>
                              <button
                                onClick={() => {
                                  onClickApprove(index, item.inputTokenAddress);
                                }}
                              >
                                {approveIndex === index
                                  ? "Approving"
                                  : "Enable"}
                              </button>
                            </div>
                          ) : item.pendingReward != "0" ? (
                            <div className={styles.flex}>
                              <div className={styles.inputBox}>
                                <input
                                  type={"number"}
                                  placeholder={0.0}
                                  readOnly
                                  value={
                                    account != "" && active
                                      ? toLocaleStringOption(
                                          balanceInCode(item.amount)
                                        )
                                      : "0"
                                  }
                                />
                                {/* <span>0 USD</span> */}
                              </div>
                              <button
                                onClick={() => {
                                  setModalMsg("Stake");
                                  onClickPopUp("stake", "Add", item);
                                }}
                                className={styles.add}
                              >
                                {/* <img src={require("../assets/images/ico_plus_bl@3x.png")} alt={"ico_plus_bl"} />  */}
                                Add
                              </button>
                              <button
                                onClick={() => {
                                  setModalMsg("Unstake");
                                  onClickPopUp("stake", "Remove", item);
                                }}
                                className={styles.remove}
                              >
                                {/* <img src={require("../assets/images/ico_minus_red@3x.png")} alt={"ico_minus_red"} />  */}
                                Remove
                              </button>
                            </div>
                          ) : (
                            <div className={styles.enableBtn}>
                              {/* <button onClick={() =>{onClickApprove(index,item.RewardToken)}}>Stake</button> */}
                              <button
                                onClick={() => {
                                  onClickPopUp("stake", "Add", item);
                                }}
                              >
                                Stake
                              </button>
                            </div>
                          )
                        ) : (
                          <button onClick={handleConnect}>
                            Connect Wallet
                          </button>
                        )}
                      </div>
                      {/* <div className={styles.myInfo}>
                        <div className={styles.title}>Info</div>
                        <div className={styles.flex}>
                          <button>
                            <Link to={"/coming"}>
                              View Token Info{" "}
                              <img
                                src={require("../assets/images/swap/ico_arrow_bl@3x.png")}
                                alt={"ico_arrow_bl"}
                              />
                            </Link>
                          </button>
                          <button
                            onClick={() => {
                              onClickViewProjectSite(item.projectURL);
                            }}
                          >
                            View Project Site{" "}
                            <img
                              src={require("../assets/images/swap/ico_arrow_bl@3x.png")}
                              alt={"ico_arrow_bl"}
                            />
                          </button>
                          <button
                            onClick={() => {
                              addTokenInfo(item);
                            }}
                          >
                            Add to Metamask{" "}
                            <img
                              src={require("../assets/images/swap/ico_arrow_bl@3x.png")}
                              alt={"ico_arrow_bl"}
                            />
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>

      {popupShow && (
        <div className={styles.popupWrap}>
          <div className={styles.popupDim} onClick={closePopup} />
          {popupType === "enough" ? (
            <div
              className={`${styles.popup_notEnough} ${styles.popupContainer}`}
            >
              <img
                className={styles.closeBtn}
                onClick={closePopup}
                src={require("../assets/images/ico_close_bl@2x.png")}
                alt={"ico_close_bl"}
              />
              <div>
                <p>
                  <img
                    className={styles.notEnough}
                    src={require("../assets/images/ico_enough@2x.png")}
                    alt={"ico_enough"}
                  />
                </p>
                <span>
                  <span>Make sure that</span> <span>you have enough gas!</span>
                </span>
              </div>
            </div>
          ) : popupType === "stake" ? (
            <div className={`${styles.popup_stakeLP} ${styles.popupContainer}`}>
              <img
                className={styles.closeBtn}
                onClick={closePopup}
                src={require("../assets/images/ico_close_bl@2x.png")}
                alt={"ico_close_bl"}
              />
              <div className={styles.title}>
                {modalMsg} {selectstakingToken.tokenSymbol}
              </div>
              <div className={styles.tokenName_balance}>
                {/* <div className={styles.tokenName}>sIVY</div> */}
                <div className={styles.tokenName}>IVY</div>
                <div className={styles.balance}>
                  Balance:{" "}
                  {modalMsg == "Stake"
                    ? toLocaleStringOption(
                        balanceInCode(selectstakingToken.ivyBalance)
                      )
                    : toLocaleStringOption(
                        balanceInCode(selectstakingToken.amount)
                      )}
                </div>
              </div>
              <div className={styles.inputWrap}>
                <input
                  type={"text"}
                  placeholder={0.0}
                  onChange={(e) => {
                    onChangeInputVal(e);
                  }}
                  value={inputAmount}
                />
                <button
                  onClick={() => {
                    onClickMaxBtn(
                      `${
                        modalMsg == "Stake"
                          ? selectstakingToken.ivyBalance
                          : selectstakingToken.amount
                      }`
                    );
                  }}
                >
                  MAX
                </button>
                <div className={styles.warning}>
                  {`${
                    modalMsg == "Stake"
                      ? Number(selectstakingToken.ivyBalance) == 0
                        ? "No Tokens to stake!"
                        : ""
                      : Number(selectstakingToken.amount) == 0
                      ? "No Tokens to stake!"
                      : ""
                  }`}
                </div>
              </div>
              <div className={styles.btnBox}>
                <button className={styles.cancel} onClick={closePopup}>
                  Cancel
                </button>
                <button
                  disabled={messageStatus}
                  className={`${styles.confirm} ${
                    messageStatus ? "" : styles.btnActive
                  }`}
                  onClick={() => {
                    onClickFunction(stakeStatus, selectstakingToken);
                  }}
                >
                  {stakeStatus}
                </button>
              </div>
              {/*<div className={styles.getTokenBtn}>*/}
              {/*    Get TokenA-TokenB LP*/}
              {/*</div>*/}
            </div>
          ) : popupType === "confirm" ? (
            <div className={`${styles.popup_confirm} ${styles.popupContainer}`}>
              <img
                className={styles.closeBtn}
                onClick={closePopup}
                src={require("../assets/images/ico_close_bl@2x.png")}
                alt={"ico_close_bl"}
              />
              <div>
                <img
                  src={require("../assets/images/swap/loading.gif")}
                  alt={"loading_gif"}
                />
              </div>
              <div className={styles.bold}>Waiting for confirmation</div>
              <div className={styles.normal}>
                Confirm this transaction in your wallet
              </div>
              {/* <div className={styles.gray}>{stakeStatus} {toLocaleStringOption(balanceInCode(inputAmount))} sIVY</div> */}
              <div className={styles.gray}>
                {modalMsg} {toLocaleStringOption(balanceInCode(inputAmount))}{" "}
                IVY
              </div>
            </div>
          ) : popupType === "submit" ? (
            <div className={`${styles.popup_confirm} ${styles.popupContainer}`}>
              <img
                className={styles.closeBtn}
                onClick={closePopup}
                src={require("../assets/images/ico_close_bl@2x.png")}
                alt={"ico_close_bl"}
              />
              <div>
                <img
                  src={require("../assets/images/swap/ico_confirmed.png")}
                  alt={"ico_confirmed"}
                />
              </div>
              <div className={styles.bold}>Transaction Submitted</div>
              {/* <div className={styles.normal}>Confirm this transaction in your wallet</div>
                                            <div className={styles.gray}>Removing 0.5 tokenA and tokenB</div> */}
              <div
                className={styles.viewExplorer}
                onClick={() => onClickExplorer()}
              >
                View on Explorer{" "}
                <img
                  src={require("../assets/images/swap/green-arrow.png")}
                  alt={"green-arrow"}
                />
              </div>
            </div>
          ) : popupType === "Failed" ? (
            <div className={`${styles.popup_confirm} ${styles.popupContainer}`}>
              <img
                className={styles.closeBtn}
                onClick={closePopup}
                src={require("../assets/images/ico_close_bl@2x.png")}
                alt={"ico_close_bl"}
              />
              <div>
                <img
                  src={require("../assets/images/ico_rejected.png")}
                  alt={"ico-rejected"}
                />
              </div>
              <div className={styles.bold}>Transaction Rejected</div>
              {/* <div className={styles.normal}>Confirm this transaction in your wallet</div> */}
              <div
                className={styles.viewExplorer}
                onClick={() => onClickExplorer()}
              >
                View on Explorer{" "}
                <img
                  src={require("../assets/images/swap/green-arrow.png")}
                  alt={"green-arrow"}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default StakeList;
