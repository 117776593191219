import React, {useEffect, useState} from 'react';
import '../assets/css/CustomSelect.css';

const CustomImgSelect = ({ itemList, sendValue }) => {
    const [selectFlip, setSelectFlip] = useState(false);
    const [selectedItem, setSelectedItem] = useState(itemList[0]);


    const selectToggle = () => {
        setSelectFlip(!selectFlip);
    }

    const getSelectItem = (item) => {
        setSelectedItem(item);
        sendValue(item);
        selectToggle();
    }

    return (
       <div className={`customSelect ${selectFlip ? 'show' : ''}`}>
            <div className={"selected"} onClick={selectToggle}>
                <span className={"coinName"}>{selectedItem}</span>
                <img src={require("../assets/images/swap/ico_arrow_bl.png")} alt={"ico_arrow_bl"} />
            </div>
            {selectFlip &&
                <div className={"itemList"}>
                    {itemList && itemList?.map((item) => {
                        return(
                            <div className={"item"} onClick={() => getSelectItem(item)} key={item}>
                                <span className={"coinName"}>{item}</span>
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}

export default  CustomImgSelect;