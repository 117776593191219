import React from 'react'
import SwapComponent from '../components/SwapComponent'

const SwapContainer = ({ swapA, swapB, clickMaxBtn, onClickChange, onChangeInputAmount ,swapAmountIn,swapAmountOut}) => {
  return (
    <div>
      <SwapComponent who={"From"} swap={swapA} clickMaxBtn={clickMaxBtn} onChangeInputAmount={onChangeInputAmount} status={swapAmountIn}/>
      <div onClick={() => onClickChange()}>▽</div>
      <SwapComponent who={"To"} swap={swapB} onChangeInputAmount={onChangeInputAmount} status={swapAmountOut}/>
    </div>
  )
}

export default SwapContainer