import React from 'react'
import RemovePercentBtn from '../components/RemovePercentBtn'

const Amount = ({clickPercentBtn,percent}) => {
    return (
        <div>
            <div>Amount</div>
            <div>{percent}%</div>
            <RemovePercentBtn clickPercentBtn={clickPercentBtn} percent={0.25}/>
            <RemovePercentBtn clickPercentBtn={clickPercentBtn} percent={0.5}/>
            <RemovePercentBtn clickPercentBtn={clickPercentBtn} percent={0.75}/>
            <RemovePercentBtn clickPercentBtn={clickPercentBtn} percent={1}/>
        </div>
    )
}

export default Amount