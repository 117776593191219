import React from 'react'

export const LpTokenInYourWallet = ({ token_0, token_1, pair }) => {
  return (
    <div>
      <hr/>
      <div>
        LP TOKENS IN YOUR WALLET
        <div>{token_0.symbol}/{token_1.symbol}&nbsp;&nbsp;&nbsp;&nbsp; {pair.haveAmount}</div>
        <div>{token_0.symbol}:&nbsp;&nbsp;&nbsp;&nbsp; {token_0.haveAmount}</div>
        <div>{token_1.symbol}:&nbsp;&nbsp;&nbsp;&nbsp; {token_1.haveAmount}</div>
      </div>
    </div>
  )
}
