import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import Slippage from "../containers/Slippage";
import SwapContainer from "../containers/SwapContainer";
import Time from "../containers/Time";
import TokenList from "../containers/TokenList";

import ERC20JSON from "../abi/ERC20.json";
import Router from "../abi/Router.json";
import Factory from "../abi/Factory.json";
import WMMTJSON from "../abi/WMMT.json";
import PairJson from "../abi/Pair.json";
import tokensListJSON from "../tokenList/tokenList.json";
import tokenList_BSC_Testnet from "../tokenList/tokenList_BSC_Testnet.json";
import tokenList_MMT_Mainnet from "../tokenList/tokenList_MMT_Mainnet.json";
import tokenList_sepolia from "../tokenList/tokenList_sepolia.json";
import tokenList_EthereumMainnet from "../tokenList/tokenList_EthereumMainnet.json";
import tokenList_MainGMMT from "../tokenList/tokenList_MainGMMT.json";
import tokenList_TestGMMT from "../tokenList/tokenList_TestGMMT.json";
import { setList, setTokenList } from "../modules/liquidity";
import AddToken from "../containers/AddToken";
import swap, {
  changeInputAmountAll,
  changeSwap,
  maxSwapA,
  maxSwapB,
  onClickNavBar,
  setSwapAHaveAmount,
  setSwapAInputAmount,
  setSwapA_Allowance,
  setSwapBHaveAmount,
  setSwapBInputAmount,
  setSwapReset,
  setSwapTokenA,
  setSwapTokenB,
} from "../modules/swap";
import { calcDecimal, returnDecimal } from "../common/commonService";
import ApproveBtn from "../containers/ApproveBtn";
import SwapBtn from "../containers/SwapBtn";
import styles from "../assets/css/Swap.module.css";
import CustomImgSelect from "../components/CustomImgSelect";
import BN from "bn.js";
import { MaxUint256 } from "@ethersproject/constants";

import {
  setTransactionHash,
  setUpdateUserDeadLine,
  setUpdateUserSlippageTolerance,
} from "../modules/user";
// import TransactionHash from '../containers/TransactionHash';

import noneTokenImg from "../assets/images/sym_none.png";
import MMTTokenImg from "../assets/images/sym_mmt.png";
import { Link } from "react-router-dom";

// let chain = "BNB"
// let chain = "MMT"
const Swap = ({
  wCoinSymbol,
  coinSymbol,
  defaultProvider,
  handleConnect,
  active,
  providerWeb3,
  web3Provider,
  account,
  ROUTER_ADDRESS,
  WMMT_ADDRESS,
  chainId,
  FACTORY_ADDRESS,
}) => {
  const dispatch = useDispatch();

  // young
  const [slipPageShow, setSlipPageShow] = useState(false);
  const [timePageShow, setTimePageShow] = useState(false);
  const [questionHover, setQuestionHover] = useState("");
  const [popupShow, setPopupShow] = useState(false);
  const [nowModalStatus, setNowModalStatus] = useState("selectToken");
  const [addTokenMenuActive, setAddTokenMenuActive] = useState("token");
  const [selectActiveA, setSelectActiveA] = useState("NAN");
  const [selectedTokenA, setSelectedTokenA] = useState("finnRT");
  const [selectActiveB, setSelectActiveB] = useState("NAN");
  const [selectedTokenB, setSelectedTokenB] = useState("finnRT");
  const [tokenAStatus, setTokenAStatus] = useState("checked");
  const [tokenBStatus, setTokenBStatus] = useState("");
  const [swapAmountIn, setSwapAmountIn] = useState(false);
  const [swapAmountOut, setSwapAmountOut] = useState(false);
  const [selectTokenAddress, setSelectTokenAddress] = useState("");
  const [swapBtnMessage, setSwapBtnMessage] = useState("");
  const [swapBtnStatus, setSwapBtnStatus] = useState(true);
  const [swapLoading, setSwapLoading] = useState(0);
  // const [transactionHash, setTransactionHash] = useState("");
  const [searchToken, setSearchToken] = useState("");
  const [fromFocusActive, setFromFocusActive] = useState(false);
  const [toFocusActive, setToFocusActive] = useState(false);

  const [addTokenList, setAddTokenList] = useState([]);
  const [myAddTokenList, setMyAddTokenList] = useState("");

  const [selectActive, setSelectActive] = useState("NAN");
  const [selectedToken, setSelectedToken] = useState("finnRT");

  const [soldAndReceived, setSoldAndReceived] = useState("0");
  const [soldAndReceivedMessage, setSoldAndReceivedMessage] = useState("");
  const [swapSymbol, setSwapSymbol] = useState("");

  const [focusInput, setFocusInput] = useState("");

  const [addTokenSelectMessage, setAddtokenSelectMessage] = useState("");

  const [insufficientStatus, setInsufficientStatus] = useState(false);

  const tokenList = useSelector((state) => state.liquidity.tokenList);
  const swapA = useSelector((state) => state.swap.swapA);
  const swapB = useSelector((state) => state.swap.swapB);
  const slippage = useSelector((state) => state.user.userSlippageTolerance);
  const time = useSelector((state) => state.user.deadline);
  const transactionHash = useSelector((state) => state.user.transactionHash);

  const [resetStatus, setResetStatus] = useState(false);
  const [swapStatus, setSwapStatus] = useState(false);

  const [infoToastPopup, setInfoToastPop] = useState(false);

  const [chain, setChain] = useState("MMT");

  const [selectToken, setSelectToken] = useState();
  const [selectType, setSelectType] = useState("");

  useEffect(() => {
    if (chainId == 8898 || chainId == 88998) {
      setChain("MMT");
    } else if (chainId == 97) {
      setChain("BNB");
    } else if (chainId == 5 || chainId == 1) {
      setChain("ETH");
    } else if (chainId == 898989 || chainId == 8989) {
      setChain("GMMT");
    }
    setSoldAndReceived("0");
    setSelectToken();
  }, [chainId]);

  const slipPageToggle = () => {
    if (slippage == "" || Number(slippage) <= 0) {
      dispatch(setUpdateUserSlippageTolerance(0.5));
    }
    setSlipPageShow(!slipPageShow);
  };

  const timePageToggle = () => {
    if (time == 0) {
      dispatch(setUpdateUserDeadLine(20 * 60));
    }
    setTimePageShow(!timePageShow);
  };

  const clickSelectToken = (tokenName) => {
    setSelectActive(tokenName);
    setSelectedToken(tokenName);
    setPopupShow(false);
  };

  // useEffect(() => {
  //   if (nowModalStatus == 'loading') {
  //     if (transactionHash != "") {
  //       // setTimeout(() => {
  //       setNowModalStatus('submit');
  //       // }, 1500)
  //     }
  //   }
  // }, [nowModalStatus, transactionHash])

  const onClickTitle = (pageName) => {
    const htmlTitle = document.querySelector("title");
    htmlTitle.innerHTML = pageName;
  };

  useEffect(() => {
    onClickTitle("Swap - Ivory Swap");
  }, []);

  const changeRadioStatus = () => {
    if (tokenAStatus == "checked") {
      setTokenAStatus("");
      setTokenBStatus("checked");
    } else {
      setTokenAStatus("checked");
      setTokenBStatus("");
    }
  };

  const [netWorkList, setNetWorkTokenList] = useState([]);

  useEffect(() => {
    let list = [];
    if (chainId == 88998) {
      for (let i = 0; i < tokensListJSON.length; i++) {
        let data = {
          address: tokensListJSON[i].address.toLowerCase(),
          decimal: tokensListJSON[i].decimal,
          image: tokensListJSON[i].image.toLowerCase(),
          name: tokensListJSON[i].name,
          price: tokensListJSON[i].price,
          symbol: tokensListJSON[i].symbol,
          inputAmount: tokensListJSON[i].inputAmount,
        };
        list.push(data);
      }
      setNetWorkTokenList(list);
    } else if (chainId == 97) {
      setNetWorkTokenList(tokenList_BSC_Testnet);
    } else if (chainId == 8898) {
      for (let i = 0; i < tokenList_MMT_Mainnet.length; i++) {
        let data = {
          address: tokenList_MMT_Mainnet[i].address.toLowerCase(),
          decimal: tokenList_MMT_Mainnet[i].decimal,
          image: tokenList_MMT_Mainnet[i].image.toLowerCase(),
          name: tokenList_MMT_Mainnet[i].name,
          price: tokenList_MMT_Mainnet[i].price,
          symbol: tokenList_MMT_Mainnet[i].symbol,
          inputAmount: tokenList_MMT_Mainnet[i].inputAmount,
        };
        list.push(data);
      }
      setNetWorkTokenList(list);
    } else if (chainId == 5) {
      for (let i = 0; i < tokenList_sepolia.length; i++) {
        let data = {
          address: tokenList_sepolia[i].address.toLowerCase(),
          decimal: tokenList_sepolia[i].decimal,
          image: tokenList_sepolia[i].image.toLowerCase(),
          name: tokenList_sepolia[i].name,
          price: tokenList_sepolia[i].price,
          symbol: tokenList_sepolia[i].symbol,
          inputAmount: tokenList_sepolia[i].inputAmount,
        };
        list.push(data);
      }
      setNetWorkTokenList(list);
    } else if (chainId == 1) {
      for (let i = 0; i < tokenList_EthereumMainnet.length; i++) {
        let data = {
          address: tokenList_EthereumMainnet[i].address.toLowerCase(),
          decimal: tokenList_EthereumMainnet[i].decimal,
          image: tokenList_EthereumMainnet[i].image.toLowerCase(),
          name: tokenList_EthereumMainnet[i].name,
          price: tokenList_EthereumMainnet[i].price,
          symbol: tokenList_EthereumMainnet[i].symbol,
          inputAmount: tokenList_EthereumMainnet[i].inputAmount,
        };
        list.push(data);
      }
      setNetWorkTokenList(list);
    } else if (chainId == 8989) {
      for (let i = 0; i < tokenList_MainGMMT.length; i++) {
        let data = {
          address: tokenList_MainGMMT[i].address.toLowerCase(),
          decimal: tokenList_MainGMMT[i].decimal,
          image: tokenList_MainGMMT[i].image.toLowerCase(),
          name: tokenList_MainGMMT[i].name,
          price: tokenList_MainGMMT[i].price,
          symbol: tokenList_MainGMMT[i].symbol,
          inputAmount: tokenList_MainGMMT[i].inputAmount,
        };
        list.push(data);
      }
      setNetWorkTokenList(list);
    } else if (chainId == 898989) {
      for (let i = 0; i < tokenList_TestGMMT.length; i++) {
        let data = {
          address: tokenList_TestGMMT[i].address.toLowerCase(),
          decimal: tokenList_TestGMMT[i].decimal,
          image: tokenList_TestGMMT[i].image.toLowerCase(),
          name: tokenList_TestGMMT[i].name,
          price: tokenList_TestGMMT[i].price,
          symbol: tokenList_TestGMMT[i].symbol,
          inputAmount: tokenList_TestGMMT[i].inputAmount,
        };
        list.push(data);
      }
      setNetWorkTokenList(list);
    }
  }, [chainId]);

  const setTokensList = async () => {
    const data = [];
    // try {
    if (netWorkList != "" && defaultProvider != undefined) {
      const defalutWeb3 = defaultProvider;
      for (let i = 0; i < netWorkList.length; i++) {
        let haveAmount = 0;
        if (account != "" && active != false) {
          if (netWorkList[i].symbol != chain && netWorkList[i].address != "") {
            let contract;
            if (netWorkList[i].address != WMMT_ADDRESS.toLowerCase()) {
              contract = new defalutWeb3.eth.Contract(
                ERC20JSON,
                netWorkList[i].address
              );
            } else {
              contract = new defalutWeb3.eth.Contract(
                WMMTJSON,
                netWorkList[i].address
              );
            }
            if (account != "") {
              const balance = await contract.methods.balanceOf(account).call();
              const decimal = await contract.methods.decimals().call();
              let decimalPow = new BN(`${10}`)
                .pow(new BN(`${decimal}`))
                .toString();
              // (new BN(`${balance}`).div((new BN(`${10}`)).pow(new BN(`${tokensListJSON[i].decimal}`))).toString();
              let decimalTest = returnDecimal(defalutWeb3, decimalPow);
              // (new BN(`${10}`)).pow(new BN(`${18}`)).toString();
              // haveAmount = (new BN(`${balance}`).div((new BN(`${10}`)).pow(new BN(`${tokensListJSON[i].decimal}`)))).toString();
              // haveAmount = web3.utils.fromWei(`${balance}`,decimalTest);
              haveAmount = fromWeiBalance(balance, decimal);
            }
          } else {
            if (account != "") {
              haveAmount = await defalutWeb3.eth.getBalance(account);
              haveAmount = defalutWeb3.utils.fromWei(`${haveAmount}`);
              // (new BN(`${haveAmount}`).div((new BN(`${10}`)).pow(new BN(`${tokensListJSON[i].decimal}`))).toString();
              // haveAmount = (new BN(`${haveAmount}`).div((new BN(`${10}`)).pow(new BN(`${tokensListJSON[i].decimal}`)))).toString();
            }
          }
        }

        const body = {
          address: netWorkList[i].address,
          symbol: netWorkList[i].symbol,
          name: netWorkList[i].name,
          decimal: netWorkList[i].decimal,
          inputAmount: "",
          image: netWorkList[i].image.toLowerCase(),
          price: netWorkList[i].price,
          haveAmount: haveAmount,
        };
        data[i] = body;
      }
      // const reset = {
      //   address: "",
      //   symbol: "",
      //   name: "",
      //   decimal: 0,
      //   inputAmount: "",
      //   haveAmount: 0,
      //   image: "",
      //   price: 0,
      // }
      // setSelectActiveA(data[0].address)
      // setSelectedTokenA(data[0].address)
      // dispatch(onClickNavBar({
      //   body: data[0],
      //   reset: reset
      // }))
      dispatch(setList(data));
    }
    // } catch (error) {
    //   console.log('err==>!!',error)
    //   // console.log("error ==> ", error)
    // }
  };

  const searchTokenAddress = async (e) => {
    // const web3 = web3Provider;
    if (defaultProvider != undefined) {
      const web3 = defaultProvider;
      const address = e.target.value;
      setSelectTokenAddress(address);
      try {
        if (address.length == 42) {
          const contract = new web3.eth.Contract(ERC20JSON, address);
          const symbol = await contract.methods.symbol().call();
          const name = await contract.methods.name().call();
          const decimal = await contract.methods.decimals().call();
          let decimalPow = new BN(`${10}`).pow(new BN(`${decimal}`)).toString();
          // let re_Decimals = returnDecimal(web3Provider, decimalPow);
          let balance = 0;
          if (account != "") {
            balance = await contract.methods.balanceOf(account).call();
          }
          const price = 5000;

          // const haveAmount = web3.utils.fromWei(balance,re_Decimals);
          const haveAmount = fromWeiBalance(balance, decimal);

          let body = {
            address: address.toLowerCase(),
            symbol: symbol,
            name: name,
            decimal: decimal,
            inputAmount: "",
            price: price,
            haveAmount: haveAmount,
            image: address.toLowerCase(),
          };

          setAddTokenList([body]);
        } else if (address == "") {
          setAddTokenList([]);
          setAddtokenSelectMessage("");
        } else {
          setAddTokenList([]);
          setAddtokenSelectMessage("Enter valid token address");
        }
      } catch (error) {
        console.log("error ==>", error);
        setAddTokenList([]);
        setAddtokenSelectMessage("Enter valid token address");
      }
    }
  };

  const clickAddTokenAndList = (item) => {
    let image = "";
    if (
      item.symbol == "MMT" ||
      item.symbol == "WMMT" ||
      item.symbol == "BTC" ||
      item.symbol == "ETH"
    ) {
      image = item.symbol.toLowerCase();
    }
    let body = {
      address: item.address,
      symbol: item.symbol,
      name: item.name,
      image: item.address.toLowerCase(),
      inputAmount: "",
      decimal: item.decimal,
    };
    onClickAddToken(body);
    body = {
      address: item.address,
      symbol: item.symbol,
      name: item.name,
      image: item.address.toLowerCase(),
      inputAmount: "",
      decimal: item.decimal,
      chainId: chainId,
    };
    let getTokenList = JSON.parse(window.localStorage.getItem("addTokenList"));
    let a;
    if (getTokenList.tokens == "") {
      a = getTokenList.tokens;
    } else {
      a = getTokenList.tokens.getTokenList;
    }

    if (a.length == 0) {
      a.push(body);
      getTokenList = a;
      window.localStorage.setItem(
        "addTokenList",
        JSON.stringify({
          tokens: {
            getTokenList,
          },
        })
      );
    } else {
      let result = true;
      for (let i = 0; i < a.length; i++) {
        if (a[i].address == body.address) {
          result = false;
        }
      }
      if (result == true) {
        a.push(body);
        getTokenList = a;
        window.localStorage.setItem(
          "addTokenList",
          JSON.stringify({
            tokens: {
              getTokenList,
            },
          })
        );
      }

      setSelectTokenAddress("");
      setAddTokenList([]);
      // 토큰 선택하면 추가하는 걸 만들기
    }
  };

  const getAddTokenList = () => {
    // if (account != "" && chainId != 0) {
    let getTokenList = JSON.parse(window.localStorage.getItem("addTokenList"));
    if (chainId != 0 && getTokenList == null) {
      window.localStorage.setItem(
        "addTokenList",
        JSON.stringify({ tokens: [] })
      );
    } else if (getTokenList.tokens != []) {
      getTokenList = getTokenList.tokens.getTokenList;
      if (getTokenList == undefined) {
        getTokenList = [];
      } else {
        let data = [];
        for (let i = 0; i < getTokenList.length; i++) {
          if (chainId == getTokenList[i].chainId) {
            data.push(getTokenList[i]);
          }
        }
        getTokenList = data;
      }
      setMyAddTokenList(getTokenList);
    }
    // }
  };

  const onClickAddToken = (item) => {
    const body = item;
    const reset = {
      address: "",
      symbol: "",
      name: "",
      decimal: 0,
      inputAmount: "",
      image: "",
      haveAmount: 0,
    };

    let q1 = swapA.address == "" && swapA.symbol == ""; // swapA 비어 있을 때
    let q2 =
      (swapA.address != "" && swapA.symbol != "") ||
      (swapA.address == "" && swapA.symbol == chain); // swapA 비어있지 않거나 address는 비어있고 symbol은 mmt일 때
    let q3 =
      swapA.address.toLowerCase() != item.address.toLowerCase() &&
      swapA.symbol != item.symbol;
    let q3_1 =
      swapA.address.toLowerCase() != item.address.toLowerCase() &&
      swapA.symbol == item.symbol;
    let q4 =
      swapA.address.toLowerCase() == item.address.toLowerCase() &&
      swapA.symbol == item.symbol;

    let q5 = swapB.address == "" && swapB.symbol == "";
    let q6 =
      (swapB.address != "" && swapB.symbol != "") ||
      (swapB.address == "" && swapB.symbol == chain);
    let q7 =
      swapB.address.toLowerCase() != item.address.toLowerCase() &&
      swapB.symbol != item.symbol;
    let q7_1 =
      swapB.address.toLowerCase() != item.address.toLowerCase() &&
      swapB.symbol == item.symbol;
    let q8 =
      swapB.address.toLowerCase() == item.address.toLowerCase() &&
      swapB.symbol == item.symbol;

    if (tokenAStatus == "checked") {
      if (q1) {
        if (q5) {
          dispatch(setSwapTokenA(body));
          setSelectActiveA(body.address);
          setSelectedTokenA(body.symbol);
        } else if (q6) {
          if (q7 || q7_1) {
            dispatch(setSwapTokenA(body));
            setSelectActiveA(body.address);
            setSelectedTokenA(body.symbol);
          } else if (q8) {
            dispatch(setSwapTokenA(body));
            dispatch(setSwapTokenB(reset));
            setSelectActiveA(body.address);
            setSelectedTokenA(body.symbol);
            setSelectActiveB("NAN");
            setSelectedTokenB("NAN");
          }
        }
      } else if (q2) {
        if (q5) {
          dispatch(setSwapTokenA(body));
          setSelectActiveA(body.address);
          setSelectedTokenA(body.symbol);
        } else if (q6) {
          if (q7 || q7_1) {
            dispatch(setSwapTokenA(body));
            setSelectActiveA(body.address);
            setSelectedTokenA(body.symbol);
          } else if (q8) {
            dispatch(setSwapTokenA(body));
            dispatch(setSwapTokenB(swapA));
            setSelectActiveA(body.address);
            setSelectedTokenA(body.symbol);
            setSelectActiveB(swapA.address);
            setSelectedTokenB(swapA.symbol);
          }
        }
      }
    } else if (tokenBStatus == "checked") {
      if (q5) {
        if (q1) {
          dispatch(setSwapTokenB(body));
          setSelectActiveB(body.address);
          setSelectedTokenB(body.symbol);
        } else if (q2) {
          if (q3 || q3_1) {
            dispatch(setSwapTokenB(body));
            setSelectActiveB(body.address);
            setSelectedTokenB(body.symbol);
          } else if (q4) {
            dispatch(setSwapTokenA(reset));
            dispatch(setSwapTokenB(body));
            setSelectActiveA("NAN");
            setSelectedTokenA("NAN");
            setSelectActiveB(body.address);
            setSelectedTokenB(body.symbol);
          }
        }
      } else if (q6) {
        if (q1) {
          dispatch(setSwapTokenB(body));
          setSelectActiveB(body.address);
          setSelectedTokenB(body.symbol);
        } else if (q2) {
          if (q3 || q3_1) {
            dispatch(setSwapTokenB(body));
            setSelectActiveB(body.address);
            setSelectedTokenB(body.symbol);
          } else if (q4) {
            dispatch(setSwapTokenA(swapB));
            dispatch(setSwapTokenB(body));
            setSelectActiveA(swapB.address);
            setSelectedTokenA(swapB.symbol);
            setSelectActiveB(body.address);
            setSelectedTokenB(body.symbol);
          }
        }
      }
    }
    if (searchToken != "") {
      setSearchToken("");
    }
    setNowModalStatus("selectToken");
    setPopupShow(false);
  };

  const checkSwapBalance = async () => {
    if (defaultProvider != undefined) {
      const web3 = defaultProvider;
      //balance
      let haveAmount = 0;
      try {
        if (account != "" && active == true) {
          if (
            (swapA.symbol == chain && swapA.address == "") ||
            (swapB.symbol == chain && swapB.address == "")
          ) {
            if (swapA.symbol == chain) {
              haveAmount = await web3.eth.getBalance(account);
              haveAmount = web3.utils.fromWei(haveAmount);
              let balance = 0;
              let decimals = 0;
              if (swapB.symbol != "") {
                const contract = new web3.eth.Contract(
                  ERC20JSON,
                  swapB.address
                );
                balance = await contract.methods.balanceOf(account).call();
                decimals = await contract.methods.decimals().call();
                let decimalPow = new BN(`${10}`)
                  .pow(new BN(`${decimals}`))
                  .toString();
                // let re_Decimals = returnDecimal(web3Provider, decimalPow);
                // balance = web3.utils.fromWei(balance,re_Decimals);
                balance = fromWeiBalance(balance, decimals);
              }
              dispatch(setSwapAHaveAmount(haveAmount));
              dispatch(setSwapBHaveAmount(balance));
            } else {
              haveAmount = await web3.eth.getBalance(account);
              haveAmount = web3.utils.fromWei(haveAmount);
              let balance = 0;
              let decimals = 0;
              if (swapA.symbol != "") {
                const contract = new web3.eth.Contract(
                  ERC20JSON,
                  swapA.address
                );
                balance = await contract.methods.balanceOf(account).call();
                decimals = await contract.methods.decimals().call();
                let decimalPow = new BN(`${10}`)
                  .pow(new BN(`${decimals}`))
                  .toString();
                // let re_Decimals = returnDecimal(web3Provider, decimalPow);
                // balance = web3.utils.fromWei(balance,re_Decimals);
                balance = fromWeiBalance(balance, decimals);
              }
              dispatch(setSwapAHaveAmount(balance));
              dispatch(setSwapBHaveAmount(haveAmount));
            }
          } else {
            let balanceA = 0;
            let decimalsA = 0;
            if (swapA.address != "") {
              const contractA = new web3.eth.Contract(ERC20JSON, swapA.address);
              const tokenABalance = await contractA.methods
                .balanceOf(account)
                .call();
              decimalsA = await contractA.methods.decimals().call();
              let decimalPow = new BN(`${10}`)
                .pow(new BN(`${decimalsA}`))
                .toString();
              // let re_Decimals = returnDecimal(web3Provider, decimalPow);
              // balanceA = web3.utils.fromWei(tokenABalance,re_Decimals);
              balanceA = fromWeiBalance(tokenABalance, decimalsA);
            }

            let balanceB = 0;
            let decimalsB = 0;
            if (swapB.address != "") {
              const contractB = new web3.eth.Contract(ERC20JSON, swapB.address);
              const tokenBBalance = await contractB.methods
                .balanceOf(account)
                .call();
              decimalsB = await contractB.methods.decimals().call();
              let decimalPow = new BN(`${10}`)
                .pow(new BN(`${decimalsB}`))
                .toString();
              // let re_Decimals = returnDecimal(web3Provider, decimalPow);
              // balanceB = web3.utils.fromWei(tokenBBalance,re_Decimals);
              balanceB = fromWeiBalance(tokenBBalance, decimalsB);
            }
            dispatch(setSwapAHaveAmount(balanceA));
            dispatch(setSwapBHaveAmount(balanceB));
          }
        } else if (active == false) {
          dispatch(setSwapAHaveAmount(haveAmount));
          dispatch(setSwapBHaveAmount(haveAmount));
        }
      } catch (error) {}
    }
  };

  const clickMaxBtn = async () => {
    const web3 = web3Provider;
    try {
      let q1 =
        swapA.symbol == chain &&
        swapA.address == "" &&
        swapB.symbol == "WMMT" &&
        swapB.address == WMMT_ADDRESS.toLowerCase();
      let q2 =
        swapA.symbol == "WMMT" &&
        swapA.address == WMMT_ADDRESS.toLowerCase() &&
        swapB.symbol == chain &&
        swapB.address == "";
      //
      if (q1 || q2) {
        dispatch(maxSwapA(swapA.haveAmount));
        dispatch(setSwapBInputAmount(swapA.haveAmount));
      } else {
        const contract = new web3.eth.Contract(Router, ROUTER_ADDRESS);
        let path = [];
        let swap0 = "";
        let swap1 = "";
        if (swapA.symbol == chain && swapA.address == "") {
          swap0 = WMMT_ADDRESS;
        } else {
          swap0 = swapA.address;
        }
        if (swapB.symbol == chain && swapB.address == "") {
          swap1 = WMMT_ADDRESS;
        } else {
          swap1 = swapB.address;
        }
        dispatch(maxSwapA(swapA.haveAmount));
        let amountIn = web3.utils.toWei(String(swapA.haveAmount));
        path.push(swap0);
        path.push(swap1);
        let swapBInputAmount = await contract.methods
          .getAmountsOut(amountIn, path)
          .call();
        swapBInputAmount = web3.utils.fromWei(swapBInputAmount[1]);
        dispatch(setSwapBInputAmount(swapBInputAmount));
        setSwapAmountIn(false);
        setSwapAmountOut(true);
      }
    } catch (error) {
      setSwapBtnMessage("Insufficient liquidity for this trade");
    }
  };

  // decimals
  const onChangeInputAmount = async (e, who) => {
    let amount = e.target.value;
    let pattern = /^[0-9]*[.]?[0-9]*$/;
    setFocusInput(who);
    try {
      if (
        defaultProvider != "" &&
        ROUTER_ADDRESS != "" &&
        WMMT_ADDRESS != "" &&
        FACTORY_ADDRESS != ""
      ) {
        if (pattern.test(amount) == true) {
          // if (account != "") {
          // const web3 = web3Provider;

          let web3Provider = defaultProvider;
          const web3 = defaultProvider;
          const contract = new web3.eth.Contract(Router, ROUTER_ADDRESS);
          let path = [];
          let swap0 = "";
          let swap1 = "";
          if (swapA.symbol == chain && swapA.address == "") {
            swap0 = WMMT_ADDRESS;
          } else {
            swap0 = swapA.address;
          }
          if (swapB.symbol == chain && swapB.address == "") {
            swap1 = WMMT_ADDRESS;
          } else {
            swap1 = swapB.address;
          }

          let factoryContract;
          let pair;

          let A =
            swapA.symbol == chain &&
            swapA.address == "" &&
            swapB.symbol == wCoinSymbol &&
            swapB.address == WMMT_ADDRESS.toLowerCase();
          let B =
            swapA.symbol == wCoinSymbol &&
            swapA.address == WMMT_ADDRESS.toLowerCase() &&
            swapB.symbol == chain &&
            swapB.address == "";
          if (who == "From") {
            dispatch(setSwapAInputAmount(amount));
            if (amount == "") {
              dispatch(setSwapBInputAmount(""));
              setSoldAndReceived(0);
              setSwapSymbol("");
            } else {
              if (swap0 != "" && swap1 != "") {
                if (Number(amount) <= 0) {
                  dispatch(setSwapBInputAmount(""));
                  setSoldAndReceived(0);
                  setSwapSymbol("");
                } else if (A) {
                  dispatch(setSwapBInputAmount(amount));
                } else if (B) {
                  dispatch(setSwapBInputAmount(amount));
                } else if (
                  checkInputAmountDecimal(swapA, amount) == false &&
                  who == "From"
                ) {
                  setSwapBtnMessage("Enter an amount");
                  setSwapBtnStatus(true);
                  dispatch(setSwapBInputAmount(""));
                  setSoldAndReceived("0");
                } else {
                  factoryContract = new web3.eth.Contract(
                    Factory,
                    FACTORY_ADDRESS
                  );
                  pair = await factoryContract.methods
                    .getPair(swap0, swap1)
                    .call();
                  if (pair == "0x0000000000000000000000000000000000000000") {
                    if (account == "") {
                      setSwapBtnStatus(false);
                      setSwapBtnMessage("Connect Wallet");
                    } else {
                      setSwapBtnStatus(true);
                      setSwapBtnMessage(
                        "Insufficient liquidity for this trade"
                      );
                    }
                    dispatch(setSwapBInputAmount(""));
                  } else {
                    if (Number(amount) * 1 != 0) {
                      if (
                        swapA.symbol == chain &&
                        swapA.address == "" &&
                        swapB.symbol == wCoinSymbol &&
                        swapB.address == WMMT_ADDRESS.toLowerCase()
                      ) {
                        //Deposit
                        dispatch(setSwapBInputAmount(amount));
                      } else if (
                        swapA.symbol == wCoinSymbol &&
                        swapA.address == WMMT_ADDRESS.toLowerCase() &&
                        swapB.symbol == chain &&
                        swapB.address == ""
                      ) {
                        //WithDraw
                        dispatch(setSwapBInputAmount(amount));
                        // } else if (Number(amount) > Number(swapA.haveAmount)) {
                        //   dispatch(setSwapBInputAmount(""));
                      } else if (
                        checkInputAmountDecimal(swapA, amount) == false &&
                        who == "From"
                      ) {
                        setSwapBtnMessage("Enter an amount");
                        setSwapBtnStatus(true);
                        dispatch(setSwapBInputAmount(""));
                        setSoldAndReceived("0");
                      } else if (
                        swapA.symbol != "" &&
                        swapB.symbol != "" &&
                        amount != ""
                      ) {
                        let decimalPow = new BN(`${10}`)
                          .pow(new BN(`${swapA.decimal}`))
                          .toString();
                        // let re_Decimals = returnDecimal(web3Provider, decimalPow);

                        // let amountIn = web3.utils.toWei(String(amount),re_Decimals);
                        let amountIn = toWeiBalance(
                          String(amount),
                          swapA.decimal
                        );
                        path.push(swap0);
                        path.push(swap1);
                        let swapBInputAmount = "";

                        let toValue;
                        let minimumValue;

                        let pairContract = new web3.eth.Contract(
                          PairJson,
                          pair
                        );

                        let pairGetReserves = await pairContract.methods
                          .getReserves()
                          .call();

                        let reserve0address = await pairContract.methods
                          .token0()
                          .call();
                        let reserve1address = await pairContract.methods
                          .token1()
                          .call();

                        let k = new BN(`${pairGetReserves[0]}`)
                          .mul(new BN(`${pairGetReserves[1]}`))
                          .toString();
                        try {
                          swapBInputAmount = await contract.methods
                            .getAmountsOut(amountIn, path)
                            .call();

                          // swapBInputAmount = web3.utils.fromWei(swapBInputAmount[1]);
                          swapBInputAmount = swapBInputAmount[1];

                          // if (swap0 == reserve0address) {
                          //   // reserve0
                          //   let mulInput = new BN(`${amountIn}`).mul(new BN(`${3}`)).div(new BN(`${1000}`)).toString();
                          //   let subInput = new BN(amountIn).sub(new BN(`${mulInput}`)).toString();
                          //   let addReserve0 = new BN(`${subInput}`).add(new BN(`${pairGetReserves[0]}`)).toString();
                          //   let divReserve1 = new BN(`${k}`).div(new BN(`${addReserve0}`)).toString()
                          //   toValue = new BN(`${pairGetReserves[1]}`).sub(new BN(`${divReserve1}`)).toString();

                          //   // let mulSlippage = new BN(`${slippage}`).mul(new BN(`${100}`));
                          //   let floorSlippage = Math.floor(slippage * 100);
                          //   let re_slippage = (new BN(`${swapBInputAmount}`).mul(new BN(`${floorSlippage}`))).div(new BN(`${10000}`)).toString()
                          //   // let re_slippage = (new BN(`${swapBInputAmount}`).mul(new BN(`${slippage * 1000}`))).toString()
                          //   minimumValue = new BN(`${toValue}`).sub(new BN(`${re_slippage}`)).toString();

                          // } else if (swap0 == reserve1address) {
                          //   // reserve1
                          //   // (new BN(`${amountIn}`).mul(new BN(`${3}`))).div(new BN(`${1000}`)).add(new BN(`${pairGetReserves[1]}`));
                          //   // new BN(`${k}`).div((new BN(`${amountIn}`).mul(new BN(`${3}`))).div(new BN(`${1000}`)).add(new BN(`${pairGetReserves[1]}`)))

                          //   let mulInput = new BN(`${amountIn}`).mul(new BN(`${3}`)).div(new BN(`${1000}`)).toString();
                          //   let subInput = new BN(amountIn).sub(new BN(`${mulInput}`)).toString();
                          //   let addReserve0 = new BN(`${subInput}`).add(new BN(`${pairGetReserves[1]}`)).toString();
                          //   let divReserve1 = new BN(`${k}`).div(new BN(`${addReserve0}`)).toString()
                          //   toValue = new BN(`${pairGetReserves[0]}`).sub(new BN(`${divReserve1}`)).toString();
                          //   let floorSlippage = Math.floor(slippage * 100);
                          //   let re_slippage = (new BN(`${swapBInputAmount}`).mul(new BN(`${floorSlippage}`))).div(new BN(`${10000}`)).toString()
                          //   minimumValue = new BN(`${toValue}`).sub(new BN(`${re_slippage}`)).toString();

                          // };

                          toValue = swapBInputAmount;
                          let floorSlippage = Math.floor(slippage * 1000);
                          let re_slippage = new BN(`${swapBInputAmount}`)
                            .mul(new BN(`${floorSlippage}`))
                            .div(new BN(`${100000}`))
                            .toString();
                          minimumValue = new BN(`${toValue}`)
                            .sub(new BN(`${re_slippage}`))
                            .toString();
                          // let t = (new BN(`${swapBInputAmount}`).mul(new BN(`${floorSlippage}`))).divmod(new BN(`${100000}`)).div.toString();
                          // 여기부터
                          let decimalPowB = new BN(`${10}`)
                            .pow(new BN(`${swapB.decimal}`))
                            .toString();
                          // let re_DecimalsB = returnDecimal(web3Provider, decimalPowB);

                          // toValue = web3.utils.fromWei(String(toValue),re_DecimalsB);
                          // minimumValue = web3.utils.fromWei(String(minimumValue),re_DecimalsB);
                          toValue = fromWeiBalance(
                            String(toValue),
                            swapB.decimal
                          );
                          minimumValue = fromWeiBalance(
                            String(minimumValue),
                            swapB.decimal
                          );
                          setSoldAndReceivedMessage("Minimum received");
                          setSoldAndReceived(minimumValue);
                          dispatch(setSwapBInputAmount(toValue));
                          setSwapSymbol(swapB.symbol);
                          setSwapAmountIn(false);
                          setSwapAmountOut(true);
                        } catch (error) {
                          // console.log("error",error)
                          dispatch(setSwapBInputAmount(""));
                          setSoldAndReceivedMessage("Received Or Sold");
                          if (account == "") {
                            setSwapBtnStatus(true);
                          }
                          setSoldAndReceived(0);
                          setSwapSymbol("");
                          setSwapBtnMessage(
                            "Insufficient liquidity for this trade"
                          );
                        }
                      }
                    }
                  }
                }
              }
            }
          } else if (who == "To") {
            dispatch(setSwapBInputAmount(amount));
            if (amount == "") {
              dispatch(setSwapAInputAmount(""));
              setSoldAndReceived(0);
              setSwapSymbol("");
            } else {
              if (swap0 != "" && swap1 != "") {
                if (Number(amount) <= 0) {
                  dispatch(setSwapAInputAmount(""));
                  setSoldAndReceived(0);
                  setSwapSymbol("");
                } else if (A) {
                  dispatch(setSwapAInputAmount(amount));
                } else if (B) {
                  dispatch(setSwapAInputAmount(amount));
                } else if (
                  checkInputAmountDecimal(swapB, amount) == false &&
                  who == "To"
                ) {
                  setSwapBtnMessage("Enter an amount");
                  setSwapBtnStatus(true);
                  dispatch(setSwapAInputAmount(""));
                  setSoldAndReceived("0");
                } else {
                  factoryContract = new web3.eth.Contract(
                    Factory,
                    FACTORY_ADDRESS
                  );
                  pair = await factoryContract.methods
                    .getPair(swap0, swap1)
                    .call();
                  if (pair == "0x0000000000000000000000000000000000000000") {
                    if (account == "") {
                      setSwapBtnStatus(false);
                      setSwapBtnMessage("Connect Wallet");
                    } else {
                      setSwapBtnStatus(true);
                      setSwapBtnMessage(
                        "Insufficient liquidity for this trade"
                      );
                    }
                    dispatch(setSwapAInputAmount(""));
                  } else {
                    if (Number(amount) * 1 != 0) {
                      if (
                        swapA.symbol == chain &&
                        swapA.address == "" &&
                        swapB.symbol == wCoinSymbol &&
                        swapB.address == WMMT_ADDRESS.toLowerCase()
                      ) {
                        //Deposit
                        dispatch(setSwapAInputAmount(amount));
                      } else if (
                        swapA.symbol == wCoinSymbol &&
                        swapA.address == WMMT_ADDRESS.toLowerCase() &&
                        swapB.symbol == chain &&
                        swapB.address == ""
                      ) {
                        //WithDraw
                        dispatch(setSwapAInputAmount(amount));
                        // } else if (Number(amount) > Number(swapA.haveAmount)) {
                        //   dispatch(setSwapAInputAmount(""));
                      } else if (
                        checkInputAmountDecimal(swapB, amount) == false &&
                        who == "To"
                      ) {
                        setSwapBtnMessage("Enter an amount");
                        setSwapBtnStatus(true);
                        dispatch(setSwapAInputAmount(""));
                        setSoldAndReceived("0");
                      } else if (
                        swapA.symbol != "" &&
                        swapB.symbol != "" &&
                        amount != ""
                      ) {
                        try {
                          let decimalPow = new BN(`${10}`)
                            .pow(new BN(`${swapB.decimal}`))
                            .toString();
                          // let re_Decimals = returnDecimal(web3Provider, decimalPow);

                          // let amountOut = web3.utils.toWei(String(amount),re_Decimals);
                          let amountOut = toWeiBalance(
                            String(amount),
                            swapB.decimal
                          );
                          path.push(swap0);
                          path.push(swap1);
                          let swapAInputAmount = await contract.methods
                            .getAmountsIn(amountOut, path)
                            .call();
                          swapAInputAmount = swapAInputAmount[0];

                          let fromValue;
                          let maximumValue;

                          // let pairContract = new web3.eth.Contract(PairJson, pair);
                          // let pairGetReserves = await pairContract.methods.getReserves().call();
                          // let reserve0address = await pairContract.methods.token0().call();
                          // let reserve1address = await pairContract.methods.token1().call();
                          // let k = new BN(`${pairGetReserves[0]}`).mul(new BN(`${pairGetReserves[1]}`)).toString();
                          // let floorSlippage = Math.floor(slippage * 100);

                          // if (swap0 == reserve0address) {
                          //   // reserve0
                          //   let subReserve0 = (new BN(`${pairGetReserves[1]}`).sub(new BN(`${amountOut}`))).toString();
                          //   let divReserve0 = new BN(`${k}`).div(new BN(`${subReserve0}`)).toString();
                          //   let subReserve1 = new BN(`${divReserve0}`).sub(new BN(`${pairGetReserves[0]}`)).toString();
                          //   // new BN(`${subReserve0}`).mul(new BN(`${3}`))
                          //   // (new BN(`${subReserve1}`).mul(new BN(`${3}`))).div(new BN(`${1000}`))
                          //   // new BN(`${subReserve1}`).add(new BN(new BN(`${subReserve1}`).mul(new BN(`${3}`))).div(new BN(`${1000}`)))

                          //   let mulInput = new BN(new BN(`${subReserve1}`).mul(new BN(`${3}`))).div(new BN(`${1000}`)).toString();
                          //   fromValue = new BN(subReserve1).add(new BN(`${mulInput}`)).toString(); //from 값

                          //   let addSlippage = (new BN(`${swapAInputAmount}`).mul(new BN(`${floorSlippage}`))).div(new BN(`${10000}`)).toString();

                          //   maximumValue = (new BN(`${fromValue}`).add(new BN(`${addSlippage}`))).toString();
                          // } else if (swap0 == reserve1address) {
                          //   // reserve1
                          //   let subReserve1 = (new BN(`${pairGetReserves[0]}`).sub(new BN(`${amountOut}`))).toString();
                          //   let divReserve1 = new BN(`${k}`).div(new BN(`${subReserve1}`)).toString();
                          //   let subReserve0 = new BN(`${divReserve1}`).sub(new BN(`${pairGetReserves[1]}`)).toString();
                          //   // new BN(`${subReserve0}`).mul(new BN(`${3}`))
                          //   // (new BN(`${subReserve1}`).mul(new BN(`${3}`))).div(new BN(`${1000}`))
                          //   // new BN(`${subReserve1}`).add(new BN(new BN(`${subReserve1}`).mul(new BN(`${3}`))).div(new BN(`${1000}`)))

                          //   let mulInput = new BN(new BN(`${subReserve0}`).mul(new BN(`${3}`))).div(new BN(`${1000}`)).toString();
                          //   fromValue = new BN(subReserve0).add(new BN(`${mulInput}`)).toString(); //from 값

                          //   let addSlippage = new BN(`${swapAInputAmount}`).mul(new BN(`${floorSlippage}`)).div(new BN(`${10000}`)).toString();
                          //   maximumValue = (new BN(`${fromValue}`).add(new BN(`${addSlippage}`))).toString();
                          // }

                          let decimalPowA = new BN(`${10}`)
                            .pow(new BN(`${swapA.decimal}`))
                            .toString();
                          // let re_DecimalsA = returnDecimal(web3Provider, decimalPowA);

                          fromValue = swapAInputAmount;
                          let floorSlippage = Math.floor(slippage * 1000);
                          let re_slippage = new BN(`${fromValue}`)
                            .mul(new BN(`${floorSlippage}`))
                            .div(new BN(`${100000}`))
                            .toString();
                          maximumValue = new BN(`${fromValue}`)
                            .add(new BN(`${re_slippage}`))
                            .toString();

                          fromValue = fromWeiBalance(
                            String(fromValue),
                            swapA.decimal
                          );
                          maximumValue = fromWeiBalance(
                            String(maximumValue),
                            swapA.decimal
                          );

                          dispatch(setSwapAInputAmount(fromValue));
                          setSoldAndReceivedMessage("Maximum sold");
                          setSoldAndReceived(maximumValue);
                          setSwapSymbol(swapA.symbol);
                          setSwapAmountIn(true);
                          setSwapAmountOut(false);
                        } catch (error) {
                          console.log(error);
                          dispatch(setSwapAInputAmount(""));
                          setSoldAndReceived(0);
                          setSoldAndReceivedMessage("Received Or Sold");
                          setSwapSymbol("");
                          if (account == "") {
                            setSwapBtnStatus(false);
                            setSwapBtnMessage("Connect Wallet");
                          } else {
                            setSwapBtnStatus(true);
                            setSwapBtnMessage(
                              "Insufficient liquidity for this trade"
                            );
                          }
                        }
                      }
                    }
                    // else {
                    //   dispatch(setSwapAInputAmount(""));
                    //   setSwapBtnStatus(true);
                    //   setSwapBtnMessage("Enter an amount")
                  }
                }
              }
            }
          }
          // }else {
          //   if (who == "From") {
          //     dispatch(setSwapAInputAmount(amount));
          //     dispatch(setSwapBInputAmount(""));
          //   } else if (who == "To") {
          //     dispatch(setSwapAInputAmount(""));
          //     dispatch(setSwapBInputAmount(amount));
          //   }
          // }
        } else if (amount == "") {
          if (who == "From") {
            dispatch(setSwapBInputAmount(""));
          } else if (who == "To") {
            dispatch(setSwapAInputAmount(""));
          }
        }
      }
    } catch (error) {
      // console.log("error",error)
    }
  };

  const onClickApprove = async (token) => {
    setSwapBtnStatus(true);
    setSwapBtnMessage("Approving");
    try {
      const web3 = web3Provider;
      // if (token.symbol != chain && token.address != "" && token.symbol != wCoinSymbol && token.address != WMMT_ADDRESS.toLowerCase()) {
      if (chainId == (await web3.eth.getChainId())) {
        if (token.symbol != chain && token.address != "") {
          const contract = new web3.eth.Contract(ERC20JSON, token.address);
          const approve = await contract.methods
            .approve(ROUTER_ADDRESS, MaxUint256.toString())
            .send({
              from: account,
            });
          if (approve.status == true) {
            if (swapA.address == token.address) {
              dispatch(setSwapA_Allowance(true));
            }
          }
        }
      } else {
        checkAllowance();
      }
    } catch (error) {
      console.log(error);
      checkAllowance();
    }
  };

  const checkInputAmountDecimal = (token, amount) => {
    try {
      if (amount != "") {
        let index = amount.indexOf(".");
        if (index != -1) {
          let decimalLength = amount.substr(index + 1, amount.length);
          if (decimalLength.length <= token.decimal) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
        // let index = token.inputAmount.indexOf(".");
        // if(index!=-1){
        //   let decimalLength = token.inputAmount.substr((index+1),token.inputAmount.length);
        //   if(decimalLength.length <= token.decimal){
        //     return true;
        //   }else{
        //     return false;
        //   }
        // }else{
        //   return true;
        // }
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    if (
      checkInputAmountDecimal(swapA, swapA.inputAmount) == false &&
      focusInput == "From"
    ) {
      setSwapBtnMessage("Enter an amount");
      setSwapBtnStatus(true);
      dispatch(setSwapBInputAmount(""));
      setSoldAndReceived("0");
    }
  }, [swapA, focusInput]);

  useEffect(() => {
    if (
      checkInputAmountDecimal(swapB, swapB.inputAmount) == false &&
      focusInput == "To"
    ) {
      setSwapBtnMessage("Enter an amount");
      setSwapBtnStatus(true);
      dispatch(setSwapAInputAmount(""));
      setSoldAndReceived("0");
    }
  }, [swapB, focusInput]);

  const checkAllowance = async () => {
    const web3 = defaultProvider;
    try {
      if (active == false) {
        setSwapBtnStatus(false);
        setSwapBtnMessage("Connect Wallet");
      } else if (
        account != "" &&
        (((swapA.inputAmount == "" || swapA.symbol != "") &&
          swapB.symbol == "") ||
          (swapA.symbol == "" &&
            (swapB.inputAmount == "" || swapB.symbol != "")))
      ) {
        setSwapBtnStatus(true);
        setSwapBtnMessage("Select Token");
      } else if (
        account != "" &&
        swapA.inputAmount != "" &&
        swapB.inputAmount != ""
      ) {
        let swap0 = "";
        let swap1 = "";

        if (swapA.symbol == chain && swapA.address == "") {
          swap0 = WMMT_ADDRESS;
        } else {
          swap0 = swapA.address;
        }
        if (swapB.symbol == chain && swapB.address == "") {
          swap1 = WMMT_ADDRESS;
        } else {
          swap1 = swapB.address;
        }
        if (
          (((await defaultProvider) != undefined?.eth.getChainId()) ==
            (await web3Provider)) !=
          undefined?.eth.getChainId()
        ) {
          if (
            checkInputAmountDecimal(swapA, swapA.inputAmount) == false &&
            focusInput == "From"
          ) {
          } else if (
            checkInputAmountDecimal(swapB, swapB.inputAmount) == false &&
            focusInput == "To"
          ) {
          } else {
            if (
              swapA.symbol == chain &&
              swapA.address == "" &&
              swapB.symbol == wCoinSymbol &&
              swapB.address == WMMT_ADDRESS.toLowerCase()
            ) {
              if (
                swapA.inputAmount != "" &&
                Number(swapA.inputAmount) <= Number(swapA.haveAmount)
              ) {
                setSwapBtnStatus(false);
                setSwapBtnMessage("Wrap");
                setInsufficientStatus(false);
              } else if (
                swapA.inputAmount == "" ||
                Number(swapA.inputAmount) > Number(swapA.haveAmount)
              ) {
                setSwapBtnStatus(true);
                setSwapBtnMessage(`Insufficient ${swapA.symbol} balance`);
                setInsufficientStatus(true);
              }
            } else if (
              swapA.symbol == wCoinSymbol &&
              swapA.address == WMMT_ADDRESS.toLowerCase() &&
              swapB.symbol == chain &&
              swapB.address == ""
            ) {
              if (
                swapA.inputAmount != "" &&
                Number(swapA.inputAmount) <= Number(swapA.haveAmount)
              ) {
                setSwapBtnStatus(false);
                setSwapBtnMessage("Unwrap");
                setInsufficientStatus(false);
              } else if (
                swapA.inputAmount == "" ||
                Number(swapA.inputAmount) > Number(swapA.haveAmount)
              ) {
                setSwapBtnStatus(true);
                setSwapBtnMessage(`Insufficient ${swapA.symbol} balance`);
                setInsufficientStatus(true);
              }
            } else {
              const factoryContract = new web3.eth.Contract(
                Factory,
                FACTORY_ADDRESS
              );
              let pair = await factoryContract.methods
                .getPair(swap0, swap1)
                .call();

              if (pair != "0x0000000000000000000000000000000000000000") {
                if (swapA.address != "" && swapA.symbol != chain) {
                  if (
                    swapA.inputAmount != "" &&
                    Number(swapA.inputAmount) <= Number(swapA.haveAmount)
                  ) {
                    const contract = new web3.eth.Contract(
                      ERC20JSON,
                      swapA.address
                    );
                    const swapAllowance = await contract.methods
                      .allowance(account, ROUTER_ADDRESS)
                      .call();
                    let bool = new BN(`${swapAllowance}`).gte(
                      new BN(`${web3.utils.toWei(`${swapA.inputAmount}`)}`)
                    );
                    // let bool = new BN(`${swapAllowance}`).lte(new BN(`${web3.utils.toWei(`${swapA.inputAmount}`)}`));
                    // if (swapAllowance != "0") {
                    // if (!bool) {
                    if (bool) {
                      setSwapBtnStatus(false);
                      setSwapBtnMessage("Swap");
                      dispatch(setSwapA_Allowance(true));
                      setInsufficientStatus(false);
                    } else {
                      dispatch(setSwapA_Allowance(false));
                      setSwapBtnStatus(false);
                      setSwapBtnMessage(`${swapA.symbol} Approve`);
                    }
                  } else if (
                    swapA.inputAmount == "" ||
                    Number(swapA.inputAmount) > Number(swapA.haveAmount)
                  ) {
                    setSwapBtnStatus(true);
                    setSwapBtnMessage(`Insufficient ${swapA.symbol} balance`);
                    setInsufficientStatus(true);
                  }
                } else {
                  if (
                    swapA.inputAmount == "" ||
                    Number(swapA.inputAmount) > Number(swapA.haveAmount)
                  ) {
                    setSwapBtnStatus(true);
                    setSwapBtnMessage(`Insufficient ${swapA.symbol} balance`);
                    setInsufficientStatus(true);
                  } else if (
                    swapA.inputAmount != "" &&
                    Number(swapA.inputAmount) <= Number(swapA.haveAmount) &&
                    swapB.inputAmount != ""
                  ) {
                    setSwapBtnStatus(false);
                    setSwapBtnMessage("Swap");
                    setInsufficientStatus(false);
                  }
                }
              } else {
                if (account == "") {
                  setSwapBtnStatus(false);
                  setSwapBtnMessage("Connect Wallet");
                } else {
                  setSwapBtnStatus(true);
                  setSwapBtnMessage("Insufficient liquidity for this trade");
                }
              }
            }
          }
        } else {
          setSwapBtnStatus(true);
          setSwapBtnMessage("Please switch network.");
          setInsufficientStatus(false);
        }
      } else if (
        account != "" &&
        (swapA.inputAmount == "" || Number(swapA.inputAmount) == 0) &&
        (swapB.inputAmount == "" || Number(swapB.inputAmount) == 0)
      ) {
        setInsufficientStatus(false);
        setSwapBtnStatus(true);
        setSwapBtnMessage("Enter an amount");
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (Number(swapA.inputAmount) > Number(swapA.haveAmount)) {
      setInsufficientStatus(true);
    } else if (Number(swapA.inputAmount) <= Number(swapA.haveAmount)) {
      setInsufficientStatus(false);
    }
  }, [swapA.inputAmount, swapA.haveAmount]);

  const onClickSwap = async () => {
    dispatch(setTransactionHash(""));
    if (swapA.token_allowance == false) {
      onClickApprove(swapA);
    } else {
      try {
        const web3 = web3Provider;
        if (chainId == (await web3.eth.getChainId())) {
          const contract = new web3.eth.Contract(Router, ROUTER_ADDRESS);
          let path = [];
          let swap0 = "";
          let swap1 = "";
          let result;
          if (swapA.symbol == chain && swapA.address == "") {
            swap0 = WMMT_ADDRESS;
          } else {
            swap0 = swapA.address;
          }
          if (swapB.symbol == chain && swapB.address == "") {
            swap1 = WMMT_ADDRESS;
          } else {
            swap1 = swapB.address;
          }
          let date = Date();
          date = +new Date(date);
          date = date / 1000;
          date = date + time;
          path.push(swap0);
          path.push(swap1);
          if (swapAmountIn == false) {
            if (
              swapA.symbol == chain &&
              swapA.address == "" &&
              swapB.symbol == wCoinSymbol &&
              swapB.address == WMMT_ADDRESS.toLowerCase()
            ) {
              //Deposit
              const WMMTContract = new web3.eth.Contract(
                WMMTJSON,
                WMMT_ADDRESS.toLowerCase()
              );
              result = await WMMTContract.methods.deposit().send({
                from: account,
                value: web3.utils.toWei(swapA.inputAmount),
              });
            } else if (
              swapA.symbol == wCoinSymbol &&
              swapA.address == WMMT_ADDRESS.toLowerCase() &&
              swapB.symbol == chain &&
              swapB.address == ""
            ) {
              //WithDraw
              const WMMTContract = new web3.eth.Contract(
                WMMTJSON,
                WMMT_ADDRESS.toLowerCase()
              );
              result = await WMMTContract.methods
                .withdraw(web3.utils.toWei(swapA.inputAmount))
                .send({
                  from: account,
                });
            } else if (swapA.symbol == chain && swapA.address == "") {
              // swapExactETHForTokens
              let decimalPow = new BN(`${10}`)
                .pow(new BN(`${swapA.decimal}`))
                .toString();
              let re_Decimals = returnDecimal(web3Provider, decimalPow);
              let value = web3.utils.toWei(
                String(swapA.inputAmount),
                re_Decimals
              );
              let swapBInputAmount = await contract.methods
                .getAmountsOut(value, path)
                .call();

              let decimalPowB = new BN(`${10}`)
                .pow(new BN(`${swapB.decimal}`))
                .toString();
              // let re_DecimalsB = returnDecimal(web3Provider, decimalPowB);

              // let soldAndReceivedMin = web3.utils.toWei(String(soldAndReceived),re_DecimalsB);
              let soldAndReceivedMin = toWeiBalance(
                String(soldAndReceived),
                swapB.decimal
              );

              // result = await contract.methods.swapExactETHForTokens(swapBInputAmount[1], path, account, date).send({
              // result = await contract.methods.swapExactETHForTokensSupportingFeeOnTransferTokens(soldAndReceivedMin, path, account, date).send({
              result = await contract.methods
                .swapExactETHForTokens(soldAndReceivedMin, path, account, date)
                .send({
                  from: account,
                  value: value,
                });
            } else if (swapB.symbol == chain && swapB.address == "") {
              // swapExactTokensForETH
              let decimalPow = new BN(`${10}`)
                .pow(new BN(`${swapA.decimal}`))
                .toString();
              // let re_Decimals = returnDecimal(web3Provider, decimalPow);

              // let swapIn = web3.utils.toWei(String(swapA.inputAmount),re_Decimals);
              let swapIn = toWeiBalance(
                String(swapA.inputAmount),
                swapA.decimal
              );

              let amountsOutMin = await contract.methods
                .getAmountsOut(swapIn, path)
                .call();

              let decimalPowB = new BN(`${10}`)
                .pow(new BN(`${swapB.decimal}`))
                .toString();
              // let re_DecimalsB = returnDecimal(web3Provider, decimalPowB);

              // let soldAndReceivedMin = web3.utils.toWei(String(soldAndReceived),re_DecimalsB);
              let soldAndReceivedMin = toWeiBalance(
                String(soldAndReceived),
                swapB.decimal
              );

              // result = await contract.methods.swapExactTokensForETH(swapIn, amountsOutMin[1], path, account, date).send({
              result = await contract.methods
                .swapExactTokensForETH(
                  swapIn,
                  soldAndReceivedMin,
                  path,
                  account,
                  date
                )
                .send({
                  from: account,
                });
            } else {
              // swapExactTokensForTokens
              let decimalPow = new BN(`${10}`)
                .pow(new BN(`${swapA.decimal}`))
                .toString();
              // let re_Decimals = returnDecimal(web3Provider, decimalPow);

              // let swapIn = web3.utils.toWei(String(swapA.inputAmount),re_Decimals);
              let swapIn = toWeiBalance(
                String(swapA.inputAmount),
                swapA.decimal
              );

              let amountsOutMin = await contract.methods
                .getAmountsOut(swapIn, path)
                .call();

              let decimalPowB = new BN(`${10}`)
                .pow(new BN(`${swapB.decimal}`))
                .toString();
              // let re_DecimalsB = returnDecimal(web3Provider, decimalPowB);

              // let soldAndReceivedMin = web3.utils.toWei(String(soldAndReceived),re_DecimalsB);
              let soldAndReceivedMin = toWeiBalance(
                String(soldAndReceived),
                swapB.decimal
              );
              // result = await contract.methods.swapExactTokensForTokens(swapIn, amountsOutMin[1], path, account, date).send({
              result = await contract.methods
                .swapExactTokensForTokens(
                  swapIn,
                  soldAndReceivedMin,
                  path,
                  account,
                  date
                )
                .send({
                  from: account,
                });
            }
          } else if (swapAmountOut == false) {
            if (
              swapA.symbol == chain &&
              swapA.address == "" &&
              swapB.symbol == wCoinSymbol &&
              swapB.address == WMMT_ADDRESS.toLowerCase()
            ) {
              //Deposit
              const WMMTContract = new web3.eth.Contract(
                WMMTJSON,
                WMMT_ADDRESS.toLowerCase()
              );
              result = await WMMTContract.methods.deposit().send({
                from: account,
                value: web3.utils.toWei(swapA.inputAmount),
              });
            } else if (
              swapA.symbol == wCoinSymbol &&
              swapA.address == WMMT_ADDRESS.toLowerCase() &&
              swapB.symbol == chain &&
              swapB.address == ""
            ) {
              //WithDraw
              const WMMTContract = new web3.eth.Contract(
                WMMTJSON,
                WMMT_ADDRESS.toLowerCase()
              );
              result = await WMMTContract.methods
                .withdraw(web3.utils.toWei(swapA.inputAmount))
                .send({
                  from: account,
                });
            } else if (swapA.symbol == chain && swapA.address == "") {
              // swapETHForExactTokens
              let decimalPow = new BN(`${10}`)
                .pow(new BN(`${swapB.decimal}`))
                .toString();
              // let re_Decimals = returnDecimal(web3Provider, decimalPow);

              // let amountOut = web3.utils.toWei(String(swapB.inputAmount),re_Decimals);
              let amountOut = toWeiBalance(
                String(swapB.inputAmount),
                swapB.decimal
              );

              let swapETHForExactTokens = await contract.methods
                .getAmountsIn(amountOut, path)
                .call();

              let decimalPowA = new BN(`${10}`)
                .pow(new BN(`${swapA.decimal}`))
                .toString();
              // let re_DecimalsA = returnDecimal(web3Provider, decimalPowA);

              // let soldAndReceivedMax = web3.utils.toWei(String(soldAndReceived),re_DecimalsA);
              let soldAndReceivedMax = toWeiBalance(
                String(soldAndReceived),
                swapA.decimal
              );

              result = await contract.methods
                .swapETHForExactTokens(amountOut, path, account, date)
                .send({
                  from: account,
                  // value: swapETHForExactTokens[0]
                  value: soldAndReceivedMax,
                });
            } else if (swapB.symbol == chain && swapB.address == "") {
              // swapTokensForExactETH
              let decimalPow = new BN(`${10}`)
                .pow(new BN(`${swapB.decimal}`))
                .toString();
              // let re_Decimals = returnDecimal(web3Provider, decimalPow);

              // let amountOut = web3.utils.toWei(String(swapB.inputAmount),re_Decimals);
              let amountOut = toWeiBalance(
                String(swapB.inputAmount),
                swapB.decimal
              );

              let amountInMax = await contract.methods
                .getAmountsIn(amountOut, path)
                .call();

              let decimalPowA = new BN(`${10}`)
                .pow(new BN(`${swapA.decimal}`))
                .toString();
              // let re_DecimalsA = returnDecimal(web3Provider, decimalPowA);

              // let soldAndReceivedMax = web3.utils.toWei(String(soldAndReceived),re_DecimalsA);
              let soldAndReceivedMax = toWeiBalance(
                String(soldAndReceived),
                swapA.decimal
              );

              // result = await contract.methods.swapTokensForExactETH(amountOut, amountInMax[0], path, account, date).send({
              result = await contract.methods
                .swapTokensForExactETH(
                  amountOut,
                  soldAndReceivedMax,
                  path,
                  account,
                  date
                )
                .send({
                  from: account,
                });
            } else {
              // swapTokensForExactTokens
              let decimalPow = new BN(`${10}`)
                .pow(new BN(`${swapB.decimal}`))
                .toString();
              // let re_Decimals = returnDecimal(web3Provider, decimalPow);

              // let amountOut = web3.utils.toWei(String(swapB.inputAmount),re_Decimals);
              let amountOut = toWeiBalance(
                String(swapB.inputAmount),
                swapB.decimal
              );

              let amountInMax = await contract.methods
                .getAmountsIn(amountOut, path)
                .call();

              let decimalPowA = new BN(`${10}`)
                .pow(new BN(`${swapA.decimal}`))
                .toString();
              // let re_DecimalsA = returnDecimal(web3Provider, decimalPowA);

              // let soldAndReceivedMax = web3.utils.toWei(String(soldAndReceived),re_DecimalsA);
              let soldAndReceivedMax = toWeiBalance(
                String(soldAndReceived),
                swapA.decimal
              );
              // result = await contract.methods.swapTokensForExactTokens(amountOut, amountInMax[0], path, account, date).send({
              result = await contract.methods
                .swapTokensForExactTokens(
                  amountOut,
                  soldAndReceivedMax,
                  path,
                  account,
                  date
                )
                .send({
                  from: account,
                });
            }
          }

          if (result.status) {
            setNowModalStatus("submit");
          } else {
            setNowModalStatus("Failed");
          }
          // setTransactionHash(result.transactionHash);
          dispatch(setTransactionHash(result.transactionHash));
          dispatch(changeInputAmountAll(""));

          setSoldAndReceived("0");
          let i = 0;
          let interval = setInterval(() => {
            i++;
            setSwapLoading(i);
            if (i == 100) {
              clearInterval(interval);
            }
          }, 100);
        } else {
          setNowModalStatus("Failed");
        }
      } catch (error) {
        console.log(error);
        setNowModalStatus("Failed");
      }
    }
  };

  const changeSlippage = async (e) => {
    let value = e.target.value;
    let pattern = /^[0-9]*[.,]?[0-9]*$/;
    let pattern2 = /^\d{0,10}(\.\d{0,2})?$/;
    if (pattern2.test(value)) {
      if (pattern.test(value)) {
        dispatch(setUpdateUserSlippageTolerance(value));
      }
    }
  };

  const focusSlippageInput = () => {
    // if(Number(slippage) >= 50 || ){
    if (Number(slippage) >= 50 || slippage == "") {
      dispatch(setUpdateUserSlippageTolerance(5));
    }
  };

  useEffect(() => {
    let q1 = swapA.symbol != "";
    let q2 = swapB.symbol != "";
    let q3 = wCoinSymbol != "";
    if (q1 && q2 && q3) {
      if (focusInput == "To") {
        // to 입력 시
        let target = {
          target: {
            value: swapB.inputAmount,
          },
        };
        onChangeInputAmount(target, "To");
      } else if (focusInput == "From") {
        // from 입력 시
        let target = {
          target: {
            value: swapA.inputAmount,
          },
        };
        onChangeInputAmount(target, "From");
      }
    }
  }, [
    wCoinSymbol,
    swapA,
    swapB,
    slippage,
    ROUTER_ADDRESS,
    WMMT_ADDRESS,
    FACTORY_ADDRESS,
  ]);

  // useEffect(() => {
  //   if (swapA.symbol != "" && swapB.symbol != "") {
  //     // if ((swapA.inputAmount != "" && swapB.inputAmount == "" && focusInput == "From")) {
  //     if (focusInput == "From") {
  //       let e = {
  //         target: {
  //           value: swapA.inputAmount,
  //         },
  //       };
  //       onChangeInputAmount(e, "From");
  //       // } else if ((swapA.inputAmount == "" && swapB.inputAmount != "" && focusInput == "To")) {
  //     } else if (focusInput == "To") {
  //       let e = {
  //         target: {
  //           value: swapB.inputAmount,
  //         },
  //       };
  //       onChangeInputAmount(e, "To");
  //     }
  //   }
  // }, [
  //   swapA,
  //   swapB,
  //   focusInput,
  //   defaultProvider,
  //   ROUTER_ADDRESS,
  //   WMMT_ADDRESS,
  //   chainId,
  //   FACTORY_ADDRESS,
  // ]);

  const onChangeTime = (e) => {
    const time = e.target.value;
    dispatch(setUpdateUserDeadLine(time * 60));
  };

  useEffect(async () => {
    // if(account!=""){
    setTokensList();
    // }
  }, [
    netWorkList,
    defaultProvider,
    active,
    account,
    ROUTER_ADDRESS,
    WMMT_ADDRESS,
    FACTORY_ADDRESS,
  ]);

  useEffect(() => {
    if (wCoinSymbol != "") {
      checkAllowance();
    }
  }, [
    wCoinSymbol,
    swapA,
    swapB,
    defaultProvider,
    active,
    account,
    ROUTER_ADDRESS,
    WMMT_ADDRESS,
    web3Provider,
  ]);

  useEffect(() => {
    getAddTokenList();
  }, [
    swapA,
    swapB,
    // active ,account, chainId
  ]);

  useEffect(() => {
    if (swapA.symbol != "" || swapB.symbol != "") {
      checkSwapBalance();
    }
  }, [swapA, swapB, defaultProvider, active, account]);

  // 스왑하고 값 초기화 하기

  const onClickChangeSwap = async () => {
    let A;
    let B;
    let data;
    if (
      (swapA.symbol == chain &&
        swapA.address == "" &&
        swapB.symbol == wCoinSymbol &&
        swapB.address == WMMT_ADDRESS.toLowerCase()) ||
      (swapA.symbol == wCoinSymbol &&
        swapA.address == WMMT_ADDRESS.toLowerCase() &&
        swapB.symbol == chain &&
        swapB.address == "")
    ) {
      A = swapB;
      B = swapA;
      data = {
        A,
        B,
      };
      dispatch(changeSwap(data));
    } else {
      A = swapB;
      B = swapA;

      data = {
        A,
        B,
      };
      let e;
      // 가운데 버튼 클릭 시
      if (focusInput == "From") {
        data = {
          A: {
            address: swapB.address,
            decimal: swapB.decimal,
            haveAmount: swapB.haveAmount,
            image: swapB.image.toLowerCase(),
            inputAmount: "",
            name: swapB.name,
            price: swapB.price,
            symbol: swapB.symbol,
            token_allowance: swapB.token_allowance,
          },
          B: {
            address: swapA.address,
            decimal: swapA.decimal,
            haveAmount: swapA.haveAmount,
            image: swapA.image.toLowerCase(),
            inputAmount: swapA.inputAmount,
            name: swapA.name,
            price: swapA.price,
            symbol: swapA.symbol,
            token_allowance: swapA.token_allowance,
          },
        };
        // From 입력 시
        setSwapAmountIn(true);
        setSwapAmountOut(false);
        e = {
          target: {
            value: swapA.inputAmount,
          },
        };
        dispatch(changeSwap(data));
        setFocusInput("To");
      } else if (focusInput == "To") {
        // To 입력 시
        data = {
          A: {
            address: swapB.address,
            decimal: swapB.decimal,
            haveAmount: swapB.haveAmount,
            image: swapB.image.toLowerCase(),
            inputAmount: swapB.inputAmount,
            name: swapB.name,
            price: swapB.price,
            symbol: swapB.symbol,
            token_allowance: swapB.token_allowance,
          },
          B: {
            address: swapA.address,
            decimal: swapA.decimal,
            haveAmount: swapA.haveAmount,
            image: swapA.image.toLowerCase(),
            inputAmount: "",
            name: swapA.name,
            price: swapA.price,
            symbol: swapA.symbol,
            token_allowance: swapA.token_allowance,
          },
        };
        setSwapAmountIn(false);
        setSwapAmountOut(true);
        e = {
          target: {
            value: swapB.inputAmount,
          },
        };

        dispatch(changeSwap(data));
        setFocusInput("From");
      } else {
        dispatch(changeSwap(data));
      }
    }
    setSwapStatus(true);
    setSelectActiveA(swapB.address);
    setSelectedTokenA(swapB.symbol);
    setSelectActiveB(swapA.address);
    setSelectedTokenB(swapA.symbol);
  };

  const onClickExplorer = () => {
    if (chainId == 8898) {
      window.open(`https://mmtscan.io/tx/${transactionHash}`);
    } else if (chainId == 88998) {
      window.open(`https://testnet.mmtscan.io/tx/${transactionHash}`);
    } else if (chainId == 97) {
      window.open(`https://testnet.bscscan.com/tx/${transactionHash}`);
    } else if (chainId == 8989) {
      window.open(`https://scan.gmmtchain.io/tx/${transactionHash}`);
    } else if (chainId == 898989) {
      window.open(`https://testnet-scan.gmmtchain.io/tx/${transactionHash}`);
    } else if (chainId == 1) {
      window.open(`https://etherscan.io/tx/${transactionHash}`);
    }
  };

  const onClickReset = () => {
    const reset = {
      address: "",
      symbol: "",
      name: "",
      inputAmount: "",
      image: "",
      decimal: 0,
      haveAmount: 0,
    };
    setSelectActiveA("NAN");
    setSelectedTokenA("NAN");
    setSelectActiveB("NAN");
    setSelectedTokenB("NAN");
    // dispatch(setSwapTokenA(reset));
    // dispatch(setSwapTokenB(reset));
    setInsufficientStatus(false);
    dispatch(setSwapReset(reset));
    dispatch(setUpdateUserSlippageTolerance(0.5));
    dispatch(setUpdateUserDeadLine(1200));
    setSoldAndReceived("0");
    setResetStatus(true);
  };

  const setSearchTokenInput = (e) => {
    let search = e.target.value;
    let result = [];
    let searchText = search.toLowerCase();
    setSearchToken(searchText);
  };

  const selectTokenList = (e) => {
    let search = e.target.value;
    // setSearchToken(search)
    let result = [];
    let searchText = search.toLowerCase();

    // let netWorkList;
    // if(chainId==88998){
    //   netWorkList = tokensListJSON;
    // }else if(chainId==97){
    //   netWorkList = tokenList_BSC_Testnet;
    // }else if(chainId==8898){
    //   netWorkList = tokenList_MMT_Mainnet;
    // }

    try {
      if (search.length == 42) {
        for (let i = 0; i < netWorkList.length; i++) {
          if (netWorkList[i].address.toLowerCase() == search.toLowerCase()) {
            // result = tokensListJSON[i];
            result.push(netWorkList[i]);
          } else {
          }
        }
      } else {
        for (let i = 0; i < netWorkList.length; i++) {
          let nameToLowerCase = netWorkList[i].name.toLowerCase();
          let symbolToLowerCase = netWorkList[i].symbol.toLowerCase();
          if (nameToLowerCase.includes(searchText)) {
            result.push(netWorkList[i]);
          } else if (symbolToLowerCase.includes(searchText)) {
            result.push(netWorkList[i]);
          }
        }
      }
      dispatch(setTokenList(result));
    } catch (error) {
      dispatch(setTokenList([]));
    }
  };

  const onClickAddTokenMetaMask = async () => {
    // await window.web3.currentProvider.sendAsync(
    await providerWeb3.sendAsync({
      jsonrpc: "2.0",
      id: chainId,
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: swapB.address,
          symbol: swapB.symbol,
          decimals: swapB.decimal,
        },
      },
    });
  };

  const onClickAuto = () => {
    dispatch(setUpdateUserSlippageTolerance(0.1));
  };

  const onClickRemoveToken = (item) => {
    let removeTokenAddress = item.address;
    let bfgetTokenList = JSON.parse(
      window.localStorage.getItem("addTokenList")
    );
    bfgetTokenList = bfgetTokenList.tokens.getTokenList;
    let getTokenList = [];
    for (let i = 0; i < bfgetTokenList.length; i++) {
      if (bfgetTokenList[i].address != removeTokenAddress) {
        getTokenList.push(bfgetTokenList[i]);
      }
    }
    window.localStorage.setItem(
      "addTokenList",
      JSON.stringify({
        tokens: {
          getTokenList,
        },
      })
    );
    setMyAddTokenList(getTokenList);
  };

  const balanceInCode = (balance) => {
    if (
      balance == undefined ||
      balance.length == undefined ||
      balance == "-" ||
      balance == "" ||
      Number(balance) == 0
    ) {
      return 0;
    } else {
      let splitBalance = balance.split(".");

      let backBalance = "";
      let afterVal = "";
      if (splitBalance.length == 2) {
        let q1 = splitBalance[1].length - (splitBalance[1].search(/[1-9]/) + 1);
        let nextVal;
        for (let i = 0; i < q1; i++) {
          nextVal = splitBalance[1].substr(
            splitBalance[1].search(/[1-9]/) + (i + 1),
            1
          );
          if (nextVal != "0") {
            afterVal = afterVal + `${nextVal}`;
            break;
          } else {
            afterVal = afterVal + "0";
          }
          if (i == q1 - 1) {
            nextVal = "";
          }
        }
        if (afterVal.search(/[1-9]/) == -1) {
          afterVal = "";
        }
        backBalance =
          splitBalance[1].substr(0, splitBalance[1].search(/[1-9]/) + 1) != ""
            ? "." +
              splitBalance[1].substr(0, splitBalance[1].search(/[1-9]/) + 1) +
              afterVal
            : "";
      }

      return splitBalance[0] + backBalance;
    }
  };

  const setSwapToken = async () => {
    if (netWorkList != "") {
      const web3 = web3Provider;
      let haveAmount = 0;
      if (account != "" && active == true) {
        haveAmount = await web3.eth.getBalance(account);
        haveAmount = web3.utils.fromWei(`${haveAmount}`);
      }

      // let netWorkList;
      // if(chainId==88998){
      //   netWorkList = tokensListJSON;
      // }else if(chainId==97){
      //   netWorkList = tokenList_BSC_Testnet;
      // }else if(chainId==8898){
      //   netWorkList = tokensListJSON;
      // }
      // /0x1ab2b7fad0bf326bf66eae336c9bfed9deb98444

      const body = {
        address: netWorkList[0].address,
        symbol: netWorkList[0].symbol,
        name: netWorkList[0].name,
        decimal: netWorkList[0].decimal,
        inputAmount: "",
        image: netWorkList[0].image.toLowerCase(),
        price: netWorkList[0].price,
        haveAmount: haveAmount,
      };

      const reset = {
        address: "",
        symbol: "",
        name: "",
        decimal: 0,
        inputAmount: "",
        haveAmount: 0,
        image: "",
        price: 0,
      };

      setSelectActiveA(body.address);
      setSelectedTokenA(body.symbol);
      setSelectActiveB("finnRt");
      setSelectedTokenB("finnRt");
      setSelectToken(body);
      setSelectType("From");
      dispatch(
        onClickNavBar({
          body: body,
          reset: reset,
        })
      );
    }
  };

  useEffect(async () => {
    setSwapToken();
  }, [
    netWorkList,
    //  account, chainId ,active, web3Provider
  ]);

  const fromWeiBalance = (balance, decimal) => {
    let result = new BN(`${10}`).pow(new BN(`${decimal}`)).toString();
    let fromWeiBalance = new BN(`${balance}`).divmod(new BN(`${result}`));
    let divBalance = fromWeiBalance.div.toString();
    let modBalance = fromWeiBalance.mod.toString();
    let subDecimal = decimal - modBalance.length;
    let zero = "";
    for (let i = 0; i < subDecimal; i++) {
      zero += "0";
    }

    let returnBalance = divBalance + "." + zero + modBalance;
    return returnBalance;
  };

  const toWeiBalance = (balance, decimal) => {
    // if (web3Provider != undefined) {
    let point = balance.split(".");
    let pointPow = 1;
    let powLength = 0;
    let lastPoint = "";
    if (point[1] != undefined) {
      powLength = point[1].length;
      pointPow = new BN(`${10}`).pow(new BN(`${point[1].length}`)).toString();
      lastPoint = point[1].substr(0, decimal);
    }

    let balancePoinPow = point[0] + lastPoint;

    let subDecimal = new BN(`${decimal}`)
      .sub(new BN(`${powLength}`))
      .toString();

    let result = new BN(`${10}`).pow(new BN(`${subDecimal}`)).toString();
    let decimalBalance = new BN(`${balancePoinPow}`)
      .mul(new BN(`${result}`))
      .toString();

    return decimalBalance;
    // }
  };

  const onErrorImg = (e) => {
    e.target.src = "logo_.png";
  };

  const onClickBtnToastPopup = () => {
    setInfoToastPop(true);
  };

  const toLocaleStringOption = (amount) => {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  useEffect(() => {
    if (swapStatus) {
      setTimeout(() => {
        setSwapStatus(false);
      }, 400);
    }
  }, [swapStatus]);

  useEffect(() => {
    if (resetStatus) {
      setTimeout(() => {
        setResetStatus(false);
      }, 400);
    }
  }, [resetStatus]);

  useEffect(() => {
    if (infoToastPopup) {
      setTimeout(() => {
        setInfoToastPop(false);
      }, 1000);
    }
  }, [infoToastPopup]);

  // useEffect(()=>{
  useLayoutEffect(() => {
    let e = {
      target: {
        value: searchToken,
      },
    };
    selectTokenList(e);
  }, [searchToken]);

  const checkModalStatusSubmit = () => {
    if (nowModalStatus == "submit") {
      setTimeout(() => {
        setPopupShow(false);
      }, [4000]);
    }
  };

  useEffect(() => {
    checkModalStatusSubmit();
  }, [nowModalStatus]);

  return (
    <div className={styles.swapBg}>
      <div className={styles.swapWrap}>
        <div className={styles.title}>Swap</div>
        <div className={styles.swapInner}>
          <div
            className={`${styles.openSoonToast} ${
              infoToastPopup ? styles.show : ""
            }`}
          >
            This page will be open soon.
          </div>
          <div className={styles.goInfo}>
            <Link
              to={`${
                process.env.REACT_APP_NETWORK_CHAINID == "8898" ? "" : "/info"
              }`}
            >
              <button
                onClick={() => {
                  if (process.env.REACT_APP_NETWORK_CHAINID == "8898") {
                    onClickBtnToastPopup();
                  }
                }}
              >
                View LP
              </button>
            </Link>
          </div>
          <div className={styles.resetBox}>
            <button
              className={`${styles.resetBtn} ${
                resetStatus ? styles.click : ""
              }`}
              onClick={() => onClickReset()}
            >
              <img
                className={styles.reset_pc}
                src={require("../assets/images/swap/ico-represh-wh.png")}
                alt={"ico-represh-wh"}
              />
              <img
                className={styles.reset_mo}
                src={require("../assets/images/swap/ico_refresh_bl.png")}
                alt={"ico-represh-bl"}
              />
            </button>
          </div>
          <div className={styles.swapFrom}>
            <div className={styles.textFrom}>
              From
              {/* {swapAmountIn ?<>(estimated)</>:<></>} */}
            </div>
            <div className={styles.coinNameWrap}>
              <div
                className={styles.selected}
                onClick={() => {
                  setPopupShow(true);
                  setNowModalStatus("selectToken");
                  setTokenAStatus("checked");
                  setTokenBStatus("");
                  setSearchToken("");
                  setSelectToken(swapA);
                  setSelectType("From");
                }}
              >
                {/* <img className={styles.symbolLogo} src={require(`../assets/images/swap/logo_${swapA.image}.png`)} alt={"symbol_logo"} /> */}
                <img
                  className={styles.symbolLogo}
                  src={`${chain}/logo_${swapA.image}.png`}
                  alt={`${swapA.symbol}`}
                  onError={onErrorImg}
                />
                <span className={styles.coinName}>{swapA.symbol}</span>
                <img
                  src={require("../assets/images/swap/ico_arrow_bl.png")}
                  alt={"ico_arrow_bl"}
                />
              </div>
              <div
                className={`${styles.quantity} ${
                  fromFocusActive ? styles.inputActive : ""
                } ${insufficientStatus ? styles.excess : ""}`}
              >
                {/* <span className={styles.minus} /> */}
                <div>
                  <input
                    type={styles.number}
                    onFocus={() => setFromFocusActive(true)}
                    onBlur={() => setFromFocusActive(false)}
                    placeholder={0}
                    value={swapA.inputAmount}
                    onChange={(e) => onChangeInputAmount(e, "From")}
                  />
                </div>
                {/* <span className={styles.plus} /> */}
              </div>
            </div>
            <div
              className={`${styles.balance} ${
                insufficientStatus ? styles.excess : ""
              }`}
            >
              <span>
                balance: {toLocaleStringOption(balanceInCode(swapA.haveAmount))}
              </span>
            </div>
            <div
              className={`${styles.swapIconWrap} ${
                swapStatus ? styles.click : ""
              }`}
              onClick={() => onClickChangeSwap()}
            >
              <div className={styles.swapIcon}></div>
              <img
                src={require("../assets/images/swap/ico_exchange_bl@2x.png")}
                alt={"ico_exchange_bl"}
              />
            </div>
          </div>
          {/* To */}
          <div className={styles.swapFrom}>
            <div className={styles.textFrom}>
              To
              {/* {swapAmountOut ?<>(estimated)</>:<></>} */}
            </div>
            <div className={styles.coinNameWrap}>
              <div
                className={styles.selected}
                onClick={() => {
                  setPopupShow(true);
                  setNowModalStatus("selectToken");
                  setTokenAStatus("");
                  setTokenBStatus("checked");
                  setSearchToken("");
                  setSelectToken(swapB);
                  setSelectType("To");
                }}
              >
                {/* <img className={styles.symbolLogo} src={require(`../assets/images/swap/logo_${swapB.image}.png`)} alt={"symbol_logo"} /> */}
                <img
                  className={styles.symbolLogo}
                  src={`${chain}/logo_${swapB.image}.png`}
                  alt={`${swapB.symbol}`}
                  onError={onErrorImg}
                />
                <span className={styles.coinName}>{swapB.symbol}</span>
                <img
                  src={require("../assets/images/swap/ico_arrow_bl.png")}
                  alt={"ico_arrow_bl"}
                />
              </div>
              <div
                className={`${styles.quantity} ${
                  toFocusActive ? styles.inputActive : ""
                } `}
              >
                <input
                  type={styles.number}
                  onFocus={() => setToFocusActive(true)}
                  onBlur={() => setToFocusActive(false)}
                  placeholder={0}
                  value={swapB.inputAmount}
                  onChange={(e) => onChangeInputAmount(e, "To")}
                />
              </div>
            </div>
            <div className={styles.balance}>
              <span>
                balance: {toLocaleStringOption(balanceInCode(swapB.haveAmount))}
              </span>
            </div>
          </div>

          <div className={styles.swapSet}>
            <div className={styles.setPrice}>
              {/* <div>Price</div>
              <div><span>$</span></div> */}
            </div>
            <div className={styles.slippage}>
              <div className={styles.flexBox}>
                <div className={styles.titleFlex}>
                  Slippage Tolerance
                  <img
                    className={styles.question}
                    onMouseEnter={() => {
                      setQuestionHover("slipPage");
                    }}
                    onMouseOut={() => {
                      setQuestionHover("");
                    }}
                    src={require("../assets/images/swap/ico_question.png")}
                    alt={"ico_question"}
                  />
                  <img
                    className={styles.setOpenBtn}
                    onClick={slipPageToggle}
                    src={require("../assets/images/swap/ico_setting_bl.png")}
                    alt={"ico_setting_bl"}
                  />
                  <div
                    className={`${styles.questionBubble} ${
                      questionHover === "slipPage" ? styles.open : ""
                    }`}
                  >
                    Your transaction will revert if the price changes
                    unfavorably by more than this percentage.
                    <div className={styles.questionArrow}></div>
                  </div>
                </div>
                <div>
                  <span className={styles.changeNum}>{slippage}</span>{" "}
                  <span>%</span>
                </div>
              </div>
              {slipPageShow ? (
                <div className={styles.transSet}>
                  <div>{/* <button onClick={onClickAuto}>AUTO</button> */}</div>
                  <div>
                    <input
                      max={1}
                      type={"text"}
                      value={slippage}
                      onChange={(e) => changeSlippage(e)}
                      onBlur={focusSlippageInput}
                    />
                    %{slippage < 0.05 ? <p>Your transaction may fail</p> : ""}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className={styles.slippage}>
              <div className={styles.flexBox}>
                <div className={styles.titleFlex}>
                  Transaction deadline
                  <img
                    className={styles.question}
                    onMouseEnter={() => {
                      setQuestionHover("transactoin");
                    }}
                    onMouseOut={() => {
                      setQuestionHover("");
                    }}
                    src={require("../assets/images/swap/ico_question.png")}
                    alt={"ico_question"}
                  />
                  <img
                    className={styles.setOpenBtn}
                    onClick={timePageToggle}
                    src={require("../assets/images/swap/ico_setting_bl.png")}
                    alt={"ico_setting_bl"}
                  />
                  <div
                    className={`${styles.questionBubble} ${
                      questionHover === "transactoin" ? styles.open : ""
                    }`}
                  >
                    Your transaction will revert if it is pending for more than
                    this long.
                    <div className={styles.questionArrow}></div>
                  </div>
                </div>
                <div>
                  <span className={styles.changeNum}>{time / 60}</span>{" "}
                  <span>Minutes</span>
                </div>
              </div>
              {timePageShow ? (
                <div className={styles.transSet}>
                  <div>{/* <button>AUTO</button> */}</div>
                  <div>
                    <input
                      max={1}
                      type={"text"}
                      value={time / 60}
                      onChange={(e) => onChangeTime(e)}
                    />
                    Minutes
                    {time / 60 == 0 ? <p>Enter a valid deadline</p> : <></>}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {soldAndReceived != "0" ? (
              <div className={styles.slippage}>
                <div className={styles.flexBox}>
                  <div className={styles.titleFlex}>
                    {soldAndReceivedMessage}
                    <img
                      className={styles.question}
                      onMouseEnter={() => {
                        setQuestionHover("minimumAndMaximum");
                      }}
                      onMouseOut={() => {
                        setQuestionHover("");
                      }}
                      src={require("../assets/images/swap/ico_question.png")}
                      alt={"ico_question"}
                    />
                    <div
                      className={`${styles.questionBubbleSoldAndReceived} ${
                        questionHover === "minimumAndMaximum" ? styles.open : ""
                      }`}
                    >
                      Your transaction will revert if there is a large,
                      unfavorable price movement before it is confirmed.
                      <div className={styles.questionArrow}></div>
                    </div>
                  </div>
                  <div>
                    {toLocaleStringOption(balanceInCode(soldAndReceived))}{" "}
                    <span>{swapSymbol}</span>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* {swapBtnStatus&&swapBtnMessage=="Insufficient liquidity for this trade"?
          <p className={styles.noneSwapText}>There are insufficient holdings available for SWAP</p>
          :<></>} */}
          <button
            disabled={swapBtnStatus}
            onClick={() => {
              if (active) {
                if (swapA.token_allowance == true) {
                  setPopupShow(true);
                  setNowModalStatus("loading");
                }
                onClickSwap();
              } else {
                handleConnect();
              }
            }}
            className={`${styles.swapBtn} ${
              swapBtnStatus ? "" : styles.btnActive
            }`}
          >
            {swapBtnMessage}
          </button>
        </div>
      </div>
      {popupShow ? (
        <div className={styles.popupWrap}>
          <div
            className={styles.popupDim}
            onClick={() => {
              setPopupShow(false);
              setSelectTokenAddress("");
              setAddTokenList([]);
            }}
          />
          <div className={styles.popupContainer}>
            <div
              className={styles.closeBtn}
              onClick={() => {
                setPopupShow(false);
                setSelectTokenAddress("");
                setAddTokenList([]);
              }}
            >
              <div className={styles.popupClose_pc}>
                <img
                  src={require("../assets/images/swap/ico_minus_bl@2x.png")}
                  alt={"ico_minus_bl"}
                />
                <img
                  src={require("../assets/images/swap/ico_minus_bl@2x.png")}
                  alt={"ico_minus_bl"}
                />
              </div>
            </div>
            {nowModalStatus === "loading" ? (
              <div className={styles.loading}>
                <div className={styles.loadingIcon}>
                  <img
                    src={require("../assets/images/swap/loading.gif")}
                    alt={"ico_minus_bl"}
                  />
                </div>
                <div className={styles.big}>Waiting for confirmation</div>
                <div className={styles.small}>
                  Confirm this transaction in your wallet
                </div>
                <div className={styles.gray}>
                  {swapBtnMessage} {balanceInCode(swapA.inputAmount)}{" "}
                  {swapA.symbol} for {balanceInCode(swapB.inputAmount)}{" "}
                  {swapB.symbol}
                </div>
              </div>
            ) : nowModalStatus === "submit" ? (
              <div className={styles.submitBox}>
                <div className={styles.confirmIcon}>
                  <img
                    src={require("../assets/images/swap/ico_confirmed.png")}
                    alt={"ico_confirmed"}
                  />
                </div>
                <div className={`${styles.big} ${styles.fw_bold}`}>
                  Transaction Submitted
                </div>
                <div
                  className={styles.margin10}
                  onClick={() => onClickExplorer()}
                  style={{ cursor: "pointer" }}
                >
                  View on Explorer{" "}
                  <img
                    src={require("../assets/images/swap/green-arrow.png")}
                    alt={"green-arrow"}
                  />
                </div>
                {/* {swapB.symbol != chain && swapB.address != WMMT_ADDRESS.toLowerCase() ? */}
                {swapB.symbol != chain ? (
                  <div
                    onClick={onClickAddTokenMetaMask}
                    style={{ cursor: "pointer" }}
                  >
                    Add{" "}
                    <span className={styles.fw_bold}>
                      &nbsp;{swapB.symbol}&nbsp;
                    </span>{" "}
                    to <span className={styles.fw_bold}>&nbsp;Metamask </span>
                    <img
                      src={require("../assets/images/swap/green-arrow.png")}
                      alt={"green-arrow"}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : nowModalStatus === "Failed" ? (
              <div className={styles.rejectedBox}>
                <div className={styles.confirmIcon}></div>
                <div className={`${styles.big} ${styles.fw_bold}`}>
                  <img
                    src={require("../assets/images/ico_rejected.png")}
                    alt={"ico-rejected"}
                  />{" "}
                  <br />
                  <p>Transaction Rejected</p>
                </div>
              </div>
            ) : nowModalStatus === "selectToken" ? (
              <div className={styles.selectTokenBox}>
                <div className={styles.selectTitle}>Select a Token</div>
                <div className={styles.inputBox}>
                  <input
                    type={"text"}
                    maxLength={42}
                    className={styles.searchInput}
                    placeholder={"Search by Name or Address"}
                    value={searchToken}
                    onChange={(e) => setSearchTokenInput(e)}
                  />
                </div>
                <div className={styles.tokenList}>
                  {tokenList == "" ? (
                    <>No results found.</>
                  ) : (
                    <>
                      {tokenList.map((item, index) => (
                        <div
                          key={index}
                          className={`${styles.tokenItem} ${
                            (selectActiveA == item.address &&
                              selectedTokenA == item.symbol) ||
                            (selectActiveB == item.address &&
                              selectedTokenB == item.symbol)
                              ? styles.active
                              : ""
                          }
                          ${
                            !(
                              (((selectActiveA == item.address &&
                                selectedTokenA == item.symbol) ||
                                (selectActiveB == item.address &&
                                  selectedTokenB == item.symbol)) &&
                                (selectType == "From"
                                  ? swapA.address
                                  : swapB.address
                                ).toLowerCase() !=
                                  selectToken.address.toLowerCase()) ||
                              item.address.toLowerCase() !=
                                selectToken.address.toLowerCase() ||
                              (selectToken.symbol == "" &&
                                selectToken.address == "")
                            )
                              ? styles.selectedActive
                              : ""
                          }
                          `}
                          onClick={() => {
                            if (
                              (selectType == "From"
                                ? swapA.address
                                : swapB.address
                              ).toLowerCase() !=
                                selectToken.address.toLowerCase() ||
                              item.address.toLowerCase() !=
                                selectToken.address.toLowerCase() ||
                              (selectToken.symbol == "" &&
                                selectToken.address == "")
                            ) {
                              onClickAddToken(item);
                            }
                          }}
                        >
                          <span
                            className={`${styles.greenCheck} ${
                              !(
                                (((selectActiveA == item.address &&
                                  selectedTokenA == item.symbol) ||
                                  (selectActiveB == item.address &&
                                    selectedTokenB == item.symbol)) &&
                                  (selectType == "From"
                                    ? swapA.address
                                    : swapB.address
                                  ).toLowerCase() !=
                                    selectToken.address.toLowerCase()) ||
                                item.address.toLowerCase() !=
                                  selectToken.address.toLowerCase() ||
                                (selectToken.symbol == "" &&
                                  selectToken.address == "")
                              )
                                ? styles.show
                                : ""
                            }`}
                          ></span>
                          {/* <img className={styles.symbolLogo} src={require(`../assets/images/swap/logo_${item.image}.png`)} alt={"symbol_logo"} /> */}
                          <img
                            className={styles.symbolLogo}
                            src={`${chain}/logo_${item.image}.png`}
                            alt={`${item.symbol}`}
                            onError={onErrorImg}
                          />
                          <span className={styles.coinName}>{item.symbol}</span>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <button
                  className={styles.addTokenBtn}
                  onClick={() => {
                    setNowModalStatus("addToken");
                    setSearchToken("");
                  }}
                >
                  Add Token
                </button>
              </div>
            ) : nowModalStatus === "addToken" ? (
              <div className={styles.addTokenBox}>
                <div className={styles.addTokenTitle}>
                  <img
                    className={styles.titleArrow}
                    onClick={() => {
                      setNowModalStatus("selectToken");
                      setSelectTokenAddress("");
                      setAddTokenList([]);
                      setAddtokenSelectMessage("");
                    }}
                    src={require("../assets/images/swap/ico_back_bl@2x.png")}
                    alt={"ico_back_bl"}
                  />
                  <span>Add Token</span>
                  <span></span>
                </div>
                {/* <div className={styles.menuTab}>
                          <div className={`${addTokenMenuActive == 'list' ? styles.active : ''}`}
                            onClick={() => {
                              setAddTokenMenuActive('list');
                              setSelectTokenAddress("");
                            }}>
                            List
                          </div>
                          <div className={`${addTokenMenuActive == 'token' ? styles.active : ''}`}
                            onClick={() => {
                              setAddTokenMenuActive('token');
                              setSelectTokenAddress("");
                            }}>
                            Token
                          </div>
                        </div> */}
                <input
                  type={"text"}
                  className={styles.searchInput}
                  placeholder={
                    addTokenMenuActive == "token"
                      ? "0x0000"
                      : "https:j// or ipfs:// or ENS name"
                  }
                  value={selectTokenAddress}
                  onChange={(e) => {
                    if (addTokenMenuActive == "token") {
                      searchTokenAddress(e);
                    }
                  }}
                />

                <div className={styles.tokenList}>
                  {addTokenMenuActive == "list" ? (
                    <></>
                  ) : (
                    <>
                      {addTokenList == "" ? (
                        <div className={`${styles.errorMessage}`}>
                          {addTokenSelectMessage}
                        </div>
                      ) : (
                        <>
                          {addTokenList.map((item, index) => (
                            <div
                              key={index}
                              className={`${styles.tokenItem} ${
                                (selectActiveA == item.address &&
                                  selectActiveA == item.address) ||
                                (selectActiveB == item.address &&
                                  selectActiveB == item.address)
                                  ? styles.active
                                  : ""
                              } ${
                                !(
                                  (((selectActiveA == item.address &&
                                    selectedTokenA == item.symbol) ||
                                    (selectActiveB == item.address &&
                                      selectedTokenB == item.symbol)) &&
                                    (selectType == "From"
                                      ? swapA.address
                                      : swapB.address
                                    ).toLowerCase() !=
                                      selectToken.address.toLowerCase()) ||
                                  item.address.toLowerCase() !=
                                    selectToken.address.toLowerCase() ||
                                  (selectToken.symbol == "" &&
                                    selectToken.address == "")
                                )
                                  ? styles.selectedActive
                                  : ""
                              }`}
                              onClick={() => {
                                if (
                                  (selectType == "From"
                                    ? swapA.address
                                    : swapB.address
                                  ).toLowerCase() !=
                                    selectToken.address.toLowerCase() ||
                                  item.address.toLowerCase() !=
                                    selectToken.address.toLowerCase() ||
                                  (selectToken.symbol == "" &&
                                    selectToken.address == "")
                                ) {
                                  clickAddTokenAndList(item);
                                }
                              }}
                            >
                              <span
                                className={`${styles.greenCheck} ${
                                  !(
                                    (((selectActiveA == item.address &&
                                      selectedTokenA == item.symbol) ||
                                      (selectActiveB == item.address &&
                                        selectedTokenB == item.symbol)) &&
                                      (selectType == "From"
                                        ? swapA.address
                                        : swapB.address
                                      ).toLowerCase() !=
                                        selectToken.address.toLowerCase()) ||
                                    item.address.toLowerCase() !=
                                      selectToken.address.toLowerCase() ||
                                    (selectToken.symbol == "" &&
                                      selectToken.address == "")
                                  )
                                    ? styles.show
                                    : ""
                                }`}
                              ></span>
                              <img
                                className={styles.symbolLogo}
                                src={`${chain}/logo_${item.image}.png`}
                                alt={`${item.symbol}`}
                                onError={onErrorImg}
                              />
                              <span className={styles.coinName}>
                                {item.symbol}
                              </span>
                            </div>
                          ))}
                        </>
                      )}
                      {myAddTokenList == "" ? (
                        ""
                      ) : (
                        <hr style={{ margin: "10px 0px 10px 0px" }} />
                      )}
                      {myAddTokenList == "" ? (
                        <></>
                      ) : (
                        <div className={styles.addMyTokenList}>
                          {myAddTokenList.map((item, index) => (
                            <div
                              key={index}
                              className={`${styles.tokenItem} ${
                                styles.closeBtnShow
                              } ${
                                (selectActiveA == item.address &&
                                  selectActiveA == item.address) ||
                                (selectActiveB == item.address &&
                                  selectActiveB == item.address)
                                  ? styles.active
                                  : ""
                              } ${
                                !(
                                  (((selectActiveA == item.address &&
                                    selectedTokenA == item.symbol) ||
                                    (selectActiveB == item.address &&
                                      selectedTokenB == item.symbol)) &&
                                    (selectType == "From"
                                      ? swapA.address
                                      : swapB.address
                                    ).toLowerCase() !=
                                      selectToken.address.toLowerCase()) ||
                                  item.address.toLowerCase() !=
                                    selectToken.address.toLowerCase() ||
                                  (selectToken.symbol == "" &&
                                    selectToken.address == "")
                                )
                                  ? styles.selectedActive
                                  : ""
                              }`}
                            >
                              <div className={styles.flexBox}>
                                <span
                                  className={`${styles.greenCheck} ${
                                    !(
                                      (((selectActiveA == item.address &&
                                        selectedTokenA == item.symbol) ||
                                        (selectActiveB == item.address &&
                                          selectedTokenB == item.symbol)) &&
                                        (selectType == "From"
                                          ? swapA.address
                                          : swapB.address
                                        ).toLowerCase() !=
                                          selectToken.address.toLowerCase()) ||
                                      item.address.toLowerCase() !=
                                        selectToken.address.toLowerCase() ||
                                      (selectToken.symbol == "" &&
                                        selectToken.address == "")
                                    )
                                      ? styles.show
                                      : ""
                                  }`}
                                ></span>
                                <img
                                  className={styles.symbolLogo}
                                  src={`${chain}/logo_${item.image}.png`}
                                  alt={`${item.symbol}`}
                                  onError={onErrorImg}
                                />
                                <span
                                  className={styles.coinName}
                                  onClick={() => {
                                    if (
                                      (selectType == "From"
                                        ? swapA.address
                                        : swapB.address
                                      ).toLowerCase() !=
                                        selectToken.address.toLowerCase() ||
                                      item.address.toLowerCase() !=
                                        selectToken.address.toLowerCase() ||
                                      (selectToken.symbol == "" &&
                                        selectToken.address == "")
                                    ) {
                                      clickAddTokenAndList(item);
                                    }
                                  }}
                                >
                                  {item.symbol}
                                </span>
                              </div>
                              <span onClick={() => onClickRemoveToken(item)}>
                                <img
                                  src={require("../assets/images/ico_close_bl.png")}
                                  alt={"ico_close"}
                                />
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Swap;
